.slider-container {
    width: 300px; /* Adjust width as needed */
    margin: 20px auto;
  }
  
  .slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 5px;
    background-color: #ddd;
    border-radius: 3px;
    outline: none;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #4CAF50;
    cursor: pointer;
  }
  
  .slider::-moz-range-thumb {
    -moz-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #4CAF50;
    cursor: pointer;
  }
  
  .slider-labels {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  .slider-labels span {
    font-size: 14px;
    color: #666;
  }