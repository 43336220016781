

.image-style-visa {
    object-fit: cover; /* Ensures the image covers the entire area without distortion */
    width: 100%;
}

@media (max-width: 768px) {
    .image-style-visa {
        max-width: 358px;
        max-height: 326px; /* Mobile */
        min-width: 100%;
        min-height: 100%;
    }
}

@media (min-width: 769px) {
    .image-style-visa {
        min-width: 358px;
        min-height: 326px; /* Large Screen */
        max-width: 100%;
        max-height: 326px;
    }
}
