.authentication-content {
  height: 100vh;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.index-card {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 60rem;
  border-radius: 20px;
  opacity: 0.92;
}

.card-container {
  text-align: left;
}

