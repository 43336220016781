/*===========================
Project Name: TripRex - Tour & Travel Agency  HTML Template
Author: Egens Lab --> (https://www.egenslab.com/)
Support: https://egenslab.desky.support/
Version: 1.0

1.COMMON CSS 
2. Mixins Css
3. Global Css
4. Topbar
5. Header
6.Home1 Banner section
7.Home1 About section
8.Home1 Facility section
9.Home1 Package Card section
10.Home1 Feature Card section
11.Home1 Package Card Tab section
12.Home1 Activities Verticle Tab section
13.Home1 Banner2 section
14.Home1 Testimonial section
15.Home1 Blog section
16.Home1 Visa section
17.Home1 Banner3 section
18.Home1 Footer section
19.Home2 banner section
20.Destination Card Slider Section
21.Tour Pack Section
22.Home2 About Section
23.Home2 Activities Section
24.Home2 banner4 Section
25.Home2 Testimonial Section
26.Home2 Teams Section
27.Home2 Video Section
28.Home2 Newsletter Section
29.Home3 banner section
30.Home3 About section
31.Home3 Destination Dropdown section
32.Home3 Tour pack section
33.Home3 Accordion With Tab section
34.Home3 Activities section
35 .Home3 Teams section
36 .Home3 Tour Type section
37 .Home3 Testimonial section
38 .Home3 Teams section
39 .Home3 Instagram Slider section
40 .Home4 Banner section
41 .Home4 destination Card Slider section
42 .Home4 about section
43 .Home4 Banner2 section
44 .Home4 Tour Pack section
45 .Home4 Activities tab With Slider section
46 .Home4 Banner With Package-card Slider section
47 .Home4 Feature-card section
48 .Home4 Visa Application section
49 .Home4 Testimonial section
50 .Home4 Blog section
51 .Home4 Activity Counter section
52 .Home5 Banner section
53 .Home5 Activity section
54 .Home5 About section
55 .Home5 Destination Card Slider section
56 .Home5 Feature section
57 .Home5 Banner2 SLider section
58 .Home5 Testimonial section
59 .Home5 Visa Application section
60 .Home5 Teams section
61 .Home5 Newsletter section
62 .Home6 Banner section
63 .Home6 Category card Slider section
64 .Home6 Destination Card Slider section
65 .Home6 About section
66 .Home6 Tour pack section
67 .Home6 Activities section
68  .Home6 Banner2 section
69  .Home6 Feature section
70 .Home6 Visa Application section
71 .Home6 Testimonial section
72 . Destination Details Page
73 . Error Page
74 . Faq Page
75 . contact Page
76 . Blog Grid Page
77 . Blog Sidebar Page
78 . Blog Standard Page
79 . Package Category Page
80 . Package Top Search Page
81 . Blog Details Page
82 . Package Grid With Sidebar Page
===========================*/
/*===========================
     01.COMMON CSS 
===========================*/
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700;800;900&amp;family=Rubik:wght@300;400;500;600;700;800;900&amp;family=Sansita&amp;family=Satisfy&amp;display=swap");
* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

:root {
  --font-rubik: "Rubik", sans-serif;
  --font-jost: "Jost", sans-serif;
  --font-satisfy: "Satisfy", sans-serif;
  --font-sansita: "Sansita", sans-serif;
  --white-color: #fff;
  --black-color: #000;
  --title-color: #100C08;
  --text-color: #787878;
  --primary-color1: #63AB45;
  --primary-color1-opc: 99, 171, 69;
  --primary-color2: #FBB03B;
  --primary-color2-opc: 251, 176, 59;
}

/*================================================
2. Mixins Css
=================================================*/
/*================================================
3. Global Css
=================================================*/
html {
  font-size: 100%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-rubik);
  color: var(--title-color);
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-rubik);
  font-weight: 600;
  line-height: 1.4;
  color: var(--title-color);
}

input {
  border: none;
  outline: none;
}

button {
  outline: none;
  border: none;
}

i.bx {
  vertical-align: middle;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
}

p {
  font-family: var(--font-jost);
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sec-mar {
  margin: 100px 0;
}
@media (max-width: 991px) {
  .sec-mar {
    margin: 8ch 0;
  }
}

.pt-120 {
  padding-top: 120px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .pt-120 {
    padding-top: 100px;
  }
}
@media (max-width: 991px) {
  .pt-120 {
    padding-top: 90px;
  }
}

.pb-120 {
  padding-bottom: 120px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .pb-120 {
    padding-bottom: 100px;
  }
}
@media (max-width: 991px) {
  .pb-120 {
    padding-bottom: 90px;
  }
}

.pt-100 {
  padding-top: 110px;
}
@media (max-width: 991px) {
  .pt-100 {
    padding-top: 80px;
  }
}

.pb-100 {
  padding-bottom: 110px;
}
@media (max-width: 991px) {
  .pb-100 {
    padding-bottom: 80px;
  }
}

.pt-90 {
  padding-top: 90px;
}
@media (max-width: 991px) {
  .pt-90 {
    padding-top: 80px;
  }
}
@media (max-width: 767px) {
  .pt-90 {
    padding-top: 70px;
  }
}

.pb-90 {
  padding-bottom: 90px;
}
@media (max-width: 991px) {
  .pb-90 {
    padding-bottom: 80px;
  }
}
@media (max-width: 767px) {
  .pb-90 {
    padding-bottom: 70px;
  }
}

.pb-80 {
  padding-bottom: 80px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .pb-80 {
    padding-bottom: 60px;
  }
}

.pb-65 {
  padding-bottom: 65px;
}

.mt-120 {
  margin-top: 120px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .mt-120 {
    margin-top: 100px;
  }
}
@media (max-width: 991px) {
  .mt-120 {
    margin-top: 90px;
  }
}

.mb-120 {
  margin-bottom: 120px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .mb-120 {
    margin-bottom: 100px;
  }
}
@media (max-width: 991px) {
  .mb-120 {
    margin-bottom: 90px;
  }
}

.mb-130 {
  margin-bottom: 130px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .mb-130 {
    margin-bottom: 100px;
  }
}
@media (max-width: 991px) {
  .mb-130 {
    margin-bottom: 90px;
  }
}

.mb-100 {
  margin-bottom: 110px;
}
@media (max-width: 991px) {
  .mb-100 {
    margin-bottom: 80px;
  }
}

.mt-100 {
  margin-top: 110px !important;
}
@media (max-width: 991px) {
  .mt-100 {
    margin-top: 80px !important;
  }
}

.mb-90 {
  margin-bottom: 90px;
}
@media (max-width: 991px) {
  .mb-90 {
    margin-bottom: 70px;
  }
}
@media (max-width: 767px) {
  .mb-90 {
    margin-bottom: 50px;
  }
}

.mb-80 {
  margin-bottom: 80px;
}
@media (max-width: 991px) {
  .mb-80 {
    margin-bottom: 70px;
  }
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-10 {
  margin-bottom: 10px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-30 {
  padding-top: 30px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pe-80 {
  padding-right: 80px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pl-110 {
  padding-left: 110px;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .pl-110 {
    padding-left: 70px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .pl-110 {
    padding-left: 40px;
  }
}
@media (max-width: 1199px) {
  .pl-110 {
    padding-left: unset;
  }
}

.mb-60 {
  margin-bottom: 60px;
}
@media (max-width: 1199px) {
  .mb-60 {
    margin-bottom: 50px;
  }
}
@media (max-width: 767px) {
  .mb-60 {
    margin-bottom: 40px;
  }
}

.mb-70 {
  margin-bottom: 70px;
}
@media (max-width: 767px) {
  .mb-70 {
    margin-bottom: 40px;
  }
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-35 {
  margin-top: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}
@media (max-width: 1199px) {
  .mb-40 {
    margin-bottom: 30px;
  }
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-25 {
  margin-top: 25px;
}

.mb-50 {
  margin-bottom: 50px;
}
@media (max-width: 767px) {
  .mb-50 {
    margin-bottom: 40px;
  }
}

.mt-60 {
  margin-top: 60px;
}
@media (max-width: 767px) {
  .mt-60 {
    margin-top: 40px;
  }
}

.mt-70 {
  margin-top: 70px;
}
@media (max-width: 991px) {
  .mt-70 {
    margin-top: 40px;
  }
}

.container.one {
  max-width: 1455px;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .container.one {
    max-width: 1370px;
  }
}
@media (max-width: 1399px) {
  .container.one {
    max-width: 1370px;
  }
}

.egns-preloader {
  position: fixed !important;
  z-index: 99999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.egns-preloader::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: -1;
}
.egns-preloader .circle-border {
  width: 100%;
  max-width: 140px;
  height: 100%;
  max-height: 140px;
  border: 0px solid var(--primary-color);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  border-radius: 50%;
  box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.1);
}
.egns-preloader .circle-border svg {
  fill: var(--primary-color1);
}
.egns-preloader .circle-border svg path {
  stroke: var(--primary-color1);
}
.egns-preloader .circle-border .moving-circle {
  width: 10px;
  height: 10px;
  background: var(--primary-color1);
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  margin: auto;
  animation: circle 2s linear infinite;
}
.egns-preloader .circle-border .moving-circle:nth-child(2) {
  width: 10px;
  height: 10px;
  background: var(--primary-color1);
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  margin: auto;
  animation: circle 2.25s linear infinite;
}
.egns-preloader .circle-border .moving-circle:nth-child(3) {
  width: 10px;
  height: 10px;
  background: var(--primary-color1);
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  margin: auto;
  animation: circle 2.5s linear infinite;
}
@keyframes circle {
  0% {
    transform: rotate(0deg) translate(-70px) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translate(-70px) rotate(-360deg);
  }
}
.egns-preloader.close {
  display: none;
  visibility: hidden;
}

.preloader-close-btn {
  position: absolute;
  right: 20px;
  top: 30px;
  font-size: 16px;
  transition: ease-in-out 0.5s linear;
  text-transform: uppercase;
  font-weight: 400;
  cursor: pointer;
}

.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: var(--primary-color1);
}
.daterangepicker .monthselect {
  outline: none;
}
.daterangepicker td.in-range {
  background-color: rgba(var(--primary-color1-opc), 0.2);
}
.daterangepicker td.active {
  background-color: var(--primary-color1);
}
.daterangepicker button {
  outline: none;
}
.daterangepicker .btn-primary {
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
}
.daterangepicker.single td.active, .daterangepicker.single td.active:hover {
  background-color: var(--primary-color1);
}
.daterangepicker.single .monthselect {
  outline: none;
}
.daterangepicker.single button {
  outline: none;
}
.daterangepicker.single .btn-primary {
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
}

.primary-btn1 {
  border-radius: 5px;
  background-color: var(--primary-color1);
  font-family: var(--font-rubik);
  font-weight: 600;
  font-size: 16px;
  color: var(--white-color);
  letter-spacing: 0.48px;
  text-transform: capitalize;
  line-height: 1;
  padding: 16px 34px;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  transition: 0.5s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  white-space: nowrap;
}
.primary-btn1 svg {
  fill: var(--white-color);
  transition: 0.5s;
}
.primary-btn1::after {
  position: absolute;
  content: "";
  display: block;
  left: 15%;
  right: -20%;
  top: -4%;
  height: 150%;
  width: 150%;
  bottom: 0;
  border-radius: 2px;
  background-color: var(--primary-color2);
  transform: skewX(45deg) scale(0, 1);
  z-index: -1;
  transition: all 0.5s ease-out 0s;
}
.primary-btn1:hover {
  color: var(--title-color);
}
.primary-btn1:hover svg {
  fill: var(--white-color);
}
.primary-btn1:hover::after {
  transform: skewX(45deg) scale(1, 1);
}
.primary-btn1.two {
  border-radius: unset;
}

.primary-btn2 {
  border-radius: 5px;
  background-color: var(--primary-color1);
  font-family: var(--font-rubik);
  font-weight: 500;
  font-size: 14px;
  color: var(--white-color);
  letter-spacing: 0.48px;
  text-transform: capitalize;
  line-height: 1;
  padding: 11px 16px;
  display: inline-flex;
  align-items: center;
  gap: 7px;
  transition: 0.5s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  white-space: nowrap;
}
.primary-btn2 svg {
  fill: var(--white-color);
  transition: 0.5s;
}
.primary-btn2::after {
  position: absolute;
  content: "";
  display: block;
  left: 15%;
  right: -20%;
  top: -4%;
  height: 150%;
  width: 150%;
  bottom: 0;
  border-radius: 2px;
  background-color: #0A1019;
  transform: skewX(45deg) scale(0, 1);
  z-index: -1;
  transition: all 0.5s ease-out 0s;
}
.primary-btn2:hover {
  color: var(--white-color);
}
.primary-btn2:hover svg {
  fill: var(--white-color);
}
.primary-btn2:hover::after {
  transform: skewX(45deg) scale(1, 1);
}
.primary-btn2.two {
  border-radius: unset;
}

.primary-btn3 {
  border-radius: 35px;
  background-color: var(--primary-color1);
  font-family: var(--font-rubik);
  font-weight: 600;
  font-size: 16px;
  color: var(--white-color);
  letter-spacing: 0.48px;
  text-transform: capitalize;
  line-height: 1;
  padding: 15px 34px;
  display: inline-flex;
  align-items: center;
  gap: 7px;
  transition: 0.5s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  white-space: nowrap;
  border-bottom: 2px solid #4A8631;
}
.primary-btn3::after {
  position: absolute;
  content: "";
  border-radius: 30px;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background-color: var(--title-color);
  transform-origin: right;
  transform: scalex(0);
  z-index: -1;
  transition: transform 0.7s;
}
.primary-btn3:hover {
  color: var(--white-color);
}
.primary-btn3:hover::after {
  transform: scalex(1);
  transform-origin: left;
}

.primary-btn4 {
  padding: 5px;
  border: 1px dashed var(--primary-color1);
  border-radius: 2px;
  display: inline-block;
}
.primary-btn4 span {
  border-radius: 5px;
  background-color: var(--primary-color1);
  font-family: var(--font-rubik);
  font-weight: 600;
  font-size: 18px;
  color: var(--white-color);
  letter-spacing: 0.48px;
  text-transform: capitalize;
  line-height: 1;
  padding: 17px 41px;
  display: inline-flex;
  align-items: center;
  gap: 7px;
  transition: 0.5s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  white-space: nowrap;
}
.primary-btn4 span svg {
  fill: var(--white-color);
  transition: 0.5s;
}
.primary-btn4 span::after {
  position: absolute;
  content: "";
  display: block;
  left: 15%;
  right: -20%;
  top: -4%;
  height: 150%;
  width: 150%;
  bottom: 0;
  border-radius: 2px;
  background-color: #0A1019;
  transform: skewX(45deg) scale(0, 1);
  z-index: -1;
  transition: all 0.5s ease-out 0s;
}
.primary-btn4:hover span {
  color: var(--white-color);
}
.primary-btn4:hover span svg {
  fill: var(--white-color);
}
.primary-btn4:hover span::after {
  transform: skewX(45deg) scale(1, 1);
}
.primary-btn4.two span {
  padding: 17px 22px;
}

.primary-btn5 {
  -webkit-mask-image: url(../img/home5/vector/primary-btn5-bg.svg);
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: cover;
  background-color: var(--primary-color1);
  padding: 11px 17px;
  white-space: nowrap;
  display: inline-flex;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: 0.5s;
}
.primary-btn5 span {
  display: inline-flex;
  align-items: center;
  gap: 7px;
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  text-transform: capitalize;
  transition: 0.5s;
}
.primary-btn5 span svg {
  fill: var(--white-color);
  transition: 0.5s;
}
.primary-btn5 ::after {
  content: "";
  position: absolute;
  z-index: -1;
  background: var(--primary-color2);
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  transform-origin: right;
  transform: scalex(0);
  transition: transform 0.5s;
}
.primary-btn5:hover ::after {
  transform: scalex(1);
  transform-origin: left;
}
.primary-btn5:hover span {
  color: var(--title-color);
}
.primary-btn5:hover span svg {
  fill: var(--title-color);
}

.secondary-btn1 {
  border-radius: 30px;
  background-color: var(--primary-color2);
  font-family: var(--font-rubik);
  font-weight: 500;
  font-size: 16px;
  color: var(--title-color);
  letter-spacing: 0.48px;
  text-transform: capitalize;
  line-height: 1;
  padding: 17px 31px;
  display: inline-flex;
  align-items: center;
  gap: 7px;
  transition: 0.5s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  white-space: nowrap;
}
.secondary-btn1::after {
  position: absolute;
  content: "";
  border-radius: 30px;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background-color: var(--title-color);
  transform-origin: right;
  transform: scalex(0);
  z-index: -1;
  transition: transform 0.7s;
}
.secondary-btn1:hover {
  color: var(--white-color);
}
.secondary-btn1:hover::after {
  transform: scalex(1);
  transform-origin: left;
}

.secondary-btn2 {
  border-radius: 35px;
  background-color: var(--primary-color2);
  font-family: var(--font-rubik);
  font-weight: 600;
  font-size: 16px;
  color: var(--title-color);
  letter-spacing: 0.48px;
  text-transform: capitalize;
  line-height: 1;
  padding: 14px 26px;
  display: inline-flex;
  align-items: center;
  gap: 7px;
  transition: 0.5s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  white-space: nowrap;
  border-bottom: 2px solid #A67C3B;
}
.secondary-btn2::after {
  position: absolute;
  content: "";
  border-radius: 30px;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background-color: var(--title-color);
  transform-origin: right;
  transform: scalex(0);
  z-index: -1;
  transition: transform 0.7s;
}
.secondary-btn2:hover {
  color: var(--white-color);
}
.secondary-btn2:hover::after {
  transform: scalex(1);
  transform-origin: left;
}

.secondary-btn3 {
  -webkit-mask-image: url(../img/home5/vector/secondary-btn3-bg.svg);
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: cover;
  background-color: var(--primary-color2);
  padding: 17px 30px;
  white-space: nowrap;
  display: inline-flex;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: 0.5s;
}
.secondary-btn3 span {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  text-transform: capitalize;
  transition: 0.5s;
}
.secondary-btn3 ::after {
  content: "";
  position: absolute;
  z-index: -1;
  background: var(--primary-color1);
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  transform-origin: right;
  transform: scalex(0);
  transition: transform 0.5s;
}
.secondary-btn3:hover ::after {
  transform: scalex(1);
  transform-origin: left;
}
.secondary-btn3:hover span {
  color: var(--white-color);
}

.secondary-btn4 {
  background-color: var(--primary-color2);
  font-family: var(--font-rubik);
  font-weight: 600;
  font-size: 16px;
  color: var(--title-color);
  letter-spacing: 0.48px;
  text-transform: capitalize;
  line-height: 1;
  padding: 18px 26px;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  transition: 0.5s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  white-space: nowrap;
}
.secondary-btn4 svg {
  fill: var(--white-color);
  transition: 0.5s;
}
.secondary-btn4::after {
  position: absolute;
  content: "";
  display: block;
  left: 15%;
  right: -20%;
  top: -4%;
  height: 150%;
  width: 150%;
  bottom: 0;
  border-radius: 2px;
  background-color: var(--primary-color1);
  transform: skewX(45deg) scale(0, 1);
  z-index: -1;
  transition: all 0.5s ease-out 0s;
}
.secondary-btn4:hover {
  color: var(--white-color);
}
.secondary-btn4:hover svg {
  fill: var(--white-color);
}
.secondary-btn4:hover::after {
  transform: skewX(45deg) scale(1, 1);
}
.secondary-btn4.two {
  border-radius: 5px;
}

.select {
  margin-bottom: 25px;
}

.nice-select {
  width: 100%;
  background: transparent;
  border-radius: 5px;
  height: 50px;
  display: flex;
  align-items: center;
  line-height: 1;
  padding: 0px 25px;
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid #F3F3F3;
}
.nice-select::after {
  height: 8px;
  width: 8px;
  border-bottom: 1px solid var(--text-color);
  border-right: 1px solid var(--text-color);
  right: 28px;
  margin-top: -5px;
}
.nice-select .current {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 14px;
  font-weight: 400;
}
.nice-select:hover {
  border-color: #F3F3F3;
  background: #F3F3F3;
}
.nice-select:focus {
  border-color: var(--primary-color1);
  background-color: var(--white-color);
}
.nice-select .list {
  border: none;
  border-radius: 5px;
  width: 100%;
  max-height: 250px;
  overflow-y: auto;
}
.nice-select .list::-webkit-scrollbar {
  width: 5px;
}
.nice-select .list::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.nice-select .list::-webkit-scrollbar-thumb {
  background: var(--text-color);
}
.nice-select .list .option {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 13px;
  font-weight: 500;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.nice-select .list .option:hover {
  color: var(--title-color);
  background-color: var(--primary-color1);
  font-weight: 500;
}
.nice-select .list .option.selected {
  background-color: var(--primary-color1);
  color: var(--title-color);
}

.slider-btn-grp .slider-btn {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 1px solid var(--white-color);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.5s;
}
.slider-btn-grp .slider-btn i {
  color: var(--white-color);
  transition: 0.5s;
}
.slider-btn-grp .slider-btn:hover {
  background-color: var(--primary-color1);
  border: 1px solid var(--primary-color1);
}
.slider-btn-grp .slider-btn.swiper-button-disabled {
  opacity: 0.2;
}
.slider-btn-grp.two {
  display: flex;
  align-items: center;
  gap: 40px;
}
.slider-btn-grp.two .slider-btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid var(--title-color);
  transition: 0.5s;
}
.slider-btn-grp.two .slider-btn i {
  color: var(--title-color);
  transition: 0.5s;
}
.slider-btn-grp.two .slider-btn:hover {
  background-color: var(--primary-color1);
  border: 1px solid var(--primary-color1);
}
.slider-btn-grp.two .slider-btn:hover i {
  color: var(--white-color);
}
.slider-btn-grp.two .slider-btn.swiper-button-disabled {
  opacity: 0.2;
}

.slider-btn-grp2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
}
@media (max-width: 991px) {
  .slider-btn-grp2 {
    gap: 30px;
  }
}
.slider-btn-grp2 .slider-btn {
  min-width: 35px;
  max-width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid var(--primary-color1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.5s;
}
.slider-btn-grp2 .slider-btn svg {
  fill: var(--primary-color1);
}
.slider-btn-grp2 .slider-btn:hover {
  background-color: var(--primary-color1);
}
.slider-btn-grp2 .slider-btn:hover svg {
  fill: var(--white-color);
}
.slider-btn-grp2 .slider-btn.swiper-button-disabled {
  opacity: 0.2;
}

.slider-btn-grp4 .slider-btn {
  width: 57px;
  height: 40px;
  border-radius: 300px;
  border: 1px dashed var(--primary-color1);
  background-color: var(--white-color);
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.5s;
}
.slider-btn-grp4 .slider-btn svg {
  fill: var(--primary-color1);
  transition: 0.5s;
}
.slider-btn-grp4 .slider-btn:hover {
  background-color: var(--primary-color1);
}
.slider-btn-grp4 .slider-btn:hover svg {
  fill: var(--white-color);
}
.slider-btn-grp4 .slider-btn.swiper-button-disabled {
  opacity: 0.4;
}

.slider-btn-grp5 {
  display: flex;
  align-items: center;
  gap: 50px;
}
.slider-btn-grp5 .slider-btn {
  cursor: pointer;
  transition: 0.5s;
}
.slider-btn-grp5 .slider-btn svg {
  fill: none;
  stroke: var(--primary-color1);
  transition: 0.5s;
}
.slider-btn-grp5 .slider-btn:hover svg {
  stroke: var(--primary-color2);
}
.slider-btn-grp5 .slider-btn.swiper-button-disabled {
  opacity: 0.2;
}
.slider-btn-grp5 .slider-btn.swiper-button-disabled:hover svg {
  stroke: var(--primary-color1);
}

.slider-btn-grp6 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
}
@media (max-width: 576px) {
  .slider-btn-grp6 {
    justify-content: center;
  }
}
.slider-btn-grp6 .slider-btn {
  cursor: pointer;
  gap: 10px;
}
.slider-btn-grp6 .slider-btn svg {
  fill: none;
  stroke: var(--title-color);
  transition: 0.5s;
}
.slider-btn-grp6 .slider-btn span {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  text-transform: capitalize;
  display: inline-block;
  transition: 0.5s;
}
.slider-btn-grp6 .slider-btn:hover svg {
  stroke: var(--primary-color1);
}
.slider-btn-grp6 .slider-btn:hover span {
  color: var(--primary-color1);
}
.slider-btn-grp6 .slider-btn.swiper-button-disabled {
  opacity: 0.2;
}
.slider-btn-grp6 .franctional-slider-pagi1 {
  width: unset;
  display: flex;
  gap: 12px;
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  text-transform: capitalize;
}

.section-title span {
  color: var(--primary-color1);
  font-family: var(--font-satisfy);
  font-size: 20px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.4px;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}
.section-title span svg {
  fill: var(--primary-color1);
}
.section-title h2 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 48px;
  font-weight: 600;
  line-height: 1.1;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .section-title h2 {
    font-size: 35px;
  }
}
@media (max-width: 576px) {
  .section-title h2 {
    font-size: 30px;
  }
}

.section-title2 .eg-section-tag {
  -webkit-mask-image: url(../img/home2/vector/section-title-tag.svg);
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: cover;
  background-color: rgba(var(--primary-color1-opc), 0.3);
  padding: 12px 40px;
  white-space: nowrap;
  display: inline-flex;
  margin-bottom: 3px;
}
.section-title2 .eg-section-tag span {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  color: var(--primary-color1);
  font-family: var(--font-satisfy);
  font-size: 17px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.51px;
}
.section-title2 .eg-section-tag.two {
  background-color: rgba(var(--primary-color2-opc), 0.3);
  padding: 15px 40px;
}
.section-title2 .eg-section-tag.two span {
  color: var(--primary-color2);
}
.section-title2 h2 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 45px;
  font-weight: 600;
  line-height: 1.1;
  margin-bottom: 0;
}
@media (max-width: 991px) {
  .section-title2 h2 {
    font-size: 40px;
  }
}
@media (max-width: 576px) {
  .section-title2 h2 {
    font-size: 32px;
  }
}
.section-title2 p {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 17px;
  font-weight: 400;
  line-height: 1.9; /* 205.882% */
  letter-spacing: 0.51px;
  margin-bottom: 0;
  padding-top: 30px;
}
.section-title2.two {
  max-width: 705px;
  width: 100%;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .section-title2.two {
    max-width: 550px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .section-title2.two {
    max-width: 450px;
  }
}
.section-title2.two h2 {
  font-size: 50px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .section-title2.two h2 {
    font-size: 48px;
  }
}
@media (max-width: 991px) {
  .section-title2.two h2 {
    font-size: 45px;
  }
}
@media (max-width: 576px) {
  .section-title2.two h2 {
    font-size: 32px;
  }
}
.section-title2.two p {
  padding-top: 10px;
}
@media (max-width: 1199px) {
  .section-title2.two p {
    font-size: 15px;
  }
}
@media (max-width: 576px) {
  .section-title2.two p {
    font-size: 14px;
  }
}

.section-title3 {
  max-width: 705px;
  width: 100%;
}
.section-title3 h2 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 38px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 0;
  position: relative;
  display: inline-block;
}
.section-title3 h2::after {
  content: "";
  position: absolute;
  bottom: 5px;
  left: 0;
  background-color: rgba(var(--primary-color1-opc), 0.15);
  border-radius: 20px;
  width: 100%;
  height: 15px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .section-title3 h2 {
    font-size: 36px;
  }
}
@media (max-width: 576px) {
  .section-title3 h2 {
    font-size: 27px;
  }
}
.section-title3 p {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 17px;
  font-weight: 400;
  line-height: 1.8;
  letter-spacing: 0.51px;
  margin-bottom: 0;
  padding-top: 15px;
}
@media (max-width: 576px) {
  .section-title3 p {
    font-size: 14px;
    padding-top: 10px;
  }
}
.section-title3.two {
  max-width: 570px;
}

.section-title4 .eg-section-tag {
  -webkit-mask-image: url(../img/home5/vector/section-title4-tag-bg.svg);
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: cover;
  background-color: var(--primary-color1);
  padding: 12px 25px;
  white-space: nowrap;
  display: inline-flex;
  margin-bottom: 10px;
}
.section-title4 .eg-section-tag span {
  color: var(--white-color);
  font-family: var(--font-jost);
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.45px;
  text-transform: uppercase;
}
@media (max-width: 576px) {
  .section-title4 .eg-section-tag span {
    font-size: 13px;
  }
}
.section-title4 h2 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 45px;
  font-weight: 600;
  line-height: 1.1;
  margin-bottom: 0;
}
@media (max-width: 991px) {
  .section-title4 h2 {
    font-size: 40px;
  }
}
@media (max-width: 576px) {
  .section-title4 h2 {
    font-size: 32px;
  }
}
.section-title4 p {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 17px;
  font-weight: 400;
  line-height: 1.9; /* 205.882% */
  letter-spacing: 0.51px;
  margin-bottom: 0;
  padding-top: 30px;
}
@media (max-width: 1399px) {
  .section-title4 p {
    padding-top: 20px;
  }
}
@media (max-width: 576px) {
  .section-title4 p {
    font-size: 15px;
    padding-top: 15px;
  }
}

.section-title5 span {
  color: var(--primary-color1);
  font-family: var(--font-satisfy);
  font-size: 21px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 1.05px;
  text-transform: capitalize;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}
.section-title5 span svg {
  fill: var(--primary-color1);
}
@media (max-width: 576px) {
  .section-title5 span {
    font-size: 18px;
  }
}
.section-title5 h2 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 45px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.9px;
  text-transform: capitalize;
  margin-bottom: 0;
}
@media (max-width: 991px) {
  .section-title5 h2 {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .section-title5 h2 {
    font-size: 30px;
  }
}
@media (max-width: 576px) {
  .section-title5 h2 {
    font-size: 28px;
  }
}
.section-title5 p {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 17px;
  font-weight: 400;
  line-height: 1.9; /* 205.882% */
  letter-spacing: 0.51px;
  margin-bottom: 0;
  padding-top: 30px;
}
@media (max-width: 991px) {
  .section-title5 p {
    font-size: 16px;
    padding-top: 20px;
  }
}
@media (max-width: 576px) {
  .section-title5 p {
    font-size: 15px;
    padding-top: 15px;
  }
}

/*=====================================
    4. Topbar
========================================*/
.top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  padding: 12px 12%;
  background-color: #ECE4D7;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .top-bar {
    padding: 12px 3%;
  }
}
@media (max-width: 1399px) {
  .top-bar {
    padding: 12px 3%;
  }
}
@media (max-width: 991px) {
  .top-bar {
    display: none;
    visibility: hidden;
  }
}
.top-bar .topbar-left {
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: 235px;
  width: 100%;
  position: relative;
}
.top-bar .topbar-left::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: rgba(16, 12, 8, 0.2);
  width: 1px;
  height: 28px;
}
.top-bar .topbar-left .icon svg {
  fill: var(--primary-color1);
}
.top-bar .topbar-left .content {
  line-height: 1;
}
.top-bar .topbar-left .content > span {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 11px;
  font-weight: 500;
  line-height: 1;
  display: block;
  margin-bottom: 4px;
}
.top-bar .topbar-left .content p {
  color: var(--primary-color1);
  font-family: var(--font-jost);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.4;
  margin-bottom: 0;
}
.top-bar .topbar-left.two .content span {
  color: var(--white-color);
  font-size: 12px;
}
.top-bar .topbar-left.two .content a {
  color: var(--primary-color1);
  font-family: var(--font-rubik);
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  transition: 0.5s;
}
.top-bar .topbar-left.two .content a:hover {
  color: var(--primary-color2);
}
.top-bar p {
  color: var(--title-color);
  font-family: var(--font-jost);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.28px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.top-bar p a {
  color: var(--primary-color1);
  text-decoration: underline;
  transition: 0.5s;
}
.top-bar p a:hover {
  color: var(--primary-color2);
}
.top-bar .topbar-right {
  max-width: 235px;
  width: 100%;
  position: relative;
}
.top-bar .topbar-right::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background: rgba(16, 12, 8, 0.2);
  width: 1px;
  height: 28px;
}
.top-bar .topbar-right .social-icon-area ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}
.top-bar .topbar-right .social-icon-area ul li a {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid var(--title-color);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--title-color);
  transition: 0.5s;
}
.top-bar .topbar-right .social-icon-area ul li a i {
  font-size: 14px;
}
.top-bar .topbar-right .social-icon-area ul li:hover a {
  border: 1px solid var(--primary-color2);
  color: var(--primary-color2);
}
.top-bar.style-2 {
  background-color: #222;
  padding: 9px 14.5%;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .top-bar.style-2 {
    padding: 9px 8%;
  }
}
@media (max-width: 1399px) {
  .top-bar.style-2 {
    padding: 9px 8%;
  }
}
@media (max-width: 1199px) {
  .top-bar.style-2 {
    padding: 9px 6%;
  }
}
.top-bar.style-2 .topbar-left .rating-area {
  display: flex;
  align-items: center;
  gap: 5px;
}
.top-bar.style-2 .topbar-left .rating-area .content .text-logo {
  display: flex;
  margin-bottom: 5px;
}
.top-bar.style-2 .topbar-left .rating-area .content .text-logo svg {
  fill: var(--white-color);
}
.top-bar.style-2 .topbar-left .rating-area .content .rating {
  display: flex;
  align-items: center;
  gap: 10px;
  line-height: 1;
}
.top-bar.style-2 .topbar-left .rating-area .content .rating ul {
  display: flex;
  align-items: center;
  gap: 5px;
}
.top-bar.style-2 .topbar-left .rating-area .content .rating ul li i {
  color: #00AA6C;
  font-size: 10px;
}
.top-bar.style-2 .topbar-left .rating-area .content .rating span {
  color: var(--white-color);
  font-size: 12px;
}
.top-bar.style-2 .topbar-left::after {
  background: rgba(255, 255, 255, 0.2);
}
.top-bar.style-2 p {
  color: var(--white-color);
}
.top-bar.style-2 .topbar-right::after {
  background: rgba(255, 255, 255, 0.2);
}
.top-bar.style-2 .topbar-right .social-icon-area ul li a {
  border: 1px solid var(--white-color);
  color: var(--white-color);
  transition: 0.5s;
}
.top-bar.style-2 .topbar-right .social-icon-area ul li:hover a {
  background-color: var(--primary-color1);
  color: var(--white-color);
  border-color: var(--primary-color1);
}

/*=====================================
     5. Header
========================================*/
@keyframes fade-down {
  0% {
    opacity: 0;
    transform: scaleY(0);
    visibility: hidden;
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
    visibility: visible;
  }
}
header.style-1 {
  position: relative;
  width: 100%;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 12%;
  transition: all 0.8s ease-out 0s;
  background-color: var(--white-color);
}
@media (min-width: 1400px) and (max-width: 1599px) {
  header.style-1 {
    padding: 0px 3%;
  }
}
@media (max-width: 1399px) {
  header.style-1 {
    padding: 0px 3%;
  }
}
header.style-1.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  box-sizing: border-box;
  background-color: var(--white-color);
  box-shadow: 5px 3px 40px rgba(0, 72, 88, 0.1);
  z-index: 999;
}
@keyframes smooth-header {
  0% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0px);
  }
}
header.style-1 .header-logo {
  padding: 15px 0;
}
@media (max-width: 576px) {
  header.style-1 .header-logo img {
    max-width: 165px;
    width: 100%;
  }
}
header.style-1 .menu-close-btn i {
  color: var(--white-color);
}
header.style-1 .nav-right {
  gap: 50px;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  header.style-1 .nav-right {
    gap: 35px;
  }
}
@media (max-width: 1199px) {
  header.style-1 .nav-right {
    gap: 25px;
  }
}
@media (max-width: 991px) {
  header.style-1 .nav-right {
    gap: 10px;
  }
}
header.style-1 .nav-right .modal-btn {
  font-family: var(--font-rubik);
  font-weight: 600;
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--title-color);
  background-color: transparent;
  padding: 0;
  transition: 0.35s;
}
header.style-1 .nav-right .modal-btn svg {
  line-height: 1;
  transition: 0.35s;
  fill: var(--title-color);
}
header.style-1 .nav-right .modal-btn:hover {
  color: var(--primary-color1);
}
header.style-1 .nav-right .modal-btn:hover svg {
  fill: var(--primary-color1);
}
header.style-1 .nav-right .primary-btn3 {
  padding: 13px 18px;
  gap: 5px;
}
header.style-1 .nav-right .icon-list {
  display: flex;
  align-items: center;
  gap: 30px;
}
header.style-1 .nav-right .icon-list li {
  cursor: pointer;
}
header.style-1 .nav-right .icon-list li svg {
  stroke: var(--title-color);
}
header.style-1 .nav-right .icon-list li:last-child svg {
  fill: var(--title-color);
  stroke: none;
}
header.style-1 .nav-right .hotline-area {
  display: flex;
  align-items: center;
  gap: 20px;
}
header.style-1 .nav-right .hotline-area .icon svg {
  fill: var(--primary-color1);
}
header.style-1 .nav-right .hotline-area .content {
  position: relative;
  line-height: 1;
}
header.style-1 .nav-right .hotline-area .content::after {
  content: "";
  height: 35px;
  width: 1px;
  background-color: rgba(16, 12, 8, 0.2);
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}
header.style-1 .nav-right .hotline-area .content span {
  font-family: var(--font-rubik);
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: var(--title-color);
  margin-bottom: 5px;
  display: inline-block;
}
header.style-1 .nav-right .hotline-area .content h6 {
  margin-bottom: 0;
  line-height: 1;
}
header.style-1 .nav-right .hotline-area .content h6 a {
  font-family: var(--font-rubik);
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: var(--primary-color1);
  transition: 0.5s;
}
header.style-1 .nav-right .hotline-area .content h6 a:hover {
  color: var(--title-color);
}
header.style-1 .nav-right .mobile-menu-btn {
  display: none;
  visibility: hidden;
}
@media (max-width: 991px) {
  header.style-1 .nav-right .mobile-menu-btn {
    display: flex;
    margin-left: 20px;
    flex-direction: column;
    align-items: end;
    visibility: visible;
    justify-content: center;
    position: relative;
  }
}
@media (max-width: 576px) {
  header.style-1 .nav-right .mobile-menu-btn {
    margin-left: 10px;
  }
}
header.style-1 .nav-right .mobile-menu-btn svg {
  fill: var(--title-color);
}
header.style-1 .main-menu {
  display: inline-block;
  position: relative;
}
header.style-1 .main-menu .mobile-menu-logo {
  display: none;
}
header.style-1 .main-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
header.style-1 .main-menu ul > li {
  display: inline-block;
  position: relative;
  padding: 0 8px;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  header.style-1 .main-menu ul > li {
    padding: 0 5px;
  }
}
header.style-1 .main-menu ul > li.position-inherit {
  position: inherit;
}
@media (max-width: 991px) {
  header.style-1 .main-menu ul > li.position-inherit {
    position: relative;
  }
}
header.style-1 .main-menu ul > li.position-inherit > a::after {
  content: "\f282";
  font-family: "bootstrap-icons";
  font-weight: 500;
  position: absolute;
  top: 15px;
  right: 2px;
  font-size: 14px;
  color: var(--text-color);
  transition: all 0.55s ease-in-out;
}
@media (max-width: 1199px) {
  header.style-1 .main-menu ul > li.position-inherit > a::after {
    right: -7px;
  }
}
@media (max-width: 991px) {
  header.style-1 .main-menu ul > li.position-inherit > a::after {
    display: none;
    visibility: hidden;
  }
}
header.style-1 .main-menu ul > li:hover i {
  color: var(--black-color2);
  font-size: 20px;
}
@media (min-width: 992px) {
  header.style-1 .main-menu ul > li:first-child {
    padding-left: 0;
  }
  header.style-1 .main-menu ul > li:first-child a {
    padding-left: 0;
  }
}
header.style-1 .main-menu ul > li a {
  color: var(--title-color);
  display: block;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  padding: 25px 15px;
  position: relative;
  font-family: var(--font-rubik);
  font-weight: 400;
  font-size: 15px;
  transition: all 0.5s ease-out 0s;
  position: relative;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  header.style-1 .main-menu ul > li a {
    padding: 15px;
  }
}
@media (max-width: 1199px) {
  header.style-1 .main-menu ul > li a {
    padding: 15px 10px;
  }
}
@media (max-width: 991px) {
  header.style-1 .main-menu ul > li a {
    padding: 32px 9px;
  }
}
header.style-1 .main-menu ul > li i {
  font-size: 20px;
  text-align: center;
  color: var(--title-color);
  font-style: normal;
  position: absolute;
  right: -5px;
  top: 35px;
  z-index: 999;
  cursor: pointer;
  display: none;
  transition: all 0.5s ease-out 0s;
  opacity: 0;
}
@media (max-width: 991px) {
  header.style-1 .main-menu ul > li i {
    opacity: 1;
  }
}
header.style-1 .main-menu ul > li i.active {
  color: var(--primary-color1);
}
header.style-1 .main-menu ul > li i.active::before {
  content: "\f2ea";
}
header.style-1 .main-menu ul > li ul.sub-menu {
  position: absolute;
  left: 0;
  right: 0;
  top: auto;
  margin: 0;
  display: none;
  min-width: 220px;
  background: var(--white-color);
  box-shadow: 0px 30px 80px rgba(8, 0, 42, 0.08);
  text-align: left;
  transform-origin: top;
}
@media (max-width: 1199px) {
  header.style-1 .main-menu ul > li ul.sub-menu {
    box-shadow: none;
  }
}
header.style-1 .main-menu ul > li ul.sub-menu > li {
  padding: 0;
  display: block;
  position: relative;
}
header.style-1 .main-menu ul > li ul.sub-menu > li i {
  position: absolute;
  top: 16px;
  right: 6px;
  display: block;
  color: var(--title-color);
  font-size: 14px;
}
header.style-1 .main-menu ul > li ul.sub-menu > li .dropdown-icon {
  color: var(--title-color);
  opacity: 1;
  top: 16px;
  font-size: 14px;
  right: 10px;
}
@media (max-width: 991px) {
  header.style-1 .main-menu ul > li ul.sub-menu > li .dropdown-icon {
    right: 0;
    top: 8px;
    font-size: 20px;
  }
}
header.style-1 .main-menu ul > li ul.sub-menu > li a {
  display: block;
  padding: 15px 15px;
  color: var(--title-color);
  font-weight: 400;
  font-family: var(--font-rubik);
  text-transform: uppercase;
  font-size: 13px;
  line-height: 1.3;
  transition: all 0.4s ease-out 0s;
  position: relative;
  border-bottom: 1px solid rgba(233, 228, 228, 0.5);
}
header.style-1 .main-menu ul > li ul.sub-menu > li a::before {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  background: linear-gradient(90deg, #fff, var(--primary-color1-opc), #fff);
  width: 100%;
  height: 1px;
  transform: scaleX(0);
  transform-origin: left;
  transition: 0.4s ease-in;
  z-index: 1;
}
@media (max-width: 991px) {
  header.style-1 .main-menu ul > li ul.sub-menu > li a {
    border-color: rgba(233, 228, 228, 0.5);
  }
  header.style-1 .main-menu ul > li ul.sub-menu > li a::before {
    background: linear-gradient(90deg, #ddd, #EFB93F, #ddd);
  }
}
header.style-1 .main-menu ul > li ul.sub-menu > li a:hover {
  color: var(--primary-color1);
}
header.style-1 .main-menu ul > li ul.sub-menu > li a:hover::before {
  transform: scale(1);
}
header.style-1 .main-menu ul > li ul.sub-menu > li a.active {
  color: var(--primary-color1);
}
header.style-1 .main-menu ul > li ul.sub-menu > li a.active::before {
  transform: scale(1);
}
header.style-1 .main-menu ul > li ul.sub-menu > li:last-child a {
  border-bottom: none;
}
header.style-1 .main-menu ul > li ul.sub-menu > li:last-child a::before {
  display: none;
}
header.style-1 .main-menu ul > li ul.sub-menu > li:hover > a {
  color: var(--primary-color1);
}
header.style-1 .main-menu ul > li ul.sub-menu > li:hover .dropdown-icon {
  color: var(--primary-color1);
}
header.style-1 .main-menu ul > li ul.sub-menu > li.active > a {
  color: var(--primary-color1);
}
header.style-1 .main-menu ul > li ul.sub-menu > li.active .dropdown-icon {
  color: var(--primary-color1);
}
header.style-1 .main-menu ul > li ul.sub-menu > li .sub-menu {
  left: 220px;
  position: absolute;
  max-width: 230px;
  min-width: 215px;
  background: var(--white-color);
  box-shadow: 0px 30px 80px rgba(8, 0, 42, 0.08);
  top: 0;
}
@media (max-width: 1199px) {
  header.style-1 .main-menu ul > li ul.sub-menu > li .sub-menu {
    box-shadow: none;
  }
}
header.style-1 .main-menu ul > li ul.sub-menu > li .sub-menu::before {
  display: none;
  visibility: hidden;
}
@media only screen and (max-width: 991px) {
  header.style-1 .main-menu ul > li ul.sub-menu > li .sub-menu {
    position: unset;
    max-width: 230px;
    min-width: 215px;
    background: transparent;
    top: 0;
  }
}
header.style-1 .main-menu ul > li ul.sub-menu > li .sub-menu > li i {
  display: block;
}
header.style-1 .main-menu ul > li ul.sub-menu > li:last-child {
  border-bottom: none;
}
header.style-1 .main-menu ul > li.menu-item-has-children {
  transition: all 0.55s ease-in-out;
}
header.style-1 .main-menu ul > li.menu-item-has-children::after {
  content: "\f4fe";
  font-family: "bootstrap-icons";
  font-weight: 500;
  position: absolute;
  top: 25px;
  right: 5px;
  font-size: 16px;
  color: var(--title-color);
  transition: all 0.55s ease-in-out;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  header.style-1 .main-menu ul > li.menu-item-has-children::after {
    right: 4px;
    top: 15px;
  }
}
@media (max-width: 1199px) {
  header.style-1 .main-menu ul > li.menu-item-has-children::after {
    right: 2px;
    top: 15px;
  }
}
@media (max-width: 991px) {
  header.style-1 .main-menu ul > li.menu-item-has-children::after {
    display: none;
    visibility: hidden;
  }
}
header.style-1 .main-menu ul > li.menu-item-has-children:hover::after {
  content: "\f2ea";
}
@media (min-width: 992px) {
  header.style-1 .main-menu ul > li:hover > ul.sub-menu {
    display: block;
    animation: fade-down 0.45s linear;
  }
}
@media (min-width: 992px) {
  header.style-1 .main-menu ul > li:hover .mega-menu {
    display: block;
    animation: fade-down 0.45s linear;
  }
}
@keyframes fade-up {
  0% {
    opacity: 0;
    transform: scaleY(0);
    visibility: hidden;
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
    visibility: visible;
  }
}
header.style-1 .main-menu ul > li:hover > a {
  color: var(--primary-color1);
}
header.style-1 .main-menu ul > li:hover::after {
  color: var(--primary-color1);
}
header.style-1 .main-menu ul > li:hover .dropdown-icon2 {
  color: var(--primary-color1);
}
header.style-1 .main-menu ul > li:hover.position-inherit > a::after {
  color: var(--primary-color1);
}
header.style-1 .main-menu ul > li.active > a {
  color: var(--primary-color1);
}
header.style-1 .main-menu ul > li.active::after {
  color: var(--primary-color1);
}
header.style-1 .main-menu ul > li.active .dropdown-icon2 {
  color: var(--primary-color1);
}
header.style-1 .main-menu ul > li.active.position-inherit > a::after {
  color: var(--primary-color1);
}
header.style-1 .main-menu ul li.menu-item-has-children > i {
  display: block;
}
header.style-1 .main-menu .topbar-right {
  padding: 20px 0;
  border-bottom: 1px solid rgba(238, 238, 238, 0.93);
}
header.style-1 .main-menu .topbar-right .header-cart-btn {
  background-color: transparent;
  font-family: var(--font-rubik);
  font-weight: 600;
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--title-color);
  background-color: transparent;
  padding: 0;
  transition: 0.35s;
}
header.style-1 .main-menu .hotline-area {
  display: flex;
  align-items: center;
  gap: 20px;
  padding-top: 20px;
}
header.style-1 .main-menu .hotline-area .icon svg {
  fill: var(--primary-color1);
}
header.style-1 .main-menu .hotline-area .content {
  position: relative;
  line-height: 1;
}
header.style-1 .main-menu .hotline-area .content::after {
  content: "";
  height: 35px;
  width: 1px;
  background-color: rgba(16, 12, 8, 0.2);
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}
header.style-1 .main-menu .hotline-area .content span {
  font-family: var(--font-rubik);
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: var(--title-color);
  margin-bottom: 5px;
  display: inline-block;
}
header.style-1 .main-menu .hotline-area .content h6 {
  margin-bottom: 0;
  line-height: 1;
}
header.style-1 .main-menu .hotline-area .content h6 a {
  font-family: var(--font-rubik);
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: var(--primary-color1);
  transition: 0.5s;
}
header.style-1 .main-menu .hotline-area .content h6 a:hover {
  color: var(--title-color);
}
@media only screen and (max-width: 991px) {
  header.style-1 .mobile-logo-area .menu-close-btn {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    border: 1px solid var(--title-color);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.35s;
  }
  header.style-1 .mobile-logo-area .menu-close-btn i {
    font-size: 26px;
    line-height: 1;
    margin-top: 2px;
    color: var(--title-color);
    transition: 0.35s;
  }
  header.style-1 .mobile-logo-area .menu-close-btn:hover {
    background-color: var(--title-color);
  }
  header.style-1 .mobile-logo-area .menu-close-btn:hover i {
    color: var(--white-color);
  }
  header.style-1 .dropdown-icon {
    color: var(--white-color);
  }
  header.style-1 .main-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 280px;
    padding: 30px 20px !important;
    z-index: 99999;
    height: 100%;
    overflow: auto;
    background: #fff;
    border-right: 1px solid #eee;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.03);
  }
  header.style-1 .main-menu.show-menu {
    transform: translateX(0);
  }
  header.style-1 .main-menu .mobile-menu-logo {
    text-align: left;
    padding-top: 20px;
    display: block;
    padding-bottom: 8px;
  }
  header.style-1 .main-menu .menu-list {
    padding-top: 50px;
    padding-bottom: 0px;
  }
  header.style-1 .main-menu ul {
    float: none;
    text-align: left;
    padding: 5px 0px 20px 0;
  }
  header.style-1 .main-menu ul li {
    display: block;
    position: relative;
    padding: 0 5px;
  }
  header.style-1 .main-menu ul li i {
    display: block;
  }
  header.style-1 .main-menu ul li a {
    padding: 10px 0;
    display: block;
  }
  header.style-1 .main-menu ul li ul.sub-menu {
    position: static;
    min-width: 200px;
    background: 0 0;
    border: none;
    opacity: 1;
    visibility: visible;
    box-shadow: none;
    transform: none;
    transition: none;
    display: none;
    margin-top: 0 !important;
    transform: translateY(0px);
    padding-left: 10px;
  }
  header.style-1 .main-menu ul li ul.sub-menu > li {
    border-bottom: 1px solid transparent;
  }
  header.style-1 .main-menu ul li ul.sub-menu > li a {
    color: var(--title-color);
    padding: 12px 0px;
  }
  header.style-1 .main-menu ul li ul.sub-menu > li a:hover {
    color: var(--primary-color1);
    margin-left: 10px;
  }
  header.style-1 .main-menu ul li ul.sub-menu > li a.active {
    color: var(--primary-color1);
  }
  header.style-1 .main-menu ul li ul.sub-menu > li i {
    color: var(--primary-color1);
    right: -13px;
  }
  header.style-1 .main-menu ul li .bi {
    top: 8px;
    font-size: 20px;
    color: var(--title-color);
  }
}
header.style-1.dashboard {
  padding: 0px 25px;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  header.style-1.dashboard {
    padding: 0px 25px;
  }
}
@media (max-width: 1399px) {
  header.style-1.dashboard {
    padding: 0px 25px;
  }
}
header.style-1.dashboard .header-log-and-sb-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 280px;
  width: 100%;
}
@media (max-width: 576px) {
  header.style-1.dashboard .header-log-and-sb-btn {
    max-width: 220px;
  }
}
header.style-1.dashboard .header-log-and-sb-btn .dashboard-sb-btn .sidebar-toggle-button {
  cursor: pointer;
}
header.style-1.dashboard .header-log-and-sb-btn .dashboard-sb-btn .sidebar-toggle-button.active {
  fill: var(--primary-color1);
}

header.style-2 {
  position: fixed;
  top: 30px;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 60px;
  transition: all 0.8s ease-out 0s;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.15);
  -webkit-backdrop-filter: blur(1.5px);
          backdrop-filter: blur(1.5px);
  max-width: 1780px;
  width: 100%;
  margin: 0 auto;
  border-radius: 300px;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  header.style-2 {
    padding: 0px 3%;
    max-width: 1380px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  header.style-2 {
    max-width: 1180px;
  }
}
@media (max-width: 1399px) {
  header.style-2 {
    padding: 0px 2%;
  }
}
@media (max-width: 991px) {
  header.style-2 {
    top: 0;
    padding: 0px 30px;
  }
}
@media (max-width: 576px) {
  header.style-2 {
    padding: 0px 20px;
  }
}
header.style-2.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.8);
  -webkit-backdrop-filter: blur(1.5px);
          backdrop-filter: blur(1.5px);
  z-index: 999;
}
@keyframes smooth-header {
  0% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0px);
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  header.style-2 .header-logo img {
    max-width: 135px;
    width: 100%;
  }
}
@media (max-width: 576px) {
  header.style-2 .header-logo img {
    max-width: 135px;
    width: 100%;
  }
}
header.style-2 .nav-right {
  gap: 50px;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  header.style-2 .nav-right {
    gap: 35px;
  }
}
@media (max-width: 1199px) {
  header.style-2 .nav-right {
    gap: 25px;
  }
}
@media (max-width: 991px) {
  header.style-2 .nav-right {
    gap: 0px;
  }
}
header.style-2 .nav-right .icon-list {
  display: flex;
  align-items: center;
}
header.style-2 .nav-right .icon-list li {
  padding: 20px;
  border-left: 1px solid rgba(255, 255, 255, 0.15);
  border-right: 1px solid rgba(255, 255, 255, 0.15);
  cursor: pointer;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  header.style-2 .nav-right .icon-list li {
    padding: 15px;
  }
}
@media (max-width: 1199px) {
  header.style-2 .nav-right .icon-list li {
    padding: 15px;
    border: unset;
  }
}
header.style-2 .nav-right .icon-list li svg {
  stroke: var(--white-color);
}
header.style-2 .nav-right .icon-list li:last-child {
  border-left: unset;
}
header.style-2 .nav-right .icon-list li:last-child svg {
  fill: var(--white-color);
  stroke: none;
}
header.style-2 .nav-right .hotline-area {
  display: flex;
  align-items: center;
  gap: 20px;
}
header.style-2 .nav-right .hotline-area .icon svg {
  fill: var(--primary-color1);
}
header.style-2 .nav-right .hotline-area .content {
  position: relative;
  line-height: 1;
}
header.style-2 .nav-right .hotline-area .content::after {
  content: "";
  height: 35px;
  width: 1px;
  background-color: rgba(16, 12, 8, 0.2);
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}
header.style-2 .nav-right .hotline-area .content span {
  font-family: var(--font-rubik);
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: var(--title-color);
  margin-bottom: 5px;
  display: inline-block;
}
header.style-2 .nav-right .hotline-area .content h6 {
  margin-bottom: 0;
  line-height: 1;
}
header.style-2 .nav-right .hotline-area .content h6 a {
  font-family: var(--font-rubik);
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: var(--primary-color1);
  transition: 0.5s;
}
header.style-2 .nav-right .hotline-area .content h6 a:hover {
  color: var(--title-color);
}
header.style-2 .nav-right .mobile-menu-btn {
  display: none;
  visibility: hidden;
}
@media (max-width: 991px) {
  header.style-2 .nav-right .mobile-menu-btn {
    display: flex;
    margin-left: 10px;
    flex-direction: column;
    align-items: end;
    visibility: visible;
    justify-content: center;
    position: relative;
  }
}
@media (max-width: 576px) {
  header.style-2 .nav-right .mobile-menu-btn {
    margin-left: 10px;
  }
}
header.style-2 .nav-right .mobile-menu-btn svg {
  fill: var(--white-color);
}
header.style-2 .main-menu {
  display: inline-block;
  position: relative;
}
header.style-2 .main-menu .mobile-menu-logo {
  display: none;
}
header.style-2 .main-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
header.style-2 .main-menu ul > li {
  display: inline-block;
  position: relative;
  padding: 0 28px;
}
@media (max-width: 1399px) {
  header.style-2 .main-menu ul > li {
    padding: 0 25px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  header.style-2 .main-menu ul > li {
    padding: 0 20px;
  }
}
header.style-2 .main-menu ul > li.position-inherit {
  position: inherit;
}
@media (max-width: 991px) {
  header.style-2 .main-menu ul > li.position-inherit {
    position: relative;
  }
}
header.style-2 .main-menu ul > li.position-inherit > a::after {
  content: "\f282";
  font-family: "bootstrap-icons";
  font-weight: 500;
  position: absolute;
  top: 15px;
  right: 2px;
  font-size: 14px;
  color: var(--text-color);
  transition: all 0.55s ease-in-out;
}
@media (max-width: 1199px) {
  header.style-2 .main-menu ul > li.position-inherit > a::after {
    right: -7px;
  }
}
@media (max-width: 991px) {
  header.style-2 .main-menu ul > li.position-inherit > a::after {
    display: none;
    visibility: hidden;
  }
}
header.style-2 .main-menu ul > li:hover i {
  color: var(--white-color);
  font-size: 20px;
}
@media (min-width: 992px) {
  header.style-2 .main-menu ul > li:first-child {
    padding-left: 0;
  }
  header.style-2 .main-menu ul > li:first-child a {
    padding-left: 0;
  }
}
header.style-2 .main-menu ul > li a {
  color: var(--white-color);
  display: block;
  text-transform: uppercase;
  padding: 20px 0px;
  position: relative;
  font-family: var(--font-rubik);
  font-weight: 400;
  font-size: 14px;
  transition: all 0.5s ease-out 0s;
  position: relative;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  header.style-2 .main-menu ul > li a {
    padding: 15px 0;
  }
}
@media (max-width: 1199px) {
  header.style-2 .main-menu ul > li a {
    padding: 15px 0px;
  }
}
@media (max-width: 991px) {
  header.style-2 .main-menu ul > li a {
    padding: 32px 0px;
  }
}
header.style-2 .main-menu ul > li i {
  font-size: 20px;
  text-align: center;
  color: var(--text-color);
  font-style: normal;
  position: absolute;
  right: -5px;
  top: 35px;
  z-index: 999;
  cursor: pointer;
  display: none;
  transition: all 0.5s ease-out 0s;
  opacity: 0;
}
@media (max-width: 991px) {
  header.style-2 .main-menu ul > li i {
    opacity: 1;
  }
}
header.style-2 .main-menu ul > li i.active {
  color: var(--primary-color1);
}
header.style-2 .main-menu ul > li i.active::before {
  content: "\f2ea";
}
header.style-2 .main-menu ul > li ul.sub-menu {
  position: absolute;
  left: 0;
  right: 0;
  top: auto;
  margin: 0;
  display: none;
  min-width: 220px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: rgba(0, 0, 0, 0.8);
  -webkit-backdrop-filter: blur(1.5px);
          backdrop-filter: blur(1.5px);
  box-shadow: 0px 30px 80px rgba(8, 0, 42, 0.08);
  text-align: left;
  transform-origin: top;
}
@media (max-width: 1199px) {
  header.style-2 .main-menu ul > li ul.sub-menu {
    box-shadow: none;
  }
}
header.style-2 .main-menu ul > li ul.sub-menu > li {
  padding: 0;
  display: block;
  position: relative;
}
header.style-2 .main-menu ul > li ul.sub-menu > li i {
  position: absolute;
  top: 14px;
  right: 6px;
  display: block;
  color: var(--title-color);
  font-size: 14px;
}
header.style-2 .main-menu ul > li ul.sub-menu > li .dropdown-icon {
  color: var(--white-color);
  opacity: 1;
  top: 14px;
  font-size: 14px;
  right: 10px;
}
@media (max-width: 991px) {
  header.style-2 .main-menu ul > li ul.sub-menu > li .dropdown-icon {
    right: 0;
    top: 8px;
    font-size: 20px;
  }
}
header.style-2 .main-menu ul > li ul.sub-menu > li a {
  display: block;
  padding: 13px 15px;
  color: var(--white-color);
  font-weight: 400;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1.3;
  letter-spacing: 0.8px;
  transition: all 0.4s ease-out 0s;
  position: relative;
  border-bottom: 1px solid rgba(233, 228, 228, 0.25);
}
header.style-2 .main-menu ul > li ul.sub-menu > li a::before {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  background: linear-gradient(90deg, #5c5b5b, var(--primary-color1-opc), #5c5b5b);
  width: 100%;
  height: 1px;
  transform: scaleX(0);
  transform-origin: left;
  transition: 0.4s ease-in;
  z-index: 1;
}
@media (max-width: 991px) {
  header.style-2 .main-menu ul > li ul.sub-menu > li a {
    border-color: rgba(233, 228, 228, 0.5);
  }
  header.style-2 .main-menu ul > li ul.sub-menu > li a::before {
    background: linear-gradient(90deg, #ddd, var(--primary-color1-opc), #ddd);
  }
}
header.style-2 .main-menu ul > li ul.sub-menu > li a:hover {
  color: var(--primary-color1);
}
header.style-2 .main-menu ul > li ul.sub-menu > li a:hover::before {
  transform: scale(1);
}
header.style-2 .main-menu ul > li ul.sub-menu > li a.active {
  color: var(--primary-color1);
}
header.style-2 .main-menu ul > li ul.sub-menu > li a.active::before {
  transform: scale(1);
}
header.style-2 .main-menu ul > li ul.sub-menu > li:last-child a {
  border-bottom: none;
}
header.style-2 .main-menu ul > li ul.sub-menu > li:last-child a::before {
  display: none;
}
header.style-2 .main-menu ul > li ul.sub-menu > li:hover > a {
  color: var(--primary-color1);
}
header.style-2 .main-menu ul > li ul.sub-menu > li:hover .dropdown-icon {
  color: var(--primary-color1);
}
header.style-2 .main-menu ul > li ul.sub-menu > li.active > a {
  color: var(--primary-color1);
}
header.style-2 .main-menu ul > li ul.sub-menu > li.active .dropdown-icon {
  color: var(--primary-color1);
}
header.style-2 .main-menu ul > li ul.sub-menu > li .sub-menu {
  left: 220px;
  position: absolute;
  max-width: 230px;
  min-width: 215px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: rgba(0, 0, 0, 0.8);
  -webkit-backdrop-filter: blur(1.5px);
          backdrop-filter: blur(1.5px);
  box-shadow: 0px 30px 80px rgba(8, 0, 42, 0.08);
  top: 0;
}
@media (max-width: 1199px) {
  header.style-2 .main-menu ul > li ul.sub-menu > li .sub-menu {
    box-shadow: none;
  }
}
header.style-2 .main-menu ul > li ul.sub-menu > li .sub-menu::before {
  display: none;
  visibility: hidden;
}
@media only screen and (max-width: 991px) {
  header.style-2 .main-menu ul > li ul.sub-menu > li .sub-menu {
    position: unset;
    max-width: 230px;
    min-width: 215px;
    background: transparent;
    top: 0;
  }
}
header.style-2 .main-menu ul > li ul.sub-menu > li .sub-menu > li i {
  display: block;
}
header.style-2 .main-menu ul > li ul.sub-menu > li:last-child {
  border-bottom: none;
}
header.style-2 .main-menu ul > li.menu-item-has-children {
  transition: all 0.55s ease-in-out;
}
header.style-2 .main-menu ul > li.menu-item-has-children::after {
  content: "\f282";
  font-family: "bootstrap-icons";
  font-weight: 500;
  position: absolute;
  top: 20px;
  right: 10px;
  font-size: 14px;
  color: var(--white-color);
  transition: all 0.55s ease-in-out;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  header.style-2 .main-menu ul > li.menu-item-has-children::after {
    right: 4px;
    top: 15px;
  }
}
@media (max-width: 1199px) {
  header.style-2 .main-menu ul > li.menu-item-has-children::after {
    right: 2px;
    top: 15px;
  }
}
@media (max-width: 991px) {
  header.style-2 .main-menu ul > li.menu-item-has-children::after {
    display: none;
    visibility: hidden;
  }
}
header.style-2 .main-menu ul > li.menu-item-has-children:hover::after {
  content: "\f286";
}
@media (min-width: 992px) {
  header.style-2 .main-menu ul > li:hover > ul.sub-menu {
    display: block;
    animation: fade-down 0.45s linear;
  }
}
@media (min-width: 992px) {
  header.style-2 .main-menu ul > li:hover .mega-menu {
    display: block;
    animation: fade-down 0.45s linear;
  }
}
@keyframes fade-up {
  0% {
    opacity: 0;
    transform: scaleY(0);
    visibility: hidden;
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
    visibility: visible;
  }
}
header.style-2 .main-menu ul > li:hover > a {
  color: var(--primary-color1);
}
header.style-2 .main-menu ul > li:hover::after {
  color: var(--primary-color1);
}
header.style-2 .main-menu ul > li:hover .dropdown-icon2 {
  color: var(--primary-color1);
}
header.style-2 .main-menu ul > li:hover.position-inherit > a::after {
  color: var(--primary-color1);
}
header.style-2 .main-menu ul > li.active > a {
  color: var(--primary-color1);
}
header.style-2 .main-menu ul > li.active > a::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  background: var(--primary-color1);
  height: 2px;
  width: 100%;
}
header.style-2 .main-menu ul > li.active::after {
  color: var(--primary-color1);
}
header.style-2 .main-menu ul > li.active .dropdown-icon2 {
  color: var(--primary-color1);
}
header.style-2 .main-menu ul > li.active.position-inherit > a::after {
  color: var(--primary-color1);
}
header.style-2 .main-menu ul li.menu-item-has-children > i {
  display: block;
}
header.style-2 .main-menu .topbar-right {
  padding: 20px 0;
  border-bottom: 1px solid rgba(238, 238, 238, 0.93);
}
header.style-2 .main-menu .topbar-right .header-cart-btn {
  background-color: transparent;
  font-family: var(--font-rubik);
  font-weight: 600;
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--white-color);
  background-color: transparent;
  padding: 0;
  transition: 0.35s;
}
header.style-2 .main-menu .topbar-right .header-cart-btn svg {
  fill: var(--white-color);
}
header.style-2 .main-menu .hotline-area {
  display: flex;
  align-items: center;
  gap: 20px;
  padding-top: 20px;
}
header.style-2 .main-menu .hotline-area .icon svg {
  fill: var(--primary-color1);
}
header.style-2 .main-menu .hotline-area .content {
  position: relative;
  line-height: 1;
}
header.style-2 .main-menu .hotline-area .content::after {
  content: "";
  height: 35px;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.2);
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}
header.style-2 .main-menu .hotline-area .content span {
  font-family: var(--font-rubik);
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: var(--white-color);
  margin-bottom: 5px;
  display: inline-block;
}
header.style-2 .main-menu .hotline-area .content h6 {
  margin-bottom: 0;
  line-height: 1;
}
header.style-2 .main-menu .hotline-area .content h6 a {
  font-family: var(--font-rubik);
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: var(--primary-color1);
  transition: 0.5s;
}
header.style-2 .main-menu .hotline-area .content h6 a:hover {
  color: var(--title-color);
}
@media only screen and (max-width: 991px) {
  header.style-2 .mobile-logo-area .menu-close-btn {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    border: 1px solid var(--white-color);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.35s;
  }
  header.style-2 .mobile-logo-area .menu-close-btn i {
    font-size: 26px;
    line-height: 1;
    margin-top: 2px;
    color: var(--white-color);
    transition: 0.35s;
  }
  header.style-2 .mobile-logo-area .menu-close-btn:hover {
    background-color: var(--primary-color1);
    border-color: var(--primary-color1);
  }
  header.style-2 .mobile-logo-area .menu-close-btn:hover i {
    color: var(--white-color);
  }
  header.style-2 .dropdown-icon {
    color: var(--white-color);
  }
  header.style-2 .main-menu {
    position: fixed;
    top: -1px;
    left: -1px;
    width: 280px;
    padding: 30px 20px !important;
    z-index: 99999;
    min-height: 100vh;
    overflow: auto;
    background: var(--title-color);
    transform: translateX(-100%);
    transition: transform 0.3s ease-in;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.03);
  }
  header.style-2 .main-menu.show-menu {
    transform: translateX(0);
  }
  header.style-2 .main-menu .mobile-menu-logo {
    text-align: left;
    padding-top: 20px;
    display: block;
    padding-bottom: 8px;
  }
  header.style-2 .main-menu .menu-list {
    padding-top: 50px;
    padding-bottom: 0px;
  }
  header.style-2 .main-menu ul {
    float: none;
    text-align: left;
    padding: 5px 0px 20px 0;
  }
  header.style-2 .main-menu ul li {
    display: block;
    position: relative;
    padding: 0 5px;
  }
  header.style-2 .main-menu ul li i {
    display: block;
  }
  header.style-2 .main-menu ul li a {
    padding: 10px 0;
    display: block;
  }
  header.style-2 .main-menu ul li ul.sub-menu {
    position: static;
    min-width: 200px;
    background: 0 0;
    border: none;
    opacity: 1;
    visibility: visible;
    box-shadow: none;
    transform: none;
    transition: none;
    display: none;
    margin-top: 0 !important;
    transform: translateY(0px);
    padding-left: 10px;
  }
  header.style-2 .main-menu ul li ul.sub-menu > li {
    border-bottom: 1px solid transparent;
  }
  header.style-2 .main-menu ul li ul.sub-menu > li a {
    color: var(--white-color);
    padding: 12px 0px;
  }
  header.style-2 .main-menu ul li ul.sub-menu > li a:hover {
    color: var(--primary-color1);
    margin-left: 10px;
  }
  header.style-2 .main-menu ul li ul.sub-menu > li a.active {
    color: var(--primary-color1);
  }
  header.style-2 .main-menu ul li ul.sub-menu > li i {
    color: var(--primary-color1);
    right: -13px;
  }
  header.style-2 .main-menu ul li .bi {
    top: 8px;
    font-size: 20px;
    color: var(--white-color);
  }
  header.style-2 .main-menu ul li.active a::before {
    display: none;
  }
}

header.style-3 {
  position: relative;
  width: 100%;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 160px;
  transition: all 0.8s ease-out 0s;
  background-color: var(--white-color);
}
@media (min-width: 1400px) and (max-width: 1599px) {
  header.style-3 {
    padding: 0px 70px;
  }
}
@media (max-width: 1399px) {
  header.style-3 {
    padding: 0px 60px;
  }
}
@media (max-width: 1199px) {
  header.style-3 {
    padding: 0px 40px;
  }
}
@media (max-width: 767px) {
  header.style-3 {
    padding: 0px 30px;
  }
}
@media (max-width: 576px) {
  header.style-3 {
    padding: 0px 20px;
  }
}
header.style-3.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  box-sizing: border-box;
  background-color: var(--white-color);
  z-index: 999;
  box-shadow: 5px 3px 40px rgba(0, 72, 88, 0.1);
}
@keyframes smooth-header {
  0% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0px);
  }
}
header.style-3 .header-logo {
  padding: 15px 0;
}
@media (max-width: 576px) {
  header.style-3 .header-logo img {
    max-width: 165px;
    width: 100%;
  }
}
header.style-3 .menu-close-btn i {
  color: var(--white-color);
}
header.style-3 .nav-right {
  gap: 50px;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  header.style-3 .nav-right {
    gap: 35px;
  }
}
@media (max-width: 1199px) {
  header.style-3 .nav-right {
    gap: 25px;
  }
}
@media (max-width: 991px) {
  header.style-3 .nav-right {
    gap: 10px;
  }
}
header.style-3 .nav-right .modal-btn {
  font-family: var(--font-rubik);
  font-weight: 600;
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--title-color);
  background-color: transparent;
  padding: 0;
  transition: 0.35s;
}
header.style-3 .nav-right .modal-btn svg {
  line-height: 1;
  transition: 0.35s;
  fill: var(--title-color);
}
header.style-3 .nav-right .modal-btn:hover {
  color: var(--primary-color1);
}
header.style-3 .nav-right .modal-btn:hover svg {
  fill: var(--primary-color1);
}
header.style-3 .nav-right .primary-btn3 {
  padding: 13px 18px;
  gap: 5px;
}
header.style-3 .nav-right .icon-list {
  display: flex;
  align-items: center;
  gap: 30px;
}
header.style-3 .nav-right .icon-list li {
  cursor: pointer;
}
header.style-3 .nav-right .icon-list li svg {
  stroke: var(--title-color);
}
header.style-3 .nav-right .icon-list li:last-child svg {
  fill: var(--title-color);
  stroke: none;
}
header.style-3 .nav-right .hotline-area {
  display: flex;
  align-items: center;
  gap: 20px;
}
header.style-3 .nav-right .hotline-area .icon svg {
  fill: var(--primary-color1);
}
header.style-3 .nav-right .hotline-area .content {
  position: relative;
  line-height: 1;
}
header.style-3 .nav-right .hotline-area .content::after {
  content: "";
  height: 35px;
  width: 1px;
  background-color: rgba(16, 12, 8, 0.2);
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}
header.style-3 .nav-right .hotline-area .content span {
  font-family: var(--font-rubik);
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: var(--title-color);
  margin-bottom: 5px;
  display: inline-block;
}
header.style-3 .nav-right .hotline-area .content h6 {
  margin-bottom: 0;
  line-height: 1;
}
header.style-3 .nav-right .hotline-area .content h6 a {
  font-family: var(--font-rubik);
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: var(--primary-color1);
  transition: 0.5s;
}
header.style-3 .nav-right .hotline-area .content h6 a:hover {
  color: var(--primary-color2);
}
header.style-3 .nav-right .mobile-menu-btn {
  display: none;
  visibility: hidden;
}
@media (max-width: 991px) {
  header.style-3 .nav-right .mobile-menu-btn {
    display: flex;
    margin-left: 15px;
    flex-direction: column;
    align-items: end;
    visibility: visible;
    justify-content: center;
    position: relative;
  }
}
@media (max-width: 576px) {
  header.style-3 .nav-right .mobile-menu-btn {
    margin-left: 10px;
  }
}
header.style-3 .nav-right .mobile-menu-btn svg {
  fill: var(--title-color);
}
header.style-3 .main-menu {
  display: inline-block;
  position: relative;
}
header.style-3 .main-menu .mobile-menu-logo {
  display: none;
}
header.style-3 .main-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
header.style-3 .main-menu ul > li {
  display: inline-block;
  position: relative;
  padding: 0 8px;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  header.style-3 .main-menu ul > li {
    padding: 0 5px;
  }
}
header.style-3 .main-menu ul > li a {
  color: var(--title-color);
  display: block;
  text-transform: capitalize;
  padding: 25px 20px;
  font-family: var(--font-rubik);
  font-weight: 400;
  font-size: 15px;
  transition: all 0.5s ease-out 0s;
  position: relative;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  header.style-3 .main-menu ul > li a {
    padding: 25px 15px;
  }
}
@media (max-width: 1199px) {
  header.style-3 .main-menu ul > li a {
    padding: 15px 10px;
  }
}
@media (max-width: 991px) {
  header.style-3 .main-menu ul > li a {
    padding: 32px 9px;
  }
}
header.style-3 .main-menu ul > li i {
  font-size: 20px;
  text-align: center;
  color: var(--text-color);
  font-style: normal;
  position: absolute;
  right: 0;
  top: 35px;
  z-index: 999;
  cursor: pointer;
  display: none;
  transition: all 0.5s ease-out 0s;
  opacity: 0;
}
@media (max-width: 991px) {
  header.style-3 .main-menu ul > li i {
    opacity: 1;
  }
}
header.style-3 .main-menu ul > li i.active {
  color: var(--primary-color1);
}
header.style-3 .main-menu ul > li i.active::before {
  content: "\f2ea";
}
header.style-3 .main-menu ul > li ul.sub-menu {
  position: absolute;
  left: 0;
  right: 0;
  top: auto;
  margin: 0;
  display: none;
  min-width: 220px;
  background: var(--white-color);
  box-shadow: 0px 30px 80px rgba(8, 0, 42, 0.08);
  text-align: left;
  transform-origin: top;
}
@media (max-width: 1199px) {
  header.style-3 .main-menu ul > li ul.sub-menu {
    box-shadow: none;
  }
}
header.style-3 .main-menu ul > li ul.sub-menu > li {
  padding: 0;
  display: block;
  position: relative;
}
header.style-3 .main-menu ul > li ul.sub-menu > li i {
  position: absolute;
  top: 16px;
  right: 6px;
  display: block;
  color: var(--title-color);
  font-size: 14px;
}
header.style-3 .main-menu ul > li ul.sub-menu > li .dropdown-icon {
  color: var(--title-color);
  opacity: 1;
  top: 16px;
  font-size: 14px;
  right: 10px;
}
@media (max-width: 991px) {
  header.style-3 .main-menu ul > li ul.sub-menu > li .dropdown-icon {
    right: 0;
    top: 8px;
    font-size: 20px;
  }
}
header.style-3 .main-menu ul > li ul.sub-menu > li a {
  display: block;
  padding: 15px 15px;
  color: var(--title-color);
  font-weight: 500;
  font-family: var(--font-jost);
  text-transform: capitalize;
  font-size: 14px;
  line-height: 1.3;
  transition: all 0.4s ease-out 0s;
  position: relative;
  border-bottom: 1px solid rgba(233, 228, 228, 0.5);
}
header.style-3 .main-menu ul > li ul.sub-menu > li a::before {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  background: linear-gradient(90deg, #fff, var(--primary-color1-opc), #fff);
  width: 100%;
  height: 1px;
  transform: scaleX(0);
  transform-origin: left;
  transition: 0.4s ease-in;
  z-index: 1;
}
@media (max-width: 991px) {
  header.style-3 .main-menu ul > li ul.sub-menu > li a {
    border-color: rgba(233, 228, 228, 0.5);
  }
  header.style-3 .main-menu ul > li ul.sub-menu > li a::before {
    background: linear-gradient(90deg, #ddd, #EFB93F, #ddd);
  }
}
header.style-3 .main-menu ul > li ul.sub-menu > li a:hover {
  color: var(--primary-color1);
}
header.style-3 .main-menu ul > li ul.sub-menu > li a:hover::before {
  transform: scale(1);
}
header.style-3 .main-menu ul > li ul.sub-menu > li a.active {
  color: var(--primary-color1);
}
header.style-3 .main-menu ul > li ul.sub-menu > li a.active::before {
  transform: scale(1);
}
header.style-3 .main-menu ul > li ul.sub-menu > li:last-child a {
  border-bottom: none;
}
header.style-3 .main-menu ul > li ul.sub-menu > li:last-child a::before {
  display: none;
}
header.style-3 .main-menu ul > li ul.sub-menu > li:hover > a {
  color: var(--primary-color1);
}
header.style-3 .main-menu ul > li ul.sub-menu > li:hover .dropdown-icon {
  color: var(--primary-color1);
}
header.style-3 .main-menu ul > li ul.sub-menu > li.active > a {
  color: var(--primary-color1);
}
header.style-3 .main-menu ul > li ul.sub-menu > li.active .dropdown-icon {
  color: var(--primary-color1);
}
header.style-3 .main-menu ul > li ul.sub-menu > li .sub-menu {
  left: 220px;
  position: absolute;
  max-width: 230px;
  min-width: 215px;
  background: var(--white-color);
  box-shadow: 0px 30px 80px rgba(8, 0, 42, 0.08);
  top: 0;
}
@media (max-width: 1199px) {
  header.style-3 .main-menu ul > li ul.sub-menu > li .sub-menu {
    box-shadow: none;
  }
}
header.style-3 .main-menu ul > li ul.sub-menu > li .sub-menu::before {
  display: none;
  visibility: hidden;
}
@media only screen and (max-width: 991px) {
  header.style-3 .main-menu ul > li ul.sub-menu > li .sub-menu {
    position: unset;
    max-width: 230px;
    min-width: 215px;
    background: transparent;
    top: 0;
  }
}
header.style-3 .main-menu ul > li ul.sub-menu > li .sub-menu > li i {
  display: block;
}
header.style-3 .main-menu ul > li ul.sub-menu > li:last-child {
  border-bottom: none;
}
@media (min-width: 992px) {
  header.style-3 .main-menu ul > li:hover > ul.sub-menu {
    display: block;
    animation: fade-down 0.45s linear;
  }
}
@keyframes fade-up {
  0% {
    opacity: 0;
    transform: scaleY(0);
    visibility: hidden;
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
    visibility: visible;
  }
}
header.style-3 .main-menu ul > li:hover i {
  color: var(--black-color2);
  font-size: 20px;
}
header.style-3 .main-menu ul > li:hover > a {
  color: var(--primary-color1);
}
header.style-3 .main-menu ul > li:hover::after {
  color: var(--primary-color1);
}
header.style-3 .main-menu ul > li:hover .dropdown-icon2 {
  color: var(--primary-color1);
}
header.style-3 .main-menu ul > li:hover.position-inherit > a::after {
  color: var(--primary-color1);
}
header.style-3 .main-menu ul > li.menu-item-has-children {
  transition: all 0.55s ease-in-out;
}
header.style-3 .main-menu ul > li.active > a {
  color: var(--primary-color1);
}
header.style-3 .main-menu ul > li.active > a::before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  background: var(--primary-color1);
  height: 5px;
  width: 5px;
  border-radius: 50%;
}
@media (max-width: 1199px) {
  header.style-3 .main-menu ul > li.active > a::before {
    bottom: 10px;
  }
}
@media (max-width: 991px) {
  header.style-3 .main-menu ul > li.active > a::before {
    display: none;
  }
}
header.style-3 .main-menu ul > li.active .dropdown-icon2 {
  color: var(--primary-color1);
}
header.style-3 .main-menu ul > li.active.menu-item-has-children::after {
  color: var(--primary-color1);
}
header.style-3 .main-menu ul li.menu-item-has-children > i {
  display: block;
}
header.style-3 .main-menu .topbar-right {
  padding: 20px 0;
  border-bottom: 1px solid rgba(238, 238, 238, 0.93);
}
header.style-3 .main-menu .topbar-right .header-cart-btn {
  background-color: transparent;
  font-family: var(--font-rubik);
  font-weight: 600;
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--title-color);
  background-color: transparent;
  padding: 0;
  transition: 0.35s;
}
header.style-3 .main-menu .hotline-area {
  display: flex;
  align-items: center;
  gap: 20px;
  padding-top: 20px;
}
header.style-3 .main-menu .hotline-area .icon svg {
  fill: var(--primary-color1);
}
header.style-3 .main-menu .hotline-area .content {
  position: relative;
  line-height: 1;
}
header.style-3 .main-menu .hotline-area .content::after {
  content: "";
  height: 35px;
  width: 1px;
  background-color: rgba(16, 12, 8, 0.2);
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}
header.style-3 .main-menu .hotline-area .content span {
  font-family: var(--font-rubik);
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: var(--title-color);
  margin-bottom: 5px;
  display: inline-block;
}
header.style-3 .main-menu .hotline-area .content h6 {
  margin-bottom: 0;
  line-height: 1;
}
header.style-3 .main-menu .hotline-area .content h6 a {
  font-family: var(--font-rubik);
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: var(--primary-color1);
  transition: 0.5s;
}
header.style-3 .main-menu .hotline-area .content h6 a:hover {
  color: var(--title-color);
}
@media only screen and (max-width: 991px) {
  header.style-3 .mobile-logo-area .menu-close-btn {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    border: 1px solid var(--title-color);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.35s;
  }
  header.style-3 .mobile-logo-area .menu-close-btn i {
    font-size: 26px;
    line-height: 1;
    margin-top: 2px;
    color: var(--title-color);
    transition: 0.35s;
  }
  header.style-3 .mobile-logo-area .menu-close-btn:hover {
    background-color: var(--title-color);
  }
  header.style-3 .mobile-logo-area .menu-close-btn:hover i {
    color: var(--white-color);
  }
  header.style-3 .dropdown-icon {
    color: var(--white-color);
  }
  header.style-3 .main-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 280px;
    padding: 30px 20px !important;
    z-index: 99999;
    height: 100%;
    overflow: auto;
    background: #fff;
    border-right: 1px solid #eee;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.03);
  }
  header.style-3 .main-menu.show-menu {
    transform: translateX(0);
  }
  header.style-3 .main-menu .mobile-menu-logo {
    text-align: left;
    padding-top: 20px;
    display: block;
    padding-bottom: 8px;
  }
  header.style-3 .main-menu .menu-list {
    padding-top: 50px;
    padding-bottom: 0px;
  }
  header.style-3 .main-menu ul {
    float: none;
    text-align: left;
    padding: 5px 0px 20px 0;
  }
  header.style-3 .main-menu ul li {
    display: block;
    position: relative;
    padding: 0 5px;
  }
  header.style-3 .main-menu ul li i {
    display: block;
  }
  header.style-3 .main-menu ul li a {
    padding: 10px 0;
    display: block;
  }
  header.style-3 .main-menu ul li ul.sub-menu {
    position: static;
    min-width: 200px;
    background: 0 0;
    border: none;
    opacity: 1;
    visibility: visible;
    box-shadow: none;
    transform: none;
    transition: none;
    display: none;
    margin-top: 0 !important;
    transform: translateY(0px);
    padding-left: 10px;
  }
  header.style-3 .main-menu ul li ul.sub-menu > li {
    border-bottom: 1px solid transparent;
  }
  header.style-3 .main-menu ul li ul.sub-menu > li a {
    color: var(--title-color);
    padding: 12px 0px;
  }
  header.style-3 .main-menu ul li ul.sub-menu > li a:hover {
    color: var(--primary-color1);
    margin-left: 10px;
  }
  header.style-3 .main-menu ul li ul.sub-menu > li a.active {
    color: var(--primary-color1);
  }
  header.style-3 .main-menu ul li ul.sub-menu > li i {
    color: var(--primary-color1);
    right: -13px;
  }
  header.style-3 .main-menu ul li .bi {
    top: 8px;
    font-size: 20px;
    color: var(--title-color);
  }
}
header.style-3.two {
  padding: 0px 11%;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  header.style-3.two {
    padding: 0px 7%;
  }
}
@media (max-width: 1399px) {
  header.style-3.two {
    padding: 0px 5%;
  }
}
header.style-3.two .main-menu > ul > li {
  padding: 0 28px;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  header.style-3.two .main-menu > ul > li {
    padding: 0 20px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  header.style-3.two .main-menu > ul > li {
    padding: 0 15px;
  }
}
@media (max-width: 1199px) {
  header.style-3.two .main-menu > ul > li {
    padding: 0 15px;
  }
}
@media (max-width: 991px) {
  header.style-3.two .main-menu > ul > li {
    padding: 0 9px;
  }
}
header.style-3.two .main-menu > ul > li > a {
  padding: 25px 0;
}
header.style-3.two .main-menu > ul > li > a::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 26px;
  left: 1px;
  width: 100%;
  border-bottom: 1px solid var(--primary-color1);
  transform-origin: right center;
  transform: scale(0, 1);
  transition: transform 0.5s;
}
@media (max-width: 991px) {
  header.style-3.two .main-menu > ul > li > a::after {
    display: none;
  }
}
header.style-3.two .main-menu > ul > li.menu-item-has-children {
  transition: all 0.55s ease-in-out;
}
header.style-3.two .main-menu > ul > li.menu-item-has-children::after {
  content: "\f287";
  font-family: "bootstrap-icons";
  font-weight: 500;
  position: absolute;
  top: 51%;
  transform: translateY(-50%);
  right: 10px;
  font-size: 6px;
  line-height: 1;
  color: rgba(16, 12, 8, 0.35);
  transition: all 0.55s ease-in-out;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  header.style-3.two .main-menu > ul > li.menu-item-has-children::after {
    right: 4px;
    top: 51%;
  }
}
@media (max-width: 1199px) {
  header.style-3.two .main-menu > ul > li.menu-item-has-children::after {
    right: 0px;
    top: 51%;
  }
}
@media (max-width: 991px) {
  header.style-3.two .main-menu > ul > li.menu-item-has-children::after {
    display: none;
    visibility: hidden;
  }
}
header.style-3.two .main-menu > ul > li:hover.menu-item-has-children::after {
  color: var(--primary-color1);
}
header.style-3.two .main-menu > ul > li:hover a::after {
  transform-origin: left center;
  transform: scale(1, 1);
}
header.style-3.two .main-menu > ul > li.active > a {
  color: var(--primary-color1);
}
header.style-3.two .main-menu > ul > li.active > a::before {
  content: "";
  display: block;
  position: absolute;
  left: 1px;
  width: 100%;
  border-bottom: 1px solid var(--primary-color1);
  transform: translateX(0);
  height: unset;
  border-radius: unset;
  bottom: 26px;
}
@media (max-width: 991px) {
  header.style-3.two .main-menu > ul > li.active > a::before {
    display: none;
  }
}
@media only screen and (max-width: 991px) {
  header.style-3.two .mobile-logo-area .menu-close-btn {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    border: 1px solid var(--title-color);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.35s;
  }
  header.style-3.two .mobile-logo-area .menu-close-btn i {
    font-size: 26px;
    line-height: 1;
    margin-top: 2px;
    color: var(--title-color);
    transition: 0.35s;
  }
  header.style-3.two .mobile-logo-area .menu-close-btn:hover {
    background-color: var(--title-color);
  }
  header.style-3.two .mobile-logo-area .menu-close-btn:hover i {
    color: var(--white-color);
  }
  header.style-3.two .dropdown-icon {
    color: var(--white-color);
  }
  header.style-3.two .main-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 280px;
    padding: 30px 20px !important;
    z-index: 99999;
    height: 100%;
    overflow: auto;
    background: #fff;
    border-right: 1px solid #eee;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.03);
  }
  header.style-3.two .main-menu.show-menu {
    transform: translateX(0);
  }
  header.style-3.two .main-menu .mobile-menu-logo {
    text-align: left;
    padding-top: 20px;
    display: block;
    padding-bottom: 8px;
  }
  header.style-3.two .main-menu .menu-list {
    padding-top: 50px;
    padding-bottom: 0px;
  }
  header.style-3.two .main-menu ul {
    float: none;
    text-align: left;
    padding: 5px 0px 20px 0;
  }
  header.style-3.two .main-menu ul li {
    display: block;
    position: relative;
    padding: 0 5px;
  }
  header.style-3.two .main-menu ul li i {
    display: block;
  }
  header.style-3.two .main-menu ul li a {
    padding: 10px 0;
    display: block;
  }
  header.style-3.two .main-menu ul li ul.sub-menu {
    position: static;
    min-width: 200px;
    background: 0 0;
    border: none;
    opacity: 1;
    visibility: visible;
    box-shadow: none;
    transform: none;
    transition: none;
    display: none;
    margin-top: 0 !important;
    transform: translateY(0px);
    padding-left: 10px;
  }
  header.style-3.two .main-menu ul li ul.sub-menu > li {
    border-bottom: 1px solid transparent;
  }
  header.style-3.two .main-menu ul li ul.sub-menu > li a {
    color: var(--title-color);
    padding: 12px 0px;
  }
  header.style-3.two .main-menu ul li ul.sub-menu > li a:hover {
    color: var(--primary-color1);
    margin-left: 10px;
  }
  header.style-3.two .main-menu ul li ul.sub-menu > li a.active {
    color: var(--primary-color1);
  }
  header.style-3.two .main-menu ul li ul.sub-menu > li i {
    color: var(--primary-color1);
    right: -13px;
  }
  header.style-3.two .main-menu ul li .bi {
    top: 8px;
    font-size: 20px;
    color: var(--title-color);
  }
}
header.style-3.three {
  padding: 0px 10%;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  header.style-3.three {
    padding: 0px 7%;
  }
}
@media (max-width: 1399px) {
  header.style-3.three {
    padding: 0px 5%;
  }
}
header.style-3.three .main-menu > ul > li {
  padding: 0 28px;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  header.style-3.three .main-menu > ul > li {
    padding: 0 20px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  header.style-3.three .main-menu > ul > li {
    padding: 0 15px;
  }
}
@media (max-width: 1199px) {
  header.style-3.three .main-menu > ul > li {
    padding: 0 15px;
  }
}
@media (max-width: 991px) {
  header.style-3.three .main-menu > ul > li {
    padding: 0 9px;
  }
}
header.style-3.three .main-menu > ul > li > a {
  padding: 25px 0;
}
header.style-3.three .main-menu > ul > li > a::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 26px;
  left: 1px;
  width: 100%;
  border-bottom: 2px solid var(--primary-color1);
  transform-origin: right center;
  transform: scale(0, 1);
  transition: transform 0.5s;
}
@media (max-width: 991px) {
  header.style-3.three .main-menu > ul > li > a::after {
    display: none;
  }
}
header.style-3.three .main-menu > ul > li.menu-item-has-children {
  transition: all 0.55s ease-in-out;
}
header.style-3.three .main-menu > ul > li.menu-item-has-children::after {
  content: "\f287";
  font-family: "bootstrap-icons";
  font-weight: 500;
  position: absolute;
  top: 51%;
  transform: translateY(-50%);
  right: 10px;
  font-size: 6px;
  line-height: 1;
  color: rgba(16, 12, 8, 0.35);
  transition: all 0.55s ease-in-out;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  header.style-3.three .main-menu > ul > li.menu-item-has-children::after {
    right: 4px;
    top: 51%;
  }
}
@media (max-width: 1199px) {
  header.style-3.three .main-menu > ul > li.menu-item-has-children::after {
    right: 0px;
    top: 51%;
  }
}
@media (max-width: 991px) {
  header.style-3.three .main-menu > ul > li.menu-item-has-children::after {
    display: none;
    visibility: hidden;
  }
}
header.style-3.three .main-menu > ul > li:hover.menu-item-has-children::after {
  color: var(--primary-color1);
}
header.style-3.three .main-menu > ul > li:hover a::after {
  transform-origin: left center;
  transform: scale(1, 1);
}
header.style-3.three .main-menu > ul > li.active > a {
  color: var(--primary-color1);
}
header.style-3.three .main-menu > ul > li.active > a::before {
  content: "";
  display: block;
  position: absolute;
  left: 1px;
  width: 100%;
  background: linear-gradient(90deg, var(--primary-color1-opc) 0%, rgba(99, 171, 69, 0) 102.51%);
  transform: translateX(0);
  height: 2px;
  border-radius: unset;
  bottom: 26px;
}
@media (max-width: 991px) {
  header.style-3.three .main-menu > ul > li.active > a::before {
    display: none;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  header.style-3.three .nav-right {
    gap: 30px;
  }
}
header.style-3.three .nav-right .icon-list {
  gap: 0;
}
header.style-3.three .nav-right .icon-list li {
  cursor: pointer;
  padding: 0 20px;
  border-right: 1px solid rgba(34, 34, 34, 0.15);
}
@media (min-width: 1200px) and (max-width: 1399px) {
  header.style-3.three .nav-right .icon-list li {
    padding: 0 15px;
  }
}
header.style-3.three .nav-right .icon-list li svg {
  stroke: none;
}
header.style-3.three .nav-right .icon-list li:first-child {
  padding-left: 0;
}
header.style-3.three .nav-right .icon-list li:first-child svg {
  fill: none;
  stroke: var(--title-color);
}
@media only screen and (max-width: 991px) {
  header.style-3.three .dropdown-icon {
    color: var(--white-color);
  }
  header.style-3.three .main-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 280px;
    padding: 30px 20px !important;
    z-index: 99999;
    height: 100%;
    overflow: auto;
    background: #fff;
    border-right: 1px solid #eee;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.03);
  }
  header.style-3.three .main-menu.show-menu {
    transform: translateX(0);
  }
  header.style-3.three .main-menu .mobile-menu-logo {
    text-align: left;
    padding-top: 20px;
    display: block;
    padding-bottom: 8px;
  }
  header.style-3.three .main-menu .menu-list {
    padding-top: 50px;
    padding-bottom: 0px;
  }
  header.style-3.three .main-menu ul {
    float: none;
    text-align: left;
    padding: 5px 0px 20px 0;
  }
  header.style-3.three .main-menu ul li {
    display: block;
    position: relative;
    padding: 0 5px;
  }
  header.style-3.three .main-menu ul li i {
    display: block;
  }
  header.style-3.three .main-menu ul li a {
    padding: 10px 0;
    display: block;
  }
  header.style-3.three .main-menu ul li ul.sub-menu {
    position: static;
    min-width: 200px;
    background: 0 0;
    border: none;
    opacity: 1;
    visibility: visible;
    box-shadow: none;
    transform: none;
    transition: none;
    display: none;
    margin-top: 0 !important;
    transform: translateY(0px);
    padding-left: 10px;
  }
  header.style-3.three .main-menu ul li ul.sub-menu > li {
    border-bottom: 1px solid transparent;
  }
  header.style-3.three .main-menu ul li ul.sub-menu > li a {
    color: var(--title-color);
    padding: 12px 0px;
  }
  header.style-3.three .main-menu ul li ul.sub-menu > li a:hover {
    color: var(--primary-color1);
    margin-left: 10px;
  }
  header.style-3.three .main-menu ul li ul.sub-menu > li a.active {
    color: var(--primary-color1);
  }
  header.style-3.three .main-menu ul li ul.sub-menu > li i {
    color: var(--primary-color1);
    right: -13px;
  }
  header.style-3.three .main-menu ul li .bi {
    top: 8px;
    font-size: 20px;
    color: var(--title-color);
  }
}
header.style-3.four {
  padding: 0px 30px;
  position: fixed;
  background-color: transparent;
  border-bottom: 1px solid rgba(16, 12, 8, 0.2);
}
header.style-3.four.sticky {
  background-color: var(--white-color);
}
header.style-3.four .main-menu > ul > li {
  padding: 0 28px;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  header.style-3.four .main-menu > ul > li {
    padding: 0 20px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  header.style-3.four .main-menu > ul > li {
    padding: 0 15px;
  }
}
@media (max-width: 1199px) {
  header.style-3.four .main-menu > ul > li {
    padding: 0 15px;
  }
}
@media (max-width: 991px) {
  header.style-3.four .main-menu > ul > li {
    padding: 0 9px;
  }
}
header.style-3.four .main-menu > ul > li > a {
  padding: 25px 0;
}
header.style-3.four .main-menu > ul > li > a::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 26px;
  left: 1px;
  width: 100%;
  border-bottom: 2px solid var(--primary-color1);
  transform-origin: right center;
  transform: scale(0, 1);
  transition: transform 0.5s;
}
@media (max-width: 991px) {
  header.style-3.four .main-menu > ul > li > a::after {
    display: none;
  }
}
header.style-3.four .main-menu > ul > li.menu-item-has-children {
  transition: all 0.55s ease-in-out;
}
header.style-3.four .main-menu > ul > li.menu-item-has-children::after {
  content: "\f151";
  font-family: bootstrap-icons !important;
  font-size: 10px;
  font-weight: 500;
  position: absolute;
  top: 51%;
  transform: translateY(-50%);
  right: 10px;
  line-height: 1;
  color: rgba(16, 12, 8, 0.35);
  transition: all 0.55s ease-in-out;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  header.style-3.four .main-menu > ul > li.menu-item-has-children::after {
    right: 5px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  header.style-3.four .main-menu > ul > li.menu-item-has-children::after {
    right: 4px;
    top: 51%;
  }
}
@media (max-width: 1199px) {
  header.style-3.four .main-menu > ul > li.menu-item-has-children::after {
    right: 0px;
    top: 51%;
  }
}
@media (max-width: 991px) {
  header.style-3.four .main-menu > ul > li.menu-item-has-children::after {
    display: none;
    visibility: hidden;
  }
}
header.style-3.four .main-menu > ul > li:hover.menu-item-has-children::after {
  color: var(--primary-color1);
}
header.style-3.four .main-menu > ul > li:hover a::after {
  transform-origin: left center;
  transform: scale(1, 1);
}
header.style-3.four .main-menu > ul > li.active > a {
  color: var(--primary-color1);
}
header.style-3.four .main-menu > ul > li.active > a::before {
  display: none;
}
header.style-3.four .main-menu > ul > li.active.menu-item-has-children::after {
  color: var(--primary-color1);
}
@media (min-width: 1200px) and (max-width: 1399px) {
  header.style-3.four .nav-right {
    gap: 30px;
  }
}
header.style-3.four .nav-right .icon-list {
  gap: 0;
}
header.style-3.four .nav-right .icon-list li {
  cursor: pointer;
  padding: 0 20px;
  border-right: 1px solid rgba(34, 34, 34, 0.15);
}
@media (min-width: 1200px) and (max-width: 1399px) {
  header.style-3.four .nav-right .icon-list li {
    padding: 0 15px;
  }
}
header.style-3.four .nav-right .icon-list li svg {
  stroke: none;
}
header.style-3.four .nav-right .icon-list li:first-child {
  padding-left: 0;
}
header.style-3.four .nav-right .icon-list li:first-child svg {
  fill: none;
  stroke: var(--title-color);
}
@media only screen and (max-width: 991px) {
  header.style-3.four .dropdown-icon {
    color: var(--white-color);
  }
  header.style-3.four .main-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 280px;
    padding: 30px 20px !important;
    z-index: 99999;
    height: 100%;
    overflow: auto;
    background: #fff;
    border-right: 1px solid #eee;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.03);
  }
  header.style-3.four .main-menu.show-menu {
    transform: translateX(0);
  }
  header.style-3.four .main-menu .mobile-menu-logo {
    text-align: left;
    padding-top: 20px;
    display: block;
    padding-bottom: 8px;
  }
  header.style-3.four .main-menu .menu-list {
    padding-top: 50px;
    padding-bottom: 0px;
  }
  header.style-3.four .main-menu ul {
    float: none;
    text-align: left;
    padding: 5px 0px 20px 0;
  }
  header.style-3.four .main-menu ul li {
    display: block;
    position: relative;
    padding: 0 5px;
  }
  header.style-3.four .main-menu ul li i {
    display: block;
  }
  header.style-3.four .main-menu ul li a {
    padding: 10px 0;
    display: block;
  }
  header.style-3.four .main-menu ul li ul.sub-menu {
    position: static;
    min-width: 200px;
    background: 0 0;
    border: none;
    opacity: 1;
    visibility: visible;
    box-shadow: none;
    transform: none;
    transition: none;
    display: none;
    margin-top: 0 !important;
    transform: translateY(0px);
    padding-left: 10px;
  }
  header.style-3.four .main-menu ul li ul.sub-menu > li {
    border-bottom: 1px solid transparent;
  }
  header.style-3.four .main-menu ul li ul.sub-menu > li a {
    color: var(--title-color);
    padding: 12px 0px;
  }
  header.style-3.four .main-menu ul li ul.sub-menu > li a:hover {
    color: var(--primary-color1);
    margin-left: 10px;
  }
  header.style-3.four .main-menu ul li ul.sub-menu > li a.active {
    color: var(--primary-color1);
  }
  header.style-3.four .main-menu ul li ul.sub-menu > li i {
    color: var(--primary-color1);
    right: -13px;
  }
  header.style-3.four .main-menu ul li .bi {
    top: 8px;
    font-size: 20px;
    color: var(--title-color);
  }
}

.right-sidebar-menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 540px;
  z-index: 99999;
  height: 100%;
  overflow: auto;
  background-image: url(../img/sidebar-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: var(--white-color);
  border-right: 1px solid #eee;
  transform: translateX(120%);
  transform-origin: right;
  transition: transform 0.5s ease-in;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.03);
}
.right-sidebar-menu::-webkit-scrollbar {
  width: 0px;
}
@media (max-width: 576px) {
  .right-sidebar-menu {
    width: 320px;
  }
}
.right-sidebar-menu.show-right-menu {
  transform: translateX(0);
}
.right-sidebar-menu .right-sidebar-close-btn {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  border: 1px solid var(--title-color);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.35s;
}
.right-sidebar-menu .right-sidebar-close-btn i {
  font-size: 26px;
  line-height: 1;
  margin-top: 2px;
  color: var(--title-color);
  transition: 0.35s;
}
.right-sidebar-menu .right-sidebar-close-btn:hover {
  background-color: var(--title-color);
}
.right-sidebar-menu .right-sidebar-close-btn:hover i {
  color: var(--white-color);
}
.right-sidebar-menu .sidebar-logo-area {
  text-align: left;
  padding: 30px;
  display: block;
  border-bottom: 1px solid rgba(16, 12, 8, 0.2);
  margin-bottom: 50px;
}
@media (max-width: 576px) {
  .right-sidebar-menu .sidebar-logo-area {
    padding: 20px;
    margin-bottom: 40px;
  }
}
.right-sidebar-menu .sidebar-content-wrap {
  padding: 0 30px;
}
@media (max-width: 576px) {
  .right-sidebar-menu .sidebar-content-wrap {
    padding: 0 20px;
  }
}
.right-sidebar-menu .sidebar-content-wrap h4 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 25px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 25px;
}
.right-sidebar-menu .sidebar-content-wrap .category-wrapper {
  margin-bottom: 70px;
}
@media (max-width: 991px) {
  .right-sidebar-menu .sidebar-content-wrap .category-wrapper {
    margin-bottom: 60px;
  }
}
@media (max-width: 576px) {
  .right-sidebar-menu .sidebar-content-wrap .category-wrapper {
    margin-bottom: 50px;
  }
}
.right-sidebar-menu .sidebar-content-wrap .category-wrapper .category-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}
@media (max-width: 576px) {
  .right-sidebar-menu .sidebar-content-wrap .category-wrapper .category-list {
    gap: 15px;
  }
}
.right-sidebar-menu .sidebar-content-wrap .category-wrapper .category-list li .single-category {
  padding: 20px 15px;
  border-radius: 5px;
  background-color: #E8F4E3;
  display: block;
  text-align: center;
  min-width: 145px;
  width: 100%;
  transition: 0.5s;
}
@media (max-width: 576px) {
  .right-sidebar-menu .sidebar-content-wrap .category-wrapper .category-list li .single-category {
    min-width: 130px;
    max-width: 130px;
    padding: 15px 10px;
  }
}
.right-sidebar-menu .sidebar-content-wrap .category-wrapper .category-list li .single-category .icon {
  margin-bottom: 15px;
}
.right-sidebar-menu .sidebar-content-wrap .category-wrapper .category-list li .single-category .icon svg {
  fill: var(--title-color);
  transition: 0.5s;
}
.right-sidebar-menu .sidebar-content-wrap .category-wrapper .category-list li .single-category h6 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 0;
  transition: 0.5s;
}
@media (max-width: 576px) {
  .right-sidebar-menu .sidebar-content-wrap .category-wrapper .category-list li .single-category h6 {
    font-size: 14px;
  }
}
.right-sidebar-menu .sidebar-content-wrap .category-wrapper .category-list li .single-category:hover {
  background-color: var(--primary-color1);
}
.right-sidebar-menu .sidebar-content-wrap .category-wrapper .category-list li .single-category:hover .icon svg {
  fill: var(--white-color);
}
.right-sidebar-menu .sidebar-content-wrap .category-wrapper .category-list li .single-category:hover h6 {
  color: var(--white-color);
}
.right-sidebar-menu .sidebar-content-wrap .destination-wrapper {
  margin-bottom: 100px;
}
@media (max-width: 991px) {
  .right-sidebar-menu .sidebar-content-wrap .destination-wrapper {
    margin-bottom: 80px;
  }
}
@media (max-width: 576px) {
  .right-sidebar-menu .sidebar-content-wrap .destination-wrapper {
    margin-bottom: 60px;
  }
}
.right-sidebar-menu .sidebar-content-wrap .destination-wrapper .destination-card2 .batch span {
  font-size: 10px;
}
.right-sidebar-menu .sidebar-content-wrap .destination-wrapper .destination-card2 .destination-card2-content span {
  font-size: 12px;
}
.right-sidebar-menu .sidebar-content-wrap .destination-wrapper .destination-card2 .destination-card2-content h4 a {
  font-size: 22px;
}
.right-sidebar-menu .sidebar-content-wrap .destination-wrapper .slide-and-view-btn-grp .destination-sidebar-prev svg, .right-sidebar-menu .sidebar-content-wrap .destination-wrapper .slide-and-view-btn-grp .destination-sidebar-next svg {
  stroke: var(--primary-color2);
  transition: 0.5s;
}
.right-sidebar-menu .sidebar-content-wrap .destination-wrapper .slide-and-view-btn-grp .destination-sidebar-prev:hover svg, .right-sidebar-menu .sidebar-content-wrap .destination-wrapper .slide-and-view-btn-grp .destination-sidebar-next:hover svg {
  stroke: var(--primary-color1);
}
.right-sidebar-menu .sidebar-bottom {
  padding: 30px;
  border-top: 1px solid rgba(16, 12, 8, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}
@media (max-width: 576px) {
  .right-sidebar-menu .sidebar-bottom {
    flex-wrap: wrap;
  }
}
.right-sidebar-menu .sidebar-bottom .hotline-area {
  display: flex;
  align-items: center;
  gap: 20px;
}
.right-sidebar-menu .sidebar-bottom .hotline-area .icon svg {
  fill: var(--primary-color1);
}
.right-sidebar-menu .sidebar-bottom .hotline-area .content {
  position: relative;
  line-height: 1;
}
.right-sidebar-menu .sidebar-bottom .hotline-area .content::after {
  content: "";
  height: 35px;
  width: 1px;
  background-color: rgba(16, 12, 8, 0.2);
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}
.right-sidebar-menu .sidebar-bottom .hotline-area .content span {
  font-family: var(--font-rubik);
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: var(--title-color);
  margin-bottom: 5px;
  display: inline-block;
}
.right-sidebar-menu .sidebar-bottom .hotline-area .content h6 {
  margin-bottom: 0;
  line-height: 1;
}
.right-sidebar-menu .sidebar-bottom .hotline-area .content h6 a {
  font-family: var(--font-rubik);
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: var(--primary-color1);
  transition: 0.5s;
}
.right-sidebar-menu .sidebar-bottom .hotline-area .content h6 a:hover {
  color: var(--title-color);
}
.right-sidebar-menu .sidebar-bottom .email-area {
  display: flex;
  align-items: center;
  gap: 10px;
}
.right-sidebar-menu .sidebar-bottom .email-area .icon svg {
  fill: var(--primary-color1);
}
.right-sidebar-menu .sidebar-bottom .email-area .content {
  position: relative;
  line-height: 1;
}
.right-sidebar-menu .sidebar-bottom .email-area .content span {
  font-family: var(--font-rubik);
  font-weight: 400;
  font-size: 12px;
  line-height: 1;
  color: var(--title-color);
  margin-bottom: 5px;
  display: inline-block;
}
.right-sidebar-menu .sidebar-bottom .email-area .content h6 {
  margin-bottom: 0;
  line-height: 1;
}
.right-sidebar-menu .sidebar-bottom .email-area .content h6 a {
  font-family: var(--font-rubik);
  font-weight: 500;
  font-size: 15px;
  color: var(--primary-color1);
  transition: 0.5s;
}
.right-sidebar-menu .sidebar-bottom .email-area .content h6 a:hover {
  color: var(--title-color);
}

.login-modal .modal-dialog {
  animation: zoomIn 1s;
}
.login-modal .modal-dialog .modal-content {
  border-radius: 20px;
  position: relative;
}
@media (max-width: 576px) {
  .login-modal .modal-dialog .modal-content {
    border-radius: 10px;
  }
}
.login-modal .modal-dialog .modal-content .modal-clode-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: #F1F1F1;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-size: 22px;
  color: var(--white-color);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
@media (max-width: 767px) {
  .login-modal .modal-dialog .modal-content .modal-clode-btn {
    width: 30px;
    height: 30px;
    top: 10px;
    right: 10px;
  }
}
.login-modal .modal-dialog .modal-content .modal-clode-btn::before, .login-modal .modal-dialog .modal-content .modal-clode-btn::after {
  transform: rotate(-45deg);
  content: "";
  position: absolute;
  top: 50%;
  left: calc(50% - 7px);
  display: block;
  height: 1px;
  width: 15px;
  background-color: var(--title-color);
  transition: all 0.3s ease-out;
}
.login-modal .modal-dialog .modal-content .modal-clode-btn::after {
  transform: rotate(-135deg);
}
.login-modal .modal-dialog .modal-content .modal-clode-btn:hover::before, .login-modal .modal-dialog .modal-content .modal-clode-btn:hover::after {
  transform: rotate(0deg);
}
.login-modal .modal-dialog .modal-content .modal-header {
  padding: 0;
  border: unset;
}
.login-modal .modal-dialog .modal-content .modal-header img {
  border-radius: 20px 20px 0 0;
}
@media (max-width: 576px) {
  .login-modal .modal-dialog .modal-content .modal-header img {
    min-height: 110px;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 10px 10px 0 0;
  }
}
.login-modal .modal-dialog .modal-content .modal-body {
  padding: 45px 40px;
}
@media (max-width: 576px) {
  .login-modal .modal-dialog .modal-content .modal-body {
    padding: 30px 20px;
  }
}
.login-modal .modal-dialog .modal-content .modal-body .login-registration-form .form-title {
  text-align: center;
  margin-bottom: 20px;
}
.login-modal .modal-dialog .modal-content .modal-body .login-registration-form .form-title h2 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 35px;
  font-weight: 500;
  letter-spacing: 0.6px;
  line-height: 1;
  margin-bottom: 15px;
}
@media (max-width: 576px) {
  .login-modal .modal-dialog .modal-content .modal-body .login-registration-form .form-title h2 {
    font-size: 30px;
  }
}
.login-modal .modal-dialog .modal-content .modal-body .login-registration-form .form-title p {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 15px;
  font-weight: 400;
  line-height: 1.4;
  margin-bottom: 0;
}
.login-modal .modal-dialog .modal-content .modal-body .login-registration-form .form-inner {
  position: relative;
}
.login-modal .modal-dialog .modal-content .modal-body .login-registration-form .form-inner input {
  border: 1px solid var(--title-color);
  border-radius: 200px;
  background-color: transparent;
  width: 100%;
  height: 46px;
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 13px;
  padding: 15px 20px;
}
.login-modal .modal-dialog .modal-content .modal-body .login-registration-form .form-inner input:focus {
  border: 1px solid var(--primary-color1);
}
.login-modal .modal-dialog .modal-content .modal-body .login-registration-form .form-inner input::-moz-placeholder {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.3px;
  text-transform: capitalize;
}
.login-modal .modal-dialog .modal-content .modal-body .login-registration-form .form-inner input::placeholder {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.3px;
  text-transform: capitalize;
}
.login-modal .modal-dialog .modal-content .modal-body .login-registration-form .form-remember-forget {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
  margin-bottom: 30px;
}
.login-modal .modal-dialog .modal-content .modal-body .login-registration-form .form-remember-forget .remember .custom-check-box {
  opacity: 0;
  display: none;
}
.login-modal .modal-dialog .modal-content .modal-body .login-registration-form .form-remember-forget .remember .custom-check-box + label {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
}
.login-modal .modal-dialog .modal-content .modal-body .login-registration-form .form-remember-forget .remember .custom-check-box + label::before {
  content: "";
  width: 15px;
  height: 15px;
  border: 1px solid var(--primary-color1);
}
.login-modal .modal-dialog .modal-content .modal-body .login-registration-form .form-remember-forget .remember .custom-check-box:checked + label::before {
  content: "\f26e";
  color: var(--white-color);
  font-family: "bootstrap-icons";
  background-color: var(--primary-color1);
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-modal .modal-dialog .modal-content .modal-body .login-registration-form .form-remember-forget .remember label {
  color: var(--primary-color1);
  font-family: var(--font-jost);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;
  text-transform: capitalize;
}
.login-modal .modal-dialog .modal-content .modal-body .login-registration-form .form-remember-forget .forget-pass {
  color: var(--primary-color1);
  font-family: var(--font-jost);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;
  text-transform: capitalize;
}
.login-modal .modal-dialog .modal-content .modal-body .login-registration-form .login-btn {
  border-radius: 35px;
  background-color: var(--title-color);
  font-family: var(--font-rubik);
  font-weight: 500;
  font-size: 16px;
  color: var(--white-color);
  letter-spacing: 0.48px;
  text-transform: capitalize;
  line-height: 1;
  padding: 15px 26px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  white-space: nowrap;
  width: 100%;
}
@media (max-width: 576px) {
  .login-modal .modal-dialog .modal-content .modal-body .login-registration-form .login-btn {
    font-size: 15px;
  }
}
.login-modal .modal-dialog .modal-content .modal-body .login-registration-form .login-btn::after {
  position: absolute;
  content: "";
  border-radius: 30px;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background-color: var(--primary-color2);
  transform-origin: right;
  transform: scalex(0);
  z-index: -1;
  transition: transform 0.7s;
}
.login-modal .modal-dialog .modal-content .modal-body .login-registration-form .login-btn:hover {
  color: var(--title-color);
}
.login-modal .modal-dialog .modal-content .modal-body .login-registration-form .login-btn:hover::after {
  transform: scalex(1);
  transform-origin: left;
}
.login-modal .modal-dialog .modal-content .modal-body .login-registration-form .divider {
  position: relative;
  text-align: center;
  margin-bottom: 20px;
}
.login-modal .modal-dialog .modal-content .modal-body .login-registration-form .divider span {
  color: #DBDBDB;
  text-align: center;
  font-family: var(--font-rubik);
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  display: inline-block;
  padding: 0 12px;
  background-color: var(--white-color);
  position: relative;
  z-index: 1;
}
.login-modal .modal-dialog .modal-content .modal-body .login-registration-form .divider::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background-color: #DBDBDB;
  width: 100%;
  height: 1px;
}
.login-modal .modal-dialog .modal-content .modal-body .login-registration-form .google-login-btn {
  border-radius: 35px;
  background-color: var(--white-color);
  border: 1px solid var(--title-color);
  font-family: var(--font-rubik);
  font-weight: 500;
  font-size: 16px;
  color: var(--title-color);
  letter-spacing: 0.48px;
  text-transform: capitalize;
  line-height: 1;
  padding: 10px 26px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition: 0.5s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  white-space: nowrap;
  width: 100%;
}
@media (max-width: 576px) {
  .login-modal .modal-dialog .modal-content .modal-body .login-registration-form .google-login-btn {
    font-size: 15px;
  }
}
.login-modal .modal-dialog .modal-content .modal-body .login-registration-form .google-login-btn::after {
  position: absolute;
  content: "";
  border-radius: 30px;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background-color: var(--title-color);
  transform-origin: right;
  transform: scalex(0);
  z-index: -1;
  transition: transform 0.7s;
}
.login-modal .modal-dialog .modal-content .modal-body .login-registration-form .google-login-btn:hover {
  color: var(--white-color);
}
.login-modal .modal-dialog .modal-content .modal-body .login-registration-form .google-login-btn:hover::after {
  transform: scalex(1);
  transform-origin: left;
}

/*=====================================
   6.Home1 Banner section
========================================*/
.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: var(--primary-color1);
  color: white;
}

.home1-banner-area {
  padding: 0 70px;
  border-radius: 30px;
  position: relative;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home1-banner-area {
    padding: 0 20px;
  }
}
@media (max-width: 1399px) {
  .home1-banner-area {
    padding: 0 20px;
  }
}
@media (max-width: 767px) {
  .home1-banner-area {
    padding: 0;
  }
}
.home1-banner-area .swiper-slide-active .eg-tag {
  animation: zoomIn 2s;
}
.home1-banner-area .swiper-slide-active h1, .home1-banner-area .swiper-slide-active h2 {
  animation: fadeInDown 1.7s;
}
.home1-banner-area .swiper-slide-active p {
  animation: fadeInUp 1.7s;
}
.home1-banner-area .swiper-slide-active .banner-content-bottom {
  animation: fadeInUp 1.7s;
}
.home1-banner-area .home1-banner-wrapper {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 30px 30px 0 0;
  position: relative;
}
.home1-banner-area .home1-banner-wrapper .home1-banner-content {
  text-align: center;
  padding: 180px 0;
  max-width: 860px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
@media (max-width: 991px) {
  .home1-banner-area .home1-banner-wrapper .home1-banner-content {
    padding: 150px 0;
  }
}
@media (max-width: 767px) {
  .home1-banner-area .home1-banner-wrapper .home1-banner-content {
    padding: 110px 0;
  }
}
@media (max-width: 576px) {
  .home1-banner-area .home1-banner-wrapper .home1-banner-content {
    padding: 90px 0;
  }
}
.home1-banner-area .home1-banner-wrapper .home1-banner-content .eg-tag {
  -webkit-mask-image: url(../img/home1/vector/destination-banner-batch-bg.svg);
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: cover;
  background-color: var(--primary-color2);
  padding: 12px 72px;
  white-space: nowrap;
  display: inline-flex;
  margin-bottom: 15px;
}
.home1-banner-area .home1-banner-wrapper .home1-banner-content .eg-tag span {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  color: var(--white-color);
  font-family: var(--font-jost);
  font-size: 20px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.4px;
}
.home1-banner-area .home1-banner-wrapper .home1-banner-content .eg-tag span svg {
  fill: var(--white-color);
}
.home1-banner-area .home1-banner-wrapper .home1-banner-content h1, .home1-banner-area .home1-banner-wrapper .home1-banner-content h2 {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 65px;
  font-weight: 800;
  line-height: 1.1;
  letter-spacing: 2px;
  margin-bottom: 20px;
}
@media (max-width: 1199px) {
  .home1-banner-area .home1-banner-wrapper .home1-banner-content h1, .home1-banner-area .home1-banner-wrapper .home1-banner-content h2 {
    font-size: 55px;
  }
}
@media (max-width: 991px) {
  .home1-banner-area .home1-banner-wrapper .home1-banner-content h1, .home1-banner-area .home1-banner-wrapper .home1-banner-content h2 {
    font-size: 45px;
  }
}
@media (max-width: 576px) {
  .home1-banner-area .home1-banner-wrapper .home1-banner-content h1, .home1-banner-area .home1-banner-wrapper .home1-banner-content h2 {
    font-size: 28px;
    margin-bottom: 15px;
  }
}
.home1-banner-area .home1-banner-wrapper .home1-banner-content p {
  color: var(--white-color);
  font-family: var(--font-jost);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.9;
  letter-spacing: 0.48px;
  text-transform: capitalize;
  margin-bottom: 40px;
}
@media (max-width: 991px) {
  .home1-banner-area .home1-banner-wrapper .home1-banner-content p {
    margin-bottom: 30px;
  }
}
@media (max-width: 576px) {
  .home1-banner-area .home1-banner-wrapper .home1-banner-content p {
    font-size: 15px;
    margin-bottom: 20px;
  }
}
.home1-banner-area .home1-banner-wrapper .home1-banner-content .banner-content-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
}
@media (max-width: 576px) {
  .home1-banner-area .home1-banner-wrapper .home1-banner-content .banner-content-bottom {
    flex-wrap: wrap;
    gap: 20px;
  }
}
.home1-banner-area .home1-banner-wrapper .home1-banner-content .banner-content-bottom .rating-area {
  display: flex;
  align-items: center;
  gap: 5px;
}
.home1-banner-area .home1-banner-wrapper .home1-banner-content .banner-content-bottom .rating-area .content .text-logo {
  display: flex;
  margin-bottom: 10px;
}
.home1-banner-area .home1-banner-wrapper .home1-banner-content .banner-content-bottom .rating-area .content .text-logo svg {
  fill: var(--white-color);
}
.home1-banner-area .home1-banner-wrapper .home1-banner-content .banner-content-bottom .rating-area .content .rating {
  display: flex;
  align-items: center;
  gap: 10px;
  line-height: 1;
}
.home1-banner-area .home1-banner-wrapper .home1-banner-content .banner-content-bottom .rating-area .content .rating ul {
  display: flex;
  align-items: center;
  gap: 5px;
}
.home1-banner-area .home1-banner-wrapper .home1-banner-content .banner-content-bottom .rating-area .content .rating ul li i {
  color: #00AA6C;
}
.home1-banner-area .home1-banner-wrapper .home1-banner-content .banner-content-bottom .rating-area .content .rating span {
  color: var(--white-color);
}
.home1-banner-area .slider-btn-grp .home1-banner-prev {
  position: absolute;
  bottom: 145px;
  left: 8%;
  z-index: 99;
}
@media (max-width: 767px) {
  .home1-banner-area .slider-btn-grp .home1-banner-prev {
    display: none;
  }
}
.home1-banner-area .slider-btn-grp .home1-banner-next {
  position: absolute;
  bottom: 145px;
  right: 8%;
  z-index: 99;
}
@media (max-width: 767px) {
  .home1-banner-area .slider-btn-grp .home1-banner-next {
    display: none;
  }
}

.home1-banner-bottom {
  margin-top: -100px;
  z-index: 99;
  position: relative;
  padding: 0 70px;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home1-banner-bottom {
    padding: 0 20px;
  }
}
@media (max-width: 1399px) {
  .home1-banner-bottom {
    padding: 0 20px;
  }
}
@media (max-width: 767px) {
  .home1-banner-bottom {
    padding: 0;
  }
}
@media (max-width: 767px) {
  .home1-banner-bottom {
    margin-top: -56px;
  }
}
.home1-banner-bottom .filter-wrapper .nav-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home1-banner-bottom .filter-wrapper .nav-buttons .nav-pills {
  border-radius: 15px 15px 0px 0px;
  background: #EDF2DE;
  display: inline-flex;
  border-bottom: 1px solid #DBE1CD;
}
@media (max-width: 576px) {
  .home1-banner-bottom .filter-wrapper .nav-buttons .nav-pills {
    border-radius: unset;
    justify-content: center;
  }
}
.home1-banner-bottom .filter-wrapper .nav-buttons .nav-pills .nav-item:first-child .nav-link {
  border-radius: 15px 0 0 0;
  border-left: unset;
}
@media (max-width: 576px) {
  .home1-banner-bottom .filter-wrapper .nav-buttons .nav-pills .nav-item:first-child .nav-link {
    border-radius: unset;
  }
}
.home1-banner-bottom .filter-wrapper .nav-buttons .nav-pills .nav-item:last-child .nav-link {
  border-radius: 0 15px 0 0;
}
@media (max-width: 576px) {
  .home1-banner-bottom .filter-wrapper .nav-buttons .nav-pills .nav-item:last-child .nav-link {
    border-radius: unset;
    border-left: unset;
  }
}
@media (max-width: 576px) {
  .home1-banner-bottom .filter-wrapper .nav-buttons .nav-pills .nav-item {
    width: 50%;
  }
}
.home1-banner-bottom .filter-wrapper .nav-buttons .nav-pills .nav-item .nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  padding: 15px 40px;
  border-radius: unset;
  border-left: 1px solid #DBE1CD;
  width: 100%;
}
@media (max-width: 991px) {
  .home1-banner-bottom .filter-wrapper .nav-buttons .nav-pills .nav-item .nav-link {
    padding: 15px 20px;
  }
}
@media (max-width: 767px) {
  .home1-banner-bottom .filter-wrapper .nav-buttons .nav-pills .nav-item .nav-link {
    padding: 15px 15px;
    font-size: 15px;
  }
}
.home1-banner-bottom .filter-wrapper .nav-buttons .nav-pills .nav-item .nav-link svg {
  fill: var(--black-color);
}
.home1-banner-bottom .filter-wrapper .nav-buttons .nav-pills .nav-item .nav-link.active {
  background-color: var(--primary-color1);
  color: var(--white-color);
}
.home1-banner-bottom .filter-wrapper .nav-buttons .nav-pills .nav-item .nav-link.active svg {
  fill: var(--white-color);
}
.home1-banner-bottom .filter-wrapper .filter-group {
  border-radius: 0px 0px 30px 30px;
  background: #EDF2DE;
}
@media (max-width: 1199px) {
  .home1-banner-bottom .filter-wrapper .filter-group {
    padding: 30px 40px;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .home1-banner-bottom .filter-wrapper .filter-group {
    padding: 30px 20px;
  }
}
@media (max-width: 576px) {
  .home1-banner-bottom .filter-wrapper .filter-group {
    padding: 20px 15px;
  }
}
.home1-banner-bottom .filter-wrapper .filter-group form {
  display: flex;
  align-items: center;
}
@media (max-width: 1199px) {
  .home1-banner-bottom .filter-wrapper .filter-group form {
    flex-direction: column;
  }
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area {
  padding: 0 80px;
  width: 100%;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .home1-banner-bottom .filter-wrapper .filter-group form .filter-area {
    padding: 0 50px;
  }
}
@media (max-width: 1199px) {
  .home1-banner-bottom .filter-wrapper .filter-group form .filter-area {
    padding: 0;
    margin-bottom: 20px;
  }
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}
@media (max-width: 1199px) {
  .home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box {
    padding: 20px;
    border: 1px solid #DBE1CD;
    width: 100%;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box {
    padding: 15px 20px;
  }
}
@media (max-width: 576px) {
  .home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box {
    position: relative;
  }
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .icon svg {
  fill: var(--primary-color1);
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input {
  line-height: 1;
}
@media (max-width: 1199px) {
  .home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input {
    width: 100%;
  }
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input label {
  color: #6A6A6A;
  font-family: var(--font-rubik);
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  display: block;
  margin-bottom: 5px;
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .select-input {
  display: flex;
  align-items: center;
  line-height: 1;
  cursor: pointer;
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .select-input input {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.3px;
  background-color: transparent;
  width: 100%;
  cursor: pointer;
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .select-input input::-moz-placeholder {
  color: var(--title-color);
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .select-input input::placeholder {
  color: var(--title-color);
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .select-input i {
  color: var(--title-color);
  font-weight: 500;
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .select-input h6 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.3px;
  min-width: 188px;
  width: 100%;
  margin-bottom: 0;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .select-input h6 {
    min-width: 150px;
  }
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap {
  padding: 10px 0;
  background-color: var(--white-color);
  border-radius: 5px;
  max-width: 300px;
  min-width: 300px;
  width: 100%;
  max-height: 250px;
  overflow-y: scroll;
  position: absolute;
  top: 55px;
  left: 0;
  z-index: 9;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.08);
  display: none;
  transform: scaleY(0);
  transform-origin: top;
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap::-webkit-scrollbar {
  width: 5px; /* width of the entire scrollbar */
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap::-webkit-scrollbar-track {
  background: #f5f4f4;
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap::-webkit-scrollbar-thumb {
  background-color: #ddd; /* color of the scroll thumb */
  border-radius: 20px; /* creates padding around scroll thumb */
}
@media (max-width: 1199px) {
  .home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap {
    max-width: 100%;
    min-width: 100%;
    top: 75px;
  }
}
@media (max-width: 767px) {
  .home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap {
    top: 65px;
  }
}
@media (max-width: 576px) {
  .home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap {
    left: 50%;
  }
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap .custom-select-search-area {
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 0 10px 5px 10px;
  padding-bottom: 5px;
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap .custom-select-search-area i {
  color: var(--primary-color1);
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap .custom-select-search-area input {
  background-color: transparent;
  color: var(--title-color);
  font-size: 14px;
  font-weight: 400;
  width: 100%;
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap .option-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.5s;
  cursor: pointer;
  padding: 0 10px;
  line-height: 1;
  margin-bottom: 5px;
  position: relative;
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap .option-list li:last-child {
  margin-bottom: 0;
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap .option-list li .destination h6 {
  font-size: 14px;
  font-family: var(--font-rubik);
  color: var(--title-color);
  font-weight: 500;
  line-height: 1.4;
  margin-bottom: 0;
}
@media (max-width: 576px) {
  .home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap .option-list li .destination h6 {
    font-size: 13px;
  }
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap .option-list li .destination p {
  font-size: 12px;
  font-family: var(--font-jost);
  color: var(--text-color);
  font-weight: 400;
  margin-bottom: 0;
  line-height: 1.4;
}
@media (max-width: 576px) {
  .home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap .option-list li .destination p {
    font-size: 11px;
  }
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap .option-list li .tour span {
  color: var(--white-color);
  font-weight: 400;
  font-family: var(--font-jost);
  font-size: 12px;
  line-height: 1;
  text-align: center;
  background-color: var(--primary-color1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 576px) {
  .home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap .option-list li .tour span {
    font-size: 13px;
  }
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap .option-list li::before {
  content: "";
  height: 100%;
  width: 3px;
  background-color: var(--title-color);
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transition: 0.5s;
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap .option-list li.single-item {
  padding: 10px 15px;
  border-bottom: 1px solid #f5f4f4;
  margin-bottom: 0;
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap .option-list li.single-item:last-child {
  border-bottom: none;
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap .option-list li.single-item h6 {
  font-size: 13px;
  font-weight: 500;
  font-family: var(--font-rubik);
  color: var(--text-color);
  margin-bottom: 0;
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap .option-list li:hover {
  background: rgba(211, 211, 211, 0.231372549);
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap .option-list li:hover::before {
  opacity: 1;
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap .guest-count .single-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px;
  border-bottom: 1px solid #f5f4f4;
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap .guest-count .single-item .title h6 {
  font-size: 14px;
  font-weight: 500;
  font-family: var(--font-rubik);
  color: var(--text-color);
  margin-bottom: 0;
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap .guest-count .single-item .title span {
  font-size: 13px;
  font-weight: 400;
  font-family: var(--font-jost);
  color: var(--text-color);
  margin-bottom: 0;
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap .guest-count .single-item .quantity-counter {
  display: flex;
  align-items: center;
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap .guest-count .single-item .quantity-counter a {
  height: 20px;
  min-width: 20px;
  max-width: 20px;
  border-radius: 50%;
  border: 1px solid var(--primary-color1);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.35s;
  font-size: 17px;
  color: var(--primary-color1);
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap .guest-count .single-item .quantity-counter a:hover {
  color: var(--white-color);
  background-color: var(--primary-color1);
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap .guest-count .single-item .quantity-counter input {
  width: 40px;
  background-color: transparent;
  text-align: center;
  line-height: 1;
  color: var(--title-color);
  font-family: var(--font-jost);
  font-size: 14px;
  font-weight: 500;
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap.active {
  display: block;
  transform: scaleY(1);
  animation: fade-down 0.3s linear;
}
@keyframes fade-down2 {
  0% {
    opacity: 0;
    transform: scaleY(0) translateX(-50%);
    visibility: hidden;
  }
  100% {
    opacity: 1;
    transform: scaleY(1) translateX(-50%);
    visibility: visible;
  }
}
@media (max-width: 576px) {
  .home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap.active {
    transform: scaleY(1) translateX(-50%);
    animation: fade-down2 0.3s linear;
  }
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap.two {
  max-width: 200px;
  min-width: 200px;
  max-height: 180px;
  padding: 0;
}
@media (max-width: 1199px) {
  .home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap.two {
    max-width: 100%;
    min-width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap.two {
    left: 0;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap.two {
    left: 0;
  }
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap.no-scroll {
  overflow-y: initial;
  max-width: unset;
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown.style-2 .select-input input {
  color: var(--white-color);
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown.style-2 .select-input input::-moz-placeholder {
  color: var(--white-color);
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown.style-2 .select-input input::placeholder {
  color: var(--white-color);
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown.style-2 .select-input i {
  color: var(--white-color);
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown.style-2 .select-input h6 {
  color: var(--white-color);
  min-width: 150px;
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown.style-2 .custom-select-wrap {
  background-color: var(--title-color);
  border: unset;
  top: 47px;
}
@media (max-width: 1199px) {
  .home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown.style-2 .custom-select-wrap {
    top: 75px;
  }
}
@media (max-width: 576px) {
  .home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown.style-2 .custom-select-wrap {
    left: 50%;
    top: 65px;
  }
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown.style-2 .custom-select-wrap::-webkit-scrollbar {
  border-radius: 10px; /* width of the entire scrollbar */
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown.style-2 .custom-select-wrap::-webkit-scrollbar-track {
  background: var(--title-color);
  border-radius: 10px;
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown.style-2 .custom-select-wrap::-webkit-scrollbar-thumb {
  background-color: var(--white-color); /* color of the scroll thumb */
  border-radius: 10px; /* roundness of the scroll thumb */
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown.style-2 .custom-select-wrap .custom-select-search-area input {
  color: var(--white-color);
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown.style-2 .custom-select-wrap .option-list li::before {
  background-color: var(--white-color);
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown.style-2 .custom-select-wrap .option-list li .destination h6, .home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown.style-2 .custom-select-wrap .option-list li .destination p {
  color: var(--white-color);
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown.style-2 .custom-select-wrap .option-list li.single-item {
  border-bottom: 1px solid #282727;
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown.style-2 .custom-select-wrap .option-list li.single-item h6 {
  color: var(--white-color);
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown.style-2 .custom-select-wrap .guest-count .single-item {
  border-bottom: 1px solid #282727;
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown.style-2 .custom-select-wrap .guest-count .single-item .title h6, .home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown.style-2 .custom-select-wrap .guest-count .single-item .title span {
  color: var(--white-color);
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown.style-2 .custom-select-wrap .guest-count .single-item .quantity-counter input {
  color: var(--white-color);
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input > .quantity-counter {
  position: relative;
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input > .quantity-counter a {
  color: var(--title-color);
  line-height: 1;
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input > .quantity-counter a i {
  font-size: 14px;
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input > .quantity-counter .quantity__plus {
  position: absolute;
  top: -8px;
  right: 20px;
}
@media (max-width: 1199px) {
  .home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input > .quantity-counter .quantity__plus {
    right: 0;
  }
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input > .quantity-counter .quantity__minus {
  position: absolute;
  top: 8px;
  right: 20px;
}
@media (max-width: 1199px) {
  .home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input > .quantity-counter .quantity__minus {
    right: 0;
  }
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input > .quantity-counter .quantity__input {
  width: 100%;
  height: unset;
  border: 1px solid #eee;
  border-radius: 5px;
  background-color: transparent;
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.48px;
  border: none;
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input > .quantity-counter .quantity__input:focus {
  border: none;
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .divider {
  position: relative;
}
.home1-banner-bottom .filter-wrapper .filter-group form .filter-area .divider::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  background-color: #DBE1CD;
  width: 1px;
  height: 78px;
}
@media (max-width: 1199px) {
  .home1-banner-bottom .filter-wrapper .filter-group form .filter-area .divider::after {
    display: none;
  }
}
.home1-banner-bottom .filter-wrapper .filter-group form button {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.54px;
  white-space: nowrap;
  background-color: var(--primary-color1);
  padding: 30px 53px;
  border-radius: 0px 0px 30px 0px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.home1-banner-bottom .filter-wrapper .filter-group form button::after {
  position: absolute;
  content: "";
  display: block;
  left: 15%;
  right: -20%;
  top: -4%;
  height: 150%;
  width: 150%;
  bottom: 0;
  border-radius: 2px;
  background-color: var(--title-color);
  transform: skewX(45deg) scale(0, 1);
  z-index: -1;
  transition: all 0.5s ease-out 0s;
}
.home1-banner-bottom .filter-wrapper .filter-group form button:hover {
  color: var(--white-color);
}
.home1-banner-bottom .filter-wrapper .filter-group form button:hover::after {
  transform: skewX(45deg) scale(1, 1);
}
@media (max-width: 1199px) {
  .home1-banner-bottom .filter-wrapper .filter-group form button {
    padding: 20px 53px;
    border-radius: 5px;
  }
}
@media (max-width: 576px) {
  .home1-banner-bottom .filter-wrapper .filter-group form button {
    padding: 16px 40px;
    font-size: 16px;
  }
}
.home1-banner-bottom.style-2 {
  margin-top: 0;
  padding: 0;
}
.home1-banner-bottom.style-2 .filter-wrapper {
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;
}
.home1-banner-bottom.style-2 .filter-wrapper .nav-buttons .nav-pills {
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-bottom: none;
  background: linear-gradient(112deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.05) 100%);
  box-shadow: 19px 15px 46px 0px rgba(0, 0, 0, 0.14);
  -webkit-backdrop-filter: blur(27.5px);
          backdrop-filter: blur(27.5px);
}
@media (max-width: 1199px) {
  .home1-banner-bottom.style-2 .filter-wrapper .nav-buttons .nav-pills {
    background: linear-gradient(112deg, rgba(16, 12, 8, 0.5) 0%, rgba(16, 12, 8, 0.8) 100%);
    -webkit-backdrop-filter: blur(120.5px);
            backdrop-filter: blur(120.5px);
  }
}
.home1-banner-bottom.style-2 .filter-wrapper .nav-buttons .nav-pills .nav-item .nav-link {
  color: var(--white-color);
  border-color: rgba(255, 255, 255, 0.15);
  padding: 15px 30px;
}
@media (max-width: 991px) {
  .home1-banner-bottom.style-2 .filter-wrapper .nav-buttons .nav-pills .nav-item .nav-link {
    padding: 13px 28.5px;
  }
}
@media (max-width: 576px) {
  .home1-banner-bottom.style-2 .filter-wrapper .nav-buttons .nav-pills .nav-item .nav-link {
    padding: 12px 15.5px;
  }
}
.home1-banner-bottom.style-2 .filter-wrapper .nav-buttons .nav-pills .nav-item .nav-link svg {
  fill: var(--white-color);
}
.home1-banner-bottom.style-2 .filter-wrapper .filter-group {
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: linear-gradient(112deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.05) 100%);
  box-shadow: 19px 15px 46px 0px rgba(0, 0, 0, 0.14);
  -webkit-backdrop-filter: blur(27.5px);
          backdrop-filter: blur(27.5px);
  border-radius: 200px;
}
@media (max-width: 1199px) {
  .home1-banner-bottom.style-2 .filter-wrapper .filter-group {
    border-radius: 30px;
    background: linear-gradient(112deg, rgba(16, 12, 8, 0.5) 0%, rgba(16, 12, 8, 0.8) 100%);
    -webkit-backdrop-filter: blur(120.5px);
            backdrop-filter: blur(120.5px);
  }
}
@media (max-width: 991px) {
  .home1-banner-bottom.style-2 .filter-wrapper .filter-group {
    border-radius: 0 0 30px 30px;
  }
}
@media (max-width: 767px) {
  .home1-banner-bottom.style-2 .filter-wrapper .filter-group {
    padding: 30px 40px;
    border-radius: 0 0 20px 20px;
  }
}
@media (max-width: 576px) {
  .home1-banner-bottom.style-2 .filter-wrapper .filter-group {
    border-radius: 0;
    padding: 30px 25px;
  }
}
.home1-banner-bottom.style-2 .filter-wrapper .filter-group .filter-area {
  padding: 0 20px;
}
@media (max-width: 1199px) {
  .home1-banner-bottom.style-2 .filter-wrapper .filter-group .filter-area {
    padding: 0;
  }
}
.home1-banner-bottom.style-2 .filter-wrapper .filter-group .filter-area .single-search-box {
  padding: 0 10px;
}
@media (max-width: 1199px) {
  .home1-banner-bottom.style-2 .filter-wrapper .filter-group .filter-area .single-search-box {
    padding: 20px;
  }
}
@media (max-width: 576px) {
  .home1-banner-bottom.style-2 .filter-wrapper .filter-group .filter-area .single-search-box {
    padding: 15px;
  }
}
.home1-banner-bottom.style-2 .filter-wrapper .filter-group .filter-area .single-search-box .searchbox-input label {
  color: var(--primary-color1);
}
.home1-banner-bottom.style-2 .filter-wrapper .filter-group .filter-area .single-search-box .searchbox-input .quantity-counter a {
  color: var(--white-color);
}
.home1-banner-bottom.style-2 .filter-wrapper .filter-group .filter-area .single-search-box .searchbox-input .quantity-counter .quantity__input {
  color: var(--white-color);
}
.home1-banner-bottom.style-2 .filter-wrapper .filter-group .filter-area .single-search-box .searchbox-input .quantity-counter .quantity__plus, .home1-banner-bottom.style-2 .filter-wrapper .filter-group .filter-area .single-search-box .searchbox-input .quantity-counter .quantity__minus {
  right: 0;
}
.home1-banner-bottom.style-2 .filter-wrapper .filter-group .filter-area .divider::after {
  background: rgba(255, 255, 255, 0.15);
  height: 58px;
}
.home1-banner-bottom.style-2 .filter-wrapper .filter-group button {
  border-radius: 0px 200px 200px 0px;
  padding: 20px 60px;
}
@media (max-width: 1199px) {
  .home1-banner-bottom.style-2 .filter-wrapper .filter-group button {
    border-radius: 35px;
    padding: 15px 50px;
  }
}
.home1-banner-bottom.style-3 {
  margin-top: -54px;
  padding: 0;
}
.home1-banner-bottom.style-4 {
  margin-top: 0;
  padding: 0;
}
.home1-banner-bottom.style-4 .filter-wrapper .nav-buttons {
  justify-content: start;
  margin-left: 30px;
  margin-bottom: -20px;
}
@media (max-width: 767px) {
  .home1-banner-bottom.style-4 .filter-wrapper .nav-buttons {
    margin-left: 0;
    justify-content: center;
  }
}
.home1-banner-bottom.style-4 .filter-wrapper .nav-buttons .nav-pills {
  background-color: #ECE4D7;
  border-radius: 10px;
  border: 1px solid rgba(16, 12, 8, 0.15);
}
@media (max-width: 767px) {
  .home1-banner-bottom.style-4 .filter-wrapper .nav-buttons .nav-pills {
    border-radius: unset;
    justify-content: center;
  }
}
.home1-banner-bottom.style-4 .filter-wrapper .nav-buttons .nav-pills .nav-item .nav-link {
  border-right: 1px solid rgba(16, 12, 8, 0.15);
  font-size: 15px;
  padding: 11px 40px;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home1-banner-bottom.style-4 .filter-wrapper .nav-buttons .nav-pills .nav-item .nav-link {
    padding: 11px 25px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .home1-banner-bottom.style-4 .filter-wrapper .nav-buttons .nav-pills .nav-item .nav-link {
    padding: 11px 20px;
  }
}
@media (max-width: 991px) {
  .home1-banner-bottom.style-4 .filter-wrapper .nav-buttons .nav-pills .nav-item .nav-link {
    padding: 11px 20px;
  }
}
.home1-banner-bottom.style-4 .filter-wrapper .nav-buttons .nav-pills .nav-item:first-child .nav-link {
  border-radius: 10px 0 0 10px;
}
@media (max-width: 576px) {
  .home1-banner-bottom.style-4 .filter-wrapper .nav-buttons .nav-pills .nav-item:first-child .nav-link {
    border-radius: unset;
  }
}
.home1-banner-bottom.style-4 .filter-wrapper .nav-buttons .nav-pills .nav-item:last-child .nav-link {
  border-radius: 0px 10px 10px 0px;
  border-right: unset;
}
@media (max-width: 576px) {
  .home1-banner-bottom.style-4 .filter-wrapper .nav-buttons .nav-pills .nav-item:last-child .nav-link {
    border-radius: unset;
  }
}
@media (max-width: 767px) {
  .home1-banner-bottom.style-4 .filter-wrapper .nav-buttons .nav-pills .nav-item {
    width: 50%;
  }
  .home1-banner-bottom.style-4 .filter-wrapper .nav-buttons .nav-pills .nav-item .nav-link {
    border-radius: 0px;
  }
  .home1-banner-bottom.style-4 .filter-wrapper .nav-buttons .nav-pills .nav-item:first-child .nav-link {
    border-radius: unset;
  }
  .home1-banner-bottom.style-4 .filter-wrapper .nav-buttons .nav-pills .nav-item:last-child .nav-link {
    border-radius: unset;
  }
}
.home1-banner-bottom.style-4 .filter-wrapper .filter-group {
  background-color: transparent;
  border: 1px solid rgba(16, 12, 8, 0.15);
  border-radius: 20px;
  padding: 45px 30px 25px 30px;
}
@media (max-width: 1799px) {
  .home1-banner-bottom.style-4 .filter-wrapper .filter-group {
    padding: 35px 10px 25px 10px;
  }
}
@media (max-width: 576px) {
  .home1-banner-bottom.style-4 .filter-wrapper .filter-group {
    border-radius: unset;
  }
}
.home1-banner-bottom.style-4 .filter-wrapper .filter-group form {
  gap: 10px;
}
@media (max-width: 1199px) {
  .home1-banner-bottom.style-4 .filter-wrapper .filter-group form {
    flex-direction: row;
  }
}
@media (max-width: 767px) {
  .home1-banner-bottom.style-4 .filter-wrapper .filter-group form {
    flex-direction: column;
  }
}
.home1-banner-bottom.style-4 .filter-wrapper .filter-group form .filter-area {
  padding: 0;
}
@media (max-width: 1199px) {
  .home1-banner-bottom.style-4 .filter-wrapper .filter-group form .filter-area {
    margin-bottom: 0;
  }
}
.home1-banner-bottom.style-4 .filter-wrapper .filter-group form .filter-area .single-search-box {
  border-radius: 10px;
  border: 1px solid rgba(16, 12, 8, 0.15);
  padding: 11px 10px;
}
@media (max-width: 1799px) {
  .home1-banner-bottom.style-4 .filter-wrapper .filter-group form .filter-area .single-search-box {
    padding: 8px 10px;
  }
}
@media (max-width: 1399px) {
  .home1-banner-bottom.style-4 .filter-wrapper .filter-group form .filter-area .single-search-box {
    width: 100%;
  }
}
.home1-banner-bottom.style-4 .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input {
  width: 100%;
}
.home1-banner-bottom.style-4 .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .select-input input {
  font-size: 14px;
}
.home1-banner-bottom.style-4 .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .select-input h6 {
  min-width: 118px;
  font-size: 14px;
}
.home1-banner-bottom.style-4 .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap {
  top: 58px;
}
@media (max-width: 1699px) {
  .home1-banner-bottom.style-4 .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap {
    top: 52px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home1-banner-bottom.style-4 .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap {
    max-width: 380px;
    min-width: 380px;
    top: 52px;
    left: 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .home1-banner-bottom.style-4 .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap {
    max-width: 280px;
    min-width: 280px;
  }
}
@media (max-width: 1399px) {
  .home1-banner-bottom.style-4 .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input > .quantity-counter .quantity__plus {
    right: 0;
  }
}
@media (max-width: 1399px) {
  .home1-banner-bottom.style-4 .filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input > .quantity-counter .quantity__minus {
    right: 0;
  }
}
.home1-banner-bottom.style-4 .filter-wrapper .filter-group form button {
  padding: 18px 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home1-banner-bottom.style-4 .filter-wrapper .filter-group form button svg {
  fill: var(--white-color);
}

/*=====================================
  7.Home1 About section
========================================*/
.home1-about-section {
  position: relative;
}
.home1-about-section .section-vector1 {
  position: absolute;
  bottom: -28%;
  left: 0;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home1-about-section .about-content .section-title h2 {
    font-size: 40px;
  }
}
@media (max-width: 576px) {
  .home1-about-section .about-content .section-title h2 {
    font-size: 30px;
  }
}
.home1-about-section .about-content .nav-pills {
  gap: 60px;
  margin-bottom: 25px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home1-about-section .about-content .nav-pills {
    gap: 35px;
  }
}
@media (max-width: 576px) {
  .home1-about-section .about-content .nav-pills {
    gap: 20px;
  }
}
.home1-about-section .about-content .nav-pills .nav-link {
  background-color: transparent;
  padding: 0;
  color: var(--text-color);
  font-family: var(--font-rubik);
  font-size: 17px;
  font-weight: 500;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 10px;
}
.home1-about-section .about-content .nav-pills .nav-link svg {
  fill: var(--text-color);
}
.home1-about-section .about-content .nav-pills .nav-link.active {
  color: var(--primary-color1);
}
.home1-about-section .about-content .nav-pills .nav-link.active svg {
  fill: var(--primary-color1);
}
.home1-about-section .about-content .tab-content {
  margin-bottom: 45px;
}
.home1-about-section .about-content .tab-content .tab-pane {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 17px;
  font-weight: 400;
  line-height: 1.9;
  letter-spacing: 0.51px;
}
@media (max-width: 576px) {
  .home1-about-section .about-content .tab-content .tab-pane {
    font-size: 14px;
  }
}
.home1-about-section .about-content .about-content-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 576px) {
  .home1-about-section .about-content .about-content-bottom {
    flex-wrap: wrap;
    gap: 20px;
  }
}
.home1-about-section .about-content .about-content-bottom .counter-area {
  display: flex;
  align-items: center;
  gap: 8px;
}
.home1-about-section .about-content .about-content-bottom .counter-area .content .number {
  display: flex;
}
.home1-about-section .about-content .about-content-bottom .counter-area .content .number h6, .home1-about-section .about-content .about-content-bottom .counter-area .content .number span {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.48px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.home1-about-section .about-content .about-content-bottom .counter-area .content p {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.42px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.home1-about-section .about-img {
  position: relative;
}
@media (max-width: 991px) {
  .home1-about-section .about-img {
    display: none;
  }
}
.home1-about-section .about-img .vector {
  position: absolute;
  left: 10%;
  bottom: -135px;
}
@media (max-width: 1199px) {
  .home1-about-section .about-img .vector {
    display: none;
  }
}

/*=====================================
  Home1 Destination Masonary section
========================================*/
.destination-card {
  position: relative;
  transition: 0.5s;
}
@media (max-width: 991px) {
  .destination-card {
    height: 100%;
  }
}
.destination-card img {
  border-radius: 10px;
}
@media (max-width: 991px) {
  .destination-card img {
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media (max-width: 576px) {
  .destination-card img {
    height: auto;
  }
}
.destination-card .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(16, 12, 8, 0.4);
  border-radius: 10px;
}
.destination-card .card-title {
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  opacity: 1;
  width: 100%;
  transition: 0.5s;
}
.destination-card .card-title h4 {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 27px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.54px;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .destination-card .card-title h4 {
    font-size: 25px;
  }
}
.destination-card .content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.4);
  text-align: center;
  opacity: 0;
  transition: all 0.5s ease-out;
}
.destination-card .content h4 {
  margin-bottom: 20px;
}
.destination-card .content h4 a {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 27px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.54px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--primary-color1);
  display: inline-block;
  transition: all 0.5s ease-out;
}
.destination-card .content h4 a:hover {
  color: var(--primary-color2);
}
.destination-card .content .eg-tag {
  -webkit-mask-image: url(../img/home1/vector/destination-tag.svg);
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: cover;
  background-color: var(--primary-color1);
  padding: 18px 48px;
  white-space: nowrap;
  display: flex;
  margin-bottom: 15px;
  transition: all 0.5s ease-out;
}
.destination-card .content .eg-tag span {
  color: var(--white-color);
  font-family: var(--font-satisfy);
  font-size: 20px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.4px;
}
.destination-card .content .eg-tag span svg {
  fill: var(--white-color);
}
.destination-card:hover .card-title {
  opacity: 0;
}
.destination-card:hover .content {
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
}

.home1-destination-section {
  position: relative;
}
.home1-destination-section .section-vector2 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
@media (max-width: 767px) {
  .home1-destination-section .section-vector2 {
    display: none;
  }
}
.home1-destination-section .destination-banner {
  background-image: url(../img/home1/destination-banner-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border-radius: 10px;
  height: 100%;
}
@media (max-width: 576px) {
  .home1-destination-section .destination-banner {
    padding: 35px 40px;
  }
}
.home1-destination-section .destination-banner .batch {
  -webkit-mask-image: url(../img/home1/vector/destination-banner-batch-bg.svg);
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: cover;
  background-color: var(--primary-color2);
  padding: 7px 29px;
  white-space: nowrap;
  display: inline-flex;
  margin-bottom: 12px;
}
.home1-destination-section .destination-banner .batch span {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  color: var(--white-color);
  font-family: var(--font-satisfy);
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.72px;
}
.home1-destination-section .destination-banner .batch span svg {
  fill: var(--white-color);
}
.home1-destination-section .destination-banner h2 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 40px;
  font-weight: 600;
  line-height: 1.1;
  margin-bottom: 35px;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .home1-destination-section .destination-banner h2 {
    font-size: 38px;
    margin-bottom: 30px;
  }
}
@media (max-width: 1199px) {
  .home1-destination-section .destination-banner h2 {
    font-size: 32px;
    margin-bottom: 25px;
  }
}
@media (max-width: 767px) {
  .home1-destination-section .destination-banner .primary-btn1 {
    padding: 16px 25px;
    font-size: 14px;
  }
}

/*=====================================
  8.Home1 Facility section
========================================*/
.franctional-slider-section {
  overflow: hidden;
  position: relative;
}
.franctional-slider-section .slider-content-wrapper {
  background-image: url(../img/home1/facility-content-bg.png), linear-gradient(180deg, #f9f4f0 0%, #f9f4f0 100%);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 100px 135px;
}
@media (max-width: 1699px) {
  .franctional-slider-section .slider-content-wrapper {
    padding: 100px 50px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .franctional-slider-section .slider-content-wrapper {
    padding: 80px 30px;
  }
}
@media (max-width: 991px) {
  .franctional-slider-section .slider-content-wrapper {
    padding: 80px 40px;
  }
}
@media (max-width: 576px) {
  .franctional-slider-section .slider-content-wrapper {
    padding: 70px 20px;
  }
}
.franctional-slider-section .slider-content-wrapper .slider-content {
  max-width: 550px;
  width: 100%;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .franctional-slider-section .slider-content-wrapper .slider-content {
    max-width: 450px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .franctional-slider-section .slider-content-wrapper .slider-content {
    max-width: 450px;
  }
}
.franctional-slider-section .slider-content-wrapper .slider-content p {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 17px;
  font-weight: 400;
  line-height: 1.9;
  letter-spacing: 0.51px;
  margin-bottom: 35px;
}
@media (max-width: 576px) {
  .franctional-slider-section .slider-content-wrapper .slider-content p {
    font-size: 15px;
    margin-bottom: 25px;
  }
}
.franctional-slider-section .slider-content-wrapper .slider-content ul {
  display: flex;
  align-items: center;
  gap: 25px;
  flex-wrap: wrap;
  padding-bottom: 55px;
}
@media (max-width: 576px) {
  .franctional-slider-section .slider-content-wrapper .slider-content ul {
    padding-bottom: 40px;
    gap: 20px;
    flex-direction: column;
    align-items: flex-start;
  }
}
.franctional-slider-section .slider-content-wrapper .slider-content ul li {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--title-color);
  font-family: var(--font-jost);
  font-size: 17px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 0.51px;
}
.franctional-slider-section .slider-content-wrapper .slider-content ul li svg {
  fill: var(--primary-color1);
}
.franctional-slider-section .slider-content-wrapper .nav-and-pagi-area {
  display: flex;
  align-items: center;
  gap: 20px;
}
.franctional-slider-section .slider-content-wrapper .nav-and-pagi-area .franctional-slider-prev-btn svg, .franctional-slider-section .slider-content-wrapper .nav-and-pagi-area .franctional-slider-next-btn svg {
  stroke: var(--primary-color2);
}
.franctional-slider-section .slider-content-wrapper .nav-and-pagi-area .franctional-slider-pagi1 {
  width: unset;
  font-size: 39px;
  display: flex;
  gap: 12px;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 200;
}
.franctional-slider-section .slider-content-wrapper .nav-and-pagi-area .franctional-slider-pagi1 .swiper-pagination-current {
  color: var(--primary-color1);
  font-family: var(--font-jost);
  font-size: 30px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.9px;
  text-transform: capitalize;
}
.franctional-slider-section .slider-content-wrapper .nav-and-pagi-area .franctional-slider-pagi1 .swiper-pagination-total {
  color: var(--primary-color2);
  font-family: var(--font-jost);
  font-size: 20px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.6px;
  text-transform: capitalize;
}
.franctional-slider-section .franctional-slider-right-img {
  position: relative;
  height: 100%;
}
.franctional-slider-section .franctional-slider-right-img > img {
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 991px) {
  .franctional-slider-section .franctional-slider-right-img {
    display: none;
  }
}
.franctional-slider-section .franctional-slider-right-img .logo {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: var(--white-color);
  border: 2px solid var(--primary-color1);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.franctional-slider-section .rating-wrapper {
  background-image: url(../img/home1/rating-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  border-radius: 20px;
  z-index: 99;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .franctional-slider-section .rating-wrapper {
    left: 55%;
  }
}
@media (max-width: 1199px) {
  .franctional-slider-section .rating-wrapper {
    display: none;
  }
}

/*=====================================
  9.Home1 Package Card section
========================================*/
.package-card {
  padding: 20px;
  border-radius: 10px;
  background-color: var(--white-color);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
  position: relative;
  overflow: hidden;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .package-card {
    padding: 15px;
  }
}
@media (max-width: 576px) {
  .package-card {
    padding: 15px;
  }
}
.package-card .package-card-img-wrap {
  position: relative;
  transition: all 0.5s ease-out;
}
.package-card .package-card-img-wrap .card-img {
  position: relative;
  overflow: hidden;
  display: block;
  border-radius: 10px;
}
.package-card .package-card-img-wrap .card-img img {
  border-radius: 10px;
  transition: all 0.5s ease-out;
}
.package-card .package-card-img-wrap .card-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(1deg, rgba(21, 47, 0, 0.56) 1.03%, rgba(21, 47, 0, 0) 90.67%);
  border-radius: 10px;
  z-index: 1;
}
.package-card .package-card-img-wrap .card-img::after {
  position: absolute;
  width: 200%;
  height: 0%;
  left: 50%;
  top: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  transform: translate(-50%, -50%) rotate(-45deg);
  content: "";
  z-index: 1;
}
.package-card .package-card-content .card-content-top {
  padding: 25px 0;
}
@media (max-width: 1399px) {
  .package-card .package-card-content .card-content-top {
    padding: 20px 0;
  }
}
.package-card .package-card-content .card-content-top h5 {
  margin-bottom: 15px;
  transition: 0.5s;
}
.package-card .package-card-content .card-content-top h5 a {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 22px;
  font-weight: 500;
  line-height: 1.4;
  text-transform: capitalize;
  transition: 0.5s;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .package-card .package-card-content .card-content-top h5 a {
    font-size: 18px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .package-card .package-card-content .card-content-top h5 a {
    font-size: 17px;
  }
}
@media (max-width: 991px) {
  .package-card .package-card-content .card-content-top h5 a {
    font-size: 20px;
  }
}
@media (max-width: 576px) {
  .package-card .package-card-content .card-content-top h5 a {
    font-size: 18px;
  }
}
.package-card .package-card-content .card-content-top h5:hover a {
  color: var(--primary-color1);
}
@media (max-width: 576px) {
  .package-card .package-card-content .card-content-top h5 {
    margin-bottom: 10px;
  }
}
.package-card .package-card-content .card-content-top .location-area {
  position: relative;
  height: 18px;
  overflow: hidden;
}
.package-card .package-card-content .card-content-top .location-area .location-list {
  position: absolute;
  white-space: nowrap;
  text-overflow: ellipsis;
  transform: translateX(0);
  transition: 8s;
  display: flex;
  align-items: center;
  gap: 35px;
}
.package-card .package-card-content .card-content-top .location-area .location-list li {
  line-height: 1;
  position: relative;
  transition: 0.5s;
}
.package-card .package-card-content .card-content-top .location-area .location-list li a {
  color: #888;
  font-family: var(--font-rubik);
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  transition: 0.5s;
}
.package-card .package-card-content .card-content-top .location-area .location-list li::before {
  content: "\f138";
  font-family: bootstrap-icons;
  color: #888;
  position: absolute;
  left: -25px;
  top: 50%;
  transform: translateY(-50%);
}
.package-card .package-card-content .card-content-top .location-area .location-list li:first-child::before {
  display: none;
}
.package-card .package-card-content .card-content-top .location-area .location-list li:hover a {
  color: var(--primary-color1);
}
.package-card .package-card-content .card-content-top .location-area:hover .scrollTextAni {
  transform: translateX(calc(200px - 100%));
}
.package-card .package-card-content .card-content-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  padding-top: 15px;
  border-top: 1px solid rgba(16, 12, 8, 0.2);
}
.package-card .package-card-content .card-content-bottom .price-area {
  line-height: 1;
}
.package-card .package-card-content .card-content-bottom .price-area h6 {
  color: #333;
  font-family: var(--font-rubik);
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.24px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.package-card .package-card-content .card-content-bottom .price-area span {
  color: var(--primary-color1);
  font-family: var(--font-rubik);
  font-size: 26px;
  font-weight: 500;
  line-height: 1;
  display: inline-block;
  text-transform: capitalize;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .package-card .package-card-content .card-content-bottom .price-area span {
    font-size: 22px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .package-card .package-card-content .card-content-bottom .price-area span {
    font-size: 20px;
  }
}
@media (max-width: 576px) {
  .package-card .package-card-content .card-content-bottom .price-area span {
    font-size: 22px;
  }
}
.package-card .package-card-content .card-content-bottom .price-area span del {
  color: rgba(16, 12, 8, 0.5);
  font-size: 16px;
  font-weight: 500;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .package-card .package-card-content .card-content-bottom .price-area span del {
    font-size: 13px;
  }
}
@media (max-width: 576px) {
  .package-card .package-card-content .card-content-bottom .price-area span del {
    font-size: 13px;
  }
}
.package-card .package-card-content .card-content-bottom .price-area p {
  color: var(--text-color);
  font-family: var(--font-rubik);
  font-size: 10px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.2px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.package-card .batch {
  position: absolute;
  top: 5px;
  left: -20px;
  z-index: 1;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .package-card .batch {
    left: -15px;
  }
}
@media (max-width: 576px) {
  .package-card .batch {
    left: -15px;
  }
}
.package-card .batch .date {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 12px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  display: inline-flex;
  padding: 8px 10px;
  background-color: var(--title-color);
}
.package-card .batch .location {
  background-color: var(--white-color);
  border: 1px solid rgba(99, 171, 69, 0.5);
  padding: 6px 10px;
  display: flex;
  align-items: center;
  gap: 7px;
}
.package-card .batch .location svg {
  fill: var(--primary-color1);
}
.package-card .batch .location .location-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}
.package-card .batch .location .location-list li {
  position: relative;
  line-height: 1;
  padding-left: 14px;
}
.package-card .batch .location .location-list li a {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.6px;
  text-transform: uppercase;
}
.package-card .batch .location .location-list li::before {
  content: "\f4fe";
  font-family: bootstrap-icons;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -5px;
  color: var(--title-color);
  font-size: 17px;
}
.package-card .batch .location .location-list li:first-child {
  padding-left: 0;
}
.package-card .batch .location .location-list li:first-child::before {
  display: none;
}
.package-card:hover .package-card-img-wrap .card-img img {
  transform: scale(1.1);
}
.package-card:hover .package-card-img-wrap .card-img::after {
  height: 250%;
  transition: all 600ms linear;
  background-color: transparent;
}
.package-card.style-2 {
  padding: 5px;
  border: 1px solid rgba(16, 12, 8, 0.15);
  box-shadow: unset;
  border-radius: unset;
}
.package-card.style-2 .package-card-img-wrap .card-img {
  border-radius: unset;
}
.package-card.style-2 .package-card-img-wrap .card-img img {
  border-radius: unset;
}
.package-card.style-2 .package-card-img-wrap .card-img::before {
  background: linear-gradient(1deg, rgba(16, 12, 8, 0.4) 1.03%, rgba(16, 12, 8, 0) 90.67%);
  border-radius: unset;
}
.package-card.style-2 .package-card-content {
  padding: 0 15px;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .package-card.style-2 .package-card-content {
    padding: 0 10px;
  }
}
.package-card.style-2 .package-card-content .card-content-bottom {
  padding: 15px 0;
}
.package-card.style-2 .package-card-content .card-content-bottom .primary-btn1 {
  border-radius: unset;
  font-size: 14px;
  font-weight: 500;
  padding: 11px 16px;
}
.package-card.style-2 .batch {
  top: 0;
  left: 0;
}

.package-card-section {
  position: relative;
}
.package-card-section .section-vector1 {
  position: absolute;
  bottom: 0;
  left: 0;
}
.package-card-section .section-vector3 {
  position: absolute;
  top: 30%;
  right: 0;
}
.package-card-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(../img/home1/vector/section-bg-vector1.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 50%;
  z-index: -1;
}

/*=====================================
  10.Home1 Feature Card section
========================================*/
.feature-card {
  background-image: url(../img/home1/feature-card-bg1.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 20px 20px 20px 10px;
  display: flex;
  align-items: center;
  gap: 20px;
  border-radius: 10px;
  border: 1px solid #EEE;
  transition: 0.5s;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .feature-card {
    gap: 15px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .feature-card {
    gap: 15px;
  }
}
@media (max-width: 576px) {
  .feature-card {
    padding: 20px 15px 20px 10px;
    gap: 10px;
  }
}
.feature-card .feature-card-icon {
  min-width: 100px;
  max-width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #DDEED6;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
}
.feature-card .feature-card-icon svg {
  fill: var(--primary-color1);
  transition: 0.5s;
}
.feature-card .feature-card-content h6 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 0.6px;
  text-transform: capitalize;
  margin-bottom: 12px;
  transition: 0.5s;
}
@media (min-width: 768px) and (max-width: 991px) {
  .feature-card .feature-card-content h6 {
    font-size: 18px;
    margin-bottom: 10px;
  }
}
@media (max-width: 576px) {
  .feature-card .feature-card-content h6 {
    font-size: 17px;
    margin-bottom: 10px;
  }
}
.feature-card .feature-card-content p {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 15px;
  font-weight: 400;
  line-height: 1.8;
  letter-spacing: 0.45px;
  margin-bottom: 0;
  transition: 0.5s;
}
@media (min-width: 768px) and (max-width: 991px) {
  .feature-card .feature-card-content p {
    font-size: 14px;
  }
}
@media (max-width: 576px) {
  .feature-card .feature-card-content p {
    font-size: 14px;
  }
}
.feature-card:hover {
  box-shadow: 0px 0px 30px -2px rgba(0, 0, 0, 0.08);
}
.feature-card:hover .feature-card-icon {
  background-color: var(--primary-color1);
}
.feature-card:hover .feature-card-icon svg {
  fill: var(--white-color);
}
.feature-card.two {
  background-image: url(../img/home1/feature-card-bg2.png);
}
.feature-card.two .feature-card-icon {
  background-color: #FEEED5;
}
.feature-card.two .feature-card-icon svg {
  fill: var(--primary-color2);
}
.feature-card.two:hover .feature-card-icon {
  background-color: var(--primary-color2);
}
.feature-card.two:hover .feature-card-icon svg {
  fill: var(--white-color);
}
.feature-card.three {
  background-image: url(../img/home1/feature-card-bg3.png);
}
.feature-card.three .feature-card-icon {
  background-color: #F5F5D3;
}
.feature-card.three .feature-card-icon svg {
  fill: #D2D234;
}
.feature-card.three:hover .feature-card-icon {
  background-color: #D2D234;
}
.feature-card.three:hover .feature-card-icon svg {
  fill: var(--white-color);
}
.feature-card.style-2 {
  background-color: var(--white-color);
  background-image: unset;
  border: unset;
  box-shadow: 0px 0px 20px -2px rgba(0, 0, 0, 0.08);
  padding: 25px 28px;
}
@media (max-width: 576px) {
  .feature-card.style-2 {
    padding: 25px 20px;
  }
}
.feature-card.style-2 .feature-card-icon {
  min-width: unset;
  max-width: unset;
  height: unset;
  background-color: unset;
  border-radius: unset;
}
.feature-card.style-2 .feature-card-icon svg {
  fill: var(--primary-color1);
  transition: 0.5s;
}
.feature-card.style-2 .feature-card-icon.orange svg {
  fill: var(--primary-color2);
}
.feature-card.style-2:hover {
  box-shadow: unset;
  background-color: var(--primary-color1);
}
.feature-card.style-2:hover .feature-card-icon svg {
  fill: var(--white-color);
}
.feature-card.style-2:hover .feature-card-content h6 {
  color: var(--white-color);
}
.feature-card.style-2:hover .feature-card-content p {
  color: var(--white-color);
}
.feature-card.style-2.secondary .feature-card-icon svg {
  fill: var(--primary-color2);
}
.feature-card.style-2.secondary:hover {
  background-color: var(--primary-color2);
}
.feature-card.style-2.secondary:hover svg {
  fill: var(--white-color);
}
.feature-card.style-3 {
  border: 1px solid #EEE;
  background: #E8F4E3;
  background-image: unset;
  flex-direction: column;
  padding: 25px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .feature-card.style-3 {
    padding: 15px;
  }
}
@media (max-width: 767px) {
  .feature-card.style-3 {
    padding: 15px;
  }
}
.feature-card.style-3 .feature-card-icon {
  min-width: unset;
  max-width: unset;
  height: unset;
  background-color: unset;
  border-radius: unset;
}
.feature-card.style-3 .feature-card-icon svg {
  fill: var(--primary-color1);
  transition: 0.5s;
}
.feature-card.style-3 .feature-card-icon.orange svg {
  fill: var(--primary-color2);
}
.feature-card.style-3 .feature-card-content {
  text-align: center;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .feature-card.style-3 .feature-card-content h6 {
    font-size: 17px;
    margin-bottom: 5px;
  }
}
@media (max-width: 767px) {
  .feature-card.style-3 .feature-card-content h6 {
    font-size: 17px;
    margin-bottom: 5px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .feature-card.style-3 .feature-card-content p {
    font-size: 14px;
    line-height: 1.5;
  }
}
@media (max-width: 576px) {
  .feature-card.style-3 .feature-card-content p {
    font-size: 14px;
    line-height: 1.5;
  }
}
.feature-card.style-3:hover {
  box-shadow: unset;
  background-color: var(--primary-color1);
}
.feature-card.style-3:hover .feature-card-icon svg {
  fill: var(--white-color);
}
.feature-card.style-3:hover .feature-card-content h6 {
  color: var(--white-color);
}
.feature-card.style-3:hover .feature-card-content p {
  color: var(--white-color);
}
.feature-card.style-3.olive-bg {
  background-color: #E7EDDB;
}
.feature-card.style-3.olive-bg .feature-card-icon svg {
  fill: #90A955;
}
.feature-card.style-3.olive-bg:hover {
  background-color: #90A955;
}
.feature-card.style-3.olive-bg:hover .feature-card-icon svg {
  fill: var(--white-color);
}
.feature-card.style-3.orange-bg {
  background-color: #FEEED5;
}
.feature-card.style-3.orange-bg .feature-card-icon svg {
  fill: var(--primary-color2);
}
.feature-card.style-3.orange-bg:hover {
  background-color: var(--primary-color2);
}
.feature-card.style-3.orange-bg:hover .feature-card-icon svg {
  fill: var(--white-color);
}
.feature-card.style-3.yellow-bg {
  background-color: #F5F5D3;
}
.feature-card.style-3.yellow-bg .feature-card-icon svg {
  fill: #D2D234;
}
.feature-card.style-3.yellow-bg:hover {
  background-color: #D1D133;
}
.feature-card.style-3.yellow-bg:hover .feature-card-icon svg {
  fill: var(--white-color);
}

.feature-card-section {
  position: relative;
}
.feature-card-section .pt-15 {
  padding-top: 15px;
}
@media (max-width: 1199px) {
  .feature-card-section .pt-15 {
    padding-top: 0;
  }
}
.feature-card-section .mb-80 {
  margin-bottom: 80px;
}
@media (max-width: 991px) {
  .feature-card-section .mb-80 {
    margin-bottom: 70px;
  }
}
.feature-card-section .section-vector4 {
  position: absolute;
  top: -40%;
  right: 0;
  z-index: -1;
}
@media (max-width: 1199px) {
  .feature-card-section .section-vector4 {
    top: -30%;
  }
}
@media (max-width: 991px) {
  .feature-card-section .section-vector4 {
    top: -15%;
  }
}
@media (max-width: 576px) {
  .feature-card-section .section-vector4 {
    top: -10%;
  }
}
.feature-card-section .banner4-slider-wrapper .banner4-card-slide .swiper-slide-active span {
  animation: fadeInDown 1.5s;
}
.feature-card-section .banner4-slider-wrapper .banner4-card-slide .swiper-slide-active h3 {
  animation: fadeInDown 1.5s;
}
.feature-card-section .banner4-slider-wrapper .banner4-card-slide .swiper-slide-active .text {
  animation: fadeInDown 1.5s;
}
.feature-card-section .banner4-slider-wrapper .banner4-card-slide .swiper-slide-active .primary-btn1 {
  animation: fadeInUp 1.5s;
}
.feature-card-section .banner4-slider-wrapper .package-card3-slide {
  padding: 10px;
  margin: -10px;
  padding-left: 0;
  margin-left: 0;
}

/*=====================================
  11.Home1 Package Card Tab section
========================================*/
.package-card-tab-section {
  background-image: url(../img/home1/package-card-tab-bg.png), linear-gradient(180deg, #faf5ee 0%, #faf5ee 100%);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 100px 0;
  position: relative;
  z-index: 1;
}
@media (max-width: 991px) {
  .package-card-tab-section {
    padding: 90px 0;
  }
}
@media (max-width: 767px) {
  .package-card-tab-section {
    padding: 80px 0;
  }
}
.package-card-tab-section .section-vector5 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.package-card-tab-section .section-vector6 {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
}
.package-card-tab-section .package-card-with-tab .nav-pills {
  display: inherit;
  -moz-columns: 5;
       columns: 5;
  gap: 0;
  margin-bottom: 50px;
  border-radius: 10px;
}
@media (max-width: 576px) {
  .package-card-tab-section .package-card-with-tab .nav-pills .nav-item {
    width: 50%;
  }
}
.package-card-tab-section .package-card-with-tab .nav-pills .nav-item .nav-link {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  background-color: var(--white-color);
  border: 1px solid rgba(var(--primary-color1-opc), 0.2);
  border-right: none;
  padding: 24px 15px;
  border-radius: unset;
  width: 100%;
}
.package-card-tab-section .package-card-with-tab .nav-pills .nav-item .nav-link.active {
  background-image: linear-gradient(180deg, rgba(16, 12, 8, 0.5) 0%, rgba(16, 12, 8, 0.5) 100%), url(../img/home1/package-card-nav-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  color: var(--white-color);
}
@media (max-width: 991px) {
  .package-card-tab-section .package-card-with-tab .nav-pills .nav-item .nav-link {
    border-right: 1px solid rgba(var(--primary-color1-opc), 0.2);
  }
}
@media (max-width: 767px) {
  .package-card-tab-section .package-card-with-tab .nav-pills .nav-item .nav-link {
    padding: 20px 35px;
  }
}
.package-card-tab-section .package-card-with-tab .nav-pills .nav-item:first-child .nav-link {
  border-radius: 10px 0 0 10px;
}
@media (max-width: 991px) {
  .package-card-tab-section .package-card-with-tab .nav-pills .nav-item:first-child .nav-link {
    border-radius: unset;
  }
}
.package-card-tab-section .package-card-with-tab .nav-pills .nav-item:nth-child(2) .nav-link.active {
  background-image: linear-gradient(180deg, rgba(16, 12, 8, 0.5) 0%, rgba(16, 12, 8, 0.5) 100%), url(../img/home1/package-card-nav-bg2.png);
}
.package-card-tab-section .package-card-with-tab .nav-pills .nav-item:nth-child(3) .nav-link.active {
  background-image: linear-gradient(180deg, rgba(16, 12, 8, 0.5) 0%, rgba(16, 12, 8, 0.5) 100%), url(../img/home1/package-card-nav-bg3.png);
}
.package-card-tab-section .package-card-with-tab .nav-pills .nav-item:nth-child(4) .nav-link.active {
  background-image: linear-gradient(180deg, rgba(16, 12, 8, 0.5) 0%, rgba(16, 12, 8, 0.5) 100%), url(../img/home1/package-card-nav-bg4.png);
}
.package-card-tab-section .package-card-with-tab .nav-pills .nav-item:last-child .nav-link {
  border-radius: 0px 10px 10px 0px;
  border-right: 1px solid rgba(var(--primary-color1-opc), 0.2);
}
@media (max-width: 991px) {
  .package-card-tab-section .package-card-with-tab .nav-pills .nav-item:last-child .nav-link {
    border-radius: unset;
  }
}
.package-card-tab-section .package-card-with-tab .nav-pills .nav-item:last-child .nav-link.active {
  background-image: linear-gradient(180deg, rgba(16, 12, 8, 0.5) 0%, rgba(16, 12, 8, 0.5) 100%), url(../img/home1/package-card-nav-bg5.png);
}
@media (max-width: 1199px) {
  .package-card-tab-section .package-card-with-tab .nav-pills {
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 576px) {
  .package-card-tab-section .package-card-with-tab .nav-pills {
    margin-bottom: 30px;
  }
}

/*=====================================
  12.Home1 Activities Verticle Tab section
========================================*/
.verticle-tab-section {
  position: relative;
}
.verticle-tab-section .verticle-tab-wrapper .verticle-tab-sidebar {
  padding-right: 15px;
}
.verticle-tab-section .verticle-tab-wrapper .verticle-tab-sidebar .nav-pills {
  display: block;
  -moz-columns: 2;
       columns: 2;
}
.verticle-tab-section .verticle-tab-wrapper .verticle-tab-sidebar .nav-pills .nav-item {
  margin-bottom: 20px;
}
.verticle-tab-section .verticle-tab-wrapper .verticle-tab-sidebar .nav-pills .nav-item:nth-child(3) {
  margin-bottom: 0;
}
.verticle-tab-section .verticle-tab-wrapper .verticle-tab-sidebar .nav-pills .nav-item:last-child {
  margin-bottom: 0;
}
.verticle-tab-section .verticle-tab-wrapper .verticle-tab-sidebar .nav-pills .nav-item .nav-link {
  background-color: rgba(var(--primary-color1-opc), 0.1);
  padding: 20px;
  text-align: center;
  cursor: pointer;
}
.verticle-tab-section .verticle-tab-wrapper .verticle-tab-sidebar .nav-pills .nav-item .nav-link .icon {
  margin-bottom: 20px;
}
.verticle-tab-section .verticle-tab-wrapper .verticle-tab-sidebar .nav-pills .nav-item .nav-link .icon svg {
  fill: var(--title-color);
}
.verticle-tab-section .verticle-tab-wrapper .verticle-tab-sidebar .nav-pills .nav-item .nav-link h6 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0;
}
.verticle-tab-section .verticle-tab-wrapper .verticle-tab-sidebar .nav-pills .nav-item .nav-link.active {
  background-color: var(--primary-color2);
}
.verticle-tab-section .verticle-tab-wrapper .verticle-tab-sidebar .nav-pills .nav-item .nav-link.active .icon svg {
  fill: var(--white-color);
}
.verticle-tab-section .verticle-tab-wrapper .verticle-tab-sidebar .nav-pills .nav-item .nav-link.active h6 {
  color: var(--white-color);
}
.verticle-tab-section .verticle-tab-wrapper .verticle-tab-content-wrap {
  display: flex;
  align-items: center;
  gap: 30px;
}
.verticle-tab-section .verticle-tab-wrapper .verticle-tab-content-wrap .verticle-tab-content {
  max-width: 470px;
  width: 100%;
}
.verticle-tab-section .verticle-tab-wrapper .verticle-tab-content-wrap .verticle-tab-content .eg-tag2 {
  -webkit-mask-image: url(../img/home1/vector/activities-content-tag.svg);
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: cover;
  background-color: var(--primary-color2);
  padding: 8px 47px;
  white-space: nowrap;
  display: inline-flex;
  margin-bottom: 15px;
}
.verticle-tab-section .verticle-tab-wrapper .verticle-tab-content-wrap .verticle-tab-content .eg-tag2 span {
  color: var(--white-color);
  font-family: var(--font-satisfy);
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.72px;
}
.verticle-tab-section .verticle-tab-wrapper .verticle-tab-content-wrap .verticle-tab-content .eg-tag2 span svg {
  fill: var(--white-color);
}
.verticle-tab-section .verticle-tab-wrapper .verticle-tab-content-wrap .verticle-tab-content h2 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 30px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0.6px;
  margin-bottom: 20px;
}
@media (max-width: 576px) {
  .verticle-tab-section .verticle-tab-wrapper .verticle-tab-content-wrap .verticle-tab-content h2 {
    font-size: 25px;
    margin-bottom: 15px;
  }
}
.verticle-tab-section .verticle-tab-wrapper .verticle-tab-content-wrap .verticle-tab-content p {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.9;
  letter-spacing: 0.48px;
  margin-bottom: 30px;
}
@media (max-width: 576px) {
  .verticle-tab-section .verticle-tab-wrapper .verticle-tab-content-wrap .verticle-tab-content p {
    font-size: 14px;
    margin-bottom: 20px;
  }
}
.verticle-tab-section .verticle-tab-wrapper .verticle-tab-content-wrap .verticle-tab-content ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 45px;
}
.verticle-tab-section .verticle-tab-wrapper .verticle-tab-content-wrap .verticle-tab-content ul li {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--title-color);
  font-family: var(--font-jost);
  font-size: 17px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 0.51px;
}
.verticle-tab-section .verticle-tab-wrapper .verticle-tab-content-wrap .verticle-tab-content ul li svg {
  fill: var(--primary-color1);
}
.verticle-tab-section .verticle-tab-wrapper .verticle-tab-content-wrap .verticle-tab-content .content-bottom-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}
@media (max-width: 576px) {
  .verticle-tab-section .verticle-tab-wrapper .verticle-tab-content-wrap .verticle-tab-content .content-bottom-area {
    flex-wrap: wrap;
  }
}
.verticle-tab-section .verticle-tab-wrapper .verticle-tab-content-wrap .verticle-tab-content .content-bottom-area .primary-btn1 {
  padding: 16px 25px;
}
.verticle-tab-section .verticle-tab-wrapper .verticle-tab-content-wrap .verticle-tab-content .content-bottom-area .video-area {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.verticle-tab-section .verticle-tab-wrapper .verticle-tab-content-wrap .verticle-tab-content .content-bottom-area .video-area .icon {
  position: relative;
}
.verticle-tab-section .verticle-tab-wrapper .verticle-tab-content-wrap .verticle-tab-content .content-bottom-area .video-area .icon .video-circle {
  stroke: var(--title-color);
  fill: none;
  transition: 0.5s;
}
.verticle-tab-section .verticle-tab-wrapper .verticle-tab-content-wrap .verticle-tab-content .content-bottom-area .video-area .icon .video-circle .top-half {
  stroke-dasharray: 320;
  stroke-dashoffset: 320;
  transition: stroke-dashoffset 0.8s cubic-bezier(0.33, 1, 0.68, 1);
}
.verticle-tab-section .verticle-tab-wrapper .verticle-tab-content-wrap .verticle-tab-content .content-bottom-area .video-area .icon i {
  color: var(--title-color);
  font-size: 30px;
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.verticle-tab-section .verticle-tab-wrapper .verticle-tab-content-wrap .verticle-tab-content .content-bottom-area .video-area h6 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.48px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.verticle-tab-section .verticle-tab-wrapper .verticle-tab-content-wrap .verticle-tab-content .content-bottom-area .video-area:hover .icon .video-circle .top-half {
  stroke-dashoffset: 0;
}
@media (max-width: 767px) {
  .verticle-tab-section .verticle-tab-wrapper .verticle-tab-content-wrap .verticle-tab-img {
    display: none;
  }
}
.verticle-tab-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(../img/home1/vector/section-bg-vector2.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.verticle-tab-section .section-vector1 {
  position: absolute;
  bottom: -25%;
  left: 0;
  z-index: -1;
}

/*=====================================
  13.Home1 Banner2 section
========================================*/
.banner2-card {
  position: relative;
  border-radius: 5px;
}
.banner2-card img {
  border-radius: 5px;
}
@media (max-width: 991px) {
  .banner2-card img {
    width: 100%;
  }
}
.banner2-card .hotline-area {
  display: flex;
  align-items: center;
  gap: 20px;
}
.banner2-card .hotline-area .icon {
  height: 60px;
  width: 60px;
  background-color: var(--white-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.banner2-card .hotline-area .icon svg {
  fill: var(--primary-color1);
}
.banner2-card .hotline-area .content {
  text-align: start;
}
.banner2-card .hotline-area .content span {
  font-size: 20px;
  font-family: var(--font-satisfy);
  line-height: 1;
  font-weight: 400;
  letter-spacing: 0.13em;
  color: var(--white-color);
  display: inline-block;
  margin-bottom: 10px;
}
.banner2-card .hotline-area .content h6 {
  margin: 0;
  line-height: 1;
}
.banner2-card .hotline-area .content h6 a {
  font-size: 24px;
  color: var(--white-color);
}
.banner2-card .banner2-content-wrap {
  padding: 60px 20px 35px 20px;
  background-image: url(../img/home1/vector/banner2-content-bg1.svg);
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  border-radius: 0 0 5px 5px;
}
.banner2-card .banner2-content-wrap .banner2-content > span {
  color: var(--white-color);
  font-family: var(--font-satisfy);
  font-size: 20px;
  font-weight: 400;
  line-height: 1;
  display: block;
  margin-bottom: 16px;
}
.banner2-card .banner2-content-wrap .banner2-content h3 {
  color: var(--primary-color2);
  font-family: var(--font-rubik);
  font-size: 35px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 5px;
}
.banner2-card .banner2-content-wrap .banner2-content > a {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 18px;
  font-weight: 600;
  line-height: 1.6;
}
@media (max-width: 576px) {
  .banner2-card .banner2-content-wrap .banner2-content > a {
    font-size: 16px;
  }
}
.banner2-card .banner2-content-wrap .banner2-content p {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 25px;
  line-height: 1.6;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .banner2-card .banner2-content-wrap .banner2-content p {
    margin-bottom: 15px;
  }
}
@media (max-width: 576px) {
  .banner2-card .banner2-content-wrap .banner2-content p {
    margin-bottom: 10px;
  }
}
.banner2-card .banner2-content-wrap .primary-btn1 {
  padding: 13px 25px;
  font-size: 15px;
}
.banner2-card .banner2-content-wrap .offer-batch {
  width: 64px;
  height: 64px;
  background-color: var(--primary-color1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.banner2-card .banner2-content-wrap .offer-batch span {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 20px;
  font-weight: 400;
  line-height: 1;
}
.banner2-card .banner2-content-wrap .offer-batch span strong {
  font-weight: 700;
}
.banner2-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(16, 12, 8, 0.2);
  border-radius: 5px;
}
.banner2-card::after {
  content: "";
  position: absolute;
  left: 15px;
  right: 15px;
  top: 15px;
  bottom: 15px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 5px;
}
.banner2-card.two::after {
  display: none;
}
.banner2-card.two .banner2-content-wrap {
  background-image: url(../img/home1/vector/banner2-content-bg2.svg);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 20px 15px 20px;
  text-align: start;
  border-radius: 0 0 5px 5px;
}
@media (max-width: 991px) {
  .banner2-card.two .banner2-content-wrap {
    padding: 35px 20px 25px 20px;
  }
}
@media (max-width: 576px) {
  .banner2-card.two .banner2-content-wrap {
    padding: 15px 20px 10px 20px;
  }
}
.banner2-card.two .banner2-content-wrap .banner2-content span {
  margin-bottom: 5px;
}
.banner2-card.three::after {
  display: none;
}
.banner2-card.three .banner2-content-wrap {
  background-image: url(../img/home1/vector/banner2-content-bg3.svg);
  padding: 38px 20px 38px 25px;
  text-align: start;
  border-radius: 5px 0 0 5px;
  height: 100%;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .banner2-card.three .banner2-content-wrap {
    padding: 22px 20px 20px 25px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .banner2-card.three .banner2-content-wrap {
    padding: 15px 20px 15px 25px;
  }
}
@media (max-width: 991px) {
  .banner2-card.three .banner2-content-wrap {
    height: 100%;
  }
}
@media (max-width: 576px) {
  .banner2-card.three .banner2-content-wrap {
    padding-left: 20px;
  }
}
.banner2-card.three .banner2-content-wrap .banner2-content {
  max-width: 250px;
  width: 100%;
}
@media (min-width: 768px) and (max-width: 991px) {
  .banner2-card.three .banner2-content-wrap .banner2-content {
    max-width: 350px;
  }
}
.banner2-card.three .banner2-content-wrap .banner2-content span {
  margin-bottom: 5px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .banner2-card.three .banner2-content-wrap .banner2-content span {
    margin-bottom: 0;
  }
}
@media (max-width: 576px) {
  .banner2-card.three .banner2-content-wrap .banner2-content span {
    font-size: 18px;
  }
}
.banner2-card.three .banner2-content-wrap .banner2-content h5 {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 19px;
  font-weight: 500;
  line-height: 1.4;
  margin-bottom: 15px;
}
.banner2-card.three .banner2-content-wrap .banner2-content h5 span {
  font-size: 24px;
  font-weight: 600;
  color: var(--primary-color2);
}
@media (min-width: 992px) and (max-width: 1199px) {
  .banner2-card.three .banner2-content-wrap .banner2-content h5 {
    margin-bottom: 5px;
  }
}
@media (max-width: 576px) {
  .banner2-card.three .banner2-content-wrap .banner2-content h5 {
    font-size: 18px;
    margin-bottom: 5px;
  }
  .banner2-card.three .banner2-content-wrap .banner2-content h5 span {
    font-size: 20px;
  }
}
.banner2-card.three .banner2-content-wrap .primary-btn1 {
  background-color: var(--primary-color2);
}
@media (max-width: 576px) {
  .banner2-card.three .banner2-content-wrap .primary-btn1 {
    padding: 13px 25px;
    font-size: 15px;
  }
}
.banner2-card.three .banner2-content-wrap .primary-btn1::after {
  background-color: var(--title-color);
}
.banner2-card.three .banner2-content-wrap .primary-btn1:hover {
  color: var(--white-color);
}
.banner2-card.four .banner2-content-wrap {
  background-image: url(../img/home1/vector/banner2-content-bg4.svg);
  bottom: unset;
  top: 0;
  text-align: start;
  padding: 30px 24px 60px 30px;
  border-radius: 5px 5px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .banner2-card.four .banner2-content-wrap {
    padding: 20px 24px 60px 25px;
    flex-wrap: wrap;
  }
}
@media (max-width: 991px) {
  .banner2-card.four .banner2-content-wrap {
    padding: 70px 40px 140px 40px;
  }
}
@media (max-width: 576px) {
  .banner2-card.four .banner2-content-wrap {
    padding: 35px 15px 50px 20px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .banner2-card.four .banner2-content-wrap .banner2-content span {
    margin-bottom: 5px;
  }
}
.banner2-card.four .banner2-content-wrap .banner2-content h3 {
  color: var(--title-color);
}
@media (min-width: 992px) and (max-width: 1199px) {
  .banner2-card.four .banner2-content-wrap .banner2-content h3 {
    font-size: 30px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .banner2-card.four .banner2-content-wrap .banner2-content p {
    font-size: 16px;
    margin-bottom: 10px;
  }
}
.banner2-card.four .banner2-content-wrap .primary-btn1::after {
  background-color: var(--title-color);
}
.banner2-card.four .banner2-content-wrap .primary-btn1:hover {
  color: var(--white-color);
}
.banner2-card.five::after {
  display: none;
}
.banner2-card.five img {
  border-radius: 10px;
}
@media (max-width: 576px) {
  .banner2-card.five img {
    min-height: 180px;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
.banner2-card.five::before {
  border-radius: 10px;
}
.banner2-card.five .banner2-content-wrap {
  background-image: url(../img/home3/vector/banner2-content-bg2.svg);
  padding: 38px 20px 38px 25px;
  text-align: center;
  right: 0;
  left: unset;
  background-size: contain;
  background-position: right center;
  border-radius: 0px 10px 10px 0px;
  height: 100%;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .banner2-card.five .banner2-content-wrap {
    padding: 22px 10px 20px 25px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .banner2-card.five .banner2-content-wrap {
    padding: 15px 10px 15px 25px;
  }
}
@media (max-width: 991px) {
  .banner2-card.five .banner2-content-wrap {
    height: 100%;
  }
}
@media (max-width: 767px) {
  .banner2-card.five .banner2-content-wrap {
    padding: 15px 10px 15px 25px;
  }
}
@media (max-width: 576px) {
  .banner2-card.five .banner2-content-wrap {
    padding-left: 20px;
  }
}
.banner2-card.five .banner2-content-wrap .banner2-content {
  max-width: 250px;
  width: 100%;
  text-align: center;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .banner2-card.five .banner2-content-wrap .banner2-content {
    max-width: 200px;
  }
}
@media (max-width: 767px) {
  .banner2-card.five .banner2-content-wrap .banner2-content {
    max-width: 200px;
  }
}
@media (max-width: 576px) {
  .banner2-card.five .banner2-content-wrap .banner2-content {
    max-width: 160px;
  }
}
.banner2-card.five .banner2-content-wrap .banner2-content span {
  margin-bottom: 5px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .banner2-card.five .banner2-content-wrap .banner2-content span {
    margin-bottom: 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .banner2-card.five .banner2-content-wrap .banner2-content span {
    font-size: 22px;
  }
}
@media (max-width: 576px) {
  .banner2-card.five .banner2-content-wrap .banner2-content span {
    font-size: 18px;
    margin-bottom: 8px;
  }
}
.banner2-card.five .banner2-content-wrap .banner2-content h5 {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 25px;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 15px;
}
.banner2-card.five .banner2-content-wrap .banner2-content h5 span {
  font-size: 35px;
  font-weight: 700;
  color: var(--primary-color2);
}
@media (min-width: 992px) and (max-width: 1199px) {
  .banner2-card.five .banner2-content-wrap .banner2-content h5 span {
    font-size: 30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .banner2-card.five .banner2-content-wrap .banner2-content h5 span {
    font-size: 38px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .banner2-card.five .banner2-content-wrap .banner2-content h5 {
    margin-bottom: 5px;
    font-size: 22px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .banner2-card.five .banner2-content-wrap .banner2-content h5 {
    font-size: 30px;
  }
}
@media (max-width: 576px) {
  .banner2-card.five .banner2-content-wrap .banner2-content h5 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .banner2-card.five .banner2-content-wrap .banner2-content h5 span {
    font-size: 25px;
  }
}
.banner2-card.five .banner2-content-wrap .banner2-content .secondary-btn1 {
  background-color: var(--primary-color1);
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  padding: 11px 17px;
}
@media (max-width: 576px) {
  .banner2-card.five .banner2-content-wrap .banner2-content .secondary-btn1 {
    padding: 10px 16px;
    font-size: 13px;
  }
}
.banner2-card.five .banner2-content-wrap .banner2-content .secondary-btn1::after {
  background-color: var(--title-color);
}
.banner2-card.five .banner2-content-wrap .banner2-content .secondary-btn1:hover {
  color: var(--white-color);
}
.banner2-card.six {
  height: 100%;
}
.banner2-card.six img {
  height: 100%;
  border-radius: 10px;
}
.banner2-card.six::before {
  display: none;
}
.banner2-card.six::after {
  display: none;
}
.banner2-card.six .banner2-content-wrap {
  background-image: url(../img/home3/vector/banner2-content-bg1.svg);
  border-radius: 0 0 10px 10px;
}
@media (max-width: 767px) {
  .banner2-card.six .banner2-content-wrap {
    padding: 50px 20px 20px 20px;
  }
}
@media (max-width: 576px) {
  .banner2-card.six .banner2-content-wrap {
    padding: 30px 20px 15px 20px;
  }
}
.banner2-card.six .banner2-content-wrap .banner2-content {
  max-width: 435px;
  width: 100%;
  margin: 0 auto;
}
.banner2-card.six .banner2-content-wrap .banner2-content h3 {
  color: var(--white-color);
  line-height: 1.2;
}
@media (max-width: 576px) {
  .banner2-card.six .banner2-content-wrap .banner2-content h3 {
    font-size: 26px;
  }
}
.banner2-card.six .banner2-content-wrap .banner2-content h3 span {
  font-size: 45px;
  color: var(--title-color);
}
@media (max-width: 576px) {
  .banner2-card.six .banner2-content-wrap .banner2-content h3 span {
    font-size: 35px;
  }
}
.banner2-card.six .banner2-content-wrap .banner2-content p {
  font-size: 14px;
  font-weight: 400;
  color: var(--title-color);
}
@media (max-width: 767px) {
  .banner2-card.six .banner2-content-wrap .banner2-content p {
    margin-bottom: 20px;
  }
}
@media (max-width: 576px) {
  .banner2-card.six .banner2-content-wrap .banner2-content p {
    font-size: 13px;
    margin-bottom: 5px;
  }
}
.banner2-card.six .banner2-content-wrap .banner2-content a {
  color: var(--title-color);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  white-space: nowrap;
}
.banner2-card.six .banner2-content-wrap .banner2-content a svg {
  fill: var(--title-color);
}

/*=====================================
  14.Home1 Testimonial section
========================================*/
.tesimonial-card-wrapper .tesimonial-card {
  padding: 35px 30px 25px 30px;
  border-radius: 10px;
  border: 1px solid rgba(34, 34, 34, 0.1);
  background: var(--white-color);
  position: relative;
  margin-bottom: 40px;
  z-index: 1;
}
@media (max-width: 1399px) {
  .tesimonial-card-wrapper .tesimonial-card {
    padding: 25px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .tesimonial-card-wrapper .tesimonial-card {
    padding: 20px;
  }
}
.tesimonial-card-wrapper .tesimonial-card .testimonial-content {
  text-align: center;
  margin-bottom: 30px;
}
@media (max-width: 576px) {
  .tesimonial-card-wrapper .tesimonial-card .testimonial-content {
    margin-bottom: 20px;
  }
}
.tesimonial-card-wrapper .tesimonial-card .testimonial-content p {
  color: rgba(16, 12, 8, 0.7);
  font-family: var(--font-jost);
  font-size: 18px;
  font-weight: 400;
  line-height: 1.9;
  letter-spacing: 0.54px;
  margin-bottom: 0;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .tesimonial-card-wrapper .tesimonial-card .testimonial-content p {
    font-size: 17px;
  }
}
@media (max-width: 1399px) {
  .tesimonial-card-wrapper .tesimonial-card .testimonial-content p {
    font-size: 17px;
  }
}
@media (max-width: 991px) {
  .tesimonial-card-wrapper .tesimonial-card .testimonial-content p {
    font-size: 16px;
  }
}
.tesimonial-card-wrapper .tesimonial-card .testimonial-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.tesimonial-card-wrapper .tesimonial-card .testimonial-bottom .rating-area .rating {
  display: flex;
  align-items: center;
  gap: 5px;
}
.tesimonial-card-wrapper .tesimonial-card .testimonial-bottom .rating-area .rating li {
  line-height: 1;
}
.tesimonial-card-wrapper .tesimonial-card .testimonial-bottom .rating-area .rating li i {
  color: #FFC107;
}
.tesimonial-card-wrapper .tesimonial-card .testimonial-bottom .rating-area span {
  font-family: var(--font-jost);
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: var(--title-color);
  display: inline-flex;
  align-items: center;
  gap: 5px;
  padding-top: 5px;
}
.tesimonial-card-wrapper .tesimonial-card .testimonial-bottom .rating-area.tripadvisor .rating li i {
  color: #00AA6C;
  font-size: 12px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .tesimonial-card-wrapper .tesimonial-card .testimonial-bottom .quote {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}
.tesimonial-card-wrapper .tesimonial-card .testimonial-bottom .quote svg {
  fill: rgba(34, 34, 34, 0.03);
}
.tesimonial-card-wrapper .tesimonial-card .testimonial-bottom .date-and-time p {
  color: #222;
  font-family: var(--font-rubik);
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 2px;
}
.tesimonial-card-wrapper .tesimonial-card .testimonial-bottom .date-and-time span {
  color: rgba(34, 34, 34, 0.5);
  font-family: var(--font-rubik);
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
}
.tesimonial-card-wrapper .tesimonial-card::before {
  content: "";
  position: absolute;
  bottom: -28px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-clip-path: polygon(100% 0, 0 0, 0 100%);
          clip-path: polygon(100% 0, 0 0, 0 100%);
  background-color: var(--white-color);
  border-left: 1px solid rgba(34, 34, 34, 0.1);
  border-right: 1px solid rgba(34, 34, 34, 0.1);
  width: 23px;
  height: 30px;
}
.tesimonial-card-wrapper .author-area {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.tesimonial-card-wrapper .author-area .author-img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
}
.tesimonial-card-wrapper .author-area .author-img img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
}
.tesimonial-card-wrapper .author-area .author-content h5 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 23px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 0;
}
.tesimonial-card-wrapper .author-area .author-content span {
  color: rgba(34, 34, 34, 0.5);
  font-family: var(--font-jost);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.32px;
}
.tesimonial-card-wrapper.style-2 {
  padding: 0 105px;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .tesimonial-card-wrapper.style-2 {
    padding: 0 80px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .tesimonial-card-wrapper.style-2 {
    padding: 0 45px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .tesimonial-card-wrapper.style-2 {
    padding: 0 35px;
  }
}
@media (max-width: 991px) {
  .tesimonial-card-wrapper.style-2 {
    padding: 0 50px;
  }
}
@media (max-width: 767px) {
  .tesimonial-card-wrapper.style-2 {
    padding: 0;
  }
}
.tesimonial-card-wrapper.style-2 .tesimonial-card {
  padding: 55px 50px;
  border-bottom: 3px solid var(--primary-color1);
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .tesimonial-card-wrapper.style-2 .tesimonial-card {
    padding: 55px 45px;
  }
}
@media (max-width: 1399px) {
  .tesimonial-card-wrapper.style-2 .tesimonial-card {
    padding: 50px 35px;
  }
}
@media (max-width: 767px) {
  .tesimonial-card-wrapper.style-2 .tesimonial-card {
    padding: 40px 25px;
  }
}
@media (max-width: 576px) {
  .tesimonial-card-wrapper.style-2 .tesimonial-card {
    padding: 25px;
  }
}
.tesimonial-card-wrapper.style-2 .tesimonial-card .testimonial-content p {
  font-size: 24px;
}
@media (max-width: 1199px) {
  .tesimonial-card-wrapper.style-2 .tesimonial-card .testimonial-content p {
    font-size: 20px;
  }
}
@media (max-width: 576px) {
  .tesimonial-card-wrapper.style-2 .tesimonial-card .testimonial-content p {
    font-size: 16px;
  }
}
.tesimonial-card-wrapper.style-2 .tesimonial-card .testimonial-bottom {
  justify-content: center;
}
.tesimonial-card-wrapper.style-2 .tesimonial-card::before {
  -webkit-clip-path: polygon(0 0, 50% 100%, 100% 0);
          clip-path: polygon(0 0, 50% 100%, 100% 0);
  width: 33px;
  height: 23px;
  bottom: -22px;
}
.tesimonial-card-wrapper.style-2 .tesimonial-card::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../img/home2/vector/testimonial-card-bg.svg);
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}
.tesimonial-card-wrapper.style-2 .tesimonial-card .quote {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.tesimonial-card-wrapper.style-2 .author-area .author-content h5 {
  color: var(--white-color);
}
.tesimonial-card-wrapper.style-2 .author-area .author-content span {
  color: rgba(255, 255, 255, 0.5);
}

.testimonial-section {
  background-color: #F3EEEA;
  padding: 110px 0;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .testimonial-section {
    padding: 90px 0;
  }
}
@media (max-width: 991px) {
  .testimonial-section {
    padding: 80px 0;
  }
}
.testimonial-section .testimonial-wrapper .testimonial-nav-area .nav-pills {
  background-color: var(--white-color);
  border-radius: 10px;
  margin-bottom: 50px;
}
@media (max-width: 576px) {
  .testimonial-section .testimonial-wrapper .testimonial-nav-area .nav-pills {
    justify-content: center;
    margin-bottom: 30px;
  }
}
.testimonial-section .testimonial-wrapper .testimonial-nav-area .nav-pills .nav-item {
  padding: 0 22px;
}
.testimonial-section .testimonial-wrapper .testimonial-nav-area .nav-pills .nav-item .nav-link {
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.28px;
  padding: 10px 0;
  height: 44px;
  position: relative;
  cursor: pointer;
}
.testimonial-section .testimonial-wrapper .testimonial-nav-area .nav-pills .nav-item .nav-link .icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #878583;
  display: flex;
  align-items: center;
  justify-content: center;
}
.testimonial-section .testimonial-wrapper .testimonial-nav-area .nav-pills .nav-item .nav-link.active {
  color: var(--primary-color1);
}
.testimonial-section .testimonial-wrapper .testimonial-nav-area .nav-pills .nav-item .nav-link.active::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--primary-color1);
}
.testimonial-section .testimonial-wrapper .testimonial-card-slider-area {
  padding: 0 12.5%;
  position: relative;
}
@media (max-width: 1699px) {
  .testimonial-section .testimonial-wrapper .testimonial-card-slider-area {
    padding: 0 7%;
  }
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .testimonial-section .testimonial-wrapper .testimonial-card-slider-area {
    padding: 0 2.5%;
  }
}
@media (max-width: 1399px) {
  .testimonial-section .testimonial-wrapper .testimonial-card-slider-area {
    padding: 0 2.5%;
  }
}
@media (max-width: 1199px) {
  .testimonial-section .testimonial-wrapper .testimonial-card-slider-area {
    padding: 0 2%;
  }
}
.testimonial-section .testimonial-wrapper .testimonial-card-slider-area .slider-btn-grp2 {
  display: block;
}
.testimonial-section .testimonial-wrapper .testimonial-card-slider-area .slider-btn-grp2 .testimonial-card-tab-prev {
  position: absolute;
  top: 30%;
  left: 105px;
  z-index: 1;
}
@media (max-width: 1699px) {
  .testimonial-section .testimonial-wrapper .testimonial-card-slider-area .slider-btn-grp2 .testimonial-card-tab-prev {
    left: 50px;
  }
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .testimonial-section .testimonial-wrapper .testimonial-card-slider-area .slider-btn-grp2 .testimonial-card-tab-prev {
    left: 5px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .testimonial-section .testimonial-wrapper .testimonial-card-slider-area .slider-btn-grp2 .testimonial-card-tab-prev {
    left: 25px;
  }
}
@media (max-width: 1199px) {
  .testimonial-section .testimonial-wrapper .testimonial-card-slider-area .slider-btn-grp2 .testimonial-card-tab-prev {
    left: 15px;
  }
}
@media (max-width: 767px) {
  .testimonial-section .testimonial-wrapper .testimonial-card-slider-area .slider-btn-grp2 .testimonial-card-tab-prev {
    display: none;
  }
}
.testimonial-section .testimonial-wrapper .testimonial-card-slider-area .slider-btn-grp2 .testimonial-card-tab-next {
  position: absolute;
  top: 30%;
  right: 105px;
  z-index: 1;
}
@media (max-width: 1699px) {
  .testimonial-section .testimonial-wrapper .testimonial-card-slider-area .slider-btn-grp2 .testimonial-card-tab-next {
    right: 50px;
  }
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .testimonial-section .testimonial-wrapper .testimonial-card-slider-area .slider-btn-grp2 .testimonial-card-tab-next {
    right: 5px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .testimonial-section .testimonial-wrapper .testimonial-card-slider-area .slider-btn-grp2 .testimonial-card-tab-next {
    right: 25px;
  }
}
@media (max-width: 1199px) {
  .testimonial-section .testimonial-wrapper .testimonial-card-slider-area .slider-btn-grp2 .testimonial-card-tab-next {
    right: 15px;
  }
}
@media (max-width: 767px) {
  .testimonial-section .testimonial-wrapper .testimonial-card-slider-area .slider-btn-grp2 .testimonial-card-tab-next {
    display: none;
  }
}

/*=====================================
  15.Home1 Blog section
========================================*/
.blog-card {
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.08);
  transition: all 0.5s ease-out;
}
.blog-card .blog-card-img-wrap {
  position: relative;
  overflow: hidden;
  transition: all 0.5s ease-out;
}
@media (max-width: 767px) {
  .blog-card .blog-card-img-wrap {
    width: 100%;
  }
}
.blog-card .blog-card-img-wrap .card-img {
  overflow: hidden;
  display: block;
  position: relative;
  border-radius: 5px 5px 0 0;
  transition: all 0.5s ease-out;
}
.blog-card .blog-card-img-wrap .card-img img {
  border-radius: 5px 5px 0 0;
  transition: all 0.5s ease-out;
}
@media (max-width: 991px) {
  .blog-card .blog-card-img-wrap .card-img img {
    width: 100%;
  }
}
.blog-card .blog-card-img-wrap .card-img::after {
  position: absolute;
  width: 200%;
  height: 0%;
  left: 50%;
  top: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  transform: translate(-50%, -50%) rotate(-45deg);
  content: "";
  z-index: 1;
}
.blog-card .blog-card-img-wrap .date {
  position: absolute;
  top: 13px;
  left: 13px;
  text-align: center;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background: rgba(16, 12, 8, 0.4);
  -webkit-backdrop-filter: blur(1.7px);
          backdrop-filter: blur(1.7px);
  transition: 0.5s;
  z-index: 9;
}
.blog-card .blog-card-img-wrap .date span {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 11px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.22px;
  text-transform: capitalize;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s;
}
.blog-card .blog-card-img-wrap .date span strong {
  font-size: 17px;
  font-weight: 600;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .blog-card .blog-card-img-wrap .date {
    top: 5px;
    left: 5px;
    width: 60px;
    height: 60px;
  }
  .blog-card .blog-card-img-wrap .date span {
    font-size: 10px;
  }
  .blog-card .blog-card-img-wrap .date span strong {
    font-size: 16px;
  }
}
@media (max-width: 576px) {
  .blog-card .blog-card-img-wrap .date {
    top: 5px;
    left: 5px;
    width: 60px;
    height: 60px;
  }
  .blog-card .blog-card-img-wrap .date span {
    font-size: 10px;
  }
  .blog-card .blog-card-img-wrap .date span strong {
    font-size: 16px;
  }
}
.blog-card .blog-card-content {
  padding: 30px 20px;
}
@media (max-width: 767px) {
  .blog-card .blog-card-content {
    padding: 20px;
  }
}
.blog-card .blog-card-content .blog-card-content-top > ul {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 20px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .blog-card .blog-card-content .blog-card-content-top > ul {
    margin-bottom: 15px;
  }
}
@media (max-width: 576px) {
  .blog-card .blog-card-content .blog-card-content-top > ul {
    gap: 15px;
  }
}
.blog-card .blog-card-content .blog-card-content-top > ul li {
  position: relative;
  color: rgba(16, 12, 8, 0.5);
  font-family: var(--font-jost);
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.26px;
  text-transform: capitalize;
}
.blog-card .blog-card-content .blog-card-content-top > ul li::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -18px;
  background-color: var(--primary-color1);
  width: 5px;
  height: 5px;
  border-radius: 50%;
}
@media (max-width: 576px) {
  .blog-card .blog-card-content .blog-card-content-top > ul li::before {
    left: -10px;
  }
}
.blog-card .blog-card-content .blog-card-content-top > ul li:first-child::before {
  display: none;
}
.blog-card .blog-card-content .blog-card-content-top > ul li a {
  color: rgba(16, 12, 8, 0.5);
  font-family: var(--font-jost);
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.26px;
  text-transform: capitalize;
  text-decoration: underline;
  transition: 0.5s;
}
.blog-card .blog-card-content .blog-card-content-top > ul li a:hover {
  color: var(--primary-color1);
}
.blog-card .blog-card-content .blog-card-content-top > ul li:nth-child(2) a {
  text-decoration: none;
}
.blog-card .blog-card-content .blog-card-content-top .blog-date {
  display: flex;
  align-items: center;
  gap: 5px;
}
.blog-card .blog-card-content .blog-card-content-top .blog-date svg {
  fill: var(--title-color);
  opacity: 0.5;
}
.blog-card .blog-card-content .blog-card-content-top .blog-date a {
  color: var(--text-color);
  font-size: 13px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.26px;
  text-transform: capitalize;
  transition: 0.5s;
}
.blog-card .blog-card-content .blog-card-content-top .blog-date a:hover {
  color: var(--primary-color1);
}
.blog-card .blog-card-content h5 {
  margin-bottom: 25px;
}
.blog-card .blog-card-content h5 a {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 23px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 0.23px;
  text-transform: capitalize;
  transition: 0.5s;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .blog-card .blog-card-content h5 a {
    font-size: 20px;
  }
}
@media (max-width: 1199px) {
  .blog-card .blog-card-content h5 a {
    font-size: 18px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .blog-card .blog-card-content h5 {
    margin-bottom: 20px;
  }
}
.blog-card .blog-card-content h5:hover a {
  color: var(--primary-color1);
}
.blog-card .blog-card-content .bottom-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}
.blog-card .blog-card-content .bottom-area > a {
  color: var(--primary-color1);
  font-family: var(--font-rubik);
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  text-decoration-line: underline;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: 0.5s;
}
.blog-card .blog-card-content .bottom-area > a > span {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid rgba(var(--primary-color1-opc), 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
}
.blog-card .blog-card-content .bottom-area > a > span svg {
  stroke: var(--primary-color1);
  transition: 0.5s;
}
.blog-card .blog-card-content .bottom-area > a:hover {
  color: var(--primary-color2);
}
.blog-card .blog-card-content .bottom-area > a:hover span {
  border-color: var(--primary-color2);
}
.blog-card .blog-card-content .bottom-area > a:hover span svg {
  transform: rotate(28deg);
  stroke: var(--primary-color2);
}
.blog-card .blog-card-content .bottom-area .social-list {
  display: flex;
  align-items: center;
  gap: 30px;
}
.blog-card .blog-card-content .bottom-area .social-list li {
  position: relative;
  transition: 0.5s;
}
.blog-card .blog-card-content .bottom-area .social-list li::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -17px;
  background: rgba(16, 12, 8, 0.2);
  width: 3px;
  height: 3px;
  border-radius: 50%;
}
.blog-card .blog-card-content .bottom-area .social-list li:first-child::before {
  display: none;
}
.blog-card .blog-card-content .bottom-area .social-list li a {
  color: rgba(16, 12, 8, 0.5);
  transition: 0.5s;
}
.blog-card .blog-card-content .bottom-area .social-list li a i {
  font-size: 16px;
}
.blog-card .blog-card-content .bottom-area .social-list li:hover a {
  color: var(--primary-color2);
}
.blog-card .blog-card-content .bottom-area > span {
  color: rgba(16, 12, 8, 0.5);
  font-family: var(--font-segoe-ui);
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.24px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 5px;
}
.blog-card .blog-card-content .bottom-area > span svg {
  fill: rgba(16, 12, 8, 0.5);
}
@media (min-width: 992px) and (max-width: 1199px) {
  .blog-card .blog-card-content.two {
    padding: 25px 15px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .blog-card .blog-card-content.two h5 a {
    font-size: 17px;
  }
}
.blog-card:hover .blog-card-img-wrap .card-img img {
  transform: scale(1.05);
}
.blog-card:hover .blog-card-img-wrap .card-img::after {
  height: 250%;
  transition: all 600ms linear;
  background-color: transparent;
}
.blog-card:hover .blog-card-img-wrap .date {
  background-color: var(--primary-color1);
}
.blog-card.two {
  display: flex;
  align-items: center;
}
@media (max-width: 576px) {
  .blog-card.two {
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .blog-card.two .blog-card-img-wrap {
    width: unset;
  }
}
@media (max-width: 576px) {
  .blog-card.two .blog-card-img-wrap {
    width: 100%;
  }
}
.blog-card.two .blog-card-img-wrap .card-img {
  border-radius: 5px 0 0 5px;
}
.blog-card.two .blog-card-img-wrap .card-img img {
  border-radius: 5px 0 0 5px;
  min-width: 290px;
  max-width: 290px;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .blog-card.two .blog-card-img-wrap .card-img img {
    min-width: 255px;
    max-width: 255px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .blog-card.two .blog-card-img-wrap .card-img img {
    min-width: 222px;
    max-width: 222px;
  }
}
@media (max-width: 767px) {
  .blog-card.two .blog-card-img-wrap .card-img img {
    min-width: 240px;
    max-width: 240px;
  }
}
@media (max-width: 576px) {
  .blog-card.two .blog-card-img-wrap .card-img img {
    min-width: unset;
    max-width: unset;
    width: 100%;
    border-radius: unset;
  }
}
.blog-card.two .blog-card-content {
  padding: 25px 20px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .blog-card.two .blog-card-content {
    padding: 15px 20px;
  }
}
@media (max-width: 767px) {
  .blog-card.two .blog-card-content {
    padding: 0 15px;
  }
}
@media (max-width: 576px) {
  .blog-card.two .blog-card-content {
    padding: 20px;
    width: 100%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .blog-card.two .blog-card-content h5 a {
    font-size: 17px;
  }
}
@media (max-width: 767px) {
  .blog-card.two .blog-card-content h5 a {
    font-size: 18px;
  }
}
.blog-card.style-2 {
  background-color: unset;
  box-shadow: none;
}
.blog-card.style-2 .blog-card-img-wrap .date {
  background-color: var(--primary-color1);
}
.blog-card.style-2 .blog-card-img-wrap .date span {
  font-size: 12px;
}
.blog-card.style-2 .blog-card-img-wrap .date span strong {
  font-size: 22px;
}
@media (max-width: 576px) {
  .blog-card.style-2 .blog-card-img-wrap .date {
    width: 60px;
    height: 60px;
  }
  .blog-card.style-2 .blog-card-img-wrap .date span {
    font-size: 11px;
  }
  .blog-card.style-2 .blog-card-img-wrap .date span strong {
    font-size: 20px;
  }
}
.blog-card.style-2 .blog-card-content {
  max-width: 500px;
  width: 100%;
  background-color: var(--white-color);
  border-radius: 5px;
  box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.08);
  margin: 0 auto;
  position: relative;
  margin-top: -65px;
  z-index: 9;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .blog-card.style-2 .blog-card-content {
    max-width: 400px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .blog-card.style-2 .blog-card-content {
    max-width: 600px;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .blog-card.style-2 .blog-card-content {
    max-width: 450px;
  }
}
@media (max-width: 576px) {
  .blog-card.style-2 .blog-card-content {
    margin-top: 0;
    max-width: unset;
    border-radius: 0 0 5px 5px;
  }
}
.blog-card.style-2 .blog-card-content ul {
  margin-bottom: 15px;
}
.blog-card.style-2:hover .blog-card-img .date {
  background-color: var(--primary-color2);
}
.blog-card.style-2:hover .blog-card-img .date span {
  color: var(--title-color);
}
.blog-card.style-3 {
  position: relative;
  background-color: unset;
  box-shadow: none;
  height: 100%;
}
.blog-card.style-3 .blog-card-img-wrap {
  height: 100%;
}
.blog-card.style-3 .blog-card-img-wrap .card-img {
  position: relative;
  display: block;
  height: 100%;
}
.blog-card.style-3 .blog-card-img-wrap .card-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(16, 12, 8, 0) 0%, #100C08 100%);
  z-index: 1;
}
.blog-card.style-3 .blog-card-img-wrap .card-img img {
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.blog-card.style-3 .blog-card-img-wrap .date {
  background-color: var(--primary-color1);
}
.blog-card.style-3 .blog-card-img-wrap .date span {
  font-size: 12px;
}
.blog-card.style-3 .blog-card-img-wrap .date span strong {
  font-size: 22px;
}
@media (max-width: 576px) {
  .blog-card.style-3 .blog-card-img-wrap .date {
    width: 60px;
    height: 60px;
  }
  .blog-card.style-3 .blog-card-img-wrap .date span {
    font-size: 11px;
  }
  .blog-card.style-3 .blog-card-img-wrap .date span strong {
    font-size: 20px;
  }
}
.blog-card.style-3 .blog-card-content {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 40px 50px;
  z-index: 2;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .blog-card.style-3 .blog-card-content {
    padding: 35px 25px;
  }
}
@media (max-width: 767px) {
  .blog-card.style-3 .blog-card-content {
    padding: 35px 25px;
  }
}
@media (max-width: 576px) {
  .blog-card.style-3 .blog-card-content {
    padding: 20px 15px;
  }
}
@media (max-width: 576px) {
  .blog-card.style-3 .blog-card-content .blog-card-content-top ul {
    margin-bottom: 10px;
  }
}
.blog-card.style-3 .blog-card-content .blog-card-content-top ul li {
  color: rgba(255, 255, 255, 0.7);
  transition: 0.5s;
}
.blog-card.style-3 .blog-card-content .blog-card-content-top ul li a {
  color: rgba(255, 255, 255, 0.7);
}
.blog-card.style-3 .blog-card-content .blog-card-content-top ul li::before {
  background-color: var(--white-color);
  transition: 0.5s;
}
.blog-card.style-3 .blog-card-content .blog-card-content-top ul li:hover a {
  color: var(--primary-color2);
}
.blog-card.style-3 .blog-card-content .blog-card-content-top ul li:hover::before {
  background-color: var(--primary-color2);
}
.blog-card.style-3 .blog-card-content h5 {
  margin-bottom: 0;
}
.blog-card.style-3 .blog-card-content h5 a {
  color: var(--white-color);
  font-size: 23px;
}
@media (max-width: 767px) {
  .blog-card.style-3 .blog-card-content h5 a {
    font-size: 20px;
  }
}
@media (max-width: 576px) {
  .blog-card.style-3 .blog-card-content h5 a {
    font-size: 17px;
  }
}
.blog-card.style-3 .blog-card-content h5:hover a {
  color: var(--primary-color2);
}
.blog-card.style-3:hover .blog-card-img-wrap .date {
  background-color: var(--primary-color2);
}
.blog-card.style-3:hover .blog-card-img-wrap .date span {
  color: var(--title-color);
}
.blog-card.style-4 {
  box-shadow: unset;
  border-radius: unset;
  border: 1px solid rgba(16, 12, 8, 0.08);
  height: 100%;
}
.blog-card.style-4 .blog-card-img img {
  border-radius: unset;
}
.blog-card.style-4 .blog-card-content {
  padding: 30px 35px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .blog-card.style-4 .blog-card-content {
    padding: 25px;
  }
}
@media (max-width: 576px) {
  .blog-card.style-4 .blog-card-content {
    padding: 20px 15px;
  }
}
.blog-card.style-4 .blog-card-content .blog-card-content-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 14px 0;
  border-top: 1px solid rgba(16, 12, 8, 0.1);
  border-bottom: 1px solid rgba(16, 12, 8, 0.1);
  position: relative;
}
@media (max-width: 576px) {
  .blog-card.style-4 .blog-card-content .blog-card-content-top {
    margin-bottom: 10px;
  }
}
.blog-card.style-4 .blog-card-content .blog-card-content-top ul {
  margin-bottom: 0;
}
.blog-card.style-4 .blog-card-content .blog-card-content-top::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(16, 12, 8, 0.1);
  width: 1px;
  height: 100%;
}
@media (max-width: 576px) {
  .blog-card.style-4 .blog-card-content .blog-card-content-top::before {
    display: none;
  }
}
.blog-card.style-4 .blog-card-content h5 a {
  font-size: 23px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .blog-card.style-4 .blog-card-content h5 a {
    font-size: 20px;
  }
}
@media (max-width: 576px) {
  .blog-card.style-4 .blog-card-content h5 a {
    font-size: 17px;
  }
}
.blog-card.style-5 {
  position: relative;
  background-color: unset;
  box-shadow: none;
  height: 100%;
}
.blog-card.style-5 .blog-card-img-wrap {
  height: 100%;
}
.blog-card.style-5 .blog-card-img-wrap .card-img {
  position: relative;
  display: block;
  height: 100%;
  border-radius: 5px;
}
.blog-card.style-5 .blog-card-img-wrap .card-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(16, 12, 8, 0) 0%, #100C08 100%);
  border-radius: 5px;
  z-index: 1;
}
.blog-card.style-5 .blog-card-img-wrap .card-img img {
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 5px;
}
.blog-card.style-5 .blog-card-content {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 40px 30px;
  z-index: 2;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .blog-card.style-5 .blog-card-content {
    padding: 35px 20px;
  }
}
@media (max-width: 767px) {
  .blog-card.style-5 .blog-card-content {
    padding: 35px 25px;
  }
}
@media (max-width: 576px) {
  .blog-card.style-5 .blog-card-content {
    padding: 20px 15px;
  }
}
@media (max-width: 576px) {
  .blog-card.style-5 .blog-card-content .blog-card-content-top ul {
    margin-bottom: 10px;
  }
}
.blog-card.style-5 .blog-card-content .blog-card-content-top ul li {
  color: rgba(255, 255, 255, 0.7);
  transition: 0.5s;
}
.blog-card.style-5 .blog-card-content .blog-card-content-top ul li a {
  color: rgba(255, 255, 255, 0.7);
}
.blog-card.style-5 .blog-card-content .blog-card-content-top ul li::before {
  background-color: var(--white-color);
  transition: 0.5s;
}
.blog-card.style-5 .blog-card-content .blog-card-content-top ul li:hover a {
  color: var(--primary-color2);
}
.blog-card.style-5 .blog-card-content .blog-card-content-top ul li:hover::before {
  background-color: var(--primary-color2);
}
.blog-card.style-5 .blog-card-content h5 {
  margin-bottom: 25px;
}
.blog-card.style-5 .blog-card-content h5 a {
  color: var(--white-color);
  font-size: 23px;
}
@media (max-width: 1199px) {
  .blog-card.style-5 .blog-card-content h5 a {
    font-size: 20px;
  }
}
@media (max-width: 576px) {
  .blog-card.style-5 .blog-card-content h5 a {
    font-size: 17px;
  }
}
.blog-card.style-5 .blog-card-content h5:hover a {
  color: var(--primary-color2);
}
.blog-card.style-5 .blog-card-content .bottom-area .social-list li {
  position: relative;
  transition: 0.5s;
}
.blog-card.style-5 .blog-card-content .bottom-area .social-list li::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -17px;
  background: rgba(255, 255, 255, 0.2);
  width: 3px;
  height: 3px;
  border-radius: 50%;
}
.blog-card.style-5 .blog-card-content .bottom-area .social-list li:first-child::before {
  display: none;
}
.blog-card.style-5 .blog-card-content .bottom-area .social-list li a {
  color: rgba(255, 255, 255, 0.5);
  transition: 0.5s;
}
.blog-card.style-5 .blog-card-content .bottom-area .social-list li a i {
  font-size: 16px;
}
.blog-card.style-5 .blog-card-content .bottom-area .social-list li:hover a {
  color: var(--primary-color2);
}
.blog-card.style-5:hover .blog-card-img .date {
  background-color: var(--primary-color2);
}
.blog-card.style-5:hover .blog-card-img .date span {
  color: var(--title-color);
}
.blog-card.style-6 {
  box-shadow: unset;
}
.blog-card.style-6 .blog-card-img-wrap .card-img {
  z-index: 2;
}
.blog-card.style-6 .blog-card-img-wrap .card-img img {
  border-radius: unset;
  transition: all 0.5s ease-out;
}
.blog-card.style-6 .blog-card-img-wrap .card-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(16, 12, 8, 0.2);
  transition: all 0.5s ease-out;
  z-index: 1;
}
.blog-card.style-6 .blog-card-content {
  padding: 25px 10px 0;
}
.blog-card.style-6:hover .blog-card-img .card-img img {
  transform: scale(1.05);
}
.blog-card.style-6:hover .blog-card-img .card-img::after {
  height: 250%;
  transition: all 600ms linear;
  background-color: transparent;
}
.blog-card.style-6:hover .blog-card-img .date {
  background-color: var(--primary-color1);
}

.blog-section {
  position: relative;
}
.blog-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(../img/home1/vector/section-bg-vector2.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.blog-section .section-vector1 {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}

/*=====================================
  16.Home1 Visa section
========================================*/
.package-card2 {
  border-radius: 10px;
  position: relative;
}
.package-card2 > a {
  display: block;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}
.package-card2 > a img {
  border-radius: 10px;
  transition: 0.5s ease-out;
}
.package-card2 > a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(16, 12, 8, 0.1);
  border-radius: 10px;
  z-index: 1;
}
.package-card2 .eg-tag {
  -webkit-mask-image: url(../img/home1/vector/package-card2-tag.svg);
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: cover;
  background-color: var(--primary-color1);
  padding: 24px 75px;
  white-space: nowrap;
  position: absolute;
  bottom: 110px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}
.package-card2 .eg-tag h4 {
  margin-bottom: 0;
}
.package-card2 .eg-tag h4 a {
  color: var(--white-color);
  font-family: var(--font-sansita);
  font-size: 26px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 1.2px;
  margin-top: -5px;
  display: block;
  transition: 0.5s;
}
.package-card2 .eg-tag h4 a:hover {
  color: var(--title-color);
}
.package-card2 .package-card2-content {
  background-image: url(../img/home1/package-card2-content-bg1.png);
  background-repeat: no-repeat;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  border-radius: 0 0 10px 10px;
  display: flex;
  align-items: center;
  padding: 18px 0;
  transition: 0.5s;
}
.package-card2 .package-card2-content .title {
  padding: 0px 20px;
  text-align: center;
  width: 50%;
}
.package-card2 .package-card2-content .title h6 {
  color: var(--white-color);
  font-family: var(--font-jost);
  font-size: 17px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.17px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.package-card2 .package-card2-content .price-area {
  padding: 0px 20px;
  text-align: center;
  width: 50%;
}
.package-card2 .package-card2-content .price-area span {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 30px;
  font-weight: 600;
  line-height: 1;
  text-transform: capitalize;
  display: inline-block;
  margin-bottom: 5px;
}
.package-card2 .package-card2-content .price-area p {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 10px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.2px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.package-card2 .package-card2-content::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.4);
  width: 1px;
  height: 100%;
}
.package-card2:hover a img {
  transform: scale(1.1);
}
.package-card2:hover .package-card2-content {
  background-image: url(../img/home1/package-card2-content-bg2.png);
}
.package-card2.style-2 .eg-tag {
  background-color: var(--primary-color2);
}

.visa-section {
  position: relative;
}
.visa-section .section-vector2 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
.visa-section .slider-btn-grp2 {
  gap: 40px;
}
@media (max-width: 576px) {
  .visa-section .slider-btn-grp2 {
    gap: 30px;
  }
}

/*=====================================
  17.Home1 Banner3 section
========================================*/
.banner3-section {
  margin-bottom: -137px;
  position: relative;
  z-index: 1;
}
@media (max-width: 991px) {
  .banner3-section {
    margin-bottom: -70px;
  }
}
.banner3-section .banner3-content {
  background-image: url(../img/home1/newsletter-bg.png), linear-gradient(180deg, #f9f4f0 0%, #f9f4f0 100%);
  padding: 86px 20px;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  border-radius: 30px;
  position: relative;
  z-index: 1;
}
@media (max-width: 576px) {
  .banner3-section .banner3-content {
    padding: 50px 20px;
    border-radius: 10px;
  }
}
.banner3-section .banner3-content h2 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 40px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 5px;
  margin-top: -10px;
}
@media (max-width: 767px) {
  .banner3-section .banner3-content h2 {
    font-size: 35px;
    margin-top: 0;
  }
}
@media (max-width: 576px) {
  .banner3-section .banner3-content h2 {
    font-size: 28px;
  }
}
.banner3-section .banner3-content p {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.16px;
  margin-bottom: 20px;
}
@media (max-width: 576px) {
  .banner3-section .banner3-content p {
    font-size: 15px;
  }
}
.banner3-section .banner3-content .from-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  border: 1px solid;
  border-color: var(--primary-color1);
  border-radius: 10px;
}
.banner3-section .banner3-content .from-inner input {
  background: transparent;
  border: none;
  outline: none;
  color: var(--title-color);
  font-size: 12px;
  font-family: var(--font-rubik);
  text-transform: capitalize;
  width: 100%;
  height: 48px;
  padding: 10px 20px;
}
.banner3-section .banner3-content .from-inner input::-moz-placeholder {
  color: rgba(16, 12, 8, 0.4);
}
.banner3-section .banner3-content .from-inner input::placeholder {
  color: rgba(16, 12, 8, 0.4);
}
.banner3-section .banner3-content .from-inner button {
  background-color: var(--primary-color1);
  padding: 9px 16px;
  border-radius: 0 10px 10px 0;
}
.banner3-section .banner3-content .from-inner button svg {
  stroke: var(--white-color);
}
.banner3-section .banner3-content .vector1 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 30px 0 0 30px;
  z-index: -1;
}
@media (max-width: 991px) {
  .banner3-section .banner3-content .vector1 {
    opacity: 0.7;
  }
}
@media (max-width: 767px) {
  .banner3-section .banner3-content .vector1 {
    display: none;
  }
}
.banner3-section .banner3-content .vector2 {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0px 30px 30px 0px;
  z-index: -1;
}
@media (max-width: 991px) {
  .banner3-section .banner3-content .vector2 {
    opacity: 0.7;
  }
}
@media (max-width: 767px) {
  .banner3-section .banner3-content .vector2 {
    display: none;
  }
}

/*=====================================
  18.Home1 Footer section
========================================*/
.footer-section {
  background-image: url(../img/footer-bg.png), linear-gradient(180deg, #1d231f 0%, #1d231f 100%);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}
.footer-section .footer-top {
  padding-top: 18%;
  padding-bottom: 95px;
}
@media (max-width: 767px) {
  .footer-section .footer-top {
    padding-bottom: 70px;
  }
}
@media (max-width: 576px) {
  .footer-section .footer-top {
    padding-top: 35%;
  }
}
.footer-section .footer-top .footer-widget .footer-logo {
  margin-bottom: 20px;
}
.footer-section .footer-top .footer-widget h3 {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 30px;
  font-weight: 600;
  line-height: 1.6;
  text-transform: capitalize;
  width: 100%;
  margin-bottom: 25px;
}
.footer-section .footer-top .footer-widget h3 span {
  font-size: 25px;
  font-weight: 400;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .footer-section .footer-top .footer-widget h3 {
    font-size: 28px;
  }
}
.footer-section .footer-top .footer-widget .primary-btn1 {
  padding: 15px 30px;
}
.footer-section .footer-top .footer-widget .widget-title {
  display: flex;
  align-items: center;
  gap: 7px;
  margin-bottom: 20px;
}
.footer-section .footer-top .footer-widget .widget-title svg {
  fill: rgba(var(--primary-color2-opc), 0.8);
}
.footer-section .footer-top .footer-widget .widget-title h5 {
  color: rgba(255, 255, 255, 0.8);
  font-family: var(--font-rubik);
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.66px;
  margin-bottom: 0px;
}
.footer-section .footer-top .footer-widget .widget-title.two {
  margin-bottom: 25px;
}
.footer-section .footer-top .footer-widget .widget-title.two h5 {
  font-size: 22px;
  font-weight: 500;
  line-height: 1.4;
}
.footer-section .footer-top .footer-widget .widget-title.two h5 span {
  color: var(--primary-color1);
}
@media (min-width: 992px) and (max-width: 1199px) {
  .footer-section .footer-top .footer-widget .widget-title.two h5 {
    font-size: 20px;
  }
}
.footer-section .footer-top .footer-widget .widget-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.footer-section .footer-top .footer-widget .widget-list li {
  margin-bottom: 10px;
  transition: 0.5s;
}
.footer-section .footer-top .footer-widget .widget-list li:last-child {
  margin-bottom: 0;
}
.footer-section .footer-top .footer-widget .widget-list li a {
  color: rgba(255, 255, 255, 0.6);
  font-family: var(--font-jost);
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.32px;
  transition: 0.5s;
}
.footer-section .footer-top .footer-widget .widget-list li:hover a {
  color: var(--primary-color1);
}
.footer-section .footer-top .footer-widget .single-contact .widget-title {
  margin-bottom: 15px;
}
.footer-section .footer-top .footer-widget .single-contact a {
  color: rgba(255, 255, 255, 0.6);
  font-family: var(--font-jost);
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.48px;
  transition: 0.5s;
}
.footer-section .footer-top .footer-widget .single-contact a:hover {
  color: var(--primary-color2);
}
.footer-section .footer-top .footer-widget p {
  color: rgba(255, 255, 255, 0.5);
  font-family: var(--font-jost);
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 35px;
}
.footer-section .footer-top .footer-widget .app-store {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 40px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .footer-section .footer-top .footer-widget .app-store {
    flex-wrap: wrap;
  }
}
.footer-section .footer-top .footer-widget .form-inner {
  display: flex;
  align-items: center;
}
.footer-section .footer-top .footer-widget .form-inner input {
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  border-radius: 5px 0px 0px 5px;
  font-family: var(--font-rubik);
  font-weight: 400;
  font-size: 13px;
  color: var(--title-color);
  height: 49px;
  padding: 10px 18px;
}
.footer-section .footer-top .footer-widget .form-inner input::-moz-placeholder {
  color: rgba(16, 12, 8, 0.6);
}
.footer-section .footer-top .footer-widget .form-inner input::placeholder {
  color: rgba(16, 12, 8, 0.6);
}
.footer-section .footer-top .footer-widget .form-inner button {
  height: 49px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  background-color: var(--primary-color1);
  border-radius: 0px 5px 5px 0px;
  transition: 0.5s;
}
.footer-section .footer-top .footer-widget .form-inner button svg {
  fill: var(--white-color);
  transition: 0.5s;
}
.footer-section .footer-top .footer-widget .form-inner button:hover {
  background-color: var(--primary-color2);
}
.footer-section .footer-top .footer-widget .form-inner button:hover svg {
  fill: var(--title-color);
}
.footer-section .footer-top .footer-widget .payment-partner .icons ul {
  display: flex;
  align-items: center;
  gap: 20px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .footer-section .footer-top .footer-widget .payment-partner .icons ul {
    flex-wrap: wrap;
    gap: 20px;
  }
}
.footer-section .footer-top .footer-widget .payment-partner .icons ul li img {
  border-radius: 4px;
}
.footer-section .footer-bottom {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 20px 0;
}
.footer-section .footer-bottom p {
  color: rgba(255, 255, 255, 0.5);
  font-family: var(--font-rubik);
  font-size: 14px;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.42px;
  margin-bottom: 0;
}
.footer-section .footer-bottom p a {
  color: var(--white-color);
  font-weight: 600;
  text-decoration: underline;
  transition: 0.5s;
}
.footer-section .footer-bottom p a:hover {
  color: var(--primary-color1);
}
@media (max-width: 576px) {
  .footer-section .footer-bottom p {
    text-align: center;
  }
}
.footer-section .footer-bottom .social-list {
  display: flex;
  align-items: center;
  gap: 20px;
}
.footer-section .footer-bottom .social-list li {
  transition: 0.5s;
}
.footer-section .footer-bottom .social-list li a {
  color: rgba(255, 255, 255, 0.5);
  transition: 0.5s;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-section .footer-bottom .social-list li a i {
  font-size: 12px;
}
.footer-section .footer-bottom .social-list li:hover a {
  color: var(--white-color);
  background-color: var(--primary-color1);
  border: 1px solid var(--primary-color1);
}
.footer-section .footer-bottom .footer-right ul {
  display: flex;
  align-items: center;
  gap: 35px;
}
.footer-section .footer-bottom .footer-right ul li {
  position: relative;
}
.footer-section .footer-bottom .footer-right ul li a {
  color: rgba(255, 255, 255, 0.6);
  font-family: var(--font-jost);
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  transition: 0.5s;
}
.footer-section .footer-bottom .footer-right ul li a:hover {
  color: var(--primary-color1);
}
.footer-section .footer-bottom .footer-right ul li::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -19px;
  background: rgba(255, 255, 255, 0.6);
  width: 5px;
  height: 5px;
  border-radius: 50%;
}
.footer-section .footer-bottom .footer-right ul li:first-child::before {
  display: none;
}
.footer-section.style-2 .footer-top {
  padding: 120px 0;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .footer-section.style-2 .footer-top {
    padding: 100px 0;
  }
}
@media (max-width: 991px) {
  .footer-section.style-2 .footer-top {
    padding: 90px 0;
  }
}
@media (max-width: 767px) {
  .footer-section.style-2 .footer-top {
    padding: 70px 0;
  }
}
.footer-section.style-3 {
  background-image: url(../img/footer-bg2.png), linear-gradient(180deg, #1d231f 0%, #1d231f 100%);
}
.footer-section.style-3 .footer-top {
  padding-bottom: 105px;
}
.footer-section.style-4 {
  background-image: url(../img/footer-bg2.png), linear-gradient(180deg, #1d231f 0%, #1d231f 100%);
  margin: 0 50px;
  border-radius: 30px 30px 0 0;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .footer-section.style-4 {
    margin: 0 30px;
    padding: 0 40px;
  }
}
@media (max-width: 1399px) {
  .footer-section.style-4 {
    margin: 0 20px;
    padding: 0 30px;
  }
}
@media (max-width: 767px) {
  .footer-section.style-4 {
    margin: 0 10px;
    padding: 0 20px;
  }
}
@media (max-width: 576px) {
  .footer-section.style-4 {
    margin: 0;
    padding: 0;
  }
}
.footer-section.style-4 .footer-top {
  padding: 110px 0;
}
@media (max-width: 1399px) {
  .footer-section.style-4 .footer-top {
    padding: 90px 0;
  }
}
@media (max-width: 991px) {
  .footer-section.style-4 .footer-top {
    padding: 80px 0;
  }
}
@media (max-width: 767px) {
  .footer-section.style-4 .footer-top {
    padding: 60px 0;
  }
}

/*=====================================
  19.Home2 banner section
========================================*/
.home2-banner-area {
  position: relative;
  padding-bottom: 120px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home2-banner-area {
    padding-bottom: 180px;
  }
}
@media (max-width: 991px) {
  .home2-banner-area {
    padding-bottom: 215px;
  }
}
@media (max-width: 767px) {
  .home2-banner-area {
    padding-bottom: 160px;
  }
}
@media (max-width: 576px) {
  .home2-banner-area {
    padding-bottom: 370px;
  }
}
.home2-banner-area .home2-banner-wrapper {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  min-height: 900px;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home2-banner-area .home2-banner-wrapper {
    min-height: 835px;
  }
}
@media (max-width: 1399px) {
  .home2-banner-area .home2-banner-wrapper {
    min-height: 835px;
  }
}
@media (max-width: 991px) {
  .home2-banner-area .home2-banner-wrapper {
    min-height: 750px;
  }
}
.home2-banner-area .home2-banner-content-wrap {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  z-index: 1;
  width: 100%;
}
.home2-banner-area .home2-banner-content-wrap .home2-banner-content {
  text-align: center;
  padding: 240px 0 120px;
  max-width: 860px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home2-banner-area .home2-banner-content-wrap .home2-banner-content {
    padding: 220px 0 70px;
  }
}
@media (max-width: 1399px) {
  .home2-banner-area .home2-banner-content-wrap .home2-banner-content {
    padding: 220px 0 70px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home2-banner-area .home2-banner-content-wrap .home2-banner-content {
    padding: 180px 0 70px;
  }
}
@media (max-width: 991px) {
  .home2-banner-area .home2-banner-content-wrap .home2-banner-content {
    padding: 130px 0 50px;
  }
}
@media (max-width: 767px) {
  .home2-banner-area .home2-banner-content-wrap .home2-banner-content {
    padding: 120px 0 30px;
  }
}
.home2-banner-area .home2-banner-content-wrap .home2-banner-content .eg-tag {
  -webkit-mask-image: url(../img/home2/vector/destination-tag-bg.svg);
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: cover;
  background-color: var(--primary-color2);
  padding: 16px 45px;
  white-space: nowrap;
  display: inline-flex;
  margin-bottom: 15px;
}
.home2-banner-area .home2-banner-content-wrap .home2-banner-content .eg-tag span {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  color: #100c08;
  font-family: var(--font-satisfy);
  font-size: 23px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.69px;
}
.home2-banner-area .home2-banner-content-wrap .home2-banner-content h1 {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 80px;
  font-weight: 700;
  line-height: 1.1;
  letter-spacing: 2px;
  margin-bottom: 55px;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home2-banner-area .home2-banner-content-wrap .home2-banner-content h1 {
    font-size: 70px;
  }
}
@media (max-width: 1399px) {
  .home2-banner-area .home2-banner-content-wrap .home2-banner-content h1 {
    font-size: 70px;
  }
}
@media (max-width: 1199px) {
  .home2-banner-area .home2-banner-content-wrap .home2-banner-content h1 {
    font-size: 55px;
  }
}
@media (max-width: 991px) {
  .home2-banner-area .home2-banner-content-wrap .home2-banner-content h1 {
    font-size: 45px;
  }
}
@media (max-width: 767px) {
  .home2-banner-area .home2-banner-content-wrap .home2-banner-content h1 {
    margin-bottom: 30px;
  }
}
@media (max-width: 576px) {
  .home2-banner-area .home2-banner-content-wrap .home2-banner-content h1 {
    font-size: 32px;
    margin-bottom: 20px;
  }
}
.home2-banner-area .home2-banner-content-wrap .home2-banner-content h1 span {
  color: var(--primary-color1);
  display: inline-block;
  line-height: 1;
  position: relative;
  display: none;
}
.home2-banner-area .home2-banner-content-wrap .home2-banner-content h1 span::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: var(--primary-color1);
}
.home2-banner-area .home2-banner-content-wrap .home2-banner-content h1 span.text-in {
  display: inline-block;
  animation: flipInX 0.75s linear;
}
@keyframes flipInX {
  0% {
    transform: perspective(400px) rotateX(90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotateX(-20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotateX(10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotateX(-5deg);
  }
  to {
    transform: perspective(400px);
  }
}
.home2-banner-area .home2-banner-content-wrap .home2-banner-content .banner-content-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: start;
  gap: 40px;
}
@media (max-width: 576px) {
  .home2-banner-area .home2-banner-content-wrap .home2-banner-content .banner-content-bottom {
    flex-wrap: wrap;
    gap: 20px;
  }
}
.home2-banner-area .home2-banner-content-wrap .home2-banner-content .banner-content-bottom .hotline-area {
  display: flex;
  align-items: center;
  gap: 20px;
  background: rgba(21, 47, 0, 0.4);
  border-radius: 125px;
  border: 1px solid var(--primary-color1);
  padding: 8px 20px;
}
@media (max-width: 576px) {
  .home2-banner-area .home2-banner-content-wrap .home2-banner-content .banner-content-bottom .hotline-area {
    padding: 4px 15px;
  }
}
.home2-banner-area .home2-banner-content-wrap .home2-banner-content .banner-content-bottom .hotline-area .icon svg {
  fill: var(--white-color);
}
.home2-banner-area .home2-banner-content-wrap .home2-banner-content .banner-content-bottom .hotline-area .content {
  position: relative;
  line-height: 1;
}
.home2-banner-area .home2-banner-content-wrap .home2-banner-content .banner-content-bottom .hotline-area .content::after {
  content: "";
  height: 35px;
  width: 1px;
  background-color: rgba(var(--primary-color1-opc), 0.2);
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}
.home2-banner-area .home2-banner-content-wrap .home2-banner-content .banner-content-bottom .hotline-area .content span {
  font-family: var(--font-rubik);
  text-align: start;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: var(--primary-color1);
  margin-bottom: 5px;
  display: inline-block;
}
@media (max-width: 576px) {
  .home2-banner-area .home2-banner-content-wrap .home2-banner-content .banner-content-bottom .hotline-area .content span {
    font-size: 13px;
  }
}
.home2-banner-area .home2-banner-content-wrap .home2-banner-content .banner-content-bottom .hotline-area .content h6 {
  margin-bottom: 0;
  line-height: 1;
}
.home2-banner-area .home2-banner-content-wrap .home2-banner-content .banner-content-bottom .hotline-area .content h6 a {
  font-family: var(--font-rubik);
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: var(--white-color);
  transition: 0.5s;
}
.home2-banner-area .home2-banner-content-wrap .home2-banner-content .banner-content-bottom .hotline-area .content h6 a:hover {
  color: var(--primary-color1);
}
@media (max-width: 576px) {
  .home2-banner-area .home2-banner-content-wrap .home2-banner-content .banner-content-bottom .hotline-area .content h6 a {
    font-size: 14px;
  }
}
.home2-banner-area .home2-banner-content-wrap .home2-banner-content .banner-content-bottom .rating-area {
  display: flex;
  align-items: center;
  gap: 5px;
}
.home2-banner-area .home2-banner-content-wrap .home2-banner-content .banner-content-bottom .rating-area .content .text-logo {
  display: flex;
  margin-bottom: 10px;
}
.home2-banner-area .home2-banner-content-wrap .home2-banner-content .banner-content-bottom .rating-area .content .text-logo svg {
  fill: var(--white-color);
}
.home2-banner-area .home2-banner-content-wrap .home2-banner-content .banner-content-bottom .rating-area .content .rating {
  display: flex;
  align-items: center;
  gap: 10px;
  line-height: 1;
}
.home2-banner-area .home2-banner-content-wrap .home2-banner-content .banner-content-bottom .rating-area .content .rating ul {
  display: flex;
  align-items: center;
  gap: 5px;
}
.home2-banner-area .home2-banner-content-wrap .home2-banner-content .banner-content-bottom .rating-area .content .rating ul li i {
  color: #00AA6C;
}
.home2-banner-area .home2-banner-content-wrap .home2-banner-content .banner-content-bottom .rating-area .content .rating span {
  color: var(--white-color);
}
.home2-banner-area .slider-btn-grp .home1-banner-prev {
  position: absolute;
  top: 50%;
  left: 50px;
  transform: translateY(-50%);
  z-index: 99;
}
@media (max-width: 991px) {
  .home2-banner-area .slider-btn-grp .home1-banner-prev {
    left: 15px;
  }
}
@media (max-width: 767px) {
  .home2-banner-area .slider-btn-grp .home1-banner-prev {
    display: none;
  }
}
.home2-banner-area .slider-btn-grp .home1-banner-next {
  position: absolute;
  top: 50%;
  right: 50px;
  transform: translateY(-50%);
  z-index: 99;
}
@media (max-width: 991px) {
  .home2-banner-area .slider-btn-grp .home1-banner-next {
    right: 15px;
  }
}
@media (max-width: 767px) {
  .home2-banner-area .slider-btn-grp .home1-banner-next {
    display: none;
  }
}

/*=====================================
  20.Destination Card Slider Section
========================================*/
.destination-card2 {
  position: relative;
  display: block;
  transition: 0.5s;
}
.destination-card2 .destination-card-img {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 10px;
  transition: 0.5s;
}
.destination-card2 .destination-card-img img {
  border-radius: 10px;
  transition: 0.5s;
}
@media (max-width: 576px) {
  .destination-card2 .destination-card-img img {
    width: 100%;
  }
}
.destination-card2 .destination-card-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(16, 12, 8, 0) 0%, rgba(16, 12, 8, 0.8) 100%);
  border-radius: 10px;
  z-index: 1;
}
.destination-card2 .batch {
  position: absolute;
  top: 15px;
  right: 15px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.destination-card2 .batch span {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  text-transform: capitalize;
  display: inline-flex;
  background-color: var(--primary-color2);
  border-radius: 3px;
  padding: 5px 10px;
  transition: 0.5s;
}
.destination-card2 .destination-card2-content {
  position: absolute;
  bottom: 25px;
  left: 25px;
  line-height: 1;
  z-index: 2;
}
.destination-card2 .destination-card2-content span {
  color: var(--primary-color1);
  font-family: var(--font-rubik);
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.28px;
  text-transform: capitalize;
  display: inline-block;
  margin-bottom: 5px;
}
.destination-card2 .destination-card2-content h4 {
  margin-bottom: 0;
  line-height: 1.1;
}
.destination-card2 .destination-card2-content h4 a {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 28px;
  font-weight: 500;
  line-height: 1.1;
  text-transform: capitalize;
  transition: 0.5s;
}
@media (max-width: 991px) {
  .destination-card2 .destination-card2-content h4 a {
    font-size: 25px;
  }
}
.destination-card2 .destination-card2-content h4:hover a {
  color: var(--primary-color2);
}
.destination-card2:hover .destination-card-img img {
  transform: scale(1.05);
}
.destination-card2:hover .batch span {
  color: var(--white-color);
  background-color: var(--primary-color1);
}
.destination-card2.style-2 .destination-card-img img {
  border-radius: 5px;
}
.destination-card2.style-2 .destination-card-img::before {
  background: linear-gradient(180deg, rgba(16, 12, 8, 0) 0%, rgba(16, 12, 8, 0.63) 100%);
  border-radius: 5px;
}
.destination-card2.style-2 .destination-card2-content-wrap {
  position: absolute;
  text-align: center;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 15px 20px 25px 20px;
}
.destination-card2.style-2 .destination-card2-content-wrap .destination-card2-content {
  position: relative;
  bottom: unset;
  left: unset;
  text-align: center;
}
.destination-card2.style-2 .destination-card2-content-wrap .destination-card2-content span {
  font-size: 15px;
}
.destination-card2.style-2 .destination-card2-content-wrap .destination-card2-content h4 a {
  font-size: 30px;
}
@media (max-width: 1199px) {
  .destination-card2.style-2 .destination-card2-content-wrap .destination-card2-content h4 a {
    font-size: 28px;
  }
}
@media (max-width: 991px) {
  .destination-card2.style-2 .destination-card2-content-wrap .destination-card2-content h4 a {
    font-size: 25px;
  }
}
.destination-card2.style-2 .destination-card2-content-wrap .eg-batch {
  display: inline-flex;
  justify-content: center;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.5s ease-in-out;
}
.destination-card2.style-2 .destination-card2-content-wrap .eg-batch .location {
  border-radius: 38px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: rgba(16, 12, 8, 0.2);
  -webkit-backdrop-filter: blur(2.5px);
          backdrop-filter: blur(2.5px);
  padding: 18px 25px;
}
.destination-card2.style-2 .destination-card2-content-wrap .eg-batch .location .location-list {
  display: flex;
  align-items: center;
  gap: 16px;
}
.destination-card2.style-2 .destination-card2-content-wrap .eg-batch .location .location-list li {
  position: relative;
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.6px;
  text-transform: uppercase;
}
.destination-card2.style-2 .destination-card2-content-wrap .eg-batch .location .location-list li::before {
  content: "\f4fe";
  font-family: bootstrap-icons;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -17px;
  color: var(--white-color);
  font-size: 17px;
}
.destination-card2.style-2 .destination-card2-content-wrap .eg-batch .location .location-list li:first-child::before {
  display: none;
}
.destination-card2.style-2:hover .destination-card2-content-wrap .eg-batch {
  opacity: 1;
  transform: scaleY(1);
}

.slide-and-view-btn-grp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
@media (max-width: 576px) {
  .slide-and-view-btn-grp {
    flex-wrap: wrap;
  }
}
.slide-and-view-btn-grp .slider-btn-grp3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
}
@media (max-width: 576px) {
  .slide-and-view-btn-grp .slider-btn-grp3 {
    justify-content: center;
  }
}
.slide-and-view-btn-grp .slider-btn-grp3 .slider-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}
.slide-and-view-btn-grp .slider-btn-grp3 .slider-btn i {
  color: var(--primary-color1);
  line-height: 1;
  transition: 0.5s;
}
.slide-and-view-btn-grp .slider-btn-grp3 .slider-btn span {
  color: var(--primary-color1);
  font-family: var(--font-rubik);
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  text-decoration: underline;
  text-transform: capitalize;
  display: inline-block;
  transition: 0.5s;
}
.slide-and-view-btn-grp .slider-btn-grp3 .slider-btn:hover i {
  color: var(--primary-color2);
}
.slide-and-view-btn-grp .slider-btn-grp3 .slider-btn:hover span {
  color: var(--primary-color2);
}
.slide-and-view-btn-grp .slider-btn-grp3 .slider-btn.swiper-button-disabled {
  opacity: 0.4;
}
.slide-and-view-btn-grp .slider-btn-grp3.two {
  max-width: 162px;
  width: 100%;
}
@media (max-width: 576px) {
  .slide-and-view-btn-grp .slider-btn-grp3.two {
    justify-content: space-between;
  }
}
@media (max-width: 576px) {
  .slide-and-view-btn-grp.style-2 .slider-btn-grp3 .slider-btn {
    display: none;
  }
}
.slide-and-view-btn-grp.style-2 .slider-btn-grp3 .slider-btn i {
  color: var(--white-color);
}
.slide-and-view-btn-grp.style-2 .slider-btn-grp3 .slider-btn span {
  color: var(--white-color);
}
.slide-and-view-btn-grp.style-2 .slider-btn-grp3 .slider-btn:hover i {
  color: var(--primary-color2);
}
.slide-and-view-btn-grp.style-2 .slider-btn-grp3 .slider-btn:hover span {
  color: var(--primary-color2);
}
.slide-and-view-btn-grp.style-3 {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 35px;
  padding: 35px 70px 0;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .slide-and-view-btn-grp.style-3 {
    padding: 35px 40px 0;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .slide-and-view-btn-grp.style-3 {
    padding: 30px 35px 0;
  }
}
@media (max-width: 991px) {
  .slide-and-view-btn-grp.style-3 {
    padding: 30px 40px 0;
  }
}
@media (max-width: 767px) {
  .slide-and-view-btn-grp.style-3 {
    padding: 30px 25px 0;
  }
}
@media (max-width: 576px) {
  .slide-and-view-btn-grp.style-3 {
    padding: 20px 15px 0;
  }
}
.slide-and-view-btn-grp.style-3 .slider-btn-grp3 {
  justify-content: space-between;
}

.destination-card2-slider-section .destination-card2-slider .swiper-slide:nth-child(even) {
  margin-top: 20px;
}
@media (max-width: 576px) {
  .destination-card2-slider-section .destination-card2-slider .swiper-slide:nth-child(even) {
    margin-top: 0;
  }
}

/*=====================================
  21.Tour Pack Section
========================================*/
.package-card3 {
  border-radius: 5px;
  background-color: var(--white-color);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
  position: relative;
  overflow: hidden;
}
.package-card3 .package-card-img {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
  transition: all 0.5s ease-out;
}
.package-card3 .package-card-img img {
  border-radius: 5px 5px 0 0;
  transition: all 0.5s ease-out;
}
.package-card3 .package-card-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(1deg, rgba(21, 47, 0, 0.56) 1.03%, rgba(21, 47, 0, 0) 90.67%);
  border-radius: 5px 5px 0 0;
  z-index: 1;
}
.package-card3 .package-card-img::after {
  position: absolute;
  width: 200%;
  height: 0%;
  left: 50%;
  top: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  transform: translate(-50%, -50%) rotate(-45deg);
  content: "";
  z-index: 1;
}
.package-card3 .package-card-img .batch {
  position: absolute;
  top: 20px;
  left: -38px;
  transform: rotate(-50deg);
  z-index: 1;
}
.package-card3 .package-card-img .batch span {
  color: var(--title-color);
  text-align: center;
  font-family: var(--font-rubik);
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.3px;
  background-color: var(--primary-color2);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  padding: 6px 10px;
}
.package-card3 .package-card-img .batch.two span {
  color: var(--white-color);
  background-color: var(--primary-color1);
}
.package-card3 .package-card-img .package-card-img-bottom {
  border-radius: 0px 0px 10px 10px;
  background-color: rgba(16, 12, 8, 0.6);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  padding: 15px 25px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .package-card3 .package-card-img .package-card-img-bottom {
    padding: 15px 20px;
  }
}
.package-card3 .package-card-img .package-card-img-bottom ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.package-card3 .package-card-img .package-card-img-bottom ul li {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  text-transform: capitalize;
}
.package-card3 .package-card-img .package-card-img-bottom ul li svg {
  fill: var(--white-color);
}
.package-card3 .package-card-img .package-card-img-bottom ul li:last-child svg {
  fill: none;
  stroke: var(--white-color);
}
.package-card3 .location-area {
  position: relative;
  height: 18px;
  overflow: hidden;
}
.package-card3 .location-area .location-list {
  position: absolute;
  white-space: nowrap;
  text-overflow: ellipsis;
  transform: translateX(0);
  transition: 6s;
  display: flex;
  align-items: center;
  gap: 35px;
}
.package-card3 .location-area .location-list li {
  line-height: 1;
  position: relative;
  transition: 0.5s;
}
.package-card3 .location-area .location-list li a {
  color: #888;
  font-family: var(--font-rubik);
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  transition: 0.5s;
}
.package-card3 .location-area .location-list li::before {
  content: "\f138";
  font-family: bootstrap-icons;
  color: #888;
  position: absolute;
  left: -25px;
  top: 50%;
  transform: translateY(-50%);
}
.package-card3 .location-area .location-list li:first-child::before {
  display: none;
}
.package-card3 .location-area .location-list li:hover a {
  color: var(--primary-color1);
}
.package-card3 .location-area:hover .scrollTextAni {
  transform: translateX(calc(200px - 100%));
}
.package-card3 .package-card-content .rating-area {
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 1;
  margin-bottom: 15px;
}
.package-card3 .package-card-content .rating-area .rating {
  display: flex;
  align-items: center;
  gap: 4px;
}
.package-card3 .package-card-content .rating-area .rating li {
  line-height: 0;
}
.package-card3 .package-card-content .rating-area .rating li i {
  color: #FFC200;
  font-size: 12px;
}
.package-card3 .package-card-content .rating-area span {
  color: #ABABAB;
  font-family: var(--font-rubik);
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
}
.package-card3 .package-card-content .card-content-top {
  padding: 25px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .package-card3 .package-card-content .card-content-top {
    padding: 25px 15px;
  }
}
@media (max-width: 991px) {
  .package-card3 .package-card-content .card-content-top {
    padding: 25px 20px;
  }
}
@media (max-width: 576px) {
  .package-card3 .package-card-content .card-content-top {
    padding: 25px 15px;
  }
}
.package-card3 .package-card-content .card-content-top h5 {
  margin-bottom: 15px;
  transition: 0.5s;
}
.package-card3 .package-card-content .card-content-top h5 a {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 22px;
  font-weight: 500;
  line-height: 1.4;
  text-transform: capitalize;
  transition: 0.5s;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .package-card3 .package-card-content .card-content-top h5 a {
    font-size: 20px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .package-card3 .package-card-content .card-content-top h5 a {
    font-size: 17px;
  }
}
@media (max-width: 991px) {
  .package-card3 .package-card-content .card-content-top h5 a {
    font-size: 20px;
  }
}
@media (max-width: 576px) {
  .package-card3 .package-card-content .card-content-top h5 a {
    font-size: 18px;
  }
}
.package-card3 .package-card-content .card-content-top h5:hover a {
  color: var(--primary-color1);
}
@media (max-width: 576px) {
  .package-card3 .package-card-content .card-content-top h5 {
    margin-bottom: 10px;
  }
}
.package-card3 .package-card-content .card-content-top .feature-list {
  display: flex;
  align-items: center;
  gap: 30px;
  line-height: 1;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .package-card3 .package-card-content .card-content-top .feature-list {
    gap: 10px;
  }
}
@media (max-width: 991px) {
  .package-card3 .package-card-content .card-content-top .feature-list {
    gap: 15px;
  }
}
.package-card3 .package-card-content .card-content-top .feature-list li {
  color: rgba(16, 12, 8, 0.5);
  font-family: var(--font-rubik);
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  text-transform: capitalize;
}
.package-card3 .package-card-content .card-content-top .feature-list li svg {
  fill: rgba(16, 12, 8, 0.5);
}
.package-card3 .package-card-content .card-content-top .feature-list li svg.with-stroke {
  fill: none;
  stroke: rgba(16, 12, 8, 0.5);
}
.package-card3 .package-card-content .card-content-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  padding: 20px 25px;
  border-top: 1px solid rgba(34, 34, 34, 0.2);
}
@media (min-width: 992px) and (max-width: 1199px) {
  .package-card3 .package-card-content .card-content-bottom {
    padding: 20px 15px;
  }
}
@media (max-width: 991px) {
  .package-card3 .package-card-content .card-content-bottom {
    padding: 20px 20px;
  }
}
@media (max-width: 576px) {
  .package-card3 .package-card-content .card-content-bottom {
    padding: 20px 15px;
  }
}
.package-card3 .package-card-content .card-content-bottom .price-area {
  line-height: 1;
}
.package-card3 .package-card-content .card-content-bottom .price-area .title {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.26px;
  text-transform: capitalize;
  display: inline-block;
  margin-bottom: 5px;
}
.package-card3 .package-card-content .card-content-bottom .price-area h6 {
  color: var(--primary-color1);
  font-family: var(--font-rubik);
  font-size: 22px;
  font-weight: 800;
  line-height: 1;
  text-transform: capitalize;
  margin-bottom: 0;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .package-card3 .package-card-content .card-content-bottom .price-area h6 {
    font-size: 20px;
  }
}
@media (max-width: 576px) {
  .package-card3 .package-card-content .card-content-bottom .price-area h6 {
    font-size: 22px;
  }
}
.package-card3 .package-card-content .card-content-bottom .price-area h6 sub {
  font-size: 17px;
  font-weight: 600;
  bottom: 0;
}
.package-card3 .package-card-content .card-content-bottom .price-area h6 del {
  color: rgba(16, 12, 8, 0.5);
  font-size: 16px;
  font-weight: 500;
}
.package-card3 .package-card-content .card-content-bottom .price-area span {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 11px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.22px;
  text-transform: capitalize;
}
.package-card3:hover .package-card-img img {
  transform: scale(1.1);
}
.package-card3:hover .package-card-img::after {
  height: 250%;
  transition: all 600ms linear;
  background-color: transparent;
}
.package-card3.style-2 {
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .package-card3.style-2 {
    flex-direction: column;
  }
}
.package-card3.style-2 .package-card-img {
  position: relative;
}
@media (max-width: 767px) {
  .package-card3.style-2 .package-card-img {
    width: 100%;
  }
}
.package-card3.style-2 .package-card-img img {
  min-width: 325px;
  max-width: 325px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 5px 0 0 5px;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .package-card3.style-2 .package-card-img img {
    min-width: 278px;
    max-width: 278px;
  }
}
@media (max-width: 767px) {
  .package-card3.style-2 .package-card-img img {
    min-width: unset;
    max-width: unset;
    width: 100%;
  }
}
.package-card3.style-2 .package-card-img .eg-tag {
  -webkit-mask-image: url(../img/home2/vector/package-card3-img-tag.svg);
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: cover;
  background-color: var(--primary-color2);
  padding: 6px 24px;
  white-space: nowrap;
  display: inline-flex;
  position: absolute;
  top: 12px;
  left: 7px;
  z-index: 1;
}
.package-card3.style-2 .package-card-img .eg-tag span {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  color: #100c08;
  font-family: var(--font-rubik);
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.69px;
}
@media (max-width: 1199px) {
  .package-card3.style-2 .package-card-content {
    width: 100%;
  }
}
.package-card3.style-2 .package-card-content .card-content-top {
  padding: 25px 30px;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .package-card3.style-2 .package-card-content .card-content-top {
    padding: 20px 20px;
  }
}
@media (max-width: 991px) {
  .package-card3.style-2 .package-card-content .card-content-top {
    padding: 25px 20px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .package-card3.style-2 .package-card-content .card-content-top .rating-area {
    margin-bottom: 10px;
  }
}
@media (max-width: 576px) {
  .package-card3.style-2 .package-card-content .card-content-top .rating-area {
    margin-bottom: 10px;
  }
}
@media (max-width: 576px) {
  .package-card3.style-2 .package-card-content .card-content-top h5 {
    margin-bottom: 15px;
  }
}
@media (max-width: 1199px) {
  .package-card3.style-2 .package-card-content .card-content-top h5 a {
    font-size: 26px;
  }
}
@media (max-width: 991px) {
  .package-card3.style-2 .package-card-content .card-content-top h5 a {
    font-size: 22px;
  }
}
@media (max-width: 576px) {
  .package-card3.style-2 .package-card-content .card-content-top h5 a {
    font-size: 18px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .package-card3.style-2 .package-card-content .card-content-top .feature-list {
    gap: 20px;
  }
}
.package-card3.style-2 .package-card-content .card-content-bottom {
  padding: 20px 30px;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .package-card3.style-2 .package-card-content .card-content-bottom {
    padding: 20px;
  }
}
@media (max-width: 991px) {
  .package-card3.style-2 .package-card-content .card-content-bottom {
    padding: 20px 20px;
  }
}
.package-card3.style-3 {
  border: 1px solid rgba(16, 12, 8, 0.08);
  box-shadow: unset;
}
.package-card3.style-3 .package-card-img {
  margin: 5px 5px 0 5px;
  border-radius: 0px 0px 10px 10px;
}
.package-card3.style-3 .package-card-img img {
  border-radius: 0px 0px 10px 10px;
}
@media (max-width: 767px) {
  .package-card3.style-3 .package-card-img img {
    width: 100%;
  }
}
.package-card3.style-3 .package-card-img::before {
  background: rgba(16, 12, 8, 0.3);
  border-radius: 0px 0px 10px 10px;
  z-index: 1;
}
.package-card3.style-3 .package-card-img .eg-batch {
  position: absolute;
  top: 10px;
  left: -5px;
  z-index: 1;
}
.package-card3.style-3 .package-card-img .eg-batch span {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.45px;
  -webkit-mask-image: url(../img/home3/vector/eg-batch-orange-vector.svg);
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: cover;
  background-color: var(--primary-color2);
  padding: 9px 46px 9px 26px;
  white-space: nowrap;
}
.package-card3.style-3 .package-card-img .eg-batch.green span {
  background-color: var(--primary-color1);
  color: var(--white-color);
}
.package-card3.style-3 .package-card-content .card-content-top {
  padding: 30px 20px;
}
@media (max-width: 1199px) {
  .package-card3.style-3 .package-card-content .card-content-top {
    padding: 25px 15px;
  }
}
.package-card3.style-3 .package-card-content .card-content-bottom {
  padding: 20px;
  border-top: 1px solid rgba(16, 12, 8, 0.06);
}
.package-card3.style-3 .package-card-content .card-content-bottom .price-area {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
  flex-wrap: wrap;
}
.package-card3.style-3 .package-card-content .card-content-bottom .price-area span {
  margin-bottom: 0;
  color: var(--text-color);
}
.package-card3.style-3 .package-card-content .card-content-bottom .price-area h6 {
  font-size: 20px;
  font-weight: 700;
  display: flex;
  align-items: baseline;
  gap: 3px;
}
.package-card3.style-3 .package-card-content .card-content-bottom .price-area h6 del {
  color: rgba(16, 12, 8, 0.5);
  font-size: 16px;
  font-weight: 500;
}
.package-card3.style-3 .package-card-content .card-content-bottom .explore-btn {
  color: var(--primary-color1);
  font-family: var(--font-rubik);
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  text-transform: capitalize;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 7px;
  transition: 0.5s;
}
.package-card3.style-3 .package-card-content .card-content-bottom .explore-btn svg {
  fill: var(--primary-color1);
  transition: 0.5s;
}
.package-card3.style-3 .package-card-content .card-content-bottom .explore-btn:hover {
  color: var(--primary-color2);
}
.package-card3.style-3 .package-card-content .card-content-bottom .explore-btn:hover svg {
  fill: var(--primary-color2);
}
.package-card3.style-4 {
  border: 1px solid rgba(16, 12, 8, 0.08);
  box-shadow: unset;
  padding: 5px;
  padding: 0;
  transition: 0.5s;
}
.package-card3.style-4 .package-card-img {
  margin: 15px 15px 0 15px;
}
.package-card3.style-4 .package-card-img img {
  border-radius: 5px;
}
@media (max-width: 767px) {
  .package-card3.style-4 .package-card-img img {
    width: 100%;
  }
}
.package-card3.style-4 .package-card-img::before {
  display: none;
}
.package-card3.style-4 .package-card-img .batch {
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(0);
  line-height: 1;
  z-index: 1;
}
.package-card3.style-4 .package-card-img .batch span {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.3px;
  background-color: var(--primary-color1);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: unset;
  padding: 9px 14px;
  text-transform: uppercase;
  border-radius: 5px 0 0 0;
}
.package-card3.style-4 .package-card-img .batch.yellow span {
  background-color: var(--primary-color2);
  color: var(--title-color);
}
.package-card3.style-4 .package-card-content .card-content-top {
  padding: 25px;
}
@media (max-width: 1199px) {
  .package-card3.style-4 .package-card-content .card-content-top {
    padding: 25px 15px;
  }
}
.package-card3.style-4 .package-card-content .card-content-top .destination-and-date-area {
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}
.package-card3.style-4 .package-card-content .card-content-top .destination-and-date-area .destination {
  display: flex;
  align-items: center;
  gap: 7px;
}
.package-card3.style-4 .package-card-content .card-content-top .destination-and-date-area .destination svg {
  fill: var(--primary-color1);
  min-width: 16px;
  max-width: 16px;
}
.package-card3.style-4 .package-card-content .card-content-top .destination-and-date-area .destination .destination-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}
.package-card3.style-4 .package-card-content .card-content-top .destination-and-date-area .destination .destination-list li {
  position: relative;
  line-height: 1;
  padding-left: 14px;
}
.package-card3.style-4 .package-card-content .card-content-top .destination-and-date-area .destination .destination-list li a {
  color: var(--primary-color1);
  font-family: var(--font-rubik);
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.6px;
  text-transform: uppercase;
}
.package-card3.style-4 .package-card-content .card-content-top .destination-and-date-area .destination .destination-list li::before {
  content: "\f4fe";
  font-family: bootstrap-icons;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -5px;
  color: var(--primary-color1);
  font-size: 17px;
}
.package-card3.style-4 .package-card-content .card-content-top .destination-and-date-area .destination .destination-list li:first-child {
  padding-left: 0;
}
.package-card3.style-4 .package-card-content .card-content-top .destination-and-date-area .destination .destination-list li:first-child::before {
  display: none;
}
.package-card3.style-4 .package-card-content .card-content-top .destination-and-date-area .date {
  display: flex;
  align-items: center;
  gap: 7px;
  white-space: nowrap;
}
.package-card3.style-4 .package-card-content .card-content-top .destination-and-date-area .date svg {
  fill: var(--primary-color1);
}
.package-card3.style-4 .package-card-content .card-content-top .destination-and-date-area .date span {
  color: var(--primary-color1);
  font-family: var(--font-rubik);
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  text-transform: capitalize;
}
.package-card3.style-4 .package-card-content .card-content-bottom {
  padding: 15px 25px;
  border-top: 1px solid rgba(16, 12, 8, 0.06);
}
.package-card3.style-4 .package-card-content .card-content-bottom .price-area .title {
  font-size: 14px;
}
.package-card3.style-4 .package-card-content .card-content-bottom .price-area h6 {
  font-size: 20px;
  font-weight: 800;
}
.package-card3.style-4 .package-card-content .card-content-bottom .explore-btn {
  color: var(--primary-color1);
  font-family: var(--font-rubik);
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  text-transform: capitalize;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 7px;
  transition: 0.5s;
}
.package-card3.style-4 .package-card-content .card-content-bottom .explore-btn svg {
  fill: var(--primary-color1);
  transition: 0.5s;
}
.package-card3.style-4 .package-card-content .card-content-bottom .explore-btn:hover {
  color: var(--primary-color2);
}
.package-card3.style-4 .package-card-content .card-content-bottom .explore-btn:hover svg {
  fill: var(--primary-color2);
}
.package-card3.style-4:hover {
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  border-color: transparent;
}
.package-card3.style-5 {
  border: 1px solid transparent;
  box-shadow: 0px 0px 25px 0px rgba(16, 12, 8, 0.08);
  border-radius: 10px;
  transition: 0.5s;
}
.package-card3.style-5 .package-card-img {
  margin: 15px 15px 0 15px;
  position: relative;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .package-card3.style-5 .package-card-img {
    margin: 10px 10px 0 10px;
  }
}
.package-card3.style-5 .package-card-img > a {
  position: relative;
  display: block;
  overflow: hidden;
}
.package-card3.style-5 .package-card-img > a img {
  border-radius: 5px;
}
@media (max-width: 767px) {
  .package-card3.style-5 .package-card-img > a img {
    width: 100%;
  }
}
.package-card3.style-5 .package-card-img > a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(16, 12, 8, 0.3);
  border-radius: 5px;
  z-index: 1;
}
.package-card3.style-5 .package-card-img > a::after {
  position: absolute;
  width: 200%;
  height: 0%;
  left: 50%;
  top: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  transform: translate(-50%, -50%) rotate(-45deg);
  content: "";
  z-index: 1;
}
.package-card3.style-5 .package-card-img::before {
  display: none;
}
.package-card3.style-5 .package-card-img::after {
  display: none;
}
.package-card3.style-5 .package-card-img .package-card-img-bottom {
  border-radius: 0px 0px 5px 5px;
  background: rgba(16, 12, 8, 0.35);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  padding: 15px 12px;
  z-index: 2;
}
.package-card3.style-5 .package-card-img .package-card-img-bottom .location-area {
  height: 12px;
}
.package-card3.style-5 .package-card-img .package-card-img-bottom .location-area .location-list li a {
  color: var(--white-color);
}
.package-card3.style-5 .package-card-img .package-card-img-bottom .location-area .location-list li::before {
  color: var(--white-color);
}
.package-card3.style-5 .package-card-img .package-card-img-bottom .location-area .location-list li:hover a {
  color: var(--primary-color1);
}
.package-card3.style-5 .package-card-img .location {
  background-color: var(--white-color);
  border: 1px solid rgba(99, 171, 69, 0.5);
  border-radius: 5px 0px;
  padding: 6px 10px;
  display: flex;
  align-items: center;
  gap: 7px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.package-card3.style-5 .package-card-img .location svg {
  fill: var(--primary-color1);
}
.package-card3.style-5 .package-card-img .location .locations-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}
.package-card3.style-5 .package-card-img .location .locations-list li {
  position: relative;
  line-height: 1;
  padding-left: 14px;
}
.package-card3.style-5 .package-card-img .location .locations-list li a {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.6px;
  text-transform: uppercase;
}
.package-card3.style-5 .package-card-img .location .locations-list li::before {
  content: "\f4fe";
  font-family: bootstrap-icons;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -5px;
  color: var(--title-color);
  font-size: 17px;
}
.package-card3.style-5 .package-card-img .location .locations-list li:first-child {
  padding-left: 0;
}
.package-card3.style-5 .package-card-img .location .locations-list li:first-child::before {
  display: none;
}
.package-card3.style-5 .package-card-content .card-content-top h5 {
  margin-bottom: 0;
}
.package-card3.style-5 .package-card-content .card-content-bottom {
  border-top: 1px solid rgba(16, 12, 8, 0.1);
  position: relative;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .package-card3.style-5 .package-card-content .card-content-bottom {
    padding: 20px 10px;
  }
}
.package-card3.style-5 .package-card-content .card-content-bottom .price-area .title {
  font-size: 14px;
}
.package-card3.style-5 .package-card-content .card-content-bottom .price-area h6 {
  font-size: 20px;
  font-weight: 800;
}
.package-card3.style-5 .package-card-content .card-content-bottom .date {
  display: flex;
  align-items: center;
  gap: 5px;
  transition: 0.5s;
}
.package-card3.style-5 .package-card-content .card-content-bottom .date svg {
  fill: rgba(16, 12, 8, 0.6);
}
.package-card3.style-5 .package-card-content .card-content-bottom .date span {
  color: rgba(16, 12, 8, 0.6);
  font-family: var(--font-rubik);
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  text-transform: capitalize;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .package-card3.style-5 .package-card-content .card-content-bottom .date span {
    font-size: 13px;
  }
}
.package-card3.style-5 .package-card-content .card-content-bottom .book-btn {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%) scaleY(0);
  opacity: 0;
  transform-origin: bottom;
  transition: 0.5s;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .package-card3.style-5 .package-card-content .card-content-bottom .book-btn {
    right: 10px;
  }
}
.package-card3.style-5:hover {
  border: 1px solid rgba(99, 171, 69, 0.3);
}
.package-card3.style-5:hover .package-card-img a::after {
  height: 250%;
  transition: all 600ms linear;
  background-color: transparent;
}
.package-card3.style-5:hover .package-card-content .card-content-bottom .date {
  opacity: 0;
}
.package-card3.style-5:hover .package-card-content .card-content-bottom .book-btn {
  transform: translateY(-50%) scaleY(1);
  opacity: 1;
}

.tour-pack-section {
  background-image: url(../img/home2/tour-pack-section-bg.png), linear-gradient(180deg, #1d231f 0%, #1d231f 100%);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 100px 0;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .tour-pack-section {
    padding: 90px 0;
  }
}
@media (max-width: 991px) {
  .tour-pack-section {
    padding: 80px 0;
  }
}

/*=====================================
  22.Home2 About Section
========================================*/
.home2-about-section {
  position: relative;
}
.home2-about-section::before {
  content: "";
  background-image: url(../img/home2/vector/home2-about-bg-vector.svg);
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.home2-about-section .about-content .facility-card {
  background-color: rgba(var(--primary-color1-opc), 0.1);
  border-radius: 10px;
  padding: 13px 22px;
  display: flex;
  align-items: center;
  gap: 15px;
  transition: 0.5s;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home2-about-section .about-content .facility-card {
    padding: 13px 10px;
  }
}
.home2-about-section .about-content .facility-card .icon {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: var(--white-color);
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.05));
  display: flex;
  align-items: center;
  justify-content: center;
}
.home2-about-section .about-content .facility-card .content h6 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 17px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.34px;
  text-transform: capitalize;
  margin-bottom: 0;
  transition: 0.5s;
}
.home2-about-section .about-content .facility-card:hover {
  background-color: var(--primary-color1);
}
.home2-about-section .about-content .facility-card:hover .content h6 {
  color: var(--white-color);
}
.home2-about-section .about-content .facility-card.two {
  background-color: rgba(var(--primary-color2-opc), 0.1);
}
.home2-about-section .about-content .facility-card.two:hover {
  background-color: var(--primary-color2);
}
.home2-about-section .about-content .facility-card.two:hover .content h6 {
  color: var(--title-color);
}
.home2-about-section .about-content .content-bottom-area {
  display: flex;
  align-items: center;
  gap: 45px;
}
@media (max-width: 576px) {
  .home2-about-section .about-content .content-bottom-area {
    flex-wrap: wrap;
    gap: 20px;
  }
}
.home2-about-section .about-content .content-bottom-area .primary-btn1 {
  padding: 16px 25px;
}
.home2-about-section .about-content .content-bottom-area .video-area {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.home2-about-section .about-content .content-bottom-area .video-area .icon {
  position: relative;
}
.home2-about-section .about-content .content-bottom-area .video-area .icon .video-circle {
  stroke: var(--title-color);
  fill: none;
  transition: 0.5s;
}
.home2-about-section .about-content .content-bottom-area .video-area .icon .video-circle .top-half {
  stroke-dasharray: 320;
  stroke-dashoffset: 320;
  transition: stroke-dashoffset 0.8s cubic-bezier(0.33, 1, 0.68, 1);
}
.home2-about-section .about-content .content-bottom-area .video-area .icon i {
  color: var(--title-color);
  font-size: 30px;
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.home2-about-section .about-content .content-bottom-area .video-area h6 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.48px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.home2-about-section .about-content .content-bottom-area .video-area:hover .icon .video-circle .top-half {
  stroke-dashoffset: 0;
}
.home2-about-section .about-img-wrap {
  position: relative;
}
.home2-about-section .about-img-wrap .about-img {
  -webkit-mask-image: url(../img/home2/home2-about-img-bg-vector.png);
  -webkit-mask-size: cover;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: cover;
  position: relative;
}
.home2-about-section .about-img-wrap .about-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(16, 12, 8, 0.3);
  z-index: 1;
}
@media (max-width: 991px) {
  .home2-about-section .about-img-wrap {
    display: none;
  }
}
.home2-about-section .about-img-wrap .experience {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: var(--primary-color1);
  padding: 15px 12px;
  max-width: 165px;
  width: 100%;
  border-radius: 10px;
}
.home2-about-section .about-img-wrap .experience h3 {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 35px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 0;
}
.home2-about-section .about-img-wrap .experience p {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0;
}
.home2-about-section .about-img-wrap .vector {
  position: absolute;
  top: -60px;
  right: 0;
  z-index: -1;
}

.tripadvisor-review {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  padding-top: 30px;
}
@media (max-width: 991px) {
  .tripadvisor-review {
    padding-top: 20px;
  }
}
.tripadvisor-review > strong {
  font-family: var(--font-rubik);
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--title-color);
}
.tripadvisor-review p {
  font-family: var(--font-jost);
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: var(--text-color);
  margin-bottom: 0;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}
.tripadvisor-review p strong {
  font-weight: 600;
}
.tripadvisor-review p a {
  color: var(--text-color);
  text-decoration: underline;
}

.single-activity {
  display: flex;
  align-items: center;
  gap: 20px;
}
.single-activity .icon svg {
  fill: var(--primary-color1);
}
.single-activity .content .number {
  display: flex;
  align-items: center;
  font-family: var(--font-rubik);
  font-weight: 700;
  line-height: 1;
  color: var(--title-color);
  margin-bottom: 4px;
}
.single-activity .content .number h5 {
  font-size: 27px;
  color: var(--title-color);
  margin-bottom: 0;
  line-height: 1;
}
.single-activity .content .number span {
  font-size: 27px;
  display: inline-block;
  line-height: 1;
}
.single-activity .content p {
  margin-bottom: 0;
  color: var(--text-color);
  line-height: 22px;
  font-size: 16px;
  font-weight: 400;
}
.single-activity.two {
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.15);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  padding: 20px;
  transition: 0.5s;
}
@media (min-width: 768px) and (max-width: 991px) {
  .single-activity.two {
    padding: 15px 10px;
  }
}
.single-activity.two .icon {
  margin-bottom: 20px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .single-activity.two .icon {
    margin-bottom: 15px;
  }
}
.single-activity.two .content .number {
  display: flex;
  align-items: center;
  font-family: var(--font-rubik);
  font-weight: 700;
  line-height: 1;
  color: var(--white-color);
  margin-bottom: 4px;
}
.single-activity.two .content .number h5 {
  font-size: 35px;
  color: var(--white-color);
  margin-bottom: 0;
  line-height: 1;
}
@media (min-width: 768px) and (max-width: 991px) {
  .single-activity.two .content .number h5 {
    font-size: 28px;
  }
}
.single-activity.two .content .number span {
  font-size: 35px;
  display: inline-block;
  line-height: 1;
}
@media (min-width: 768px) and (max-width: 991px) {
  .single-activity.two .content .number span {
    font-size: 28px;
  }
}
.single-activity.two .content p {
  color: rgba(255, 255, 255, 0.6);
}
@media (min-width: 768px) and (max-width: 991px) {
  .single-activity.two .content p {
    font-size: 14px;
  }
}
.single-activity.two:hover {
  background: rgba(99, 171, 69, 0.2);
}

.activities-counter {
  padding: 40px 0;
  border-bottom: 1px solid rgba(16, 12, 8, 0.1);
  border-top: 1px solid rgba(16, 12, 8, 0.1);
}
@media (max-width: 767px) {
  .activities-counter {
    padding: 25px 0;
  }
}
.activities-counter .single-activity {
  display: flex;
  align-items: center;
  gap: 20px;
}
.activities-counter .single-activity .icon svg {
  fill: var(--primary-color1);
}
.activities-counter .single-activity .content .number {
  display: flex;
  align-items: center;
  font-family: var(--font-rubik);
  font-weight: 700;
  line-height: 1;
  color: var(--title-color);
  margin-bottom: 4px;
}
.activities-counter .single-activity .content .number h5 {
  font-size: 27px;
  margin-bottom: 0;
  line-height: 1;
}
.activities-counter .single-activity .content .number span {
  font-size: 27px;
  display: inline-block;
  line-height: 1;
}
.activities-counter .single-activity .content p {
  margin-bottom: 0;
  color: var(--text-color);
  line-height: 22px;
  font-size: 16px;
  font-weight: 400;
}
.activities-counter .divider {
  position: relative;
}
.activities-counter .divider::after {
  content: "";
  height: 41px;
  width: 1px;
  background-color: rgba(19, 20, 26, 0.16);
  position: absolute;
  right: 0;
  top: 50%;
  left: unset;
  transform: translateY(-50%);
}
@media (max-width: 991px) {
  .activities-counter .divider::after {
    display: none;
    visibility: hidden;
  }
}
.activities-counter.two {
  padding: 30px 0;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .activities-counter.two {
    padding: 20px 0;
  }
}
.activities-counter.two .single-activity {
  gap: 10px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .activities-counter.two .single-activity {
    flex-direction: column;
  }
}
.activities-counter.two .single-activity .content .number {
  font-weight: 600;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .activities-counter.two .single-activity .content .number {
    justify-content: center;
  }
}
.activities-counter.two .single-activity .content .number h5 {
  font-size: 22px;
}
.activities-counter.two .single-activity .content .number span {
  font-size: 22px;
}
.activities-counter.two .divider {
  position: relative;
}
@media (max-width: 991px) {
  .activities-counter.two .divider::after {
    display: block;
    visibility: inherit;
  }
}
@media (max-width: 767px) {
  .activities-counter.two .divider::after {
    display: none;
    visibility: hidden;
  }
}

/*=====================================
  23.Home2 Activities Section
========================================*/
.slider-and-tab-section .activities-slider-group {
  width: 100%;
  height: 100%;
}
.slider-and-tab-section .activities-slider-group li {
  width: 100%;
  height: 100%;
  display: none;
  visibility: hidden;
}
.slider-and-tab-section .activities-slider-group li.active {
  display: block;
  visibility: visible;
}
.slider-and-tab-section .slider-area {
  position: relative;
  height: 100%;
}
.slider-and-tab-section .slider-area .activities-img-slider {
  height: 100%;
}
.slider-and-tab-section .slider-area .slide-img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100%;
}
.slider-and-tab-section .slider-area .swiper-pagination5 {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: unset !important;
  display: flex;
  flex-direction: column;
  z-index: 9;
  bottom: unset;
  gap: 15px;
}
.slider-and-tab-section .slider-area .swiper-pagination5 .swiper-pagination-bullet {
  height: 16px;
  width: 16px;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.5);
  opacity: 1;
  position: relative;
}
.slider-and-tab-section .slider-area .swiper-pagination5 .swiper-pagination-bullet::after {
  content: "";
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.slider-and-tab-section .slider-area .swiper-pagination5 .swiper-pagination-bullet.swiper-pagination-bullet-active {
  border-color: var(--white-color);
}
.slider-and-tab-section .slider-area .swiper-pagination5 .swiper-pagination-bullet.swiper-pagination-bullet-active::after {
  background-color: var(--white-color);
}
.slider-and-tab-section .tab-area {
  background-image: linear-gradient(180deg, #fffcea 0%, #fffcea 100%), url(../img/home2/activities-content-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 100px 110px;
}
@media (max-width: 1699px) {
  .slider-and-tab-section .tab-area {
    padding: 100px 80px;
  }
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .slider-and-tab-section .tab-area {
    padding: 70px 40px;
  }
}
@media (max-width: 1399px) {
  .slider-and-tab-section .tab-area {
    padding: 70px 20px;
  }
}
.slider-and-tab-section .tab-area .tab-content-area .tab-sidebar {
  padding-right: 35px;
}
@media (max-width: 1699px) {
  .slider-and-tab-section .tab-area .tab-content-area .tab-sidebar {
    padding-right: 20px;
  }
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .slider-and-tab-section .tab-area .tab-content-area .tab-sidebar {
    padding-right: 0;
  }
}
@media (max-width: 1399px) {
  .slider-and-tab-section .tab-area .tab-content-area .tab-sidebar {
    padding-right: 0;
  }
}
.slider-and-tab-section .tab-area .tab-content-area .tab-sidebar .nav-pills {
  display: block;
}
.slider-and-tab-section .tab-area .tab-content-area .tab-sidebar .nav-pills .nav-item {
  margin-bottom: 25px;
}
.slider-and-tab-section .tab-area .tab-content-area .tab-sidebar .nav-pills .nav-item:last-child {
  margin-bottom: 0;
}
.slider-and-tab-section .tab-area .tab-content-area .tab-sidebar .nav-pills .nav-item .nav-link {
  background-color: var(--white-color);
  border: 1px solid rgba(238, 238, 238, 0.6);
  padding: 4px 5px;
  display: flex;
  align-items: center;
  border-radius: 200px;
  cursor: pointer;
}
.slider-and-tab-section .tab-area .tab-content-area .tab-sidebar .nav-pills .nav-item .nav-link .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #DDEED6;
}
.slider-and-tab-section .tab-area .tab-content-area .tab-sidebar .nav-pills .nav-item .nav-link .icon svg {
  fill: var(--primary-color1);
}
.slider-and-tab-section .tab-area .tab-content-area .tab-sidebar .nav-pills .nav-item .nav-link h6 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.54px;
  text-transform: capitalize;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 0;
}
@media (max-width: 1399px) {
  .slider-and-tab-section .tab-area .tab-content-area .tab-sidebar .nav-pills .nav-item .nav-link h6 {
    font-size: 16px;
  }
}
.slider-and-tab-section .tab-area .tab-content-area .tab-sidebar .nav-pills .nav-item .nav-link.active {
  background-color: var(--primary-color2);
}
.slider-and-tab-section .tab-area .tab-content-area .tab-sidebar .nav-pills .nav-item .nav-link.active .icon {
  background: rgba(16, 12, 8, 0.2);
}
.slider-and-tab-section .tab-area .tab-content-area .tab-sidebar .nav-pills .nav-item .nav-link.active .icon svg {
  fill: var(--white-color);
}
.slider-and-tab-section .tab-area .tab-content-area .tab-sidebar .nav-pills .nav-item .nav-link.active h6 {
  color: var(--white-color);
}
.slider-and-tab-section .tab-area .tab-content-area .tab-content-wrap h2 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 30px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0.6px;
  margin-bottom: 20px;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .slider-and-tab-section .tab-area .tab-content-area .tab-content-wrap h2 {
    font-size: 28px;
  }
}
@media (max-width: 1399px) {
  .slider-and-tab-section .tab-area .tab-content-area .tab-content-wrap h2 {
    font-size: 26px;
    margin-bottom: 15px;
  }
}
@media (max-width: 576px) {
  .slider-and-tab-section .tab-area .tab-content-area .tab-content-wrap h2 {
    font-size: 25px;
    margin-bottom: 15px;
  }
}
.slider-and-tab-section .tab-area .tab-content-area .tab-content-wrap p {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.9;
  letter-spacing: 0.48px;
  margin-bottom: 30px;
}
@media (max-width: 1399px) {
  .slider-and-tab-section .tab-area .tab-content-area .tab-content-wrap p {
    font-size: 15px;
    margin-bottom: 20px;
  }
}
@media (max-width: 576px) {
  .slider-and-tab-section .tab-area .tab-content-area .tab-content-wrap p {
    font-size: 14px;
  }
}
.slider-and-tab-section .tab-area .tab-content-area .tab-content-wrap ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 45px;
}
@media (max-width: 1399px) {
  .slider-and-tab-section .tab-area .tab-content-area .tab-content-wrap ul {
    margin-bottom: 35px;
  }
}
.slider-and-tab-section .tab-area .tab-content-area .tab-content-wrap ul li {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--title-color);
  font-family: var(--font-jost);
  font-size: 17px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 0.51px;
}
.slider-and-tab-section .tab-area .tab-content-area .tab-content-wrap ul li svg {
  fill: var(--primary-color1);
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .slider-and-tab-section .tab-area .tab-content-area .tab-content-wrap ul li {
    font-size: 18px;
  }
}
.slider-and-tab-section .tab-area .tab-content-area .tab-content-wrap .content-bottom-area {
  display: flex;
  align-items: center;
  gap: 35px;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .slider-and-tab-section .tab-area .tab-content-area .tab-content-wrap .content-bottom-area {
    gap: 20px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .slider-and-tab-section .tab-area .tab-content-area .tab-content-wrap .content-bottom-area {
    flex-wrap: wrap;
    gap: 15px;
  }
}
@media (max-width: 576px) {
  .slider-and-tab-section .tab-area .tab-content-area .tab-content-wrap .content-bottom-area {
    flex-wrap: wrap;
    gap: 20px;
  }
}
.slider-and-tab-section .tab-area .tab-content-area .tab-content-wrap .content-bottom-area .primary-btn1 {
  padding: 16px 25px;
}
.slider-and-tab-section .tab-area .tab-content-area .tab-content-wrap .content-bottom-area .video-area {
  display: flex;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
  cursor: pointer;
}
.slider-and-tab-section .tab-area .tab-content-area .tab-content-wrap .content-bottom-area .video-area .icon {
  position: relative;
}
.slider-and-tab-section .tab-area .tab-content-area .tab-content-wrap .content-bottom-area .video-area .icon .video-circle {
  stroke: var(--title-color);
  fill: none;
  transition: 0.5s;
}
.slider-and-tab-section .tab-area .tab-content-area .tab-content-wrap .content-bottom-area .video-area .icon .video-circle .top-half {
  stroke-dasharray: 320;
  stroke-dashoffset: 320;
  transition: stroke-dashoffset 0.8s cubic-bezier(0.33, 1, 0.68, 1);
}
.slider-and-tab-section .tab-area .tab-content-area .tab-content-wrap .content-bottom-area .video-area .icon i {
  color: var(--title-color);
  font-size: 30px;
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.slider-and-tab-section .tab-area .tab-content-area .tab-content-wrap .content-bottom-area .video-area h6 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.48px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.slider-and-tab-section .tab-area .tab-content-area .tab-content-wrap .content-bottom-area .video-area:hover .icon .video-circle .top-half {
  stroke-dashoffset: 0;
}

/*=====================================
  24.Home2 banner4 Section
========================================*/
.banner4-card {
  position: relative;
  height: 100%;
}
.banner4-card img {
  border-radius: 10px;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 1199px) {
  .banner4-card img {
    width: 100%;
  }
}
.banner4-card .banner4-content-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(88deg, #4C7CED 1.75%, rgba(76, 124, 237, 0.97) 24.14%, rgba(76, 124, 237, 0.95) 41.61%, rgba(76, 124, 237, 0) 98.26%);
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
}
.banner4-card .banner4-content-wrapper .banner4-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .banner4-card .banner4-content-wrapper .banner4-content {
    padding: 50px 40px;
  }
}
@media (max-width: 576px) {
  .banner4-card .banner4-content-wrapper .banner4-content {
    padding: 25px 15px;
  }
}
.banner4-card .banner4-content-wrapper .banner4-content span {
  color: var(--white-color);
  font-family: var(--font-satisfy);
  font-size: 20px;
  font-weight: 400;
  line-height: 1;
  display: inline-block;
  margin-bottom: 15px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .banner4-card .banner4-content-wrapper .banner4-content span {
    font-size: 28px;
  }
}
@media (max-width: 576px) {
  .banner4-card .banner4-content-wrapper .banner4-content span {
    font-size: 16px;
    margin-bottom: 10px;
  }
}
.banner4-card .banner4-content-wrapper .banner4-content h3 {
  color: var(--primary-color2);
  font-family: var(--font-rubik);
  font-size: 35px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 10px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .banner4-card .banner4-content-wrapper .banner4-content h3 {
    font-size: 50px;
  }
}
@media (max-width: 576px) {
  .banner4-card .banner4-content-wrapper .banner4-content h3 {
    font-size: 28px;
    margin-bottom: 5px;
  }
}
.banner4-card .banner4-content-wrapper .banner4-content .text {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 18px;
  font-weight: 600;
  line-height: 1.6;
  display: block;
  margin-bottom: 20px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .banner4-card .banner4-content-wrapper .banner4-content .text {
    font-size: 22px;
  }
}
@media (max-width: 576px) {
  .banner4-card .banner4-content-wrapper .banner4-content .text {
    font-size: 16px;
    margin-bottom: 10px;
  }
}
.banner4-card .banner4-content-wrapper .banner4-content .primary-btn1 {
  padding: 11px 18px;
  font-size: 14px;
  font-weight: 500;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .banner4-card .banner4-content-wrapper .banner4-content .primary-btn1 {
    padding: 16px 34px;
    font-size: 16px;
    font-weight: 600;
  }
}
.banner4-card.two .banner4-content-wrapper {
  background: linear-gradient(88deg, var(--primary-color2-opc) 1.58%, rgba(251, 176, 59, 0.97) 19.9%, rgba(251, 176, 59, 0.95) 34.2%, rgba(251, 176, 59, 0) 80.56%);
}
.banner4-card.two .banner4-content-wrapper .banner4-content h3 {
  color: var(--title-color);
}
.banner4-card.three .banner4-content-wrapper {
  background: linear-gradient(86deg, var(--primary-color1-opc) 2.77%, rgba(99, 171, 69, 0.97) 17.83%, rgba(99, 171, 69, 0.95) 29.59%, rgba(99, 171, 69, 0) 67.7%);
}
.banner4-card.three .banner4-content-wrapper .banner4-content .primary-btn1 {
  background-color: var(--primary-color2);
  color: var(--title-color);
}
@media (max-width: 576px) {
  .banner4-card.four img {
    min-height: 180px;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
.banner4-card.four .banner4-content-wrapper {
  background: linear-gradient(87deg, #BC6C25 2.1%, rgba(188, 108, 37, 0.97) 16.21%, rgba(188, 108, 37, 0.95) 27.22%, rgba(188, 108, 37, 0) 62.92%);
}
.banner4-card.four .banner4-content-wrapper .banner4-content {
  padding: 50px 20px;
}
@media (max-width: 576px) {
  .banner4-card.four .banner4-content-wrapper .banner4-content {
    padding: 25px 15px;
    align-items: start;
  }
}
.banner4-card.four .banner4-content-wrapper .banner4-content span {
  font-size: 20px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .banner4-card.four .banner4-content-wrapper .banner4-content span {
    margin-bottom: 10px;
  }
}
@media (max-width: 576px) {
  .banner4-card.four .banner4-content-wrapper .banner4-content span {
    font-size: 16px;
    margin-bottom: 10px;
  }
}
.banner4-card.four .banner4-content-wrapper .banner4-content h3 {
  color: var(--title-color);
  font-size: 35px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .banner4-card.four .banner4-content-wrapper .banner4-content h3 {
    margin-bottom: 5px;
  }
}
@media (max-width: 576px) {
  .banner4-card.four .banner4-content-wrapper .banner4-content h3 {
    font-size: 28px;
    margin-bottom: 5px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .banner4-card.four .banner4-content-wrapper .banner4-content .text {
    font-size: 18px;
    margin-bottom: 15px;
  }
}
.banner4-card.four .banner4-content-wrapper .banner4-content .primary-btn1 {
  padding: 11px 18px;
  font-size: 14px;
  font-weight: 500;
}
@media (max-width: 576px) {
  .banner4-card.four .banner4-content-wrapper .banner4-content .primary-btn1 {
    padding: 9px 10px;
    font-size: 13px;
  }
}

/*=====================================
  25.Home2 Testimonial Section
========================================*/
.home2-testimonial-section {
  background-image: url(../img/home2/home2-testimonial-bg.png), linear-gradient(180deg, #1d231f 0%, #1d231f 100%);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 160px 0 160px 14%;
  position: relative;
  z-index: 1;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home2-testimonial-section {
    padding: 90px 0 90px 8%;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .home2-testimonial-section {
    padding: 90px 0 90px 4%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home2-testimonial-section {
    padding: 80px 0 80px 2%;
  }
}
@media (max-width: 991px) {
  .home2-testimonial-section {
    padding: 80px 50px 80px 50px;
  }
}
@media (max-width: 767px) {
  .home2-testimonial-section {
    padding: 80px 30px 80px 30px;
  }
}
@media (max-width: 576px) {
  .home2-testimonial-section {
    padding: 70px 10px 70px 10px;
  }
}
.home2-testimonial-section .testimonial-content-wrapper .section-title2 h2 {
  color: var(--white-color);
  line-height: 1.2;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .home2-testimonial-section .testimonial-content-wrapper .section-title2 h2 {
    font-size: 40px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home2-testimonial-section .testimonial-content-wrapper .section-title2 h2 {
    font-size: 32px;
  }
}
@media (max-width: 576px) {
  .home2-testimonial-section .testimonial-content-wrapper .section-title2 h2 {
    font-size: 26px;
  }
}
.home2-testimonial-section .testimonial-content-wrapper .section-title2 p {
  color: var(--white-color);
  font-size: 16px;
  font-weight: 300;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home2-testimonial-section .testimonial-content-wrapper .section-title2 p {
    padding-top: 25px;
  }
}
.home2-testimonial-section .testimonial-content-wrapper .review-wrap h6 {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 25px;
}
.home2-testimonial-section .testimonial-content-wrapper .review-wrap .rating-area {
  display: flex;
  align-items: center;
  gap: 30px;
}
@media (max-width: 576px) {
  .home2-testimonial-section .testimonial-content-wrapper .review-wrap .rating-area {
    flex-wrap: wrap;
    gap: 20px;
  }
}
.home2-testimonial-section .testimonial-content-wrapper .review-wrap .rating-area .single-rating a {
  background-color: #243123;
  padding: 14px 23px;
  border-radius: 10px;
  display: block;
  transition: 0.5s;
}
.home2-testimonial-section .testimonial-content-wrapper .review-wrap .rating-area .single-rating a .icon {
  margin-bottom: 5px;
  line-height: 1;
}
.home2-testimonial-section .testimonial-content-wrapper .review-wrap .rating-area .single-rating a .rating {
  display: flex;
  align-items: center;
  gap: 7px;
  line-height: 1;
}
.home2-testimonial-section .testimonial-content-wrapper .review-wrap .rating-area .single-rating a .rating span {
  font-size: 14px;
  font-weight: 400;
  font-family: var(--font-jost);
  color: var(--white-color);
}
.home2-testimonial-section .testimonial-content-wrapper .review-wrap .rating-area .single-rating a:hover {
  background-color: #333122;
}
.home2-testimonial-section .testimonial-content-wrapper .review-wrap .rating-area .single-rating:last-child a {
  background-color: #333122;
}
.home2-testimonial-section .testimonial-content-wrapper .review-wrap .rating-area .single-rating:last-child a:hover {
  background-color: #243123;
}
.home2-testimonial-section::after {
  content: url(../img/home2/testi-section-vector.png);
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}
@media (max-width: 991px) {
  .home2-testimonial-section::after {
    display: none;
  }
}

/*=====================================
  26.Home2 Teams Section
========================================*/
.teams-card {
  position: relative;
  border: 1px solid rgba(16, 12, 8, 0.1);
  border-radius: 10px;
  transition: 0.5s ease-in-out;
  overflow: hidden;
}
.teams-card > img {
  border-radius: 10px;
  width: 100%;
}
@media (max-width: 767px) {
  .teams-card > img {
    max-height: 520px;
  }
}
.teams-card .teams-img {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.5s ease-in-out;
}
@media (max-width: 576px) {
  .teams-card .teams-img {
    top: 60px;
  }
}
.teams-card .teams-img img {
  min-width: 200px;
  height: 200px;
  width: 100%;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
  transition: 0.5s;
  transition: 0.5s ease-in-out;
}
@media (max-width: 767px) {
  .teams-card .teams-img img {
    max-height: 520px;
  }
}
@media (max-width: 576px) {
  .teams-card .teams-img img {
    min-width: 250px;
    height: 250px;
  }
}
.teams-card .teams-content {
  position: absolute;
  bottom: 0;
  background-color: var(--white-color);
  text-align: center;
  padding: 25px 20px;
  border-radius: 10px;
  width: 100%;
  margin: 0 auto;
  line-height: 1;
  z-index: 1;
  transition: 0.5s ease-in-out;
}
@media (min-width: 768px) and (max-width: 991px) {
  .teams-card .teams-content {
    max-width: 338px;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .teams-card .teams-content {
    max-width: 514px;
  }
}
@media (max-width: 576px) {
  .teams-card .teams-content {
    max-width: unset;
  }
}
.teams-card .teams-content h4 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 25px;
  font-weight: 600;
  line-height: 1.1;
  margin-bottom: 7px;
  transition: 0.5s;
}
@media (max-width: 767px) {
  .teams-card .teams-content h4 {
    font-size: 22px;
  }
}
.teams-card .teams-content span {
  color: rgba(16, 12, 8, 0.5);
  font-family: var(--font-jost);
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
  display: inline-block;
  transition: 0.5s;
}
.teams-card .social-list {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgba(16, 12, 8, 0.2);
  padding: 30px 12px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 45px;
  border-radius: 10px 0 0 10px;
  opacity: 0;
  transform: scaleX(0);
  transform-origin: left;
  transition: 0.5s ease-in-out;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .teams-card .social-list {
    gap: 30px;
  }
}
@media (max-width: 991px) {
  .teams-card .social-list {
    padding: 60px 12px 40px;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .teams-card .social-list {
    padding: 20px 12px 40px;
    gap: 30px;
  }
}
.teams-card .social-list li {
  position: relative;
  line-height: 1;
  transition: 0.5s;
}
.teams-card .social-list li::before {
  content: "";
  position: absolute;
  top: -22px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(255, 255, 255, 0.2);
  width: 3px;
  height: 3px;
  border-radius: 50%;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .teams-card .social-list li::before {
    top: -15px;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .teams-card .social-list li::before {
    top: -15px;
  }
}
.teams-card .social-list li:first-child::before {
  display: none;
}
.teams-card .social-list li a i {
  color: var(--white-color);
  font-size: 22px;
  transition: 0.5s;
}
.teams-card .social-list li a svg {
  fill: var(--white-color);
}
.teams-card .social-list li:hover a i {
  color: var(--primary-color2);
}
.teams-card .social-list li:hover a svg {
  fill: var(--primary-color2);
}
.teams-card:hover .teams-img {
  top: 0;
  width: 100%;
}
.teams-card:hover .teams-img img {
  min-width: unset;
  height: unset;
  border-radius: 10px;
}
.teams-card:hover .teams-content {
  background-color: var(--primary-color1);
  bottom: 0;
}
.teams-card:hover .teams-content h4 {
  color: var(--white-color);
}
.teams-card:hover .teams-content span {
  color: rgba(255, 255, 255, 0.7);
}
.teams-card:hover .social-list {
  opacity: 1;
  transform: scaleX(1);
}

.teams-section .teams-card-slider {
  padding: 10px;
  margin: -10px;
}
@media (max-width: 767px) {
  .teams-section .slide-and-view-btn-grp {
    display: none;
  }
}

/*=====================================
  27.Home2 Video Section
========================================*/
.video-section {
  position: relative;
  max-height: 700px;
}
@media (max-width: 1199px) {
  .video-section {
    max-height: 600px;
  }
}
@media (max-width: 576px) {
  .video-section {
    max-height: 450px;
  }
}
.video-section video {
  width: 100%;
  height: 700px;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 1199px) {
  .video-section video {
    height: 600px;
  }
}
@media (max-width: 576px) {
  .video-section video {
    height: 450px;
  }
}
.video-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(16, 12, 8, 0.6);
}

.banner5-section {
  position: relative;
  margin-top: -140px;
}
.banner5-section .slider-btn-grp2 {
  display: block;
}
@media (max-width: 991px) {
  .banner5-section .slider-btn-grp2 {
    display: none;
  }
}
.banner5-section .slider-btn-grp2 .slider-btn {
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  border: 1px solid var(--white-color);
}
.banner5-section .slider-btn-grp2 .slider-btn svg {
  fill: var(--white-color);
}
.banner5-section .slider-btn-grp2 .slider-btn:hover {
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.banner5-section .slider-btn-grp2 .slider-btn:hover svg {
  fill: rgba(255, 255, 255, 0.5);
}
.banner5-section .slider-btn-grp2 .banner5-slider-prev {
  position: absolute;
  top: 75px;
  left: 100px;
  z-index: 1;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .banner5-section .slider-btn-grp2 .banner5-slider-prev {
    left: 50px;
  }
}
@media (max-width: 1199px) {
  .banner5-section .slider-btn-grp2 .banner5-slider-prev {
    left: 20px;
  }
}
.banner5-section .slider-btn-grp2 .banner5-slider-next {
  position: absolute;
  top: 75px;
  right: 100px;
  z-index: 1;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .banner5-section .slider-btn-grp2 .banner5-slider-next {
    right: 50px;
  }
}
@media (max-width: 1199px) {
  .banner5-section .slider-btn-grp2 .banner5-slider-next {
    right: 20px;
  }
}

.banner5-card {
  background-image: url(../img/home2/banner5-card-bg.png), linear-gradient(180deg, white 0%, white 100%);
  background-size: cover;
  background-repeat: no-repeat;
  max-width: 820px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  padding: 60px 50px;
  border-radius: 30px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .banner5-card {
    max-width: 700px;
  }
}
@media (max-width: 991px) {
  .banner5-card {
    padding: 50px 30px;
  }
}
@media (max-width: 767px) {
  .banner5-card {
    flex-direction: column;
    padding: 35px 25px;
    border-radius: 20px;
  }
}
@media (max-width: 576px) {
  .banner5-card {
    padding: 20px 25px 35px;
  }
}
.banner5-card .banner5-content {
  position: relative;
}
@media (max-width: 767px) {
  .banner5-card .banner5-content {
    text-align: center;
    margin-bottom: 10px;
  }
}
.banner5-card .banner5-content::before {
  content: "";
  position: absolute;
  top: 50%;
  right: -80px;
  transform: translateY(-50%);
  width: 1px;
  height: 100%;
  background: rgba(16, 12, 8, 0.1);
  border-radius: 5px;
}
@media (max-width: 991px) {
  .banner5-card .banner5-content::before {
    right: -50px;
  }
}
@media (max-width: 767px) {
  .banner5-card .banner5-content::before {
    display: none;
  }
}
.banner5-card .banner5-content span {
  color: var(--primary-color2);
  font-family: var(--font-satisfy);
  font-size: 20px;
  font-weight: 400;
  line-height: 1;
  display: inline-block;
  margin-bottom: 15px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .banner5-card .banner5-content span {
    font-size: 28px;
  }
}
@media (max-width: 576px) {
  .banner5-card .banner5-content span {
    font-size: 16px;
    margin-bottom: 10px;
  }
}
.banner5-card .banner5-content h3 {
  color: var(--primary-color1);
  font-family: var(--font-rubik);
  font-size: 60px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 10px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .banner5-card .banner5-content h3 {
    font-size: 50px;
  }
}
@media (max-width: 991px) {
  .banner5-card .banner5-content h3 {
    font-size: 48px;
  }
}
@media (max-width: 576px) {
  .banner5-card .banner5-content h3 {
    font-size: 30px;
    margin-bottom: 5px;
  }
}
.banner5-card .banner5-content a {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 25px;
  font-weight: 600;
  line-height: 1.6;
  display: block;
  margin-bottom: 20px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .banner5-card .banner5-content a {
    font-size: 22px;
  }
}
@media (max-width: 991px) {
  .banner5-card .banner5-content a {
    font-size: 20px;
  }
}
@media (max-width: 576px) {
  .banner5-card .banner5-content a {
    font-size: 18px;
    margin-bottom: 10px;
  }
}
.banner5-card .banner5-timer {
  text-align: center;
}
.banner5-card .banner5-timer h6 {
  color: var(--title-color);
  font-size: 18px;
  font-family: var(--font-rubik);
  font-weight: 500;
  line-height: 1;
  text-decoration-line: underline;
  margin-bottom: 20px;
}
@media (max-width: 576px) {
  .banner5-card .banner5-timer h6 {
    margin-bottom: 15px;
  }
}
.banner5-card .banner5-timer .countdown-timer ul {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .banner5-card .banner5-timer .countdown-timer ul {
    gap: 15px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .banner5-card .banner5-timer .countdown-timer ul {
    gap: 12px;
  }
}
@media (max-width: 576px) {
  .banner5-card .banner5-timer .countdown-timer ul {
    gap: 12px;
  }
}
.banner5-card .banner5-timer .countdown-timer ul li {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 30px;
  font-weight: 600;
  line-height: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .banner5-card .banner5-timer .countdown-timer ul li {
    font-size: 23px;
  }
}
@media (max-width: 576px) {
  .banner5-card .banner5-timer .countdown-timer ul li {
    font-size: 22px;
  }
}
.banner5-card .banner5-timer .countdown-timer ul li span {
  color: rgba(34, 34, 34, 0.7);
  font-family: var(--font-jost);
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
}
.banner5-card .banner5-timer .countdown-timer ul li span:last-child {
  display: none;
  visibility: hidden;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .banner5-card .banner5-timer .countdown-timer ul li span {
    font-size: 13px;
  }
}
.banner5-card .banner5-timer .countdown-timer ul svg {
  fill: rgba(33, 33, 33, 0.15);
}
.banner5-card .primary-btn2 {
  position: absolute;
  bottom: -18px;
  left: 50%;
  transform: translateX(-50%);
}

/*=====================================
  28.Home2 Newsletter Section
========================================*/
.banner6-section {
  background-image: url(../img/home2/home2-newsletter-bg.png), linear-gradient(180deg, #202f59 0%, #202f59 100%);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0 8.5%;
  position: relative;
  z-index: 1;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .banner6-section {
    padding: 0 5%;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .banner6-section {
    padding: 0 2%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .banner6-section {
    padding: 0 20px;
  }
}
@media (max-width: 991px) {
  .banner6-section {
    padding: 50px 20px;
  }
}
.banner6-section .single-contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}
@media (max-width: 991px) {
  .banner6-section .single-contact {
    padding: 0;
  }
}
.banner6-section .single-contact .icon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: var(--primary-color2);
  box-shadow: 0px 0px 20px var(--primary-color2);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
}
.banner6-section .single-contact .icon svg {
  fill: var(--white-color);
}
@media (min-width: 992px) and (max-width: 1199px) {
  .banner6-section .single-contact .icon {
    margin-bottom: 20px;
  }
}
.banner6-section .single-contact .content {
  text-align: center;
}
.banner6-section .single-contact .content span {
  color: var(--primary-color2);
  font-family: var(--font-rubik);
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  display: block;
  margin-bottom: 15px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .banner6-section .single-contact .content span {
    font-size: 18px;
    margin-bottom: 10px;
  }
}
.banner6-section .single-contact .content a {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 25px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.75px;
  transition: 0.5s;
}
@media (max-width: 1199px) {
  .banner6-section .single-contact .content a {
    font-size: 22px;
  }
}
.banner6-section .single-contact .content a:hover {
  color: var(--primary-color2);
}
.banner6-section .single-contact.green .icon {
  background-color: var(--primary-color1);
  box-shadow: 0px 0px 20px var(--primary-color1);
}
.banner6-section .single-contact.green .content span {
  color: var(--primary-color1);
}
.banner6-section .single-contact.green a:hover {
  color: var(--primary-color1);
}
.banner6-section .banner6-content {
  padding: 75px 70px;
  text-align: center;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}
@media (min-width: 992px) and (max-width: 1199px) {
  .banner6-section .banner6-content {
    padding: 75px 30px;
  }
}
@media (max-width: 991px) {
  .banner6-section .banner6-content {
    border: unset;
    padding: 0;
  }
}
.banner6-section .banner6-content h2 {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 40px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 5px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .banner6-section .banner6-content h2 {
    font-size: 36px;
  }
}
@media (max-width: 576px) {
  .banner6-section .banner6-content h2 {
    font-size: 30px;
  }
}
.banner6-section .banner6-content p {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.16px;
  margin-bottom: 20px;
}
@media (max-width: 576px) {
  .banner6-section .banner6-content p {
    font-size: 15px;
  }
}
.banner6-section .banner6-content .from-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  border: 1px solid var(--primary-color1);
  border-radius: 10px;
}
.banner6-section .banner6-content .from-inner input {
  background: transparent;
  border: none;
  outline: none;
  color: var(--white-color);
  font-size: 12px;
  font-family: var(--font-rubik);
  width: 100%;
  height: 48px;
  padding: 10px 20px;
}
.banner6-section .banner6-content .from-inner input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.banner6-section .banner6-content .from-inner input::placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.banner6-section .banner6-content .from-inner button {
  background-color: var(--primary-color1);
  padding: 9px 16px;
  border-radius: 0 10px 10px 0;
}
.banner6-section .banner6-content .from-inner button svg {
  stroke: var(--white-color);
}
.banner6-section .vector1 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.banner6-section .vector2 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

/*=====================================
  29.Home3 banner section
========================================*/
.home3-banner-area {
  position: relative;
  overflow: hidden;
}
.home3-banner-area .swiper-slide-active .eg-tag {
  animation: zoomIn 2s;
}
.home3-banner-area .swiper-slide-active h1, .home3-banner-area .swiper-slide-active h2 {
  animation: fadeInDown 1.7s;
}
.home3-banner-area .swiper-slide-active p {
  animation: fadeInUp 1.7s;
}
.home3-banner-area .swiper-slide-active .banner-content-bottom {
  animation: fadeInUp 1.7s;
}
.home3-banner-area .home3-banner-wrapper {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}
.home3-banner-area .home3-banner-wrapper .home3-banner-content-wrap {
  width: 100%;
}
.home3-banner-area .home3-banner-wrapper .home3-banner-content-wrap .home3-banner-content {
  text-align: center;
  padding: 170px 0 230px;
  max-width: 970px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home3-banner-area .home3-banner-wrapper .home3-banner-content-wrap .home3-banner-content {
    padding: 160px 0 220px;
  }
}
@media (max-width: 1399px) {
  .home3-banner-area .home3-banner-wrapper .home3-banner-content-wrap .home3-banner-content {
    padding: 150px 0 200px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home3-banner-area .home3-banner-wrapper .home3-banner-content-wrap .home3-banner-content {
    padding: 120px 0 180px;
  }
}
@media (max-width: 991px) {
  .home3-banner-area .home3-banner-wrapper .home3-banner-content-wrap .home3-banner-content {
    padding: 90px 0 150px;
  }
}
@media (max-width: 767px) {
  .home3-banner-area .home3-banner-wrapper .home3-banner-content-wrap .home3-banner-content {
    padding: 80px 0 140px;
  }
}
.home3-banner-area .home3-banner-wrapper .home3-banner-content-wrap .home3-banner-content .eg-tag {
  -webkit-mask-image: url(../img/home3/vector/destination-tag-bg.svg);
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: cover;
  background-color: var(--primary-color1);
  padding: 16px 45px;
  white-space: nowrap;
  display: inline-flex;
  margin-bottom: 15px;
}
.home3-banner-area .home3-banner-wrapper .home3-banner-content-wrap .home3-banner-content .eg-tag span {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  color: var(--white-color);
  font-family: var(--font-satisfy);
  font-size: 23px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.69px;
}
.home3-banner-area .home3-banner-wrapper .home3-banner-content-wrap .home3-banner-content h1, .home3-banner-area .home3-banner-wrapper .home3-banner-content-wrap .home3-banner-content h2 {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 80px;
  font-weight: 700;
  line-height: 1.1;
  letter-spacing: 2px;
  margin-bottom: 55px;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home3-banner-area .home3-banner-wrapper .home3-banner-content-wrap .home3-banner-content h1, .home3-banner-area .home3-banner-wrapper .home3-banner-content-wrap .home3-banner-content h2 {
    font-size: 70px;
  }
}
@media (max-width: 1399px) {
  .home3-banner-area .home3-banner-wrapper .home3-banner-content-wrap .home3-banner-content h1, .home3-banner-area .home3-banner-wrapper .home3-banner-content-wrap .home3-banner-content h2 {
    font-size: 60px;
  }
}
@media (max-width: 1199px) {
  .home3-banner-area .home3-banner-wrapper .home3-banner-content-wrap .home3-banner-content h1, .home3-banner-area .home3-banner-wrapper .home3-banner-content-wrap .home3-banner-content h2 {
    font-size: 55px;
  }
}
@media (max-width: 991px) {
  .home3-banner-area .home3-banner-wrapper .home3-banner-content-wrap .home3-banner-content h1, .home3-banner-area .home3-banner-wrapper .home3-banner-content-wrap .home3-banner-content h2 {
    font-size: 45px;
  }
}
@media (max-width: 767px) {
  .home3-banner-area .home3-banner-wrapper .home3-banner-content-wrap .home3-banner-content h1, .home3-banner-area .home3-banner-wrapper .home3-banner-content-wrap .home3-banner-content h2 {
    margin-bottom: 30px;
    font-size: 40px;
  }
}
@media (max-width: 576px) {
  .home3-banner-area .home3-banner-wrapper .home3-banner-content-wrap .home3-banner-content h1, .home3-banner-area .home3-banner-wrapper .home3-banner-content-wrap .home3-banner-content h2 {
    font-size: 35px;
    margin-bottom: 20px;
  }
}
.home3-banner-area .home3-banner-wrapper .home3-banner-content-wrap .home3-banner-content h1 span, .home3-banner-area .home3-banner-wrapper .home3-banner-content-wrap .home3-banner-content h2 span {
  color: var(--primary-color1);
  line-height: 1;
}
.home3-banner-area .home3-banner-wrapper .home3-banner-content-wrap .home3-banner-content .banner-content-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: start;
  gap: 40px;
}
@media (max-width: 576px) {
  .home3-banner-area .home3-banner-wrapper .home3-banner-content-wrap .home3-banner-content .banner-content-bottom {
    flex-wrap: wrap;
    gap: 20px;
  }
}
@media (max-width: 767px) {
  .home3-banner-area .home3-banner-wrapper .home3-banner-content-wrap .home3-banner-content .banner-content-bottom .primary-btn4 span {
    padding: 15px 30px;
    font-size: 16px;
  }
}
.home3-banner-area .home3-banner-wrapper .home3-banner-content-wrap .home3-banner-content .banner-content-bottom .video-area {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.home3-banner-area .home3-banner-wrapper .home3-banner-content-wrap .home3-banner-content .banner-content-bottom .video-area .icon {
  position: relative;
}
.home3-banner-area .home3-banner-wrapper .home3-banner-content-wrap .home3-banner-content .banner-content-bottom .video-area .icon .video-circle {
  stroke: var(--white-color);
  fill: none;
  transition: 0.5s;
}
.home3-banner-area .home3-banner-wrapper .home3-banner-content-wrap .home3-banner-content .banner-content-bottom .video-area .icon .video-circle .top-half {
  stroke-dasharray: 320;
  stroke-dashoffset: 320;
  transition: stroke-dashoffset 0.8s cubic-bezier(0.33, 1, 0.68, 1);
}
.home3-banner-area .home3-banner-wrapper .home3-banner-content-wrap .home3-banner-content .banner-content-bottom .video-area .icon i {
  color: var(--white-color);
  font-size: 30px;
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.home3-banner-area .home3-banner-wrapper .home3-banner-content-wrap .home3-banner-content .banner-content-bottom .video-area h6 {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.48px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.home3-banner-area .home3-banner-wrapper .home3-banner-content-wrap .home3-banner-content .banner-content-bottom .video-area:hover .icon .video-circle .top-half {
  stroke-dashoffset: 0;
}
.home3-banner-area .social-list {
  position: absolute;
  top: 50%;
  left: 30px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 45px;
  z-index: 1;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home3-banner-area .social-list {
    left: 20px;
  }
}
@media (max-width: 991px) {
  .home3-banner-area .social-list {
    display: none;
  }
}
.home3-banner-area .social-list li {
  position: relative;
  line-height: 1;
  transition: 0.5s;
}
.home3-banner-area .social-list li::before {
  content: "";
  position: absolute;
  top: -22px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(255, 255, 255, 0.2);
  width: 3px;
  height: 3px;
  border-radius: 50%;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .home3-banner-area .social-list li::before {
    top: -15px;
  }
}
.home3-banner-area .social-list li:first-child::before {
  display: none;
}
.home3-banner-area .social-list li a i {
  color: var(--white-color);
  font-size: 22px;
  transition: 0.5s;
}
.home3-banner-area .social-list li a svg {
  fill: var(--white-color);
}
.home3-banner-area .social-list li:hover a i {
  color: var(--primary-color2);
}
.home3-banner-area .social-list li:hover a svg {
  fill: var(--primary-color2);
}
.home3-banner-area .slider-btn-grp {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -65px;
  z-index: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 165px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home3-banner-area .slider-btn-grp {
    right: -75px;
  }
}
@media (max-width: 991px) {
  .home3-banner-area .slider-btn-grp {
    display: none;
  }
}
.home3-banner-area .slider-btn-grp .slider-btn {
  width: 35px;
  height: 35px;
}
.home3-banner-area .slider-btn-grp .progress-pagination {
  position: relative;
  transform: rotate(90deg);
  width: 230px;
  height: 2px;
  top: unset;
  left: unset;
  background-color: rgba(255, 255, 255, 0.2);
}
.home3-banner-area .slider-btn-grp .progress-pagination .swiper-pagination-progressbar-fill {
  background: var(--white-color);
}

/*=====================================
  30.Home3 About section
========================================*/
.home3-about-section .about-content .facilities li {
  display: flex;
  align-items: start;
  gap: 15px;
  margin-bottom: 30px;
}
.home3-about-section .about-content .facilities li:last-child {
  margin-bottom: 0;
}
.home3-about-section .about-content .facilities li .icon {
  min-width: 56px;
  min-height: 56px;
  border-radius: 50%;
  background-color: rgba(var(--primary-color1-opc), 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
}
.home3-about-section .about-content .facilities li .icon svg {
  fill: var(--primary-color1);
}
.home3-about-section .about-content .facilities li .icon.secondary {
  background-color: rgba(var(--primary-color2-opc), 0.2);
}
.home3-about-section .about-content .facilities li .icon.secondary svg {
  fill: var(--primary-color2);
}
.home3-about-section .about-content .facilities li .content h5 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 22px;
  font-weight: 600;
  line-height: 1; /* 100% */
  text-transform: capitalize;
  margin-bottom: 10px;
}
@media (max-width: 576px) {
  .home3-about-section .about-content .facilities li .content h5 {
    font-size: 20px;
    margin-bottom: 8px;
  }
}
.home3-about-section .about-content .facilities li .content p {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 15px;
  font-weight: 400;
  line-height: 1.8;
  letter-spacing: 0.45px;
  margin-bottom: 0;
}
@media (max-width: 576px) {
  .home3-about-section .about-content .facilities li .content p {
    font-size: 14px;
  }
}
@media (max-width: 1199px) {
  .home3-about-section .about-video-and-img {
    padding-left: 10px;
  }
}
@media (max-width: 991px) {
  .home3-about-section .about-video-and-img {
    display: none;
  }
}
.home3-about-section .about-video-and-img .badge {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 160px;
  border-radius: 100%;
  animation: identifier 20s linear infinite;
  margin-bottom: 35px;
}
@media (max-width: 1199px) {
  .home3-about-section .about-video-and-img .badge {
    display: none;
  }
}
.home3-about-section .about-video-and-img .badge .counter-area {
  display: block;
  margin: 0;
  width: 99px;
  height: 99px;
  border-radius: 50%;
  background-color: var(--primary-color1);
  animation: identifier 20s linear infinite;
  animation-direction: reverse;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home3-about-section .about-video-and-img .badge .counter-area .counter-content-wrap {
  text-align: center;
}
.home3-about-section .about-video-and-img .badge .counter-area .counter-content-wrap .number {
  display: flex;
  align-items: center;
  font-family: var(--font-rubik);
  font-weight: 700;
  font-size: 20px;
  line-height: 1;
  color: var(--white-color);
  margin-bottom: 10px;
}
.home3-about-section .about-video-and-img .badge .counter-area .counter-content-wrap .number h5 {
  margin-bottom: 0;
  color: var(--white-color);
  line-height: 1;
}
.home3-about-section .about-video-and-img .badge .counter-area .counter-content-wrap p {
  color: var(--white-color);
  font-family: var(--font-jost);
  font-size: 12px;
  font-weight: 500;
  line-height: 1.3;
  text-transform: uppercase;
  margin-bottom: 0;
}
.home3-about-section .about-video-and-img .badge .badge__char {
  display: block;
  position: absolute;
  top: 0;
  left: calc(50% - 5px);
  height: 80px;
  width: 10px;
  background-repeat: round;
  text-align: center;
  text-transform: uppercase;
  line-height: 1;
  font-weight: 500;
  font-size: 17px;
  color: rgba(16, 12, 8, 0.5);
  font-family: var(--font-rubik);
  transform-origin: bottom center;
  transform: rotate(var(--char-rotate));
}
@keyframes identifier {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}
.home3-about-section .about-video-and-img .video-wrapper {
  position: relative;
  min-width: 305px;
  z-index: 1;
}
@media (max-width: 1199px) {
  .home3-about-section .about-video-and-img .video-wrapper {
    min-width: unset;
  }
}
.home3-about-section .about-video-and-img .video-wrapper img {
  border-radius: 50px;
}
@media (max-width: 1199px) {
  .home3-about-section .about-video-and-img .video-wrapper img {
    width: 100%;
  }
}
.home3-about-section .about-video-and-img .video-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  background-color: rgba(16, 12, 8, 0.5);
}
.home3-about-section .about-video-and-img .video-wrapper .video-area {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.home3-about-section .about-video-and-img .video-wrapper .video-area .icon {
  position: relative;
}
.home3-about-section .about-video-and-img .video-wrapper .video-area .icon .video-circle {
  stroke: var(--white-color);
  fill: none;
  transition: 0.5s;
}
.home3-about-section .about-video-and-img .video-wrapper .video-area .icon .video-circle .top-half {
  stroke-dasharray: 320;
  stroke-dashoffset: 320;
  transition: stroke-dashoffset 0.8s cubic-bezier(0.33, 1, 0.68, 1);
}
.home3-about-section .about-video-and-img .video-wrapper .video-area .icon i {
  color: var(--white-color);
  font-size: 30px;
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.home3-about-section .about-video-and-img .video-wrapper .video-area h6 {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.48px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.home3-about-section .about-video-and-img .video-wrapper .video-area:hover .icon .video-circle .top-half {
  stroke-dashoffset: 0;
}
.home3-about-section .about-video-and-img .about-section-img {
  position: relative;
}
@media (max-width: 1199px) {
  .home3-about-section .about-video-and-img .about-section-img {
    display: none;
  }
}
.home3-about-section .about-video-and-img .about-section-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(16, 12, 8, 0.3);
}
.home3-about-section .about-video-and-img .about-section-img::after {
  content: "";
  position: absolute;
  top: -9px;
  left: -9px;
  width: 105%;
  height: 100%;
  border: 1px solid rgba(99, 171, 69, 0.3);
  z-index: -1;
}

/*=====================================
  31.Home3 Destination Dropdown section
========================================*/
.destination-dropdown-card {
  position: relative;
  border-radius: 5px;
}
.destination-dropdown-card .destination-dropdown-card-img {
  position: relative;
  overflow: hidden;
}
.destination-dropdown-card .destination-dropdown-card-img img {
  border-radius: 5px;
  transition: all 0.5s ease-out;
}
@media (max-width: 991px) {
  .destination-dropdown-card .destination-dropdown-card-img img {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .destination-dropdown-card .destination-dropdown-card-img img {
    min-height: 160px;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media (max-width: 576px) {
  .destination-dropdown-card .destination-dropdown-card-img img {
    min-height: 180px;
  }
}
.destination-dropdown-card .destination-dropdown-card-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(16, 12, 8, 0.25);
  border-radius: 5px;
  z-index: 1;
}
.destination-dropdown-card .eg-batch {
  position: absolute;
  top: 12px;
  left: 0;
  z-index: 2;
}
.destination-dropdown-card .eg-batch span {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  text-transform: capitalize;
  padding: 4px 12px;
  background-color: var(--primary-color2);
  border-radius: 0px 3px 3px 0px;
}
.destination-dropdown-card .destination-dropdown-content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 0px 25px;
  z-index: 2;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .destination-dropdown-card .destination-dropdown-content {
    padding: 0px 30px 0px 20px;
  }
}
@media (max-width: 767px) {
  .destination-dropdown-card .destination-dropdown-content {
    padding: 0px 25px 0px 20px;
  }
}
@media (max-width: 576px) {
  .destination-dropdown-card .destination-dropdown-content {
    padding: 0px 20px 20px 15px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
  }
}
.destination-dropdown-card .destination-dropdown-content .title {
  display: flex;
  align-items: end;
  align-self: end;
  padding-bottom: 15px;
}
@media (max-width: 576px) {
  .destination-dropdown-card .destination-dropdown-content .title {
    align-self: flex-start;
    padding-bottom: 10px;
  }
}
.destination-dropdown-card .destination-dropdown-content .title h4 {
  margin-bottom: 0;
}
.destination-dropdown-card .destination-dropdown-content .title h4 a {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 28px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.56px;
  transition: 0.5s;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .destination-dropdown-card .destination-dropdown-content .title h4 a {
    font-size: 22px;
  }
}
@media (max-width: 767px) {
  .destination-dropdown-card .destination-dropdown-content .title h4 a {
    font-size: 25px;
  }
}
.destination-dropdown-card .destination-dropdown-content .title h4 a:hover {
  color: var(--primary-color2);
}
.destination-dropdown-card .destination-dropdown-content .details-btn {
  color: #FFF;
  font-family: var(--font-rubik);
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  white-space: nowrap;
  padding: 10px 15px;
  border-radius: 5px;
  background: rgba(99, 171, 69, 0.6);
  -webkit-backdrop-filter: blur(1.5px);
          backdrop-filter: blur(1.5px);
  position: relative;
  overflow: hidden;
  transition: 0.5s;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .destination-dropdown-card .destination-dropdown-content .details-btn {
    padding: 12px 18px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .destination-dropdown-card .destination-dropdown-content .details-btn {
    padding: 12px 18px;
  }
}
@media (max-width: 576px) {
  .destination-dropdown-card .destination-dropdown-content .details-btn {
    padding: 12px 16px;
  }
}
.destination-dropdown-card .destination-dropdown-content .details-btn::after {
  position: absolute;
  content: "";
  display: block;
  left: 15%;
  right: -20%;
  top: -4%;
  height: 150%;
  width: 150%;
  bottom: 0;
  border-radius: 2px;
  background: var(--primary-color2);
  transform: skewX(45deg) scale(0, 1);
  z-index: -1;
  transition: all 0.5s ease-out 0s;
}
.destination-dropdown-card .destination-dropdown-content .details-btn:hover {
  color: var(--title-color);
}
.destination-dropdown-card .destination-dropdown-content .details-btn:hover::after {
  transform: skewX(45deg) scale(1, 1);
}
.destination-dropdown-card .destination-dropdown-icon {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 48px;
  height: 27px;
  background-color: var(--white-color);
  border-radius: 50px 0 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0 0 5px;
  cursor: pointer;
  z-index: 9;
}
.destination-dropdown-card .destination-dropdown-icon svg {
  fill: var(--title-color);
}
.destination-dropdown-card .destination-wrap {
  box-shadow: 5px 3px 40px rgba(0, 72, 88, 0.1);
  background-color: #EEF3F1;
  position: absolute;
  bottom: -147px;
  left: 0;
  padding: 30px 70px;
  border-radius: 0 0 5px 5px;
  width: 100%;
  display: none;
  transform: scaleY(0);
  transform-origin: top;
  z-index: 9;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .destination-dropdown-card .destination-wrap {
    padding: 30px 60px;
  }
}
@media (max-width: 1399px) {
  .destination-dropdown-card .destination-wrap {
    padding: 30px 60px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .destination-dropdown-card .destination-wrap {
    padding: 30px 40px;
  }
}
@media (max-width: 767px) {
  .destination-dropdown-card .destination-wrap {
    padding: 25px 35px;
    bottom: -136px;
  }
}
@media (max-width: 767px) {
  .destination-dropdown-card .destination-wrap {
    padding: 25px;
    bottom: -248px;
  }
}
.destination-dropdown-card .destination-wrap.active {
  display: block;
  transform: scaleY(1);
  animation: fade-down 0.3s linear;
}
.destination-dropdown-card .destination-wrap .destination-list li {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 20px;
}
.destination-dropdown-card .destination-wrap .destination-list li:last-child {
  margin-bottom: 0;
}
.destination-dropdown-card .destination-wrap .destination-list li a {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  transition: 0.5s;
}
.destination-dropdown-card .destination-wrap .destination-list li svg {
  fill: var(--title-color);
  transition: 0.5s;
}
.destination-dropdown-card .destination-wrap .destination-list li:hover a {
  color: var(--primary-color1);
}
.destination-dropdown-card .destination-wrap .destination-list li:hover svg {
  fill: var(--primary-color1);
}
.destination-dropdown-card:hover .destination-dropdown-card-img img {
  transform: scale(1.05);
}

.destination-dropdown-section {
  background-image: url(../img/home3/destination-dropdown-bg.png), linear-gradient(180deg, #EEF3F1 0%, #EEF3F1 100%);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 110px 14%;
  position: relative;
}
@media (max-width: 1699px) {
  .destination-dropdown-section {
    padding: 110px 12%;
  }
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .destination-dropdown-section {
    padding: 110px 10%;
  }
}
@media (max-width: 1399px) {
  .destination-dropdown-section {
    padding: 110px 5%;
  }
}
@media (max-width: 1199px) {
  .destination-dropdown-section {
    padding: 90px 3%;
  }
}
@media (max-width: 767px) {
  .destination-dropdown-section {
    padding: 80px 2%;
  }
}
@media (max-width: 576px) {
  .destination-dropdown-section {
    padding: 60px 2%;
  }
}
.destination-dropdown-section .vector1 {
  position: absolute;
  top: 0;
  left: 0;
}
@media (max-width: 991px) {
  .destination-dropdown-section .vector1 {
    display: none;
  }
}
.destination-dropdown-section .vector2 {
  position: absolute;
  bottom: 0;
  right: 0;
}
@media (max-width: 991px) {
  .destination-dropdown-section .vector2 {
    display: none;
  }
}
@media (max-width: 991px) {
  .destination-dropdown-section .primary-btn4.two span {
    font-size: 16px;
  }
}
@media (max-width: 576px) {
  .destination-dropdown-section .primary-btn4.two span {
    padding: 13px 16px;
    font-size: 15px;
  }
}

/*=====================================
  32.Home3 Tour pack section
========================================*/
.home3-tour-pack-section {
  position: relative;
}
.home3-tour-pack-section .section-vector1 {
  position: absolute;
  top: 0;
  right: 0;
}
@media (max-width: 767px) {
  .home3-tour-pack-section .section-vector1 {
    display: none;
  }
}
.home3-tour-pack-section .package-card-slider-wrap {
  position: relative;
}
@media (max-width: 991px) {
  .home3-tour-pack-section .package-card-slider-wrap .slider-btn-grp4 {
    display: none;
  }
}
.home3-tour-pack-section .package-card-slider-wrap .slider-btn-grp4 .package-card-slider-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -100px;
  z-index: 1;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home3-tour-pack-section .package-card-slider-wrap .slider-btn-grp4 .package-card-slider-prev {
    left: -28px;
    top: 54%;
  }
}
@media (max-width: 1399px) {
  .home3-tour-pack-section .package-card-slider-wrap .slider-btn-grp4 .package-card-slider-prev {
    left: -28px;
  }
}
@media (max-width: 1199px) {
  .home3-tour-pack-section .package-card-slider-wrap .slider-btn-grp4 .package-card-slider-prev {
    left: -22px;
    top: 45%;
  }
}
.home3-tour-pack-section .package-card-slider-wrap .slider-btn-grp4 .package-card-slider-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -100px;
  z-index: 1;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home3-tour-pack-section .package-card-slider-wrap .slider-btn-grp4 .package-card-slider-next {
    right: -28px;
    top: 54%;
  }
}
@media (max-width: 1399px) {
  .home3-tour-pack-section .package-card-slider-wrap .slider-btn-grp4 .package-card-slider-next {
    right: -28px;
  }
}
@media (max-width: 1199px) {
  .home3-tour-pack-section .package-card-slider-wrap .slider-btn-grp4 .package-card-slider-next {
    right: -22px;
    top: 45%;
  }
}

/*=====================================
  33.Home3 Accordion With Tab section
========================================*/
.faq-content .accordion .accordion-item {
  border: none;
  background-color: transparent;
}
.faq-content .accordion .accordion-item:first-child .accordion-header .accordion-button {
  padding-top: 0;
}
.faq-content .accordion .accordion-item .accordion-header .accordion-button {
  border: none;
  border-radius: unset;
  box-shadow: none;
  color: var(--title-color);
  font-family: var(--font-rubik);
  background-color: transparent;
  font-size: 17px;
  font-weight: 500;
  line-height: 1.4;
  text-transform: capitalize;
  padding: 0;
  padding-bottom: 15px;
  padding-top: 15px;
  border-bottom: 1px solid var(--title-color);
}
.faq-content .accordion .accordion-item .accordion-header .accordion-button::after {
  width: unset;
  height: unset;
  content: "\f4fe";
  font-family: bootstrap-icons;
  background-image: none;
  font-weight: 800;
  font-size: 22px;
}
.faq-content .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
  background-color: unset;
}
.faq-content .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
  content: "\f2ea";
}
@media (max-width: 576px) {
  .faq-content .accordion .accordion-item .accordion-header .accordion-button {
    font-size: 15px;
  }
}
.faq-content .accordion .accordion-item .accordion-body {
  padding: 20px;
  padding-bottom: 15px;
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.9;
  letter-spacing: 0.48px;
}
@media (max-width: 576px) {
  .faq-content .accordion .accordion-item .accordion-body {
    font-size: 14px;
    padding: 15px 10px;
  }
}

.accordion-with-tab-section {
  padding-top: 180px;
  position: relative;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .accordion-with-tab-section {
    padding-top: 160px;
  }
}
@media (max-width: 991px) {
  .accordion-with-tab-section {
    padding-top: 90px;
  }
}
@media (max-width: 767px) {
  .accordion-with-tab-section {
    padding-top: 70px;
  }
}
.accordion-with-tab-section .vector1 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
@media (max-width: 991px) {
  .accordion-with-tab-section .vector1 {
    display: none;
  }
}
.accordion-with-tab-section .vector2 {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}
@media (max-width: 991px) {
  .accordion-with-tab-section .vector2 {
    display: none;
  }
}
.accordion-with-tab-section .accordion-with-tab-img {
  position: relative;
  transform: translateY(-60px);
}
@media (min-width: 992px) and (max-width: 1199px) {
  .accordion-with-tab-section .accordion-with-tab-img {
    transform: translateY(-40px);
  }
}
@media (max-width: 991px) {
  .accordion-with-tab-section .accordion-with-tab-img {
    display: none;
  }
}
.accordion-with-tab-section .accordion-with-tab-img img {
  max-height: 760px;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .accordion-with-tab-section .accordion-with-tab-img img {
    min-height: 760px;
  }
}
.accordion-with-tab-section .accordion-with-tab-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(16, 12, 8, 0.5);
}
.accordion-with-tab-section .accordion-with-tab-content {
  padding: 80px 35px 80px 125px;
  background-color: #F3F8F6;
  border-radius: 20px;
  margin-left: -90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .accordion-with-tab-section .accordion-with-tab-content {
    padding: 60px 35px 60px 110px;
  }
}
@media (max-width: 991px) {
  .accordion-with-tab-section .accordion-with-tab-content {
    margin-left: 0;
    padding: 60px 35px;
  }
}
@media (max-width: 767px) {
  .accordion-with-tab-section .accordion-with-tab-content {
    padding: 50px 25px;
    border-radius: 10px;
  }
}
@media (max-width: 576px) {
  .accordion-with-tab-section .accordion-with-tab-content {
    padding: 40px 20px;
  }
}
.accordion-with-tab-section .accordion-with-tab-content > h2 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 38px;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 30px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .accordion-with-tab-section .accordion-with-tab-content > h2 {
    font-size: 35px;
  }
}
@media (max-width: 767px) {
  .accordion-with-tab-section .accordion-with-tab-content > h2 {
    font-size: 35px;
    margin-bottom: 25px;
  }
}
@media (max-width: 576px) {
  .accordion-with-tab-section .accordion-with-tab-content > h2 {
    font-size: 24px;
    margin-bottom: 15px;
  }
}
.accordion-with-tab-section .accordion-with-tab-content .accordion-with-tab-wrap .nav-pills {
  border: 1px solid rgba(var(--primary-color1-opc), 0.3);
  border-radius: 5px;
  margin-bottom: 40px;
  display: inline-flex;
}
@media (max-width: 576px) {
  .accordion-with-tab-section .accordion-with-tab-content .accordion-with-tab-wrap .nav-pills {
    border: none;
    gap: 10px;
  }
}
.accordion-with-tab-section .accordion-with-tab-content .accordion-with-tab-wrap .nav-pills .nav-item .nav-link {
  color: rgba(16, 12, 8, 0.7);
  font-family: var(--font-rubik);
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  border-radius: unset;
  padding: 14px 32px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .accordion-with-tab-section .accordion-with-tab-content .accordion-with-tab-wrap .nav-pills .nav-item .nav-link {
    padding: 14px 25px;
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .accordion-with-tab-section .accordion-with-tab-content .accordion-with-tab-wrap .nav-pills .nav-item .nav-link {
    padding: 14px 25px;
    font-size: 14px;
  }
}
@media (max-width: 576px) {
  .accordion-with-tab-section .accordion-with-tab-content .accordion-with-tab-wrap .nav-pills .nav-item .nav-link {
    padding: 13px 19px;
    border-radius: 5px;
    border: 1px solid rgba(var(--primary-color1-opc), 0.3);
  }
}
.accordion-with-tab-section .accordion-with-tab-content .accordion-with-tab-wrap .nav-pills .nav-item .nav-link.active {
  background-color: var(--primary-color1);
  color: var(--white-color);
}
.accordion-with-tab-section .accordion-with-tab-content .accordion-with-tab-wrap .nav-pills .nav-item:first-child .nav-link {
  border-radius: 5px 0 0 5px;
  border-right: 1px solid rgba(var(--primary-color1-opc), 0.3);
}
@media (max-width: 576px) {
  .accordion-with-tab-section .accordion-with-tab-content .accordion-with-tab-wrap .nav-pills .nav-item:first-child .nav-link {
    border-right: none;
    border-radius: 5px;
  }
}
.accordion-with-tab-section .accordion-with-tab-content .accordion-with-tab-wrap .nav-pills .nav-item:last-child .nav-link {
  border-radius: 0px 5px 5px 0px;
  border-left: 1px solid rgba(var(--primary-color1-opc), 0.3);
}
@media (max-width: 576px) {
  .accordion-with-tab-section .accordion-with-tab-content .accordion-with-tab-wrap .nav-pills .nav-item:last-child .nav-link {
    border: 1px solid rgba(var(--primary-color1-opc), 0.3);
    border-radius: 5px;
  }
}

/*=====================================
  34.Home3 Activities section
========================================*/
.activities-section {
  overflow: hidden;
}
.activities-section .activities-tab-with-slider {
  display: flex;
}
@media (max-width: 991px) {
  .activities-section .activities-tab-with-slider {
    display: block;
  }
}
.activities-section .activities-tab-with-slider .nav-pills {
  background: #E6ECDE;
  flex: 0 0 auto;
  width: 20%;
}
@media (max-width: 1699px) {
  .activities-section .activities-tab-with-slider .nav-pills {
    width: 22%;
  }
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .activities-section .activities-tab-with-slider .nav-pills {
    width: 25%;
  }
}
@media (max-width: 1399px) {
  .activities-section .activities-tab-with-slider .nav-pills {
    width: 25%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .activities-section .activities-tab-with-slider .nav-pills {
    width: 28%;
  }
}
@media (max-width: 991px) {
  .activities-section .activities-tab-with-slider .nav-pills {
    width: 100%;
  }
}
.activities-section .activities-tab-with-slider .nav-pills .nav-item {
  position: relative;
}
.activities-section .activities-tab-with-slider .nav-pills .nav-item .nav-link {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  text-transform: capitalize;
  padding: 37px 30px;
  border-bottom: 1px solid rgba(16, 12, 8, 0.06);
  border-radius: unset;
}
.activities-section .activities-tab-with-slider .nav-pills .nav-item .nav-link svg {
  fill: var(--title-color);
}
.activities-section .activities-tab-with-slider .nav-pills .nav-item .nav-link .details-btn {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid var(--title-color);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
}
.activities-section .activities-tab-with-slider .nav-pills .nav-item .nav-link .details-btn svg {
  stroke: var(--title-color);
  transition: 0.5s;
}
.activities-section .activities-tab-with-slider .nav-pills .nav-item .nav-link .details-btn:hover {
  background-color: var(--white-color);
}
.activities-section .activities-tab-with-slider .nav-pills .nav-item .nav-link .details-btn:hover svg {
  transform: rotate(33deg);
  stroke: var(--title-color);
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .activities-section .activities-tab-with-slider .nav-pills .nav-item .nav-link {
    padding: 20px;
    font-size: 17px;
  }
  .activities-section .activities-tab-with-slider .nav-pills .nav-item .nav-link .details-btn {
    right: 20px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .activities-section .activities-tab-with-slider .nav-pills .nav-item .nav-link {
    padding: 20px 15px;
    font-size: 16px;
  }
  .activities-section .activities-tab-with-slider .nav-pills .nav-item .nav-link .details-btn {
    right: 15px;
  }
}
@media (max-width: 991px) {
  .activities-section .activities-tab-with-slider .nav-pills .nav-item .nav-link {
    padding: 20px 25px;
    font-size: 18px;
  }
  .activities-section .activities-tab-with-slider .nav-pills .nav-item .nav-link .details-btn {
    right: 25px;
  }
}
@media (max-width: 767px) {
  .activities-section .activities-tab-with-slider .nav-pills .nav-item .nav-link {
    padding: 15px 20px;
    font-size: 16px;
  }
  .activities-section .activities-tab-with-slider .nav-pills .nav-item .nav-link .details-btn {
    right: 20px;
  }
}
.activities-section .activities-tab-with-slider .nav-pills .nav-item .nav-link.active {
  color: var(--white-color);
  background-color: var(--primary-color1);
}
.activities-section .activities-tab-with-slider .nav-pills .nav-item .nav-link.active > svg {
  fill: var(--white-color);
}
.activities-section .activities-tab-with-slider .nav-pills .nav-item .nav-link.active .details-btn {
  border-color: var(--white-color);
}
.activities-section .activities-tab-with-slider .nav-pills .nav-item .nav-link.active .details-btn svg {
  stroke: var(--white-color);
  fill: none;
}
.activities-section .activities-tab-with-slider .nav-pills .nav-item .nav-link.active .details-btn:hover {
  background-color: var(--white-color);
}
.activities-section .activities-tab-with-slider .nav-pills .nav-item .nav-link.active .details-btn:hover svg {
  transform: rotate(33deg);
  stroke: var(--title-color);
}
.activities-section .activities-tab-with-slider .nav-pills .nav-item:last-child .nav-link {
  border-bottom: unset;
}
.activities-section .activities-tab-with-slider .tab-content {
  flex: 0 0 auto;
  width: 80%;
}
@media (max-width: 1699px) {
  .activities-section .activities-tab-with-slider .tab-content {
    width: 78%;
  }
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .activities-section .activities-tab-with-slider .tab-content {
    width: 75%;
  }
}
@media (max-width: 1399px) {
  .activities-section .activities-tab-with-slider .tab-content {
    width: 75%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .activities-section .activities-tab-with-slider .tab-content {
    width: 72%;
  }
}
@media (max-width: 991px) {
  .activities-section .activities-tab-with-slider .tab-content {
    width: 100%;
  }
}
.activities-section .activities-tab-with-slider .tab-content .tab-pane {
  height: 100%;
}
.activities-section .activities-tab-with-slider .tab-content .tab-pane .tab-slider-wrap {
  height: 100%;
  position: relative;
}
.activities-section .activities-tab-with-slider .tab-content .tab-pane .tab-slider-wrap .activities-tab-img-slider {
  height: 100%;
}
.activities-section .activities-tab-with-slider .tab-content .tab-pane .tab-slider-wrap .activities-tab-img-slider .activities-tab-img {
  position: relative;
  height: 100%;
}
.activities-section .activities-tab-with-slider .tab-content .tab-pane .tab-slider-wrap .activities-tab-img-slider .activities-tab-img img {
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 991px) {
  .activities-section .activities-tab-with-slider .tab-content .tab-pane .tab-slider-wrap .activities-tab-img-slider .activities-tab-img img {
    min-height: 350px;
  }
}
.activities-section .activities-tab-with-slider .tab-content .tab-pane .tab-slider-wrap .activities-tab-img-slider .activities-tab-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(16, 12, 8, 0.5);
}
.activities-section .activities-tab-with-slider .tab-content .tab-pane .tab-slider-wrap .number-of-img {
  position: absolute;
  top: 25px;
  left: 45px;
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 5px;
  z-index: 1;
}
@media (max-width: 1399px) {
  .activities-section .activities-tab-with-slider .tab-content .tab-pane .tab-slider-wrap .number-of-img {
    left: 35px;
  }
}
@media (max-width: 991px) {
  .activities-section .activities-tab-with-slider .tab-content .tab-pane .tab-slider-wrap .number-of-img {
    left: 25px;
  }
}
.activities-section .activities-tab-with-slider .tab-content .tab-pane .tab-slider-wrap .number-of-img svg {
  fill: var(--white-color);
}
.activities-section .activities-tab-with-slider .tab-content .tab-pane .tab-slider-wrap .slider-btn-grp4 {
  position: absolute;
  bottom: 25px;
  right: 40px;
  display: flex;
  align-items: center;
  gap: 40px;
  z-index: 19;
}
@media (max-width: 767px) {
  .activities-section .activities-tab-with-slider .tab-content .tab-pane .tab-slider-wrap .slider-btn-grp4 {
    display: none;
  }
}
.activities-section .activities-tab-with-slider .tab-content .tab-pane .tab-slider-wrap .slider-btn-grp4 .slider-btn {
  width: 45px;
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(77px);
          backdrop-filter: blur(77px);
}
.activities-section .activities-tab-with-slider .tab-content .tab-pane .tab-slider-wrap .slider-btn-grp4 .slider-btn:hover {
  background-color: var(--primary-color1);
}

/*=====================================
  35 .Home3 Teams section
========================================*/
.feature-with-content-section {
  position: relative;
}
.feature-with-content-section .section-vector {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}
@media (max-width: 991px) {
  .feature-with-content-section .section-vector {
    display: none;
  }
}
.feature-with-content-section .feature-with-content-wrapper .feature-content-wrap .eg-tag2 {
  -webkit-mask-image: url(../img/home3/vector/activities-content-tag.svg);
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: cover;
  background-color: var(--primary-color2);
  padding: 8px 40px;
  white-space: nowrap;
  display: inline-flex;
  margin-bottom: 15px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .feature-with-content-section .feature-with-content-wrapper .feature-content-wrap .eg-tag2 {
    margin-bottom: 8px;
  }
}
@media (max-width: 767px) {
  .feature-with-content-section .feature-with-content-wrapper .feature-content-wrap .eg-tag2 {
    margin-bottom: 10px;
  }
}
.feature-with-content-section .feature-with-content-wrapper .feature-content-wrap .eg-tag2 span {
  color: var(--white-color);
  font-family: var(--font-satisfy);
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.72px;
}
.feature-with-content-section .feature-with-content-wrapper .feature-content-wrap .eg-tag2 span svg {
  fill: var(--white-color);
}
.feature-with-content-section .feature-with-content-wrapper .feature-content-wrap h2 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 30px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0.6px;
  margin-bottom: 20px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .feature-with-content-section .feature-with-content-wrapper .feature-content-wrap h2 {
    font-size: 25px;
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .feature-with-content-section .feature-with-content-wrapper .feature-content-wrap h2 {
    font-size: 25px;
    margin-bottom: 10px;
  }
}
@media (max-width: 576px) {
  .feature-with-content-section .feature-with-content-wrapper .feature-content-wrap h2 {
    font-size: 20px;
  }
}
.feature-with-content-section .feature-with-content-wrapper .feature-content-wrap > p {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.9;
  letter-spacing: 0.48px;
  margin-bottom: 30px;
}
@media (max-width: 1199px) {
  .feature-with-content-section .feature-with-content-wrapper .feature-content-wrap > p {
    margin-bottom: 20px;
  }
}
@media (max-width: 576px) {
  .feature-with-content-section .feature-with-content-wrapper .feature-content-wrap > p {
    font-size: 14px;
    margin-bottom: 20px;
  }
}
.feature-with-content-section .feature-with-content-wrapper .feature-content-wrap .tripadvisor-review {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  padding-top: 30px;
}
@media (max-width: 1199px) {
  .feature-with-content-section .feature-with-content-wrapper .feature-content-wrap .tripadvisor-review {
    padding-top: 20px;
  }
}
.feature-with-content-section .feature-with-content-wrapper .feature-content-wrap .tripadvisor-review > strong {
  font-family: var(--font-rubik);
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--title-color);
}
.feature-with-content-section .feature-with-content-wrapper .feature-content-wrap .tripadvisor-review p {
  font-family: var(--font-jost);
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: var(--text-color);
  margin-bottom: 0;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}
.feature-with-content-section .feature-with-content-wrapper .feature-content-wrap .tripadvisor-review p strong {
  font-weight: 600;
}
.feature-with-content-section .feature-with-content-wrapper .feature-content-wrap .tripadvisor-review p a {
  color: var(--text-color);
  text-decoration: underline;
}

/*=====================================
  36 .Home3 Tour Type section
========================================*/
.tour-type-tab-slider-section {
  position: relative;
}
.tour-type-tab-slider-section .section-vector1 {
  position: absolute;
  top: 0;
  right: 0;
}
@media (max-width: 991px) {
  .tour-type-tab-slider-section .section-vector1 {
    display: none;
  }
}
.tour-type-tab-slider-section .section-vector2 {
  position: absolute;
  bottom: 0;
  left: 0;
}
@media (max-width: 991px) {
  .tour-type-tab-slider-section .section-vector2 {
    display: none;
  }
}
.tour-type-tab-slider-section .tab-slider-wrap .nav-pills {
  position: relative;
}
.tour-type-tab-slider-section .tab-slider-wrap .nav-pills .tour-tab-slider {
  width: 100%;
}
.tour-type-tab-slider-section .tab-slider-wrap .nav-pills .tour-tab-slider .nav-item .nav-link {
  border-radius: 5px;
  background: #E8F4E3;
  text-align: center;
  padding: 25px 20px;
  cursor: pointer;
}
.tour-type-tab-slider-section .tab-slider-wrap .nav-pills .tour-tab-slider .nav-item .nav-link .icon {
  margin-bottom: 15px;
}
.tour-type-tab-slider-section .tab-slider-wrap .nav-pills .tour-tab-slider .nav-item .nav-link .icon svg {
  fill: var(--title-color);
}
.tour-type-tab-slider-section .tab-slider-wrap .nav-pills .tour-tab-slider .nav-item .nav-link .content h5 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 0;
}
@media (max-width: 576px) {
  .tour-type-tab-slider-section .tab-slider-wrap .nav-pills .tour-tab-slider .nav-item .nav-link .content h5 {
    font-size: 15px;
  }
}
.tour-type-tab-slider-section .tab-slider-wrap .nav-pills .tour-tab-slider .nav-item .nav-link.active {
  background-color: var(--primary-color1);
}
.tour-type-tab-slider-section .tab-slider-wrap .nav-pills .tour-tab-slider .nav-item .nav-link.active .icon svg {
  fill: var(--white-color);
}
.tour-type-tab-slider-section .tab-slider-wrap .nav-pills .tour-tab-slider .nav-item .nav-link.active .content h5 {
  color: var(--white-color);
}
.tour-type-tab-slider-section .tab-slider-wrap .nav-pills .slider-btn-grp4 .slider-btn {
  width: 44px;
  height: 32px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -26px;
  z-index: 1;
}
@media (max-width: 576px) {
  .tour-type-tab-slider-section .tab-slider-wrap .nav-pills .slider-btn-grp4 .slider-btn {
    left: -10px;
  }
}
.tour-type-tab-slider-section .tab-slider-wrap .nav-pills .slider-btn-grp4 .slider-btn.tour-tab-slider-next {
  right: -26px;
  left: unset;
}
@media (max-width: 576px) {
  .tour-type-tab-slider-section .tab-slider-wrap .nav-pills .slider-btn-grp4 .slider-btn.tour-tab-slider-next {
    right: -10px;
  }
}

/*=====================================
  37 .Home3 Testimonial section
========================================*/
.testimonial-card2 {
  text-align: center;
  border-radius: 10px;
  border: 1px solid rgba(34, 34, 34, 0.1);
  background-image: url(../img/home3/testimonial-card-bg.png), linear-gradient(180deg, whitesmoke 0%, whitesmoke 100%);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 35px 30px;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .testimonial-card2 {
    padding: 30px 20px;
  }
}
@media (max-width: 1399px) {
  .testimonial-card2 {
    padding: 30px 20px;
  }
}
@media (max-width: 991px) {
  .testimonial-card2 {
    padding: 25px 15px;
  }
}
.testimonial-card2 .rating {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-bottom: 15px;
}
@media (max-width: 576px) {
  .testimonial-card2 .rating {
    margin-bottom: 10px;
  }
}
.testimonial-card2 .rating li {
  line-height: 1;
}
.testimonial-card2 .rating li i {
  color: #FFC107;
}
.testimonial-card2 h4 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 25px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.75px;
  margin-bottom: 20px;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .testimonial-card2 h4 {
    font-size: 22px;
  }
}
@media (max-width: 1399px) {
  .testimonial-card2 h4 {
    font-size: 20px;
    margin-bottom: 15px;
  }
}
.testimonial-card2 p {
  color: var(--text-color);
  font-family: var(--font-rubik);
  font-size: 17px;
  font-weight: 400;
  line-height: 1.9;
  letter-spacing: 0.51px;
  margin-bottom: 25px;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .testimonial-card2 p {
    font-size: 15px;
  }
}
@media (max-width: 1199px) {
  .testimonial-card2 p {
    font-size: 15px;
    margin-bottom: 20px;
  }
}
.testimonial-card2 .tesimonial-card-bottom {
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
}
@media (max-width: 1399px) {
  .testimonial-card2 .tesimonial-card-bottom {
    padding: 0;
  }
}
@media (max-width: 576px) {
  .testimonial-card2 .tesimonial-card-bottom {
    justify-content: center;
  }
}
@media (max-width: 576px) {
  .testimonial-card2 .tesimonial-card-bottom .quote {
    display: none;
  }
}
.testimonial-card2 .tesimonial-card-bottom .quote svg {
  fill: var(--title-color);
  opacity: 0.03;
}
.testimonial-card2 .tesimonial-card-bottom .author-area .author-img {
  margin-bottom: 15px;
}
.testimonial-card2 .tesimonial-card-bottom .author-area .author-img img {
  min-width: 50px;
  max-width: 50px;
  height: 50px;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
}
.testimonial-card2 .tesimonial-card-bottom .author-area .author-name-desig {
  line-height: 1;
}
.testimonial-card2 .tesimonial-card-bottom .author-area .author-name-desig h5 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 5px;
}
.testimonial-card2 .tesimonial-card-bottom .author-area .author-name-desig span {
  color: var(--text-color);
  font-family: var(--font-rubik);
  font-size: 13px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.26px;
}
.testimonial-card2.style-2 {
  background-image: url(../img/home4/home4-testimonial-card-bg.png), linear-gradient(180deg, #2d3330 0%, #2d3330 100%);
  border-radius: 20px;
}
.testimonial-card2.style-2 h4 {
  color: var(--white-color);
}
.testimonial-card2.style-2 p {
  color: rgba(255, 255, 255, 0.5);
}
.testimonial-card2.style-2 .tesimonial-card-bottom {
  justify-content: center;
}
.testimonial-card2.style-2 .tesimonial-card-bottom .author-area .author-name-desig h5 {
  color: var(--white-color);
}
.testimonial-card2.style-2 .tesimonial-card-bottom .author-area .author-name-desig span {
  color: rgba(255, 255, 255, 0.5);
}

.home3-testimonial-section {
  background-image: url(../img/home3/testimonial-section-bg.png), linear-gradient(180deg, #1d231f 0%, #1d231f 100%);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 120px 40px;
  overflow: hidden;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home3-testimonial-section {
    padding: 120px 25px;
  }
}
@media (max-width: 1399px) {
  .home3-testimonial-section {
    padding: 110px 20px;
  }
}
@media (max-width: 1199px) {
  .home3-testimonial-section {
    padding: 90px 20px;
  }
}
@media (max-width: 991px) {
  .home3-testimonial-section {
    padding: 70px 15px;
  }
}
.home3-testimonial-section .section-title2 h2 {
  color: var(--white-color);
}
.home3-testimonial-section .section-title2 p {
  color: rgba(255, 255, 255, 0.5);
}
.home3-testimonial-section .testimonial-card-slider-area .testimonial-bottom-area {
  padding: 12px 30px;
  border-radius: 5px;
  background: #FAF6E7;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .home3-testimonial-section .testimonial-card-slider-area .testimonial-bottom-area {
    padding: 12px 20px;
  }
}
@media (max-width: 576px) {
  .home3-testimonial-section .testimonial-card-slider-area .testimonial-bottom-area {
    flex-direction: column;
    gap: 15px;
    justify-content: center;
  }
}
.home3-testimonial-section .testimonial-card-slider-area .testimonial-bottom-area .rating-area .rating-top {
  display: flex;
  align-items: center;
  gap: 2px;
  margin-bottom: 2px;
}
@media (max-width: 576px) {
  .home3-testimonial-section .testimonial-card-slider-area .testimonial-bottom-area .rating-area .rating-top {
    justify-content: center;
  }
}
.home3-testimonial-section .testimonial-card-slider-area .testimonial-bottom-area .rating-area .rating-top .logo {
  line-height: 1;
}
.home3-testimonial-section .testimonial-card-slider-area .testimonial-bottom-area .rating-area .rating-top span {
  color: var(--title-color);
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
}
.home3-testimonial-section .testimonial-card-slider-area .testimonial-bottom-area .rating-area .rating-bottom {
  line-height: 1;
  display: flex;
  align-items: baseline;
  gap: 5px;
}
.home3-testimonial-section .testimonial-card-slider-area .testimonial-bottom-area .rating-area .rating-bottom strong {
  color: var(--title-color);
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
}
.home3-testimonial-section .testimonial-card-slider-area .testimonial-bottom-area .rating-area .rating-bottom .rating {
  display: flex;
  align-items: center;
  gap: 10px;
  line-height: 1;
}
.home3-testimonial-section .testimonial-card-slider-area .testimonial-bottom-area .rating-area .rating-bottom .rating ul {
  display: flex;
  align-items: center;
  gap: 5px;
}
.home3-testimonial-section .testimonial-card-slider-area .testimonial-bottom-area .rating-area .rating-bottom .rating ul li i {
  color: #00AA6C;
  font-size: 10px;
}
.home3-testimonial-section .testimonial-card-slider-area .testimonial-bottom-area .rating-area .rating-bottom .rating span {
  color: var(--white-color);
}
.home3-testimonial-section .testimonial-card-slider-area .testimonial-bottom-area .rating-area .rating-bottom span {
  color: var(--text-color);
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
}
@media (max-width: 767px) {
  .home3-testimonial-section .testimonial-card-slider-area .testimonial-bottom-area .testimonial-pagination {
    display: none;
  }
}
.home3-testimonial-section .testimonial-card-slider-area .testimonial-bottom-area .testimonial-pagination .swiper-pagination5 {
  width: unset !important;
  z-index: 9;
  gap: 15px;
  line-height: 1;
}
.home3-testimonial-section .testimonial-card-slider-area .testimonial-bottom-area .testimonial-pagination .swiper-pagination5 .swiper-pagination-bullet {
  height: 16px;
  width: 16px;
  background-color: var(--primary-color1);
  opacity: 1;
  position: relative;
}
.home3-testimonial-section .testimonial-card-slider-area .testimonial-bottom-area .testimonial-pagination .swiper-pagination5 .swiper-pagination-bullet.swiper-pagination-bullet-active {
  border: 1px solid var(--primary-color1);
  background-color: transparent;
}
.home3-testimonial-section .testimonial-card-slider-area .testimonial-bottom-area .testimonial-pagination .swiper-pagination5 .swiper-pagination-bullet.swiper-pagination-bullet-active::after {
  content: "";
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: var(--primary-color1);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.home3-testimonial-section .testimonial-card-slider-area .testimonial-bottom-area .review-btn .primary-btn4 {
  padding: 3px;
  border-radius: unset;
}
.home3-testimonial-section .testimonial-card-slider-area .testimonial-bottom-area .review-btn .primary-btn4 span {
  font-size: 12px;
  font-weight: 500;
  padding: 10px 12px;
  border-radius: unset;
}

/*=====================================
  38 .Home3 Teams section
========================================*/
.teams-card2 {
  border: 1px solid rgba(16, 12, 8, 0.08);
  transition: 0.5s ease-in-out;
}
.teams-card2 .teams-img {
  position: relative;
  overflow: hidden;
  transition: 0.5s ease-in-out;
}
.teams-card2 .teams-img img {
  transition: all 0.5s ease-out;
}
@media (max-width: 767px) {
  .teams-card2 .teams-img img {
    width: 100%;
  }
}
.teams-card2 .teams-img .social-list {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(16, 12, 8, 0.5);
  -webkit-backdrop-filter: blur(1.5px);
          backdrop-filter: blur(1.5px);
  padding: 13px 28px;
  display: flex;
  align-items: center;
  gap: 45px;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: bottom;
  transition: 0.5s ease-in-out;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .teams-card2 .teams-img .social-list {
    gap: 30px;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .teams-card2 .teams-img .social-list {
    padding: 80px 12px 40px;
  }
}
.teams-card2 .teams-img .social-list li {
  position: relative;
  line-height: 1;
  transition: 0.5s;
}
.teams-card2 .teams-img .social-list li::before {
  content: "";
  position: absolute;
  left: -22px;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.2);
  width: 3px;
  height: 3px;
  border-radius: 50%;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .teams-card2 .teams-img .social-list li::before {
    top: -15px;
  }
}
.teams-card2 .teams-img .social-list li:first-child::before {
  display: none;
}
.teams-card2 .teams-img .social-list li a i {
  color: var(--white-color);
  font-size: 22px;
  transition: 0.5s;
}
.teams-card2 .teams-img .social-list li a svg {
  fill: var(--white-color);
  transition: 0.5s;
}
.teams-card2 .teams-img .social-list li:hover a i {
  color: var(--primary-color2);
}
.teams-card2 .teams-img .social-list li:hover a svg {
  fill: var(--primary-color2);
}
.teams-card2 .teams-content {
  background-color: var(--white-color);
  text-align: center;
  padding: 25px 20px;
  transition: 0.5s ease-in-out;
}
.teams-card2 .teams-content h4 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 25px;
  font-weight: 600;
  line-height: 1.1;
  margin-bottom: 3px;
  transition: 0.5s;
}
.teams-card2 .teams-content span {
  color: rgba(16, 12, 8, 0.5);
  font-family: var(--font-jost);
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
  display: inline-block;
  transition: 0.5s;
}
.teams-card2:hover .teams-img img {
  transform: scale(1.1);
}
.teams-card2:hover .teams-img .social-list {
  opacity: 1;
  transform: scaleY(1);
}
.teams-card2:hover .teams-content {
  background-color: var(--primary-color1);
  bottom: 0;
}
.teams-card2:hover .teams-content h4 {
  color: var(--white-color);
}
.teams-card2:hover .teams-content span {
  color: rgba(255, 255, 255, 0.7);
}
.teams-card2.style-2 .teams-img .social-list {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(16, 12, 8, 0.01);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: bottom;
  transition: 0.5s ease-in-out;
}
.teams-card2.style-2 .teams-img .social-list li {
  position: relative;
  line-height: 1;
  transition: 0.5s;
}
.teams-card2.style-2 .teams-img .social-list li::before {
  display: none;
}
.teams-card2.style-2 .teams-img .social-list li a {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: var(--white-color);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
}
.teams-card2.style-2 .teams-img .social-list li a i {
  color: var(--title-color);
  font-size: 22px;
  transition: 0.5s;
}
.teams-card2.style-2 .teams-img .social-list li a svg {
  fill: var(--title-color);
  transition: 0.5s;
}
.teams-card2.style-2 .teams-img .social-list li:hover a {
  background-color: var(--primary-color1);
}
.teams-card2.style-2 .teams-img .social-list li:hover a i {
  color: var(--white-color);
}
.teams-card2.style-2 .teams-img .social-list li:hover a svg {
  fill: var(--white-color);
}
.teams-card2.style-2 .teams-content {
  text-align: start;
}
.teams-card2.style-2 .teams-content h4 {
  font-size: 23px;
}
.teams-card2.style-2 .teams-content span {
  font-size: 16px;
}
.teams-card2.style-2:hover .teams-img .social-list {
  opacity: 1;
  transform: scaleY(1);
}
.teams-card2.style-3 {
  border-radius: 5px;
  border: unset;
  box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.08);
}
.teams-card2.style-3 .teams-img {
  border-radius: 5px 5px 0 0;
}
.teams-card2.style-3 .teams-img img {
  border-radius: 5px 5px 0 0;
}
.teams-card2.style-3 .teams-img .social-list {
  width: unset;
  border-radius: 5px 5px 0px 0px;
  padding: 0;
  gap: 0;
  left: 50%;
  opacity: 0;
  transform: scaleY(0) translateX(-50%);
  transform-origin: bottom;
  transition: 0.5s ease-in-out;
}
.teams-card2.style-3 .teams-img .social-list li::before {
  display: none;
}
.teams-card2.style-3 .teams-img .social-list li a {
  padding: 12px 20px;
  border-left: 1px solid rgba(217, 217, 217, 0.3);
  display: block;
}
.teams-card2.style-3 .teams-img .social-list li:first-child a {
  border: unset;
}
.teams-card2.style-3:hover .teams-img .social-list {
  opacity: 1;
  transform: scaleY(1) translateX(-50%);
}
.teams-card2.style-4 {
  border: unset;
}
.teams-card2.style-4 .teams-img {
  overflow: hidden;
}
.teams-card2.style-4 .teams-img .social-list {
  width: unset;
  border-radius: 10px 10px 0px 0px;
  padding: 0;
  gap: 0;
  left: 50%;
  bottom: 50px;
  background-color: var(--primary-color1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  opacity: 0;
  transform: scaleY(0) translateX(-50%);
  transform-origin: bottom;
  transition: 0.5s ease-in-out;
}
.teams-card2.style-4 .teams-img .social-list li {
  padding: 8px 16px;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
}
.teams-card2.style-4 .teams-img .social-list li a i {
  font-size: 20px;
}
.teams-card2.style-4 .teams-img .social-list li::before {
  display: none;
}
.teams-card2.style-4 .teams-img .social-list li:first-child {
  border: unset;
}
.teams-card2.style-4 .teams-content {
  border-radius: 10px;
  padding: 20px;
  border: 1px solid rgba(16, 12, 8, 0.15);
  background: var(--white-color);
  max-width: 270px;
  width: 100%;
  margin: 0 auto;
  margin-top: -50px;
  position: relative;
  z-index: 1;
  line-height: 1;
}
.teams-card2.style-4 .teams-content h4 {
  margin-bottom: 10px;
  font-size: 23px;
}
.teams-card2.style-4:hover .teams-img img {
  transform: scale(1.1);
}
.teams-card2.style-4:hover .teams-img .social-list {
  opacity: 1;
  transform: scaleY(1) translateX(-50%);
}
.teams-card2.style-4:hover .teams-content {
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
  bottom: 0;
}
.teams-card2.style-4:hover .teams-content h4 {
  color: var(--white-color);
}
.teams-card2.style-4:hover .teams-content span {
  color: rgba(255, 255, 255, 0.7);
}

.home3-teams-section {
  position: relative;
}
.home3-teams-section::before {
  content: url(../img/home3/vector/teams-section-vector.svg);
  position: absolute;
  left: 0;
  bottom: -90px;
}
@media (max-width: 991px) {
  .home3-teams-section::before {
    display: none;
  }
}
.home3-teams-section .slider-btn-grp4 {
  display: flex;
  align-items: center;
  gap: 50px;
}
@media (max-width: 1399px) {
  .home3-teams-section .slider-btn-grp4 {
    gap: 30px;
  }
}
@media (max-width: 767px) {
  .home3-teams-section .slider-btn-grp4 {
    display: none;
  }
}

/*=====================================
  39 .Home3 Instagram Slider section
========================================*/
.instagram-slider-section {
  margin-bottom: -120px;
}
@media (max-width: 991px) {
  .instagram-slider-section {
    margin-bottom: -80px;
  }
}
@media (max-width: 767px) {
  .instagram-slider-section {
    margin-bottom: -50px;
  }
}
.instagram-slider-section .insta-section-title {
  margin-bottom: 45px;
}
@media (max-width: 1199px) {
  .instagram-slider-section .insta-section-title {
    margin-bottom: 35px;
  }
}
.instagram-slider-section .insta-section-title h3 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 30px;
  font-weight: 600;
  line-height: 1;
  text-transform: capitalize;
  margin-bottom: 25px;
  position: relative;
}
.instagram-slider-section .insta-section-title h3::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100px;
  height: 2px;
  background: var(--title-color);
  border-radius: 5px;
}
.instagram-slider-section .insta-section-title p {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
  text-transform: capitalize;
  margin-bottom: 0;
}
.instagram-slider-section .instagram-slider-wrapper {
  overflow: hidden;
}
.instagram-slider-section .instagram-slider-wrapper .instagram-slider-img {
  position: relative;
  line-height: 1;
}
.instagram-slider-section .instagram-slider-wrapper .instagram-slider-img img {
  border-radius: 10px 10px 0px 0px;
}
@media (max-width: 576px) {
  .instagram-slider-section .instagram-slider-wrapper .instagram-slider-img img {
    width: 100%;
  }
}
.instagram-slider-section .instagram-slider-wrapper .instagram-slider-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(16, 12, 8, 0.4);
  border-radius: 10px 10px 0px 0px;
}
.instagram-slider-section .instagram-slider-wrapper .instagram-slider-img .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(var(--primary-color1-opc), 0.5);
  border-radius: 10px 10px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: bottom;
  z-index: 99;
  transition: 0.75s;
}
.instagram-slider-section .instagram-slider-wrapper .instagram-slider-img .overlay svg {
  fill: var(--white-color);
}
.instagram-slider-section .instagram-slider-wrapper .instagram-slider-img:hover .overlay {
  opacity: 1;
  transform: scaleY(1);
}
.instagram-slider-section .instagram-slider-wrapper .instagram-slider {
  position: relative;
}
.instagram-slider-section .instagram-slider-wrapper .instagram-slider::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20%;
  height: 100%;
  background: linear-gradient(90deg, var(--primary-color2-opc) 0%, rgba(99, 171, 69, 0) 30.08%);
  opacity: 0.4;
  z-index: 9;
}
@media (max-width: 767px) {
  .instagram-slider-section .instagram-slider-wrapper .instagram-slider::before {
    display: none;
  }
}
.instagram-slider-section .instagram-slider-wrapper .instagram-slider::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 20%;
  height: 100%;
  background: linear-gradient(98deg, rgba(99, 171, 69, 0) 30.08%, var(--primary-color2-opc) 100%);
  opacity: 0.4;
  z-index: 9;
}
@media (max-width: 767px) {
  .instagram-slider-section .instagram-slider-wrapper .instagram-slider::after {
    display: none;
  }
}

/*=====================================
  40 .Home4 Banner section
========================================*/
.home4-banner-area {
  position: relative;
  background-image: url(../img/home4/home4-banner-bg.png), linear-gradient(180deg, #ece4d7 0%, #ece4d7 100%);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 25px;
}
@media (max-width: 1799px) {
  .home4-banner-area {
    padding: 20px 15px;
  }
}
@media (max-width: 991px) {
  .home4-banner-area {
    padding: 0px;
  }
}
.home4-banner-area .banner-wrapper {
  padding: 20px 20px 20px 40px;
  border: 1px dashed rgba(16, 12, 8, 0.5);
  position: relative;
}
@media (max-width: 1799px) {
  .home4-banner-area .banner-wrapper {
    padding: 20px 15px;
  }
}
@media (max-width: 1199px) {
  .home4-banner-area .banner-wrapper {
    padding: 90px 20px;
    border: none;
  }
}
.home4-banner-area .banner-wrapper .banner-content-wrap {
  padding-right: 20px;
}
@media (max-width: 1799px) {
  .home4-banner-area .banner-wrapper .banner-content-wrap {
    padding-right: 0;
  }
}
@media (max-width: 1199px) {
  .home4-banner-area .banner-wrapper .banner-content-wrap {
    width: 100%;
  }
}
.home4-banner-area .banner-wrapper .banner-content-wrap .mb-40 {
  margin-bottom: 40px;
}
@media (max-width: 1199px) {
  .home4-banner-area .banner-wrapper .banner-content-wrap .mb-40 {
    margin-bottom: 0;
  }
}
.home4-banner-area .banner-wrapper .banner-content-wrap .banner-content {
  max-width: 750px;
  width: 100%;
  padding-left: 30px;
  margin-bottom: 75px;
}
@media (max-width: 1199px) {
  .home4-banner-area .banner-wrapper .banner-content-wrap .banner-content {
    margin-bottom: 50px;
  }
}
@media (max-width: 767px) {
  .home4-banner-area .banner-wrapper .banner-content-wrap .banner-content {
    padding-left: 0;
  }
}
.home4-banner-area .banner-wrapper .banner-content-wrap .banner-content h1 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 55px;
  font-weight: 800;
  line-height: 1.2;
  margin-bottom: 20px;
}
@media (max-width: 991px) {
  .home4-banner-area .banner-wrapper .banner-content-wrap .banner-content h1 {
    font-size: 48px;
  }
}
@media (max-width: 767px) {
  .home4-banner-area .banner-wrapper .banner-content-wrap .banner-content h1 {
    font-size: 40px;
  }
}
@media (max-width: 576px) {
  .home4-banner-area .banner-wrapper .banner-content-wrap .banner-content h1 {
    font-size: 28px;
    margin-bottom: 15px;
  }
}
.home4-banner-area .banner-wrapper .banner-content-wrap .banner-content p {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.8;
  letter-spacing: 0.48px;
  text-transform: capitalize;
  margin-bottom: 0;
}
@media (max-width: 576px) {
  .home4-banner-area .banner-wrapper .banner-content-wrap .banner-content p {
    font-size: 15px;
  }
}
.home4-banner-area .banner-wrapper .banner-img {
  -webkit-clip-path: polygon(100% 0, 100% 70%, 70% 100%, 0 100%, 0 0);
          clip-path: polygon(100% 0, 100% 70%, 70% 100%, 0 100%, 0 0);
  overflow: hidden;
  position: relative;
}
@media (max-width: 1199px) {
  .home4-banner-area .banner-wrapper .banner-img {
    display: none;
  }
}
.home4-banner-area .banner-wrapper .banner-img img {
  min-height: 670px;
  -o-object-fit: cover;
     object-fit: cover;
}
.home4-banner-area .banner-wrapper .banner-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(16, 12, 8, 0.3);
}
.home4-banner-area .banner-wrapper .social-list {
  position: absolute;
  top: 50%;
  right: -12px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 45px;
  background-color: #ECE4D7;
  z-index: 1;
  padding: 50px 0 90px 0;
}
@media (max-width: 1199px) {
  .home4-banner-area .banner-wrapper .social-list {
    padding: 50px 0;
  }
}
@media (max-width: 991px) {
  .home4-banner-area .banner-wrapper .social-list {
    display: none;
  }
}
.home4-banner-area .banner-wrapper .social-list li {
  position: relative;
  line-height: 1;
  transition: 0.5s;
}
.home4-banner-area .banner-wrapper .social-list li::before {
  content: "";
  position: absolute;
  top: -22px;
  left: 50%;
  transform: translateX(-50%);
  background: var(--title-color);
  width: 3px;
  height: 3px;
  border-radius: 50%;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .home4-banner-area .banner-wrapper .social-list li::before {
    top: -15px;
  }
}
.home4-banner-area .banner-wrapper .social-list li:first-child::before {
  display: none;
}
.home4-banner-area .banner-wrapper .social-list li a i {
  color: var(--title-color);
  font-size: 22px;
  transition: 0.5s;
}
.home4-banner-area .banner-wrapper .social-list li a svg {
  fill: var(--title-color);
}
.home4-banner-area .banner-wrapper .social-list li:hover a i {
  color: var(--primary-color1);
}
.home4-banner-area .banner-wrapper .social-list li:hover a svg {
  fill: var(--primary-color1);
}
.home4-banner-area .banner-wrapper .airplane-icon {
  position: absolute;
  top: 19%;
  right: -10px;
  z-index: 1;
}
@media (max-width: 991px) {
  .home4-banner-area .banner-wrapper .airplane-icon {
    display: none;
  }
}
.home4-banner-area .banner-wrapper .airplane-icon svg {
  fill: var(--title-color);
}
.home4-banner-area .banner-wrapper .banner-slider-btn-area {
  padding: 10px;
  background-image: url(../img/home4/banner-slider-btn-bg.png);
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: end;
  justify-content: end;
  position: absolute;
  right: -1px;
  bottom: -10px;
  z-index: 99;
}
@media (max-width: 1199px) {
  .home4-banner-area .banner-wrapper .banner-slider-btn-area {
    display: none;
  }
}
.home4-banner-area .banner-wrapper .banner-slider-btn-area .banner-slider-btn-grp {
  display: flex;
  align-items: center;
  gap: 45px;
  position: relative;
}
.home4-banner-area .banner-wrapper .banner-slider-btn-area .banner-slider-btn-grp .slider-btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #152F00;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.35s;
}
.home4-banner-area .banner-wrapper .banner-slider-btn-area .banner-slider-btn-grp .slider-btn i {
  transition: 0.35s;
  font-size: 18px;
  color: #152F00;
}
.home4-banner-area .banner-wrapper .banner-slider-btn-area .banner-slider-btn-grp .slider-btn:hover {
  border-color: var(--primary-color1);
  background-color: var(--primary-color1);
}
.home4-banner-area .banner-wrapper .banner-slider-btn-area .banner-slider-btn-grp .slider-btn:hover i {
  color: var(--white-color);
}

/*=====================================
  41 .Home4 destination Card Slider section
========================================*/
.home4-destination-card-slider-section {
  position: relative;
}
.home4-destination-card-slider-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(../img/home4/vector/home4-section-bg.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 100%;
  z-index: -1;
}

/*=====================================
  42 .Home4 about section
========================================*/
.home4-about-section .about-content .section-title3 {
  max-width: unset;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .home4-about-section .about-content .section-title3 {
    margin-bottom: 20px;
  }
}
@media (max-width: 576px) {
  .home4-about-section .about-content .section-title3 h2 {
    font-size: 26px;
  }
}
.home4-about-section .about-content .section-title3 h2 span {
  color: var(--primary-color1);
}
.home4-about-section .about-content .section-title3 h2::after {
  display: none;
}
.home4-about-section .about-content .section-title3 p {
  padding-top: 25px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home4-about-section .about-content .section-title3 p {
    padding-top: 15px;
  }
}
@media (max-width: 767px) {
  .home4-about-section .about-content .section-title3 p {
    padding-top: 15px;
  }
}
.home4-about-section .about-content .author-area {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 30px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home4-about-section .about-content .author-area {
    gap: 20px;
  }
}
@media (max-width: 767px) {
  .home4-about-section .about-content .author-area {
    gap: 20px;
    margin-bottom: 20px;
  }
}
@media (max-width: 576px) {
  .home4-about-section .about-content .author-area {
    gap: 15px;
    flex-wrap: wrap;
  }
}
.home4-about-section .about-content .author-area .author-img img {
  min-width: 120px;
  max-width: 120px;
  height: 120px;
  border-radius: 50%;
}
.home4-about-section .about-content .author-area .author-content p {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 17px;
  font-weight: 400;
  line-height: 1.9; /* 205.882% */
  letter-spacing: 0.51px;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .home4-about-section .about-content .author-area .author-content p {
    font-size: 16px;
  }
}
@media (max-width: 576px) {
  .home4-about-section .about-content .author-area .author-content p {
    font-size: 15px;
  }
}
.home4-about-section .about-content .service-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 35px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home4-about-section .about-content .service-list {
    gap: 15px;
  }
}
@media (max-width: 767px) {
  .home4-about-section .about-content .service-list {
    margin-bottom: 25px;
  }
}
.home4-about-section .about-content .service-list li {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--title-color);
  font-family: var(--font-jost);
  font-size: 17px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 0.51px;
}
.home4-about-section .about-content .service-list li svg {
  fill: var(--primary-color1);
}
.home4-about-section .about-content .single-counter {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 17px 25px;
  border-radius: 10px;
  background: rgba(var(--primary-color1-opc), 0.1);
  -webkit-backdrop-filter: blur(32px);
          backdrop-filter: blur(32px);
  position: relative;
  overflow: hidden;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home4-about-section .about-content .single-counter {
    padding: 17px 20px;
  }
}
.home4-about-section .about-content .single-counter .star {
  position: absolute;
  top: 0;
  right: 0;
}
.home4-about-section .about-content .single-counter .star svg {
  fill: var(--primary-color2);
}
.home4-about-section .about-content .single-counter .star.two svg {
  fill: var(--title-color);
}
.home4-about-section .about-content .single-counter .icon svg {
  fill: var(--primary-color1);
}
.home4-about-section .about-content .single-counter .content .number {
  display: flex;
  align-items: center;
  font-family: var(--font-rubik);
  font-weight: 700;
  line-height: 1;
  color: var(--primary-color1);
  margin-bottom: 4px;
}
.home4-about-section .about-content .single-counter .content .number h5 {
  color: var(--primary-color1);
  font-size: 36px;
  margin-bottom: 0;
  line-height: 1;
}
@media (max-width: 576px) {
  .home4-about-section .about-content .single-counter .content .number h5 {
    font-size: 32px;
  }
}
.home4-about-section .about-content .single-counter .content .number span {
  font-size: 36px;
  display: inline-block;
  line-height: 1;
}
@media (max-width: 576px) {
  .home4-about-section .about-content .single-counter .content .number span {
    font-size: 32px;
  }
}
.home4-about-section .about-content .single-counter .content p {
  margin-bottom: 0;
  color: var(--title-color);
  line-height: 22px;
  font-size: 16px;
  font-weight: 500;
}
.home4-about-section .about-img-wrap {
  position: relative;
  width: 100%;
  height: 100%;
}
@media (max-width: 991px) {
  .home4-about-section .about-img-wrap {
    display: none;
  }
}
.home4-about-section .about-img-wrap .about-img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home4-about-section .about-img-wrap .about-img {
    height: 85%;
  }
}
.home4-about-section .about-img-wrap .shape {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--white-color);
  mix-blend-mode: screen;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home4-about-section .about-img-wrap .shape {
    height: 85%;
  }
}

/*=====================================
  43 .Home4 Banner2 section
========================================*/
.home4-banner2-area {
  padding: 0 90px;
  position: relative;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home4-banner2-area {
    padding: 0 30px;
  }
}
@media (max-width: 1399px) {
  .home4-banner2-area {
    padding: 0 30px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home4-banner2-area {
    padding: 0 15px;
  }
}
@media (max-width: 991px) {
  .home4-banner2-area {
    padding: 0;
  }
}
@media (max-width: 1199px) {
  .home4-banner2-area .slider-btn-grp2 {
    display: none;
  }
}
.home4-banner2-area .slider-btn-grp2 .slider-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home4-banner2-area .slider-btn-grp2 .slider-btn {
    left: 5px;
  }
}
@media (max-width: 1399px) {
  .home4-banner2-area .slider-btn-grp2 .slider-btn {
    left: 5px;
  }
}
.home4-banner2-area .slider-btn-grp2 .slider-btn.home4-banner2-slider-next {
  left: unset;
  right: 30px;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home4-banner2-area .slider-btn-grp2 .slider-btn.home4-banner2-slider-next {
    right: 5px;
  }
}
@media (max-width: 1399px) {
  .home4-banner2-area .slider-btn-grp2 .slider-btn.home4-banner2-slider-next {
    right: 5px;
  }
}
.home4-banner2-area .home4-banner2-wrapper {
  background-image: url(../img/home4/home4-banner2-bg.png), linear-gradient(180deg, #1d231f 0%, #1d231f 100%);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0 0 0 50px;
  border-radius: 10px;
  overflow: hidden;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .home4-banner2-area .home4-banner2-wrapper {
    padding: 0 0 0 30px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home4-banner2-area .home4-banner2-wrapper {
    padding: 0 0 0 20px;
  }
}
@media (max-width: 991px) {
  .home4-banner2-area .home4-banner2-wrapper {
    padding: 0;
  }
}
.home4-banner2-area .home4-banner2-wrapper .home4-banner2-content {
  text-align: center;
}
@media (max-width: 991px) {
  .home4-banner2-area .home4-banner2-wrapper .home4-banner2-content {
    padding: 50px 20px;
  }
}
.home4-banner2-area .home4-banner2-wrapper .home4-banner2-content span {
  color: var(--primary-color1);
  font-family: var(--font-satisfy);
  font-size: 25px;
  font-weight: 400;
  line-height: 1;
  display: inline-block;
  margin-bottom: 20px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home4-banner2-area .home4-banner2-wrapper .home4-banner2-content span {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .home4-banner2-area .home4-banner2-wrapper .home4-banner2-content span {
    margin-bottom: 10px;
  }
}
.home4-banner2-area .home4-banner2-wrapper .home4-banner2-content h2 {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 57px;
  font-weight: 700;
  line-height: 1.1;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home4-banner2-area .home4-banner2-wrapper .home4-banner2-content h2 {
    font-size: 50px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .home4-banner2-area .home4-banner2-wrapper .home4-banner2-content h2 {
    font-size: 42px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home4-banner2-area .home4-banner2-wrapper .home4-banner2-content h2 {
    font-size: 35px;
  }
}
@media (max-width: 767px) {
  .home4-banner2-area .home4-banner2-wrapper .home4-banner2-content h2 {
    font-size: 50px;
  }
}
@media (max-width: 576px) {
  .home4-banner2-area .home4-banner2-wrapper .home4-banner2-content h2 {
    font-size: 32px;
  }
}
.home4-banner2-area .home4-banner2-wrapper .home4-banner2-content h2 strong {
  color: var(--primary-color2);
  font-size: 45px;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home4-banner2-area .home4-banner2-wrapper .home4-banner2-content h2 strong {
    font-size: 40px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .home4-banner2-area .home4-banner2-wrapper .home4-banner2-content h2 strong {
    font-size: 38px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home4-banner2-area .home4-banner2-wrapper .home4-banner2-content h2 strong {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .home4-banner2-area .home4-banner2-wrapper .home4-banner2-content h2 strong {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .home4-banner2-area .home4-banner2-wrapper .home4-banner2-content h2 strong {
    font-size: 28px;
    font-weight: 600;
  }
}
.home4-banner2-area .home4-banner2-wrapper .home4-banner2-content p {
  color: var(--white-color);
  font-family: var(--font-jost);
  font-size: 18px;
  font-weight: 400;
  line-height: 1.7;
  letter-spacing: 0.54px;
  text-transform: capitalize;
  max-width: 420px;
  width: 100%;
  margin: 0 auto 35px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home4-banner2-area .home4-banner2-wrapper .home4-banner2-content p {
    font-size: 16px;
    margin: 0 auto 20px;
  }
}
@media (max-width: 767px) {
  .home4-banner2-area .home4-banner2-wrapper .home4-banner2-content p {
    margin: 0 auto 20px;
  }
}
@media (max-width: 576px) {
  .home4-banner2-area .home4-banner2-wrapper .home4-banner2-content p {
    font-size: 15px;
  }
}
.home4-banner2-area .home4-banner2-wrapper .home4-banner2-content .secondary-btn1 {
  background-color: var(--primary-color1);
  padding: 18px 33px;
  color: var(--white-color);
}
@media (max-width: 576px) {
  .home4-banner2-area .home4-banner2-wrapper .home4-banner2-content .secondary-btn1 {
    padding: 16px 30px;
  }
}
.home4-banner2-area .home4-banner2-wrapper .home4-banner2-content .secondary-btn1::after {
  background-color: var(--primary-color2);
}
.home4-banner2-area .home4-banner2-wrapper .home4-banner2-content .secondary-btn1:hover {
  color: var(--title-color);
}
.home4-banner2-area .home4-banner2-wrapper .home4-banner2-img img {
  width: 100%;
}
@media (max-width: 991px) {
  .home4-banner2-area .home4-banner2-wrapper .home4-banner2-img {
    display: none;
  }
}

/*=====================================
  44 .Home4 Tour Pack section
========================================*/
.view-btn {
  color: var(--primary-color1);
  font-family: var(--font-rubik);
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
  text-transform: capitalize;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: 0.5s;
}
.view-btn .arrow {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white-color);
  border: 1px solid rgba(var(--primary-color1-opc), 0.1);
  transition: 0.5s;
}
.view-btn .arrow svg {
  fill: none;
  stroke: var(--primary-color1);
  transition: 0.5s;
}
.view-btn:hover {
  color: var(--primary-color2);
}
.view-btn:hover .arrow {
  border-color: var(--primary-color2);
}
.view-btn:hover .arrow svg {
  transform: rotate(30deg);
  stroke: var(--primary-color2);
}

.home4-tour-pack-section {
  position: relative;
}
.home4-tour-pack-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(../img/home4/vector/home4-section-bg2.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 100%;
  z-index: -1;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home4-tour-pack-section .package-card3.style-4 .package-card-img {
    margin: 10px 10px 0 10px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home4-tour-pack-section .package-card3.style-4 .package-card-content .card-content-top {
    padding: 20px 10px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home4-tour-pack-section .package-card3.style-4 .package-card-content .card-content-bottom {
    padding: 15px;
  }
}

/*=====================================
  45 .Home4 Activities tab With Slider section
========================================*/
@media (min-width: 992px) and (max-width: 1199px) {
  .home4-tab-with-slider-section .title-and-nav .section-title3 p {
    font-size: 15px;
  }
}
.home4-tab-with-slider-section .title-and-nav .nav-pills {
  padding-top: 50px;
}
@media (max-width: 1199px) {
  .home4-tab-with-slider-section .title-and-nav .nav-pills {
    padding-top: 30px;
  }
}
.home4-tab-with-slider-section .title-and-nav .nav-pills .nav-item .nav-link {
  position: relative;
  border-radius: 5px;
  background: rgba(var(--primary-color1-opc), 0.1);
  padding: 30px 20px;
  text-align: start;
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
  transition: 0.5s;
  cursor: pointer;
}
.home4-tab-with-slider-section .title-and-nav .nav-pills .nav-item .nav-link .details-btn {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid var(--title-color);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  position: absolute;
  right: 10px;
  top: 10px;
  opacity: 0;
}
.home4-tab-with-slider-section .title-and-nav .nav-pills .nav-item .nav-link .details-btn svg {
  stroke: var(--title-color);
  transition: 0.5s;
}
.home4-tab-with-slider-section .title-and-nav .nav-pills .nav-item .nav-link .details-btn:hover {
  background-color: var(--white-color);
}
.home4-tab-with-slider-section .title-and-nav .nav-pills .nav-item .nav-link .details-btn:hover svg {
  transform: rotate(33deg);
  stroke: var(--title-color);
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home4-tab-with-slider-section .title-and-nav .nav-pills .nav-item .nav-link {
    padding: 25px 15px;
    gap: 12px;
  }
}
.home4-tab-with-slider-section .title-and-nav .nav-pills .nav-item .nav-link .icon svg {
  fill: var(--title-color);
}
.home4-tab-with-slider-section .title-and-nav .nav-pills .nav-item .nav-link .content h5 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home4-tab-with-slider-section .title-and-nav .nav-pills .nav-item .nav-link .content h5 {
    font-size: 17px;
  }
}
.home4-tab-with-slider-section .title-and-nav .nav-pills .nav-item .nav-link:hover .details-btn {
  opacity: 1;
}
.home4-tab-with-slider-section .title-and-nav .nav-pills .nav-item .nav-link.active {
  background-color: var(--primary-color1);
}
.home4-tab-with-slider-section .title-and-nav .nav-pills .nav-item .nav-link.active .icon svg {
  fill: var(--white-color);
}
.home4-tab-with-slider-section .title-and-nav .nav-pills .nav-item .nav-link.active .content h5 {
  color: var(--white-color);
}
.home4-tab-with-slider-section .title-and-nav .nav-pills .nav-item .nav-link.active .details-btn {
  border-color: var(--white-color);
}
.home4-tab-with-slider-section .title-and-nav .nav-pills .nav-item .nav-link.active .details-btn svg {
  stroke: var(--white-color);
}
.home4-tab-with-slider-section .title-and-nav .nav-pills .nav-item .nav-link.active .details-btn:hover {
  background-color: var(--white-color);
}
.home4-tab-with-slider-section .title-and-nav .nav-pills .nav-item .nav-link.active .details-btn:hover svg {
  transform: rotate(33deg);
  stroke: var(--title-color);
}
.home4-tab-with-slider-section .tab-with-slider-img-wrap {
  position: relative;
}
.home4-tab-with-slider-section .tab-with-slider-img-wrap .tab-with-slider-img {
  position: relative;
  border-radius: 5px;
}
.home4-tab-with-slider-section .tab-with-slider-img-wrap .tab-with-slider-img img {
  border-radius: 5px;
}
@media (max-width: 991px) {
  .home4-tab-with-slider-section .tab-with-slider-img-wrap .tab-with-slider-img img {
    width: 100%;
  }
}
.home4-tab-with-slider-section .tab-with-slider-img-wrap .tab-with-slider-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(16, 12, 8, 0.3);
  border-radius: 5px;
}
.home4-tab-with-slider-section .tab-with-slider-img-wrap .slider-btn-grp2 {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: var(--white-color);
  gap: 25px;
  padding: 15px;
  border-radius: 5px 0 0px 0;
  z-index: 1;
}
.home4-tab-with-slider-section .tab-with-slider-img-wrap .slider-btn-grp2 .slider-btn {
  width: 28px;
  height: 28px;
}

/*=====================================
  46 .Home4 Banner With Package-card Slider section
========================================*/
.home4-banner3-with-package-slider {
  overflow: hidden;
}
.home4-banner3-with-package-slider .home4-banner3-wrapper {
  background-image: url(../img/home4/home4-banner3-bg.png), linear-gradient(180deg, #1d231f 0%, #1d231f 100%);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
}
@media (max-width: 1199px) {
  .home4-banner3-with-package-slider .home4-banner3-wrapper .home4-banner3-img {
    display: none;
  }
}
.home4-banner3-with-package-slider .home4-banner3-wrapper .home4-banner3-content {
  text-align: center;
  padding: 100px 0;
}
@media (max-width: 1199px) {
  .home4-banner3-with-package-slider .home4-banner3-wrapper .home4-banner3-content {
    padding: 70px 0;
  }
}
@media (max-width: 767px) {
  .home4-banner3-with-package-slider .home4-banner3-wrapper .home4-banner3-content {
    padding: 60px 20px;
  }
}
.home4-banner3-with-package-slider .home4-banner3-wrapper .home4-banner3-content span {
  color: var(--primary-color1);
  font-family: var(--font-satisfy);
  font-size: 25px;
  font-weight: 400;
  line-height: 1;
  display: inline-block;
  margin-bottom: 15px;
}
@media (max-width: 1199px) {
  .home4-banner3-with-package-slider .home4-banner3-wrapper .home4-banner3-content span {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .home4-banner3-with-package-slider .home4-banner3-wrapper .home4-banner3-content span {
    margin-bottom: 10px;
  }
}
.home4-banner3-with-package-slider .home4-banner3-wrapper .home4-banner3-content h2 {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 45px;
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 30px;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .home4-banner3-with-package-slider .home4-banner3-wrapper .home4-banner3-content h2 {
    font-size: 42px;
  }
}
@media (max-width: 576px) {
  .home4-banner3-with-package-slider .home4-banner3-wrapper .home4-banner3-content h2 {
    font-size: 32px;
  }
}
.home4-banner3-with-package-slider .home4-banner3-wrapper .home4-banner3-content h2 strong {
  color: var(--primary-color2);
  font-size: 50px;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home4-banner3-with-package-slider .home4-banner3-wrapper .home4-banner3-content h2 strong {
    font-size: 40px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .home4-banner3-with-package-slider .home4-banner3-wrapper .home4-banner3-content h2 strong {
    font-size: 38px;
  }
}
@media (max-width: 1199px) {
  .home4-banner3-with-package-slider .home4-banner3-wrapper .home4-banner3-content h2 strong {
    font-size: 35px;
  }
}
.home4-banner3-with-package-slider .package-card-slider-wrapper {
  background-image: url(../img/home4/package-card-slider-bg.png), linear-gradient(180deg, #f4f9f1 0%, #f4f9f1 100%);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 75px 135px 75px 75px;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home4-banner3-with-package-slider .package-card-slider-wrapper {
    padding: 50px 40px 50px 30px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .home4-banner3-with-package-slider .package-card-slider-wrapper {
    padding: 50px 25px 50px 15px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home4-banner3-with-package-slider .package-card-slider-wrapper {
    padding: 50px 25px 50px 15px;
  }
}
@media (max-width: 991px) {
  .home4-banner3-with-package-slider .package-card-slider-wrapper {
    padding: 50px 40px;
  }
}
@media (max-width: 576px) {
  .home4-banner3-with-package-slider .package-card-slider-wrapper {
    padding: 30px 15px;
  }
}
@media (max-width: 767px) {
  .home4-banner3-with-package-slider .package-card-slider-wrapper .slider-btn-grp5 {
    display: none;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .home4-banner3-with-package-slider .package-card-slider-wrapper .package-card3.style-4 .package-card-img {
    margin: 15px 15px 0 15px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home4-banner3-with-package-slider .package-card-slider-wrapper .package-card3.style-4 .package-card-img {
    margin: 10px 10px 0 10px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .home4-banner3-with-package-slider .package-card-slider-wrapper .package-card3.style-4 .package-card-content .card-content-top {
    padding: 20px 10px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home4-banner3-with-package-slider .package-card-slider-wrapper .package-card3.style-4 .package-card-content .card-content-top {
    padding: 20px 10px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .home4-banner3-with-package-slider .package-card-slider-wrapper .package-card3.style-4 .package-card-content .card-content-bottom {
    padding: 15px 10px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home4-banner3-with-package-slider .package-card-slider-wrapper .package-card3.style-4 .package-card-content .card-content-bottom {
    padding: 15px 10px;
  }
}

/*=====================================
  47 .Home4 Feature-card section
========================================*/
.feature-card2 {
  padding: 35px 30px;
  border-radius: 5px;
  border: 1px solid rgba(16, 12, 8, 0.1);
  background-color: var(--white-color);
  transition: 0.5s;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .feature-card2 {
    padding: 30px 20px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .feature-card2 {
    padding: 25px 15px;
  }
}
@media (max-width: 991px) {
  .feature-card2 {
    padding: 25px 20px;
  }
}
.feature-card2 .feature-card-top {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 25px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .feature-card2 .feature-card-top {
    margin-bottom: 15px;
  }
}
@media (max-width: 991px) {
  .feature-card2 .feature-card-top {
    margin-bottom: 15px;
  }
}
.feature-card2 .feature-card-top .icon svg {
  fill: var(--primary-color1);
  transition: 0.5s;
}
.feature-card2 .feature-card-top .title h4 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 23px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.69px;
  text-transform: capitalize;
  margin-bottom: 0;
  max-width: 235px;
  width: 100%;
  transition: 0.5s;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .feature-card2 .feature-card-top .title h4 {
    font-size: 18px;
  }
}
@media (max-width: 991px) {
  .feature-card2 .feature-card-top .title h4 {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .feature-card2 .feature-card-top .title h4 {
    max-width: unset;
  }
}
.feature-card2 .feature-card-content p {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 15px;
  font-weight: 400;
  line-height: 1.9;
  letter-spacing: 0.45px;
  margin-bottom: 0;
  transition: 0.5s;
}
@media (max-width: 991px) {
  .feature-card2 .feature-card-content p {
    font-size: 14px;
  }
}
.feature-card2:hover {
  background-color: var(--primary-color1);
  border: 1px solid transparent;
}
.feature-card2:hover .feature-card-top .icon svg {
  fill: var(--white-color);
}
.feature-card2:hover .feature-card-top .title h4 {
  color: var(--white-color);
}
.feature-card2:hover .feature-card-content p {
  color: var(--white-color);
}

.home4-feature-card-section {
  position: relative;
}
.home4-feature-card-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(../img/home4/vector/home4-section-bg.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 100%;
  z-index: -1;
}

/*=====================================
  48 .Home4 Visa Application section
========================================*/
.package-card4 {
  display: flex;
  background-image: url(../img/home4/package-card4-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid #eee;
  border-radius: 5px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .package-card4 {
    flex-direction: column;
  }
}
@media (max-width: 576px) {
  .package-card4 {
    flex-direction: column;
  }
}
.package-card4 .package-card-img {
  overflow: hidden;
  min-width: 250px;
  max-width: 250px;
  display: block;
  border-radius: 5px 0 0 5px;
  transition: all 0.5s ease-out;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .package-card4 .package-card-img {
    min-width: 240px;
    max-width: 240px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .package-card4 .package-card-img {
    min-width: unset;
    max-width: unset;
  }
}
@media (max-width: 576px) {
  .package-card4 .package-card-img {
    min-width: unset;
    max-width: unset;
  }
}
.package-card4 .package-card-img img {
  min-width: 250px;
  max-width: 250px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 5px 0 0 5px;
  transition: all 0.5s ease-out;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .package-card4 .package-card-img img {
    min-width: 240px;
    max-width: 240px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .package-card4 .package-card-img img {
    min-width: unset;
    max-width: unset;
    min-height: 315px;
    max-height: 315px;
    width: 100%;
    border-radius: 5px 5px 0 0;
    -o-object-position: top;
       object-position: top;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .package-card4 .package-card-img img {
    height: 100%;
  }
}
@media (max-width: 576px) {
  .package-card4 .package-card-img img {
    min-width: unset;
    max-width: unset;
    min-height: 350px;
    max-height: 350px;
    -o-object-position: top;
       object-position: top;
    width: 100%;
    border-radius: 5px 5px 0 0;
  }
}
@media (max-width: 991px) {
  .package-card4 .package-card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
.package-card4 .package-card-content .card-content-top {
  padding: 30px 20px;
}
.package-card4 .package-card-content .card-content-top h5 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 22px;
  font-weight: 500;
  line-height: 1.3;
  text-transform: capitalize;
  margin-bottom: 25px;
}
@media (max-width: 1399px) {
  .package-card4 .package-card-content .card-content-top h5 {
    font-size: 20px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .package-card4 .package-card-content .card-content-top h5 {
    font-size: 24px;
    margin-bottom: 25px;
  }
}
@media (max-width: 767px) {
  .package-card4 .package-card-content .card-content-top h5 {
    font-size: 20px;
    margin-bottom: 20px;
  }
}
.package-card4 .package-card-content .card-content-top ul {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
}
@media (max-width: 767px) {
  .package-card4 .package-card-content .card-content-top ul {
    gap: 17px;
  }
}
.package-card4 .package-card-content .card-content-top ul li {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
  text-transform: capitalize;
}
@media (min-width: 768px) and (max-width: 991px) {
  .package-card4 .package-card-content .card-content-top ul li {
    font-size: 17px;
  }
}
.package-card4 .package-card-content .card-content-top ul li span {
  color: var(--title-color);
  font-weight: 500;
}
@media (min-width: 576px) and (max-width: 768px) {
  .package-card4 .package-card-content .card-content-top {
    padding: 20px 10px;
  }
}
@media (max-width: 576px) {
  .package-card4 .package-card-content .card-content-top {
    padding: 20px 15px;
  }
}
.package-card4 .package-card-content .card-content-bottom {
  padding: 15px 20px;
  border-top: 1px solid rgba(238, 238, 238, 0.8);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}
@media (min-width: 576px) and (max-width: 768px) {
  .package-card4 .package-card-content .card-content-bottom {
    padding: 15px 10px;
    gap: 5px;
  }
}
.package-card4 .package-card-content .card-content-bottom .price-area {
  line-height: 1;
}
.package-card4 .package-card-content .card-content-bottom .price-area span {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.26px;
  text-transform: capitalize;
  display: inline-block;
  margin-bottom: 5px;
}
.package-card4 .package-card-content .card-content-bottom .price-area h6 {
  color: var(--primary-color1);
  font-family: var(--font-rubik);
  font-size: 22px;
  font-weight: 800;
  line-height: 1;
  text-transform: capitalize;
  margin-bottom: 0;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .package-card4 .package-card-content .card-content-bottom .price-area h6 {
    font-size: 20px;
  }
}
@media (max-width: 576px) {
  .package-card4 .package-card-content .card-content-bottom .price-area h6 {
    font-size: 22px;
  }
}
.package-card4 .package-card-content .card-content-bottom .price-area h6 strong {
  font-size: 17px;
  font-weight: 600;
}
.package-card4 .package-card-content .card-content-bottom .price-area h6 span {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 11px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.22px;
  text-transform: capitalize;
}
.package-card4 .apply-btn {
  display: flex;
  align-items: center;
  background-color: var(--primary-color1);
  gap: 5px;
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  text-transform: capitalize;
  padding: 10px 20px;
  border-radius: 5px;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: all 0.5s ease-out 0s;
}
.package-card4 .apply-btn .arrow {
  min-width: 18px;
  max-width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid var(--white-color);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease-out 0s;
}
.package-card4 .apply-btn .arrow i {
  font-size: 11px;
  transform: rotate(-30deg);
  color: var(--white-color);
  transition: all 0.5s ease-out 0s;
}
.package-card4 .apply-btn::after {
  position: absolute;
  content: "";
  display: block;
  left: 15%;
  right: -20%;
  top: -4%;
  height: 150%;
  width: 150%;
  bottom: 0;
  border-radius: 2px;
  background-color: var(--primary-color2);
  transform: skewX(45deg) scale(0, 1);
  z-index: -1;
  transition: all 0.5s ease-out 0s;
}
.package-card4 .apply-btn:hover {
  color: var(--title-color);
}
.package-card4 .apply-btn:hover .arrow {
  border: 1px solid var(--title-color);
}
.package-card4 .apply-btn:hover .arrow i {
  transform: rotate(0deg);
  color: var(--title-color);
}
.package-card4 .apply-btn:hover::after {
  transform: skewX(45deg) scale(1, 1);
}
.package-card4:hover .package-card-img img {
  transform: scale(1.1);
}
.package-card4.two {
  background-image: url(../img/home4/package-card4-bg.png), linear-gradient(180deg, white 0%, white 100%);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
}
.package-card4.two .package-card-img {
  position: relative;
}
@media (max-width: 1399px) {
  .package-card4.two .package-card-img {
    height: 100%;
  }
}
.package-card4.two .package-card-img img {
  width: 100%;
}
@media (max-width: 1399px) {
  .package-card4.two .package-card-img img {
    height: 100%;
  }
}
.package-card4.two .package-card-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(16, 12, 8, 0.2);
}
.package-card4.two .package-card-img::after {
  position: absolute;
  width: 200%;
  height: 0%;
  left: 50%;
  top: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  transform: translate(-50%, -50%) rotate(-45deg);
  content: "";
  z-index: 1;
}
.package-card4.two .apply-btn {
  background-color: var(--primary-color2);
  color: var(--title-color);
  z-index: 9;
  transition: all 0.5s ease-out 0s;
}
.package-card4.two .apply-btn .arrow {
  border: 1px solid var(--title-color);
  transition: all 0.5s ease-out 0s;
}
.package-card4.two .apply-btn .arrow i {
  color: var(--title-color);
  transition: all 0.5s ease-out 0s;
}
.package-card4.two .apply-btn::after {
  position: absolute;
  content: "";
  background-color: var(--primary-color1);
  transform: skewX(45deg) scale(0, 1);
  z-index: -1;
  transition: all 0.5s ease-out 0s;
}
.package-card4.two .apply-btn:hover {
  color: var(--white-color);
}
.package-card4.two .apply-btn:hover .arrow {
  border: 1px solid var(--white-color);
}
.package-card4.two .apply-btn:hover .arrow i {
  transform: rotate(0deg);
  color: var(--white-color);
}
.package-card4.two .apply-btn:hover::after {
  transform: skewX(45deg) scale(1, 1);
}
.package-card4.two .package-card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .package-card4.two .package-card-content .card-content-top {
    padding: 20px 15px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .package-card4.two .package-card-content .card-content-top h5 {
    font-size: 20px;
    margin-bottom: 20px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .package-card4.two .package-card-content .card-content-top ul {
    gap: 18px;
  }
}
.package-card4.two:hover .package-card-img img {
  transform: none;
}
.package-card4.two:hover .package-card-img::after {
  height: 250%;
  transition: all 600ms linear;
  background-color: transparent;
}
.package-card4.three {
  display: block;
  background-image: unset;
  background-color: var(--white-color);
}
.package-card4.three .package-card-img {
  overflow: hidden;
  min-width: unset;
  max-width: unset;
}
.package-card4.three .package-card-img img {
  min-width: unset;
  max-width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 5px 5px 0 0px;
}
@media (max-width: 767px) {
  .package-card4.three .package-card-img img {
    width: 100%;
  }
}
.package-card4.three .package-card-content .card-content-top {
  padding: 25px;
}
@media (max-width: 1399px) {
  .package-card4.three .package-card-content .card-content-top {
    padding: 25px 20px;
  }
}
@media (max-width: 1199px) {
  .package-card4.three .package-card-content .card-content-top {
    padding: 20px 15px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .package-card4.three .package-card-content .card-content-top h5 {
    font-size: 18px;
  }
}
@media (max-width: 576px) {
  .package-card4.three .package-card-content .card-content-top h5 {
    font-size: 18px;
  }
}
.package-card4.three .package-card-content .card-content-top ul {
  display: block;
  -moz-columns: 2;
       columns: 2;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .package-card4.three .package-card-content .card-content-top ul {
    -moz-column-gap: 0;
         column-gap: 0;
  }
}
@media (max-width: 576px) {
  .package-card4.three .package-card-content .card-content-top ul {
    -moz-column-gap: 0;
         column-gap: 0;
  }
}
.package-card4.three .package-card-content .card-content-top ul li {
  margin-bottom: 25px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .package-card4.three .package-card-content .card-content-top ul li {
    margin-bottom: 20px;
    font-size: 14px;
  }
}
@media (max-width: 576px) {
  .package-card4.three .package-card-content .card-content-top ul li {
    margin-bottom: 20px;
    font-size: 14px;
  }
}
.package-card4.three .package-card-content .card-content-top ul li span {
  color: var(--title-color);
  font-weight: 500;
}
.package-card4.three .package-card-content .card-content-top ul li:nth-child(2), .package-card4.three .package-card-content .card-content-top ul li:last-child {
  margin-bottom: 0;
}
.package-card4.three .package-card-content .card-content-bottom {
  border-top: 1px solid rgba(16, 12, 8, 0.1);
}
.package-card4.three .package-card-content .card-content-bottom .apply-btn {
  padding: 11px 22px;
  border-radius: unset;
}
.package-card4.four .package-card-img {
  min-width: 230px;
  max-width: 230px;
}
@media (max-width: 576px) {
  .package-card4.four .package-card-img {
    min-width: unset;
    max-width: unset;
  }
}
.package-card4.four .package-card-img img {
  min-width: 230px;
  max-width: 230px;
  max-height: 260px;
}
@media (max-width: 991px) {
  .package-card4.four .package-card-img img {
    max-height: unset;
  }
}
@media (max-width: 576px) {
  .package-card4.four .package-card-img img {
    min-width: unset;
    max-width: unset;
    min-height: 350px;
    max-height: 350px;
    -o-object-position: center;
       object-position: center;
    width: 100%;
    border-radius: 5px 5px 0 0;
  }
}
.package-card4.four .package-card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.package-card4.four .package-card-content .card-content-top {
  padding: 30px 20px;
}
.package-card4.four .package-card-content .card-content-bottom {
  padding: 15px 20px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .package-card4.four {
    flex-direction: row;
  }
}

.home4-visa-application-section .package-card4-slider-wrapper {
  padding: 0 14%;
}
@media (max-width: 1799px) {
  .home4-visa-application-section .package-card4-slider-wrapper {
    padding: 0 10%;
  }
}
@media (max-width: 1699px) {
  .home4-visa-application-section .package-card4-slider-wrapper {
    padding: 0 8%;
  }
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home4-visa-application-section .package-card4-slider-wrapper {
    padding: 0 2%;
  }
}
@media (max-width: 1399px) {
  .home4-visa-application-section .package-card4-slider-wrapper {
    padding: 0;
  }
}
@media (max-width: 1199px) {
  .home4-visa-application-section .package-card4-slider-wrapper {
    padding: 0 20px;
  }
}
@media (max-width: 767px) {
  .home4-visa-application-section .package-card4-slider-wrapper {
    padding: 0 10px;
  }
}
@media (max-width: 576px) {
  .home4-visa-application-section .package-card4-slider-wrapper {
    padding: 0;
  }
}

/*=====================================
  49 .Home4 Testimonial section
========================================*/
.home4-testimonial-section {
  background-image: url(../img/home4/hom4-testiomial-bg.png), linear-gradient(180deg, #1d231f 0%, #1d231f 100%);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 90px 75px;
  position: relative;
  overflow: hidden;
}
@media (max-width: 1699px) {
  .home4-testimonial-section {
    padding: 90px 60px;
  }
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home4-testimonial-section {
    padding: 90px 30px;
  }
}
@media (max-width: 1399px) {
  .home4-testimonial-section {
    padding: 90px 20px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .home4-testimonial-section {
    padding: 70px 15px;
  }
}
@media (max-width: 767px) {
  .home4-testimonial-section {
    padding: 70px 20px;
  }
}
@media (max-width: 576px) {
  .home4-testimonial-section {
    padding: 70px 10px;
  }
}
.home4-testimonial-section .home4-testimonial-card-slider .swiper-slide {
  transition: 0.5s;
}
.home4-testimonial-section .home4-testimonial-card-slider .swiper-slide-next {
  margin-top: 30px;
}
@media (max-width: 1199px) {
  .home4-testimonial-section .home4-testimonial-card-slider .swiper-slide-next {
    margin-top: 0;
  }
}
.home4-testimonial-section .section-title3 h2 {
  color: var(--white-color);
}
.home4-testimonial-section .section-title3 h2::after {
  background-color: rgba(var(--primary-color2-opc), 0.15);
}
.home4-testimonial-section .section-title3 p {
  color: var(--white-color);
}
.home4-testimonial-section .testimonial-card-slider-area .slider-btn-grp5 {
  justify-content: space-between;
  position: relative;
  z-index: 9;
}
@media (max-width: 767px) {
  .home4-testimonial-section .testimonial-card-slider-area .slider-btn-grp5 {
    display: none;
  }
}
.home4-testimonial-section .testimonial-card-slider-area .slider-btn-grp5 .slider-btn {
  cursor: pointer;
  transition: 0.5s;
}
.home4-testimonial-section .testimonial-card-slider-area .slider-btn-grp5 .slider-btn svg {
  fill: none;
  stroke: var(--primary-color2);
  transition: 0.5s;
}
.home4-testimonial-section .testimonial-card-slider-area .slider-btn-grp5 .slider-btn:hover svg {
  stroke: var(--primary-color1);
}
.home4-testimonial-section .testimonial-card-slider-area .slider-btn-grp5 .slider-btn.swiper-button-disabled {
  opacity: 0.2;
}
.home4-testimonial-section .testimonial-card-slider-area .slider-btn-grp5 .slider-btn.swiper-button-disabled:hover svg {
  stroke: var(--primary-color2);
}
.home4-testimonial-section::before {
  content: url(../img/home4/vector/testi-vector1.svg);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
@media (max-width: 991px) {
  .home4-testimonial-section::before {
    display: none;
  }
}
.home4-testimonial-section::after {
  content: url(../img/home4/vector/testi-vector2.svg);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
@media (max-width: 991px) {
  .home4-testimonial-section::after {
    display: none;
  }
}

/*=====================================
  50 .Home4 Blog section
========================================*/
.home4-blog-section .pt-15 {
  padding-top: 15px;
}
@media (max-width: 991px) {
  .home4-blog-section .pt-15 {
    padding-top: 0;
  }
}
.home4-blog-section .pb-15 {
  padding-bottom: 15px;
}
@media (max-width: 991px) {
  .home4-blog-section .pb-15 {
    padding-bottom: 0;
  }
}
@media (max-width: 1199px) {
  .home4-blog-section .blog-card .blog-card-content {
    padding: 25px 15px;
  }
}
@media (max-width: 991px) {
  .home4-blog-section .blog-card .blog-card-content .blog-card-content-top ul {
    margin-bottom: 15px;
  }
}
@media (max-width: 1199px) {
  .home4-blog-section .blog-card .blog-card-content h5 {
    margin-bottom: 20px;
  }
}
@media (max-width: 1199px) {
  .home4-blog-section .blog-card .blog-card-content h5 a {
    font-size: 18px;
  }
}

/*=====================================
  51 .Home4 Activity Counter section
========================================*/
.home4-activity-counter-section {
  background-image: linear-gradient(180deg, rgba(16, 12, 8, 0.6) 0%, rgba(16, 12, 8, 0.6) 100%), url(../img/home4/activity-counter-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 90px 0;
}
@media (max-width: 991px) {
  .home4-activity-counter-section {
    padding: 70px 0;
  }
}

/*=====================================
  52 .Home5 Banner section
========================================*/
.home5-banner-area {
  background-image: url(../img/home5/home5-banner-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 70px 0 70px 0;
  position: relative;
  overflow: hidden;
  max-height: 790px;
  overflow: hidden;
}
@media (max-width: 1699px) {
  .home5-banner-area {
    padding: 70px 0 70px 0;
    max-height: 765px;
  }
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home5-banner-area {
    max-height: 685px;
  }
}
@media (max-width: 1399px) {
  .home5-banner-area {
    max-height: 690px;
  }
}
@media (max-width: 1199px) {
  .home5-banner-area {
    padding: 60px 2% 60px 2%;
  }
}
@media (max-width: 576px) {
  .home5-banner-area {
    padding: 40px 2% 40px 2%;
  }
}
.home5-banner-area .scroll-text {
  writing-mode: vertical-rl;
}
.home5-banner-area .scroll-text h2 {
  white-space: nowrap;
  font-family: var(--font-rubik);
  font-size: 60px;
  font-weight: 500;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  margin-bottom: 0;
  -webkit-text-fill-color: white;
  -webkit-text-stroke: 1px #5f5d5d;
  animation: marqueeTop 50s linear infinite;
}
@keyframes marqueeTop {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(0, -100%, 0);
  }
}
.home5-banner-area .banner-wrapper {
  position: relative;
  max-width: 1370px;
  width: 100%;
}
@media (max-width: 1699px) {
  .home5-banner-area .banner-wrapper {
    max-width: 1320px;
  }
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home5-banner-area .banner-wrapper {
    max-width: 1150px;
  }
}
@media (max-width: 1399px) {
  .home5-banner-area .banner-wrapper {
    max-width: 920px;
  }
}
@media (max-width: 1199px) {
  .home5-banner-area .banner-wrapper {
    max-width: 100%;
  }
}
.home5-banner-area .banner-wrapper img {
  max-width: 1370px;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 1699px) {
  .home5-banner-area .banner-wrapper img {
    max-width: 1320px;
  }
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home5-banner-area .banner-wrapper img {
    max-width: 1150px;
  }
}
@media (max-width: 1399px) {
  .home5-banner-area .banner-wrapper img {
    max-width: 920px;
    min-height: 550px;
  }
}
@media (max-width: 1199px) {
  .home5-banner-area .banner-wrapper img {
    max-width: 100%;
  }
}
.home5-banner-area .banner-wrapper .number-of-img {
  position: absolute;
  bottom: 15px;
  left: 20px;
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 5px;
  z-index: 1;
}
.home5-banner-area .banner-wrapper .number-of-img svg {
  fill: var(--white-color);
}
.home5-banner-area .banner-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(34, 34, 34, 0.3);
}
.home5-banner-area .banner-wrapper .banner-price-card {
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.44);
  background-image: url(../img/home5/banner-price-card-bg.png), linear-gradient(112deg, rgba(34, 34, 34, 0.5) 0%, rgba(34, 34, 34, 0.5) 100%);
  -webkit-backdrop-filter: blur(27.5px);
          backdrop-filter: blur(27.5px);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -220px;
  padding: 125px 40px 55px 40px;
  max-width: 445px;
  width: 100%;
  margin-right: 7.5%;
  z-index: 1;
}
@media (max-width: 1699px) {
  .home5-banner-area .banner-wrapper .banner-price-card {
    margin-right: 5%;
    right: -190px;
  }
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home5-banner-area .banner-wrapper .banner-price-card {
    right: -170px;
  }
}
@media (max-width: 1399px) {
  .home5-banner-area .banner-wrapper .banner-price-card {
    padding: 90px 30px 40px 30px;
    max-width: 390px;
  }
}
@media (max-width: 1199px) {
  .home5-banner-area .banner-wrapper .banner-price-card {
    right: 0;
    margin-right: 0;
  }
}
@media (max-width: 576px) {
  .home5-banner-area .banner-wrapper .banner-price-card {
    padding: 80px 15px 30px 15px;
    bottom: -100px;
    right: unset;
    top: unset;
    left: 50%;
    transform: translateX(-50%);
  }
}
.home5-banner-area .banner-wrapper .banner-price-card h4 {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 28px;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 25px;
}
@media (max-width: 576px) {
  .home5-banner-area .banner-wrapper .banner-price-card h4 {
    font-size: 22px;
    margin-bottom: 15px;
  }
}
.home5-banner-area .banner-wrapper .banner-price-card .rating-and-date {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 25px;
}
@media (max-width: 576px) {
  .home5-banner-area .banner-wrapper .banner-price-card .rating-and-date {
    margin-bottom: 15px;
  }
}
.home5-banner-area .banner-wrapper .banner-price-card .rating-and-date .rating-area {
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 1;
}
.home5-banner-area .banner-wrapper .banner-price-card .rating-and-date .rating-area .rating {
  display: flex;
  align-items: center;
  gap: 4px;
}
.home5-banner-area .banner-wrapper .banner-price-card .rating-and-date .rating-area .rating li {
  line-height: 0;
}
.home5-banner-area .banner-wrapper .banner-price-card .rating-and-date .rating-area .rating li i {
  color: #FFC200;
  font-size: 12px;
}
.home5-banner-area .banner-wrapper .banner-price-card .rating-and-date .rating-area span {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 13px;
  font-weight: 400;
  line-height: 1;
}
.home5-banner-area .banner-wrapper .banner-price-card .rating-and-date .date {
  display: flex;
  align-items: center;
  gap: 5px;
}
.home5-banner-area .banner-wrapper .banner-price-card .rating-and-date .date svg {
  fill: var(--white-color);
}
.home5-banner-area .banner-wrapper .banner-price-card .rating-and-date .date span {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  text-transform: capitalize;
}
.home5-banner-area .banner-wrapper .banner-price-card > ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
  flex-wrap: wrap;
  padding: 25px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin-bottom: 15px;
}
@media (max-width: 576px) {
  .home5-banner-area .banner-wrapper .banner-price-card > ul {
    padding: 15px 0;
    gap: 15px;
  }
}
.home5-banner-area .banner-wrapper .banner-price-card > ul li {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
  text-transform: capitalize;
}
.home5-banner-area .banner-wrapper .banner-price-card > ul li svg {
  fill: var(--white-color);
}
@media (max-width: 576px) {
  .home5-banner-area .banner-wrapper .banner-price-card > ul li {
    font-size: 13px;
  }
}
.home5-banner-area .banner-wrapper .banner-price-card .banner-price-card-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}
.home5-banner-area .banner-wrapper .banner-price-card .banner-price-card-bottom .price-area span {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.24px;
  text-transform: capitalize;
}
.home5-banner-area .banner-wrapper .banner-price-card .banner-price-card-bottom .price-area h4 {
  color: var(--primary-color1);
  font-family: var(--font-rubik);
  font-size: 26px;
  font-weight: 500;
  line-height: 22px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.home5-banner-area .banner-wrapper .banner-price-card .banner-price-card-bottom .price-area h4 del {
  color: var(--white-color);
  font-size: 15px;
  font-weight: 400;
  text-transform: capitalize;
}
.home5-banner-area .banner-wrapper .banner-price-card .banner-price-card-bottom .price-area h4 span {
  color: var(--white-color);
  font-size: 16px;
}
@media (max-width: 576px) {
  .home5-banner-area .banner-wrapper .banner-price-card .banner-price-card-bottom .price-area h4 {
    font-size: 22px;
  }
  .home5-banner-area .banner-wrapper .banner-price-card .banner-price-card-bottom .price-area h4 del {
    font-size: 13px;
  }
}
.home5-banner-area .banner-wrapper .banner-price-card .banner-price-card-bottom .price-area p {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 10px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.2px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.home5-banner-area .banner-wrapper .banner-price-card .batch {
  position: absolute;
  top: 15px;
  left: 0;
  z-index: 1;
}
@media (max-width: 576px) {
  .home5-banner-area .banner-wrapper .banner-price-card .batch {
    top: 0;
  }
}
.home5-banner-area .banner-wrapper .banner-price-card .batch > span {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  padding: 6px 8px;
  background-color: var(--title-color);
}
.home5-banner-area .banner-wrapper .banner-price-card .batch .packcage-name {
  border: 1px solid rgba(21, 47, 0, 0.6);
  background: rgba(255, 255, 255, 0.5);
  padding: 6px 10px;
  display: flex;
  align-items: center;
  gap: 7px;
}
.home5-banner-area .banner-wrapper .banner-price-card .batch .packcage-name svg {
  fill: var(--primary-color1);
}
.home5-banner-area .banner-wrapper .banner-price-card .batch .packcage-name span {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 13px;
  font-weight: 400;
  line-height: 1;
  text-transform: capitalize;
}
.home5-banner-area .slider-btn-grp {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 70px;
  display: flex;
  flex-direction: column;
  gap: 35px;
  z-index: 1;
}
@media (max-width: 1799px) {
  .home5-banner-area .slider-btn-grp {
    right: 25px;
  }
}
@media (max-width: 1699px) {
  .home5-banner-area .slider-btn-grp {
    right: 20px;
  }
}
@media (max-width: 1499px) {
  .home5-banner-area .slider-btn-grp {
    right: 5px;
  }
}
@media (max-width: 1399px) {
  .home5-banner-area .slider-btn-grp {
    right: 15px;
  }
}
@media (max-width: 1199px) {
  .home5-banner-area .slider-btn-grp {
    display: none;
  }
}
.home5-banner-area .slider-btn-grp .slider-btn {
  border: 1px solid #222;
}
.home5-banner-area .slider-btn-grp .slider-btn i {
  color: #222;
  font-size: 18px;
}
.home5-banner-area .slider-btn-grp .slider-btn:hover {
  background-color: var(--primary-color1);
  border: 1px solid var(--primary-color1);
}
.home5-banner-area .slider-btn-grp .slider-btn:hover i {
  color: var(--white-color);
}
@media (max-width: 576px) {
  .home5-banner-area .home5-banner-slider {
    padding-bottom: 100px;
  }
}

/*=====================================
  53 .Home5 Activity section
========================================*/
.activity-card {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}
.activity-card img {
  border-radius: 5px;
  transition: all 0.5s ease-out;
}
@media (max-width: 991px) {
  .activity-card img {
    width: 100%;
  }
}
.activity-card .country-name {
  line-height: 1;
  background-color: var(--white-color);
  padding: 6px 10px;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  position: absolute;
  left: 0;
  top: 15px;
  z-index: 2;
}
.activity-card .country-name img {
  height: 15px;
  width: 20px;
  border-radius: 0;
}
.activity-card .activity-card-content-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(16, 12, 8, 0.3);
  border-radius: 5px;
  padding: 15px;
  display: flex;
  align-items: end;
}
.activity-card .activity-card-content-wrapper .activity-card-content {
  background-color: var(--white-color);
  padding: 20px 25px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
  transition: 0.5s;
}
.activity-card .activity-card-content-wrapper .activity-card-content .icon svg {
  fill: var(--title-color);
  transition: 0.5s;
}
.activity-card .activity-card-content-wrapper .activity-card-content .content {
  padding-left: 15px;
  border-left: 1px solid rgba(16, 12, 8, 0.1);
  transition: 0.5s;
}
.activity-card .activity-card-content-wrapper .activity-card-content .content h6 {
  margin-bottom: 0;
}
.activity-card .activity-card-content-wrapper .activity-card-content .content h6 a {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  text-transform: capitalize;
  transition: 0.5s;
}
.activity-card:hover img {
  transform: scale(1.1);
}
.activity-card:hover .activity-card-content-wrapper .activity-card-content {
  background-color: var(--primary-color1);
}
.activity-card:hover .activity-card-content-wrapper .activity-card-content .icon svg {
  fill: var(--white-color);
}
.activity-card:hover .activity-card-content-wrapper .activity-card-content .content {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}
.activity-card:hover .activity-card-content-wrapper .activity-card-content .content h6 a {
  color: var(--white-color);
}

.home5-activity-card-slider-section .activity-card-slider-wrap {
  position: relative;
}
.home5-activity-card-slider-section .activity-card-slider-wrap .slider-btn-grp2 {
  display: block;
}
@media (max-width: 1199px) {
  .home5-activity-card-slider-section .activity-card-slider-wrap .slider-btn-grp2 {
    display: none;
  }
}
.home5-activity-card-slider-section .activity-card-slider-wrap .slider-btn-grp2 .slider-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -10%;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home5-activity-card-slider-section .activity-card-slider-wrap .slider-btn-grp2 .slider-btn {
    left: -45px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .home5-activity-card-slider-section .activity-card-slider-wrap .slider-btn-grp2 .slider-btn {
    left: -38px;
  }
}
.home5-activity-card-slider-section .activity-card-slider-wrap .slider-btn-grp2 .activity-card-slider-next {
  right: -10%;
  left: unset;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home5-activity-card-slider-section .activity-card-slider-wrap .slider-btn-grp2 .activity-card-slider-next {
    right: -45px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .home5-activity-card-slider-section .activity-card-slider-wrap .slider-btn-grp2 .activity-card-slider-next {
    right: -38px;
  }
}

/*=====================================
  54 .Home5 About section
========================================*/
.home5-about-section {
  margin-bottom: 180px;
}
@media (max-width: 1199px) {
  .home5-about-section {
    margin-bottom: 100px;
  }
}
@media (max-width: 991px) {
  .home5-about-section {
    margin-bottom: 90px;
  }
}
@media (max-width: 767px) {
  .home5-about-section {
    margin-bottom: 70px;
  }
}
.home5-about-section .about-img-wrap {
  background-image: url(../img/home5/about-img-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 991px) {
  .home5-about-section .about-img-wrap {
    display: none;
  }
}
.home5-about-section .about-img-wrap .about-top-img {
  margin-top: -90px;
  position: relative;
}
@media (max-width: 1199px) {
  .home5-about-section .about-img-wrap .about-top-img {
    margin-top: 0;
  }
}
.home5-about-section .about-img-wrap .about-top-img img {
  border-radius: 10px;
}
@media (max-width: 1199px) {
  .home5-about-section .about-img-wrap .about-top-img img {
    width: 100%;
  }
}
.home5-about-section .about-img-wrap .about-top-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(16, 12, 8, 0.2);
  border-radius: 10px;
}
.home5-about-section .about-img-wrap .about-bottom-img {
  min-width: 376px;
  margin-left: -190px;
  margin-bottom: -130px;
  position: relative;
}
.home5-about-section .about-img-wrap .about-bottom-img img {
  border-radius: 10px;
}
.home5-about-section .about-img-wrap .about-bottom-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(16, 12, 8, 0.2);
  border-radius: 10px;
}
@media (max-width: 1199px) {
  .home5-about-section .about-img-wrap .about-bottom-img {
    display: none;
  }
}
@media (max-width: 1399px) {
  .home5-about-section .about-content .section-title4 h2 {
    font-size: 40px;
  }
}
@media (max-width: 1199px) {
  .home5-about-section .about-content .section-title4 h2 {
    font-size: 35px;
  }
}
@media (max-width: 576px) {
  .home5-about-section .about-content .section-title4 h2 {
    font-size: 25px;
  }
}
.home5-about-section .about-content .facility-card {
  background-color: rgba(var(--primary-color1-opc), 0.1);
  border-radius: 10px;
  display: flex;
  align-items: center;
  transform: rotate(-3deg);
}
@media (max-width: 576px) {
  .home5-about-section .about-content .facility-card {
    transform: rotate(0deg);
  }
}
.home5-about-section .about-content .facility-card .icon {
  padding: 15px 10px;
  background-color: rgba(var(--primary-color1-opc), 0.3);
  border-radius: 5px 0px 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home5-about-section .about-content .facility-card .icon {
    padding: 20px 10px;
  }
}
.home5-about-section .about-content .facility-card .icon svg {
  fill: var(--primary-color1);
}
.home5-about-section .about-content .facility-card .content {
  padding: 20px 15px;
}
@media (max-width: 1399px) {
  .home5-about-section .about-content .facility-card .content {
    padding: 20px 12px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home5-about-section .about-content .facility-card .content {
    padding: 12px;
  }
}
.home5-about-section .about-content .facility-card .content h6 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.34px;
  text-transform: capitalize;
  margin-bottom: 0;
  transition: 0.5s;
}
@media (min-width: 576px) and (max-width: 768px) {
  .home5-about-section .about-content .facility-card .content h6 {
    font-size: 15px;
  }
}
.home5-about-section .about-content .facility-card.two {
  transform: rotate(3deg);
}
@media (max-width: 576px) {
  .home5-about-section .about-content .facility-card.two {
    transform: rotate(0deg);
  }
}
.home5-about-section .about-content .content-bottom-area {
  display: flex;
  align-items: center;
  gap: 40px;
}
@media (max-width: 576px) {
  .home5-about-section .about-content .content-bottom-area {
    flex-wrap: wrap;
    gap: 20px;
  }
}
.home5-about-section .about-content .content-bottom-area .hotline-area {
  display: flex;
  align-items: center;
  gap: 10px;
}
.home5-about-section .about-content .content-bottom-area .hotline-area .icon {
  width: 47px;
  height: 47px;
  border-radius: 50%;
  border: 1px solid var(--primary-color1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.home5-about-section .about-content .content-bottom-area .hotline-area .icon svg {
  fill: var(--primary-color1);
}
.home5-about-section .about-content .content-bottom-area .hotline-area .content {
  line-height: 1;
}
.home5-about-section .about-content .content-bottom-area .hotline-area .content span {
  font-family: var(--font-rubik);
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: var(--title-color);
  margin-bottom: 5px;
  display: inline-block;
}
.home5-about-section .about-content .content-bottom-area .hotline-area .content h6 {
  margin-bottom: 0;
  line-height: 1;
}
.home5-about-section .about-content .content-bottom-area .hotline-area .content h6 a {
  font-family: var(--font-rubik);
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: var(--primary-color1);
  transition: 0.5s;
}
.home5-about-section .about-content .content-bottom-area .hotline-area .content h6 a:hover {
  color: var(--primary-color2);
}

/*=====================================
  55 .Home5 Destination Card Slider section
========================================*/
.destination-card3 {
  border-radius: 10px;
  position: relative;
}
.destination-card3 .destination-card-img {
  position: relative;
  display: block;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  transition: all 0.5s ease-out;
}
.destination-card3 .destination-card-img img {
  border-radius: 10px 10px 0 0;
  transition: all 0.5s ease-out;
}
.destination-card3 .destination-card-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(16, 12, 8, 0.2);
  border-radius: 10px 10px 0 0;
  z-index: 1;
}
.destination-card3 .destination-card-content {
  border: 1px solid #edeaea;
  border-top: unset;
  background: #FCF7F2;
  text-align: center;
  padding: 20px;
  border-radius: 0 0 10px 10px;
  transition: 0.5s;
}
.destination-card3 .destination-card-content h4 {
  line-height: 1;
  margin-bottom: 0;
}
.destination-card3 .destination-card-content h4 a {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 24px;
  font-weight: 500;
  text-transform: capitalize;
  transition: 0.5s;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .destination-card3 .destination-card-content h4 a {
    font-size: 22px;
  }
}
@media (max-width: 576px) {
  .destination-card3 .destination-card-content h4 a {
    font-size: 22px;
  }
}
.destination-card3 .batch {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top;
  line-height: 1;
  transition: 0.5s;
  z-index: 2;
}
.destination-card3 .batch span {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  text-transform: capitalize;
  display: inline-block;
  padding: 7px 16px;
  border-radius: 10px 0 10px 0;
  background-color: var(--primary-color1);
}
.destination-card3:hover .destination-card-img img {
  transform: scale(1.1);
}
.destination-card3:hover .batch {
  opacity: 1;
  transform: scaleY(1);
}
.destination-card3:hover .destination-card-content {
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
}
.destination-card3:hover .destination-card-content h4 a {
  color: var(--white-color);
}
.destination-card3.location-card {
  max-width: 250px;
  min-width: 250px;
  box-shadow: 0px 25px 25px rgba(0, 0, 0, 0.1);
}
@media (max-width: 576px) {
  .destination-card3.location-card {
    max-width: 150px;
    min-width: 150px;
    border-radius: 5px;
  }
}
.destination-card3.location-card .batch {
  opacity: 1;
  transform: scaleY(1);
}
.destination-card3.location-card .destination-card-content {
  background-color: var(--white-color);
  border: unset;
  line-height: 1;
  padding: 15px;
}
@media (max-width: 767px) {
  .destination-card3.location-card .destination-card-content {
    border-radius: 10px;
  }
}
@media (max-width: 576px) {
  .destination-card3.location-card .destination-card-content {
    border-radius: 5px;
  }
}
.destination-card3.location-card .destination-card-content h4 a {
  font-size: 22px;
}
@media (max-width: 576px) {
  .destination-card3.location-card .destination-card-content h4 a {
    font-size: 18px;
  }
}
.destination-card3.location-card .destination-card-content span {
  color: var(--primary-color1);
  font-family: var(--font-rubik);
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  padding-top: 10px;
  display: none;
}
@media (max-width: 767px) {
  .destination-card3.location-card .destination-card-content span {
    display: block;
  }
}
@media (max-width: 576px) {
  .destination-card3.location-card .destination-card-content span {
    font-size: 13px;
    padding-top: 7px;
  }
}
.destination-card3.location-card:hover .destination-card-img img {
  transform: scale(1.1);
}
.destination-card3.location-card:hover .batch {
  opacity: 1;
  transform: scaleY(1);
}
.destination-card3.location-card:hover .destination-card-content {
  background-color: var(--white-color);
}
.destination-card3.location-card:hover .destination-card-content h4 a {
  color: var(--title-color);
}

.home5-destination-card-slider-section {
  padding: 0 7%;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home5-destination-card-slider-section {
    padding: 0 2%;
  }
}
@media (max-width: 1399px) {
  .home5-destination-card-slider-section {
    padding: 0 2%;
  }
}
@media (max-width: 767px) {
  .home5-destination-card-slider-section {
    padding: 0 10px;
  }
}
@media (max-width: 576px) {
  .home5-destination-card-slider-section {
    padding: 0;
  }
}
.home5-destination-card-slider-section .destination-card-slider-wrap {
  background-image: url(../img/home5/destination-card-slider-bg.png), linear-gradient(180deg, #fff9f3 0%, #fff9f3 100%);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 90px 10.5%;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home5-destination-card-slider-section .destination-card-slider-wrap {
    padding: 90px 8%;
  }
}
@media (max-width: 1399px) {
  .home5-destination-card-slider-section .destination-card-slider-wrap {
    padding: 90px 4%;
  }
}
@media (max-width: 1199px) {
  .home5-destination-card-slider-section .destination-card-slider-wrap {
    padding: 70px 3%;
  }
}
@media (max-width: 767px) {
  .home5-destination-card-slider-section .destination-card-slider-wrap {
    padding: 60px 3%;
  }
}
@media (max-width: 576px) {
  .home5-destination-card-slider-section .destination-card-slider-wrap {
    padding: 50px 20px;
  }
}
.home5-destination-card-slider-section .destination-card-slider-wrap .destination-card-with-slider {
  position: relative;
}
.home5-destination-card-slider-section .destination-card-slider-wrap .destination-card-with-slider .destination-card3-slider {
  padding: 0 10px;
  margin: 0 -10px;
}
.home5-destination-card-slider-section .destination-card-slider-wrap .destination-card-with-slider .slider-btn-grp2 {
  display: block;
}
@media (max-width: 767px) {
  .home5-destination-card-slider-section .destination-card-slider-wrap .destination-card-with-slider .slider-btn-grp2 {
    display: none;
  }
}
.home5-destination-card-slider-section .destination-card-slider-wrap .destination-card-with-slider .slider-btn-grp2 .slider-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -14.5%;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home5-destination-card-slider-section .destination-card-slider-wrap .destination-card-with-slider .slider-btn-grp2 .slider-btn {
    left: -11%;
  }
}
@media (max-width: 1399px) {
  .home5-destination-card-slider-section .destination-card-slider-wrap .destination-card-with-slider .slider-btn-grp2 .slider-btn {
    left: -6%;
  }
}
@media (max-width: 1199px) {
  .home5-destination-card-slider-section .destination-card-slider-wrap .destination-card-with-slider .slider-btn-grp2 .slider-btn {
    left: -5.5%;
  }
}
.home5-destination-card-slider-section .destination-card-slider-wrap .destination-card-with-slider .slider-btn-grp2 .destination-card3-slider-next {
  right: -14.5%;
  left: unset;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home5-destination-card-slider-section .destination-card-slider-wrap .destination-card-with-slider .slider-btn-grp2 .destination-card3-slider-next {
    right: -11%;
  }
}
@media (max-width: 1399px) {
  .home5-destination-card-slider-section .destination-card-slider-wrap .destination-card-with-slider .slider-btn-grp2 .destination-card3-slider-next {
    right: -6%;
  }
}
@media (max-width: 1199px) {
  .home5-destination-card-slider-section .destination-card-slider-wrap .destination-card-with-slider .slider-btn-grp2 .destination-card3-slider-next {
    right: -5.5%;
  }
}

/*=====================================
  56 .Home5 Feature section
========================================*/
@media (max-width: 1399px) {
  .home5-feature-section .feature-content .section-title4 h2 {
    font-size: 40px;
  }
}
@media (max-width: 1199px) {
  .home5-feature-section .feature-content .section-title4 h2 {
    font-size: 35px;
  }
}
@media (max-width: 576px) {
  .home5-feature-section .feature-content .section-title4 h2 {
    font-size: 25px;
  }
}
.home5-feature-section .feature-content .feature-card3 {
  border-radius: 5px;
  background: rgba(var(--primary-color1-opc), 0.1);
  padding: 25px 20px;
  text-align: start;
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
  transition: 0.5s;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home5-feature-section .feature-content .feature-card3 {
    padding: 25px 15px;
    gap: 12px;
  }
}
.home5-feature-section .feature-content .feature-card3 .icon svg {
  fill: var(--title-color);
  transition: 0.5s;
}
.home5-feature-section .feature-content .feature-card3 .content h5 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 0;
  transition: 0.5s;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home5-feature-section .feature-content .feature-card3 .content h5 {
    font-size: 17px;
  }
}
.home5-feature-section .feature-content .feature-card3:hover {
  background-color: var(--primary-color1);
}
.home5-feature-section .feature-content .feature-card3:hover .icon svg {
  fill: var(--white-color);
}
.home5-feature-section .feature-content .feature-card3:hover .content h5 {
  color: var(--white-color);
}
.home5-feature-section .feature-img-wrap {
  position: relative;
  width: 100%;
  padding-left: 110px;
}
@media (max-width: 991px) {
  .home5-feature-section .feature-img-wrap {
    display: none;
  }
}
.home5-feature-section .feature-img-wrap .feature-top-img {
  position: relative;
  max-width: 520px;
  width: 100%;
  border-radius: 340px;
}
.home5-feature-section .feature-img-wrap .feature-top-img img {
  border-radius: 340px;
}
.home5-feature-section .feature-img-wrap .feature-top-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(16, 12, 8, 0.3);
  border-radius: 340px;
}
.home5-feature-section .feature-img-wrap .feature-bottom-img {
  position: absolute;
  max-width: 368px;
  width: 100%;
  border-radius: 300px;
  bottom: 0;
  left: 0;
  border: 9px solid var(--white-color);
}
.home5-feature-section .feature-img-wrap .feature-bottom-img img {
  max-width: 368px;
  border-radius: 300px;
  width: 100%;
}
.home5-feature-section .feature-img-wrap .feature-bottom-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(16, 12, 8, 0.2);
  border-radius: 300px;
}

/*=====================================
  57 .Home5 Banner2 SLider section
========================================*/
.home5-banner2-section {
  padding: 0 7%;
}
@media (max-width: 1699px) {
  .home5-banner2-section {
    padding: 0 4%;
  }
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home5-banner2-section {
    padding: 0 2%;
  }
}
@media (max-width: 1399px) {
  .home5-banner2-section {
    padding: 0 2%;
  }
}
@media (max-width: 991px) {
  .home5-banner2-section {
    padding: 0 10px;
  }
}
@media (max-width: 576px) {
  .home5-banner2-section {
    padding: 0;
  }
}
.home5-banner2-section .swiper-slide-active span {
  animation: fadeInDown 1.7s;
}
.home5-banner2-section .swiper-slide-active h2 {
  animation: fadeInDown 1.7s;
}
.home5-banner2-section .swiper-slide-active p {
  animation: fadeInUp 1.7s;
}
.home5-banner2-section .swiper-slide-active a {
  animation: fadeInUp 1.7s;
}
.home5-banner2-section .home5-banner2-wrapper {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 10px;
  padding: 160px 0 160px 80px;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home5-banner2-section .home5-banner2-wrapper {
    padding: 150px 0 150px 50px;
  }
}
@media (max-width: 1399px) {
  .home5-banner2-section .home5-banner2-wrapper {
    padding: 150px 0 150px 40px;
  }
}
@media (max-width: 1199px) {
  .home5-banner2-section .home5-banner2-wrapper {
    padding: 100px 0 100px 30px;
  }
}
@media (max-width: 991px) {
  .home5-banner2-section .home5-banner2-wrapper {
    background-position: 70%;
    padding: 90px 0 90px 20px;
  }
}
@media (max-width: 767px) {
  .home5-banner2-section .home5-banner2-wrapper {
    padding: 70px 20px 70px 20px;
    background-image: unset !important;
    background-color: #F9F4F0;
  }
}
.home5-banner2-section .home5-banner2-wrapper .home5-banner2-content {
  text-align: center;
  max-width: 520px;
  width: 100%;
}
@media (max-width: 1399px) {
  .home5-banner2-section .home5-banner2-wrapper .home5-banner2-content {
    max-width: 380px;
  }
}
@media (max-width: 1199px) {
  .home5-banner2-section .home5-banner2-wrapper .home5-banner2-content {
    max-width: 340px;
  }
}
@media (max-width: 991px) {
  .home5-banner2-section .home5-banner2-wrapper .home5-banner2-content {
    max-width: 300px;
  }
}
@media (max-width: 767px) {
  .home5-banner2-section .home5-banner2-wrapper .home5-banner2-content {
    margin: 0 auto;
    max-width: 500px;
  }
}
.home5-banner2-section .home5-banner2-wrapper .home5-banner2-content > span {
  color: var(--primary-color1);
  font-family: var(--font-satisfy);
  font-size: 25px;
  font-weight: 400;
  line-height: 1;
  display: inline-block;
  margin-bottom: 15px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home5-banner2-section .home5-banner2-wrapper .home5-banner2-content > span {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .home5-banner2-section .home5-banner2-wrapper .home5-banner2-content > span {
    margin-bottom: 10px;
  }
}
.home5-banner2-section .home5-banner2-wrapper .home5-banner2-content h2 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 57px;
  font-weight: 700;
  line-height: 1.1;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home5-banner2-section .home5-banner2-wrapper .home5-banner2-content h2 {
    font-size: 50px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .home5-banner2-section .home5-banner2-wrapper .home5-banner2-content h2 {
    font-size: 42px;
  }
}
@media (max-width: 1199px) {
  .home5-banner2-section .home5-banner2-wrapper .home5-banner2-content h2 {
    font-size: 35px;
  }
}
@media (max-width: 991px) {
  .home5-banner2-section .home5-banner2-wrapper .home5-banner2-content h2 {
    font-size: 32px;
  }
}
@media (max-width: 576px) {
  .home5-banner2-section .home5-banner2-wrapper .home5-banner2-content h2 {
    font-size: 30px;
  }
}
.home5-banner2-section .home5-banner2-wrapper .home5-banner2-content h2 strong {
  color: var(--primary-color2);
  font-size: 45px;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home5-banner2-section .home5-banner2-wrapper .home5-banner2-content h2 strong {
    font-size: 40px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .home5-banner2-section .home5-banner2-wrapper .home5-banner2-content h2 strong {
    font-size: 38px;
  }
}
@media (max-width: 1199px) {
  .home5-banner2-section .home5-banner2-wrapper .home5-banner2-content h2 strong {
    font-size: 30px;
  }
}
@media (max-width: 991px) {
  .home5-banner2-section .home5-banner2-wrapper .home5-banner2-content h2 strong {
    font-size: 28px;
    font-weight: 600;
  }
}
.home5-banner2-section .home5-banner2-wrapper .home5-banner2-content p {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 18px;
  font-weight: 400;
  line-height: 1.7;
  letter-spacing: 0.54px;
  text-transform: capitalize;
}
@media (max-width: 1199px) {
  .home5-banner2-section .home5-banner2-wrapper .home5-banner2-content p {
    font-size: 16px;
    margin: 0 auto 20px;
  }
}
@media (max-width: 991px) {
  .home5-banner2-section .home5-banner2-wrapper .home5-banner2-content p {
    font-size: 15px;
  }
}
.home5-banner2-section .home5-banner2-wrapper .home5-banner2-content .primary-btn5 {
  padding: 17px 33px;
}
.home5-banner2-section .home5-banner2-wrapper .home5-banner2-content .primary-btn5 span {
  font-size: 16px;
  font-weight: 600;
}
.home5-banner2-section .home5-banner2-wrapper .home5-banner2-content.two > span {
  color: var(--title-color);
}
.home5-banner2-section .home5-banner2-wrapper .home5-banner2-content.two h2 {
  color: var(--white-color);
}
.home5-banner2-section .home5-banner2-wrapper .home5-banner2-content.two p {
  color: var(--white-color);
}
.home5-banner2-section .home5-banner2-slider {
  position: relative;
}
.home5-banner2-section .home5-banner2-slider .swiper-pagination5 {
  position: absolute;
  bottom: 20px;
  left: unset;
  right: 20px;
  width: unset !important;
  display: flex;
  z-index: 9;
  gap: 15px;
}
@media (max-width: 767px) {
  .home5-banner2-section .home5-banner2-slider .swiper-pagination5 {
    right: unset;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (max-width: 576px) {
  .home5-banner2-section .home5-banner2-slider .swiper-pagination5 {
    display: none;
  }
}
.home5-banner2-section .home5-banner2-slider .swiper-pagination5 .swiper-pagination-bullet {
  height: 16px;
  width: 16px;
  background-color: transparent;
  border: 1px solid var(--title-color);
  opacity: 1;
  position: relative;
}
.home5-banner2-section .home5-banner2-slider .swiper-pagination5 .swiper-pagination-bullet.swiper-pagination-bullet-active {
  border-color: var(--primary-color1);
}
.home5-banner2-section .home5-banner2-slider .swiper-pagination5 .swiper-pagination-bullet.swiper-pagination-bullet-active::after {
  content: "";
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: var(--primary-color1);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.home5-banner2-section .home5-banner2-slider::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-image: url(../img/home5/vector/pagination-bg.svg);
  width: 200px;
  height: 141px;
}
@media (max-width: 767px) {
  .home5-banner2-section .home5-banner2-slider::after {
    display: none;
  }
}

.map-wrap {
  position: relative;
}
.map-wrap img {
  -o-object-fit: cover;
     object-fit: cover;
}
.map-wrap .country-area {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.map-wrap .country-area > ul {
  height: 100px;
}
.map-wrap .country-area > ul > li {
  display: inline-flex;
  position: absolute;
}
.map-wrap .country-area > ul > li:nth-child(1) {
  left: 31%;
  bottom: 27%;
}
.map-wrap .country-area > ul > li:nth-child(2) {
  left: 15%;
  bottom: 57%;
}
.map-wrap .country-area > ul > li:nth-child(3) {
  left: 17%;
  top: 27%;
}
.map-wrap .country-area > ul > li:nth-child(4) {
  left: 38%;
  top: 15%;
}
.map-wrap .country-area > ul > li:nth-child(5) {
  right: 19%;
  top: 17%;
}
.map-wrap .country-area > ul > li:nth-child(6) {
  right: 41%;
  top: 35%;
}
.map-wrap .country-area > ul > li:nth-child(7) {
  right: 17%;
  top: 40%;
}
.map-wrap .country-area > ul > li:nth-child(8) {
  right: 44%;
  top: 53%;
}
.map-wrap .country-area > ul > li:nth-child(9) {
  right: 45%;
  bottom: 22%;
}
.map-wrap .country-area > ul > li:nth-child(10) {
  right: 8%;
  bottom: 19%;
}
.map-wrap .country-area > ul > li .dot-main {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.map-wrap .country-area > ul > li .dot-main .waves {
  position: absolute;
  width: 35px;
  height: 35px;
  background: var(--primary-color1);
  filter: blur(2px);
  opacity: 0;
  border-radius: 100%;
  right: -16px;
  bottom: -8px;
  z-index: 1;
  animation: waves 3s ease-in-out infinite;
}
@media (max-width: 576px) {
  .map-wrap .country-area > ul > li .dot-main .waves {
    width: 20px;
    height: 20px;
  }
}
.map-wrap .country-area > ul > li .dot-main .waves.wave-1 {
  animation-delay: 0s;
}
.map-wrap .country-area > ul > li .dot-main .waves.wave-2 {
  animation-delay: 1s;
}
.map-wrap .country-area > ul > li .dot-main .waves.wave-3 {
  animation-delay: 2s;
  background: var(--primary-color);
}
@keyframes waves {
  0% {
    transform: scale(0.2, 0.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  50% {
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  }
  100% {
    transform: scale(0.9, 0.9);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}
.map-wrap .country-area > ul > li .destination-card3 {
  position: absolute;
  left: 50%;
  bottom: 25px;
  transform: translateX(-50%) scale(0);
  transition: 0.5s;
  z-index: 2;
}
.map-wrap .country-area > ul > li .destination-card3::after {
  content: "";
  height: 18px;
  width: 18px;
  border: 1px solid #eee;
  border-top: unset;
  border-left: unset;
  background-color: var(--white-color);
  display: block;
  position: absolute;
  left: 50%;
  bottom: -9px;
  transform: translateX(-50%) rotate(45deg);
}
@media (max-width: 767px) {
  .map-wrap .country-area > ul > li .destination-card3 .destination-card-img {
    display: none;
  }
}
.map-wrap .country-area > ul > li.active .location-card {
  transform: translateX(-50%) scale(1);
}
.map-wrap .counter-wrap {
  border: 1px solid #EEE;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 40px;
}
@media (max-width: 1199px) {
  .map-wrap .counter-wrap {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 60px 20px;
  }
}
@media (max-width: 767px) {
  .map-wrap .counter-wrap {
    flex-direction: column;
    width: unset;
    justify-content: start;
    align-items: start;
    padding: 60px 20px;
  }
}
@media (max-width: 576px) {
  .map-wrap .counter-wrap {
    display: flex;
  }
}
.map-wrap .counter-wrap .single-counter {
  display: flex;
  gap: 30px;
  padding: 50px 0px;
  min-width: 230px;
}
@media (max-width: 1399px) {
  .map-wrap .counter-wrap .single-counter {
    padding: 30px 0px;
  }
}
@media (max-width: 1199px) {
  .map-wrap .counter-wrap .single-counter {
    padding: 30px 0px;
  }
}
@media (max-width: 991px) {
  .map-wrap .counter-wrap .single-counter {
    padding: 20px 0px;
  }
}
.map-wrap .counter-wrap .single-counter:first-child {
  padding-top: 0;
}
@media (max-width: 1199px) {
  .map-wrap .counter-wrap .single-counter:first-child {
    padding: 0px;
  }
}
@media (max-width: 767px) {
  .map-wrap .counter-wrap .single-counter:first-child {
    padding: 30px 0px;
    padding-top: 0;
  }
}
.map-wrap .counter-wrap .single-counter:last-child {
  padding-bottom: 0;
}
@media (max-width: 1199px) {
  .map-wrap .counter-wrap .single-counter:last-child {
    padding: 0px;
  }
}
@media (max-width: 767px) {
  .map-wrap .counter-wrap .single-counter:last-child {
    padding: 30px 0px;
    padding-bottom: 0;
  }
}
.map-wrap .counter-wrap .single-counter .icon svg {
  fill: #566064;
}
.map-wrap .counter-wrap .single-counter .content .number {
  display: flex;
  align-items: center;
  line-height: 1;
  margin-bottom: 10px;
  margin-top: -6px;
}
.map-wrap .counter-wrap .single-counter .content .number h2 {
  margin-bottom: 0;
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 40px;
  font-weight: 600;
  line-height: 1;
}
.map-wrap .counter-wrap .single-counter .content .number > span {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 40px;
  font-weight: 600;
  line-height: 1;
}
.map-wrap .counter-wrap .single-counter .content p {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 15px;
  font-weight: 500;
}

/*=====================================
  58 .Home5 Testimonial section
========================================*/
.home5-testimonal-slider-section .rating-area {
  display: flex;
  align-items: center;
  gap: 30px;
}
@media (max-width: 576px) {
  .home5-testimonal-slider-section .rating-area {
    gap: 10px;
  }
}
.home5-testimonal-slider-section .rating-area li {
  background-color: var(--white-color);
  border-radius: 10px;
  box-shadow: 0px 0px 45px 0px rgba(0, 0, 0, 0.08);
  padding: 10px 22px;
}
@media (max-width: 576px) {
  .home5-testimonal-slider-section .rating-area li {
    padding: 10px 15px;
  }
}
.home5-testimonal-slider-section .rating-area li .logo {
  margin-bottom: 6px;
}
.home5-testimonal-slider-section .rating-area li .star {
  display: flex;
  align-items: center;
  gap: 5px;
  line-height: 1;
}
.home5-testimonal-slider-section .rating-area li .star span {
  color: var(--title-color);
  font-size: 13px;
}
@media (max-width: 991px) {
  .home5-testimonal-slider-section .testimonal-slider-wrap .testimonal-slider-left-img {
    display: none;
  }
}
.home5-testimonal-slider-section .testimonal-slider-wrap .testimonal-slider-left-img img {
  border-radius: 5px;
}
.home5-testimonal-slider-section .testimonal-slider-wrap .testimonial-slider-area {
  margin-left: -70px;
}
@media (max-width: 991px) {
  .home5-testimonal-slider-section .testimonal-slider-wrap .testimonial-slider-area {
    margin-left: 0;
  }
}
.home5-testimonal-slider-section .testimonal-slider-wrap .testimonial-slider-area .swiper-slide-active .testi-content-top {
  animation: fadeInDown 1.7s;
}
.home5-testimonal-slider-section .testimonal-slider-wrap .testimonial-slider-area .swiper-slide-active .author-name-desig {
  animation: fadeInUp 1.7s;
}
.home5-testimonal-slider-section .testimonal-slider-wrap .testimonial-slider-area .testimonial-wrapper {
  display: flex;
  align-items: center;
  gap: 25px;
}
@media (max-width: 767px) {
  .home5-testimonal-slider-section .testimonal-slider-wrap .testimonial-slider-area .testimonial-wrapper {
    gap: 15px;
  }
}
@media (max-width: 576px) {
  .home5-testimonal-slider-section .testimonal-slider-wrap .testimonial-slider-area .testimonial-wrapper {
    flex-direction: column;
  }
}
.home5-testimonal-slider-section .testimonal-slider-wrap .testimonial-slider-area .testimonial-wrapper .testi-img {
  min-width: 115px;
  max-width: 115px;
  height: 115px;
  border-radius: 50%;
}
.home5-testimonal-slider-section .testimonal-slider-wrap .testimonial-slider-area .testimonial-wrapper .testi-img img {
  min-width: 115px;
  max-width: 115px;
  min-height: 115px;
  max-height: 115px;
  border-radius: 50%;
  border: 5px solid var(--white-color);
  -o-object-fit: cover;
     object-fit: cover;
}
.home5-testimonal-slider-section .testimonal-slider-wrap .testimonial-slider-area .testimonial-wrapper .testi-content {
  width: 100%;
}
.home5-testimonal-slider-section .testimonal-slider-wrap .testimonial-slider-area .testimonial-wrapper .testi-content .testi-content-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 30px;
}
@media (max-width: 991px) {
  .home5-testimonal-slider-section .testimonal-slider-wrap .testimonial-slider-area .testimonial-wrapper .testi-content .testi-content-top {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .home5-testimonal-slider-section .testimonal-slider-wrap .testimonial-slider-area .testimonial-wrapper .testi-content .testi-content-top {
    margin-bottom: 15px;
  }
}
@media (max-width: 576px) {
  .home5-testimonal-slider-section .testimonal-slider-wrap .testimonial-slider-area .testimonial-wrapper .testi-content .testi-content-top {
    margin-bottom: 10px;
  }
}
.home5-testimonal-slider-section .testimonal-slider-wrap .testimonial-slider-area .testimonial-wrapper .testi-content .testi-content-top .rating-title .rating {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 15px;
}
@media (max-width: 991px) {
  .home5-testimonal-slider-section .testimonal-slider-wrap .testimonial-slider-area .testimonial-wrapper .testi-content .testi-content-top .rating-title .rating {
    margin-bottom: 10px;
  }
}
.home5-testimonal-slider-section .testimonal-slider-wrap .testimonial-slider-area .testimonial-wrapper .testi-content .testi-content-top .rating-title .rating li {
  line-height: 1;
}
.home5-testimonal-slider-section .testimonal-slider-wrap .testimonial-slider-area .testimonial-wrapper .testi-content .testi-content-top .rating-title .rating li i {
  color: #FFC107;
}
.home5-testimonal-slider-section .testimonal-slider-wrap .testimonial-slider-area .testimonial-wrapper .testi-content .testi-content-top .rating-title h4 {
  color: var(--Title, #100C08);
  font-family: var(--font-rubik);
  font-size: 32px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.96px;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .home5-testimonal-slider-section .testimonal-slider-wrap .testimonial-slider-area .testimonial-wrapper .testi-content .testi-content-top .rating-title h4 {
    font-size: 28px;
  }
}
@media (max-width: 576px) {
  .home5-testimonal-slider-section .testimonal-slider-wrap .testimonial-slider-area .testimonial-wrapper .testi-content .testi-content-top .rating-title h4 {
    font-size: 25px;
  }
}
@media (max-width: 767px) {
  .home5-testimonal-slider-section .testimonal-slider-wrap .testimonial-slider-area .testimonial-wrapper .testi-content .testi-content-top .quote {
    display: none;
  }
}
.home5-testimonal-slider-section .testimonal-slider-wrap .testimonial-slider-area .testimonial-wrapper .testi-content .testi-content-top .quote svg {
  fill: var(--title-color);
}
.home5-testimonal-slider-section .testimonal-slider-wrap .testimonial-slider-area .testimonial-wrapper .testi-content p {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 22px;
  font-weight: 400;
  line-height: 1.9;
  margin-bottom: 50px;
}
@media (max-width: 1199px) {
  .home5-testimonal-slider-section .testimonal-slider-wrap .testimonial-slider-area .testimonial-wrapper .testi-content p {
    font-size: 18px;
    margin-bottom: 40px;
  }
}
@media (max-width: 991px) {
  .home5-testimonal-slider-section .testimonal-slider-wrap .testimonial-slider-area .testimonial-wrapper .testi-content p {
    margin-bottom: 35px;
  }
}
@media (max-width: 767px) {
  .home5-testimonal-slider-section .testimonal-slider-wrap .testimonial-slider-area .testimonial-wrapper .testi-content p {
    font-size: 16px;
    margin-bottom: 25px;
  }
}
.home5-testimonal-slider-section .testimonal-slider-wrap .testimonial-slider-area .testimonial-wrapper .testi-content .author-name-desig h5 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 22px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 10px;
}
@media (max-width: 576px) {
  .home5-testimonal-slider-section .testimonal-slider-wrap .testimonial-slider-area .testimonial-wrapper .testi-content .author-name-desig h5 {
    font-size: 20px;
  }
}
.home5-testimonal-slider-section .testimonal-slider-wrap .testimonial-slider-area .testimonial-wrapper .testi-content .author-name-desig span {
  color: var(--primary-color1);
  font-family: var(--font-jost);
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
}
@media (max-width: 576px) {
  .home5-testimonal-slider-section .testimonal-slider-wrap .testimonial-slider-area .testimonial-wrapper .testi-content .author-name-desig span {
    font-size: 16px;
  }
}
.home5-testimonal-slider-section .testimonal-slider-wrap .testimonial-slider-area .home5-testimonal-slider {
  position: relative;
}
.home5-testimonal-slider-section .testimonal-slider-wrap .testimonial-slider-area .home5-testimonal-slider .slider-btn-grp5 {
  position: absolute;
  right: 0;
  bottom: 15px;
  z-index: 9;
}
@media (max-width: 767px) {
  .home5-testimonal-slider-section .testimonal-slider-wrap .testimonial-slider-area .home5-testimonal-slider .slider-btn-grp5 {
    display: none;
  }
}

/*=====================================
  59 .Home5 Visa Application section
========================================*/
.home5-visa-application-section {
  padding: 0 7%;
}
@media (max-width: 1699px) {
  .home5-visa-application-section {
    padding: 0 4%;
  }
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home5-visa-application-section {
    padding: 0 2%;
  }
}
@media (max-width: 1399px) {
  .home5-visa-application-section {
    padding: 0 2%;
  }
}
.home5-visa-application-section .visa-application-wrapper {
  background-image: url(../img/home5/visa-application-section-bg.png), linear-gradient(180deg, #edf2f5 0%, #edf2f5 100%);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 110px 7%;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home5-visa-application-section .visa-application-wrapper {
    padding: 110px 5%;
  }
}
@media (max-width: 1399px) {
  .home5-visa-application-section .visa-application-wrapper {
    padding: 110px 2%;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .home5-visa-application-section .visa-application-wrapper {
    padding: 110px 10px;
  }
}
@media (max-width: 1199px) {
  .home5-visa-application-section .visa-application-wrapper {
    padding: 90px 2%;
  }
}
@media (max-width: 767px) {
  .home5-visa-application-section .visa-application-wrapper {
    padding: 70px 2%;
  }
}

/*=====================================
  60 .Home5 Teams section
========================================*/
.home5-teams-section .teams-slider-area .home5-teams-card-slider {
  padding: 10px;
  margin: -10px;
}
.home5-teams-section .teams-pagination-area .teams-pagination {
  width: unset !important;
  display: flex;
  gap: 15px;
}
.home5-teams-section .teams-pagination-area .teams-pagination .swiper-pagination-bullet {
  height: 16px;
  width: 16px;
  background-color: transparent;
  border: 1px solid var(--title-color);
  opacity: 1;
  position: relative;
}
.home5-teams-section .teams-pagination-area .teams-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  border-color: var(--primary-color1);
}
.home5-teams-section .teams-pagination-area .teams-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active::after {
  content: "";
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: var(--primary-color1);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/*=====================================
  61 .Home5 Newsletter section
========================================*/
.home5-banner3-section {
  background-image: linear-gradient(90deg, rgba(249, 244, 240, 0) 38.83%, #F9F4F0 46.62%), url(../img/home5/home5-banner3-img.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
  overflow: hidden;
  padding: 120px 20% 120px 0;
}
@media (max-width: 1699px) {
  .home5-banner3-section {
    padding: 120px 17% 120px 0;
  }
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home5-banner3-section {
    padding: 120px 12% 120px 0;
  }
}
@media (max-width: 1399px) {
  .home5-banner3-section {
    padding: 120px 6% 120px 0;
  }
}
@media (max-width: 1199px) {
  .home5-banner3-section {
    padding: 120px 6% 120px 0;
  }
}
@media (max-width: 991px) {
  .home5-banner3-section {
    padding: 100px 3% 100px 0;
  }
}
@media (max-width: 767px) {
  .home5-banner3-section {
    padding: 70px 2% 70px 0;
  }
}
@media (max-width: 576px) {
  .home5-banner3-section {
    background-color: #F9F4F0;
    background-image: unset;
    padding: 60px 0;
  }
}
.home5-banner3-section .home5-banner3-content {
  position: relative;
  max-width: 564px;
  width: 100%;
  margin-left: auto;
  text-align: center;
}
@media (max-width: 1199px) {
  .home5-banner3-section .home5-banner3-content {
    max-width: 460px;
  }
}
@media (max-width: 991px) {
  .home5-banner3-section .home5-banner3-content {
    max-width: 370px;
  }
}
@media (max-width: 767px) {
  .home5-banner3-section .home5-banner3-content {
    max-width: 290px;
  }
}
@media (max-width: 576px) {
  .home5-banner3-section .home5-banner3-content {
    margin: 0 auto;
  }
}
.home5-banner3-section .home5-banner3-content h2 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 50px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 15px;
}
@media (max-width: 1199px) {
  .home5-banner3-section .home5-banner3-content h2 {
    font-size: 45px;
  }
}
@media (max-width: 991px) {
  .home5-banner3-section .home5-banner3-content h2 {
    font-size: 35px;
  }
}
@media (max-width: 767px) {
  .home5-banner3-section .home5-banner3-content h2 {
    font-size: 28px;
    margin-bottom: 10px;
  }
}
.home5-banner3-section .home5-banner3-content p {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.16px;
  margin-bottom: 35px;
}
@media (max-width: 767px) {
  .home5-banner3-section .home5-banner3-content p {
    font-size: 15px;
    margin-bottom: 20px;
  }
}
.home5-banner3-section .home5-banner3-content .from-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(var(--primary-color1-opc), 0.5);
  border-radius: 10px;
}
@media (max-width: 576px) {
  .home5-banner3-section .home5-banner3-content .from-inner {
    border-radius: 5px;
  }
}
.home5-banner3-section .home5-banner3-content .from-inner input {
  background: transparent;
  border: none;
  outline: none;
  color: var(--title-color);
  font-size: 12px;
  font-family: var(--font-rubik);
  width: 100%;
  height: 48px;
  padding: 10px 20px;
}
@media (max-width: 576px) {
  .home5-banner3-section .home5-banner3-content .from-inner input {
    height: 42px;
  }
}
.home5-banner3-section .home5-banner3-content .from-inner input::-moz-placeholder {
  color: rgba(16, 12, 8, 0.4);
}
.home5-banner3-section .home5-banner3-content .from-inner input::placeholder {
  color: rgba(16, 12, 8, 0.4);
}
.home5-banner3-section .home5-banner3-content .from-inner button {
  background-color: var(--primary-color1);
  padding: 9px 16px;
  border-radius: 0 10px 10px 0;
}
@media (max-width: 576px) {
  .home5-banner3-section .home5-banner3-content .from-inner button {
    border-radius: 0 5px 5px 0;
    padding: 6px 16px;
  }
}
.home5-banner3-section .home5-banner3-content .from-inner button svg {
  stroke: var(--white-color);
}

/*=====================================
  62 .Home6 Banner section
========================================*/
.package-card5 {
  position: relative;
  transition: 0.5s;
}
@media (max-width: 576px) {
  .package-card5 > img {
    width: 100%;
    max-height: 380px;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
.package-card5 .card-content-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(34, 34, 34, 0.7);
  display: flex;
  align-items: end;
  transition: 0.75s;
  transform-origin: bottom;
  opacity: 0;
  transform: scaleY(0);
}
.package-card5 .card-content-wrapper .batch {
  position: absolute;
  top: 20px;
  left: 0;
}
.package-card5 .card-content-wrapper .batch > span {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  padding: 6px 8px;
  background-color: var(--title-color);
}
.package-card5 .card-content-wrapper .batch .packcage-name {
  border: 1px solid rgba(16, 12, 8, 0.5);
  background: #FFF;
  padding: 6px 10px;
  display: flex;
  align-items: center;
  gap: 7px;
}
.package-card5 .card-content-wrapper .batch .packcage-name svg {
  fill: var(--primary-color1);
}
.package-card5 .card-content-wrapper .batch .packcage-name span {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 13px;
  font-weight: 400;
  line-height: 1;
  text-transform: capitalize;
}
.package-card5 .card-content-wrapper .package-card-content {
  padding: 40px 35px;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .package-card5 .card-content-wrapper .package-card-content {
    padding: 40px 18px;
  }
}
@media (max-width: 1399px) {
  .package-card5 .card-content-wrapper .package-card-content {
    padding: 40px 18px 30px;
  }
}
@media (max-width: 767px) {
  .package-card5 .card-content-wrapper .package-card-content {
    padding: 40px 15px 20px;
  }
}
.package-card5 .card-content-wrapper .package-card-content h3 {
  margin-bottom: 20px;
  line-height: 1.2;
}
.package-card5 .card-content-wrapper .package-card-content h3 a {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 30px;
  font-weight: 500;
  transition: 0.5s;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .package-card5 .card-content-wrapper .package-card-content h3 a {
    font-size: 25px;
  }
}
@media (max-width: 1399px) {
  .package-card5 .card-content-wrapper .package-card-content h3 a {
    font-size: 23px;
  }
}
@media (max-width: 767px) {
  .package-card5 .card-content-wrapper .package-card-content h3 a {
    font-size: 20px;
  }
}
.package-card5 .card-content-wrapper .package-card-content h3 a:hover {
  color: var(--primary-color1);
}
.package-card5 .card-content-wrapper .package-card-content .rating-and-date {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 25px;
  flex-wrap: wrap;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .package-card5 .card-content-wrapper .package-card-content .rating-and-date {
    gap: 10px;
  }
}
@media (max-width: 767px) {
  .package-card5 .card-content-wrapper .package-card-content .rating-and-date {
    gap: 10px;
    margin-bottom: 20px;
  }
}
.package-card5 .card-content-wrapper .package-card-content .rating-and-date .rating-area {
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 1;
}
.package-card5 .card-content-wrapper .package-card-content .rating-and-date .rating-area .rating {
  display: flex;
  align-items: center;
  gap: 4px;
}
.package-card5 .card-content-wrapper .package-card-content .rating-and-date .rating-area .rating li {
  line-height: 0;
}
.package-card5 .card-content-wrapper .package-card-content .rating-and-date .rating-area .rating li i {
  color: #FFC200;
  font-size: 12px;
}
.package-card5 .card-content-wrapper .package-card-content .rating-and-date .rating-area span {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
}
.package-card5 .card-content-wrapper .package-card-content .rating-and-date .date {
  display: flex;
  align-items: center;
  gap: 5px;
}
.package-card5 .card-content-wrapper .package-card-content .rating-and-date .date svg {
  fill: var(--white-color);
}
.package-card5 .card-content-wrapper .package-card-content .rating-and-date .date span {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  text-transform: capitalize;
}
.package-card5 .card-content-wrapper .package-card-content .card-content-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  flex-wrap: wrap;
  padding-top: 10px;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}
.package-card5 .card-content-wrapper .package-card-content .card-content-bottom .price-area span {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.24px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.package-card5 .card-content-wrapper .package-card-content .card-content-bottom .price-area h6 {
  color: var(--primary-color1);
  font-family: var(--font-rubik);
  font-size: 26px;
  font-weight: 500;
  line-height: 1;
  text-transform: capitalize;
  margin-bottom: 0;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .package-card5 .card-content-wrapper .package-card-content .card-content-bottom .price-area h6 {
    font-size: 20px;
  }
}
@media (max-width: 576px) {
  .package-card5 .card-content-wrapper .package-card-content .card-content-bottom .price-area h6 {
    font-size: 22px;
  }
}
.package-card5 .card-content-wrapper .package-card-content .card-content-bottom .price-area h6 span {
  font-size: 16px;
  color: var(--primary-color1);
}
.package-card5 .card-content-wrapper .package-card-content .card-content-bottom .price-area p {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 10px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.2px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.package-card5:hover .card-content-wrapper {
  opacity: 1;
  transform: scaleY(1);
}
.package-card5.destination .card-content-wrapper .package-card-content {
  text-align: center;
}
.package-card5.destination .card-content-wrapper .package-card-content .icon {
  margin-bottom: 20px;
}
@media (max-width: 1399px) {
  .package-card5.destination .card-content-wrapper .package-card-content .icon {
    margin-bottom: 15px;
  }
}
.package-card5.destination .card-content-wrapper .package-card-content h1, .package-card5.destination .card-content-wrapper .package-card-content h2 {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 45px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 1.35px;
  margin-bottom: 20px;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .package-card5.destination .card-content-wrapper .package-card-content h1, .package-card5.destination .card-content-wrapper .package-card-content h2 {
    font-size: 40px;
  }
}
@media (max-width: 1399px) {
  .package-card5.destination .card-content-wrapper .package-card-content h1, .package-card5.destination .card-content-wrapper .package-card-content h2 {
    font-size: 30px;
    margin-bottom: 15px;
  }
}
.package-card5.destination .card-content-wrapper .package-card-content p {
  color: var(--white-color);
  font-family: var(--font-jost);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.8;
  letter-spacing: 0.48px;
  margin-bottom: 45px;
}
@media (max-width: 1399px) {
  .package-card5.destination .card-content-wrapper .package-card-content p {
    margin-bottom: 35px;
  }
}
@media (max-width: 767px) {
  .package-card5.destination .card-content-wrapper .package-card-content p {
    font-size: 15px;
    margin-bottom: 20px;
  }
}
@media (max-width: 576px) {
  .package-card5.destination .card-content-wrapper .package-card-content .primary-btn1 {
    font-size: 14px;
    font-weight: 500;
    padding: 14px 30px;
  }
}

.home6-banner-area {
  background-image: url(../img/home6/home6-banner-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 130px 20px 40px 20px;
  position: relative;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home6-banner-area {
    padding: 130px 15px 40px 15px;
  }
}
@media (max-width: 1399px) {
  .home6-banner-area {
    padding: 130px 15px 40px 15px;
  }
}
@media (max-width: 767px) {
  .home6-banner-area {
    padding: 110px 20px 40px 20px;
  }
}
@media (max-width: 576px) {
  .home6-banner-area {
    padding: 110px 0px 40px 0px;
  }
}
.home6-banner-area .bannner-slider-wrapper .slider-btn-grp6 {
  padding-top: 30px;
}
@media (max-width: 767px) {
  .home6-banner-area .bannner-slider-wrapper .slider-btn-grp6 {
    display: none;
  }
}

/*=====================================
  63 .Home6 Category card Slider section
========================================*/
.category-card {
  border: 1px solid rgba(16, 12, 8, 0.1);
  background: var(--white-color);
  transition: 0.5s;
}
.category-card .card-img {
  display: block;
  overflow: hidden;
}
.category-card .card-img img {
  transition: all 0.5s ease-out;
}
.category-card .card-content {
  padding: 65px 20px 30px;
  text-align: center;
  position: relative;
}
.category-card .card-content .icon {
  width: 85px;
  height: 85px;
  border: 1px solid rgba(16, 12, 8, 0.15);
  background-color: var(--white-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -42px;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.5s;
}
.category-card .card-content .icon svg {
  fill: var(--title-color);
  transition: 0.5s;
}
.category-card .card-content h4 {
  margin-bottom: 0;
  line-height: 1.1;
}
.category-card .card-content h4 a {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 25px;
  font-weight: 600;
  transition: 0.5s;
}
.category-card .card-content h4 a:hover {
  color: var(--primary-color1);
}
.category-card:hover .card-img img {
  transform: scale(1.1);
}
.category-card:hover .card-content .icon {
  background-color: var(--primary-color1);
}
.category-card:hover .card-content .icon svg {
  fill: var(--white-color);
}

/*=====================================
  64 .Home6 Destination Card Slider section
========================================*/
.destination-card4 {
  height: 400px;
  border-radius: 200px;
  position: relative;
  overflow: hidden;
  transition: 0.5s;
}
@media (max-width: 991px) {
  .destination-card4 {
    height: unset;
    border-radius: 50%;
  }
}
.destination-card4 .card-img {
  position: relative;
  display: block;
  transition: 0.5s;
}
.destination-card4 .card-img img {
  height: 400px;
  border-radius: 200px;
  transition: 0.5s;
  width: 100%;
}
@media (max-width: 991px) {
  .destination-card4 .card-img img {
    height: unset;
    border-radius: 50%;
  }
}
.destination-card4 .card-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(16, 12, 8, 0.2);
  border-radius: 200px;
}
@media (max-width: 991px) {
  .destination-card4 .card-img::before {
    border-radius: 50%;
  }
}
.destination-card4 .card-content-wrap {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}
.destination-card4 .card-content-wrap .batch {
  line-height: 1;
  display: flex;
  justify-content: center;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: bottom;
  transition: 0.5s;
}
.destination-card4 .card-content-wrap .batch ul {
  background-color: var(--primary-color2);
  padding: 8px 20px;
  border-radius: 10px 10px 0px 0px;
  display: inline-flex;
  align-items: center;
  gap: 16px;
}
.destination-card4 .card-content-wrap .batch ul li {
  position: relative;
  line-height: 1;
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.6px;
  text-transform: uppercase;
}
.destination-card4 .card-content-wrap .batch ul li::before {
  content: "\f4fe";
  font-family: bootstrap-icons;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -17px;
  color: var(--title-color);
  font-size: 17px;
}
.destination-card4 .card-content-wrap .batch ul li:first-child::before {
  display: none;
}
.destination-card4 .card-content-wrap .card-content {
  text-align: center;
  background-color: var(--primary-color1);
  padding: 26px 80px;
}
.destination-card4 .card-content-wrap .card-content h4 {
  line-height: 1.1;
  margin-bottom: 0;
}
.destination-card4 .card-content-wrap .card-content h4 a {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 24px;
  font-weight: 500;
  text-transform: capitalize;
  transition: 0.5s;
}
.destination-card4 .card-content-wrap .card-content h4 a:hover {
  color: var(--title-color);
}
.destination-card4:hover {
  transform: translateY(-20px);
}
.destination-card4:hover .card-content-wrap .batch {
  opacity: 1;
  transform: scaleY(1);
}

.home6-destination-card-slider-section {
  background-image: url(../img/home6/destination-section-bg.png), linear-gradient(180deg, #f0faef 0%, #f0faef 100%);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 110px 0;
  position: relative;
  overflow: hidden;
}
@media (max-width: 1199px) {
  .home6-destination-card-slider-section {
    padding: 90px 0;
  }
}
@media (max-width: 767px) {
  .home6-destination-card-slider-section {
    padding: 50px 0;
  }
}
.home6-destination-card-slider-section::before {
  content: url(../img/home6/vector/destination-section-vector1.png);
  position: absolute;
  top: 0;
  left: 0;
}
.home6-destination-card-slider-section::after {
  content: url(../img/home6/vector/destination-section-vector2.png);
  position: absolute;
  bottom: 0;
  right: 0;
  line-height: 0.7;
}
.home6-destination-card-slider-section .home6-destination-card-slider {
  padding: 20px 0;
}
.home6-destination-card-slider-section .slider-btn-grp.two {
  position: relative;
  z-index: 1;
}

/*=====================================
  65 .Home6 About section
========================================*/
@media (min-width: 1200px) and (max-width: 1399px) {
  .home6-about-section .about-content .section-title5 h2 {
    font-size: 38px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home6-about-section .about-content .section-title5 h2 {
    font-size: 30px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home6-about-section .about-content .section-title5 p {
    font-size: 15px;
    padding-top: 15px;
  }
}
.home6-about-section .about-content .facilities li {
  display: flex;
  align-items: start;
  gap: 15px;
  margin-bottom: 30px;
}
@media (max-width: 1199px) {
  .home6-about-section .about-content .facilities li {
    margin-bottom: 20px;
  }
}
.home6-about-section .about-content .facilities li:last-child {
  margin-bottom: 0;
}
.home6-about-section .about-content .facilities li .icon svg {
  fill: var(--primary-color1);
}
.home6-about-section .about-content .facilities li .content h5 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 22px;
  font-weight: 600;
  line-height: 1; /* 100% */
  text-transform: capitalize;
  margin-bottom: 10px;
}
@media (max-width: 576px) {
  .home6-about-section .about-content .facilities li .content h5 {
    font-size: 20px;
    margin-bottom: 8px;
  }
}
.home6-about-section .about-content .facilities li .content p {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 15px;
  font-weight: 400;
  line-height: 1.8;
  letter-spacing: 0.45px;
  margin-bottom: 0;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home6-about-section .about-content .facilities li .content p {
    font-size: 14px;
  }
}
@media (max-width: 576px) {
  .home6-about-section .about-content .facilities li .content p {
    font-size: 14px;
  }
}
@media (max-width: 1199px) {
  .home6-about-section .about-video-and-img {
    padding-left: 10px;
  }
}
@media (max-width: 991px) {
  .home6-about-section .about-video-and-img {
    display: none;
  }
}
.home6-about-section .about-video-and-img .video-and-batch-wrap {
  padding-left: 80px;
  transform: translateY(40px);
  position: relative;
  z-index: 1;
}
@media (max-width: 1199px) {
  .home6-about-section .about-video-and-img .video-and-batch-wrap {
    padding-left: 0;
    transform: translateY(0);
  }
}
.home6-about-section .about-video-and-img .video-and-batch-wrap::before {
  content: url(../img/home6/about-video-and-batch-vector.png);
  position: absolute;
  top: 0;
  left: 0;
}
@media (max-width: 1199px) {
  .home6-about-section .about-video-and-img .video-and-batch-wrap::before {
    display: none;
  }
}
.home6-about-section .about-video-and-img .video-and-batch-wrap .video-and-batch {
  gap: 50px;
  display: flex;
  align-items: center;
}
@media (max-width: 1199px) {
  .home6-about-section .about-video-and-img .video-and-batch-wrap .video-and-batch {
    flex-direction: column;
    align-items: start;
    gap: 40px;
  }
}
.home6-about-section .about-video-and-img .video-and-batch-wrap .video-and-batch .badge {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 160px;
  border-radius: 100%;
  animation: identifier 20s linear infinite;
}
.home6-about-section .about-video-and-img .video-and-batch-wrap .video-and-batch .badge .counter-area {
  display: block;
  margin: 0;
  width: 99px;
  height: 99px;
  border-radius: 50%;
  background-color: var(--primary-color1);
  animation: identifier 20s linear infinite;
  animation-direction: reverse;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home6-about-section .about-video-and-img .video-and-batch-wrap .video-and-batch .badge .counter-area .counter-content-wrap {
  text-align: center;
}
.home6-about-section .about-video-and-img .video-and-batch-wrap .video-and-batch .badge .counter-area .counter-content-wrap .number {
  display: flex;
  align-items: center;
  font-family: var(--font-rubik);
  font-weight: 700;
  font-size: 20px;
  line-height: 1;
  color: var(--white-color);
  margin-bottom: 10px;
}
.home6-about-section .about-video-and-img .video-and-batch-wrap .video-and-batch .badge .counter-area .counter-content-wrap .number h5 {
  margin-bottom: 0;
  color: var(--white-color);
  line-height: 1;
}
.home6-about-section .about-video-and-img .video-and-batch-wrap .video-and-batch .badge .counter-area .counter-content-wrap p {
  color: var(--white-color);
  font-family: var(--font-jost);
  font-size: 12px;
  font-weight: 500;
  line-height: 1.3;
  text-transform: uppercase;
  margin-bottom: 0;
}
.home6-about-section .about-video-and-img .video-and-batch-wrap .video-and-batch .badge .badge__char {
  display: block;
  position: absolute;
  top: 0;
  left: calc(50% - 5px);
  height: 80px;
  width: 10px;
  background-repeat: round;
  text-align: center;
  text-transform: uppercase;
  line-height: 1;
  font-weight: 500;
  font-size: 17px;
  color: rgba(16, 12, 8, 0.5);
  font-family: var(--font-rubik);
  transform-origin: bottom center;
  transform: rotate(var(--char-rotate));
}
@keyframes identifier {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}
.home6-about-section .about-video-and-img .video-and-batch-wrap .video-and-batch .video-wrapper {
  position: relative;
  z-index: 1;
}
.home6-about-section .about-video-and-img .video-and-batch-wrap .video-and-batch .video-wrapper img {
  border-radius: 50px;
  border-bottom: 2px solid var(--white-color);
}
@media (max-width: 1199px) {
  .home6-about-section .about-video-and-img .video-and-batch-wrap .video-and-batch .video-wrapper img {
    width: 100%;
  }
}
.home6-about-section .about-video-and-img .video-and-batch-wrap .video-and-batch .video-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 2px;
  right: 0;
  border-radius: 50px;
  background-color: rgba(16, 12, 8, 0.5);
}
.home6-about-section .about-video-and-img .video-and-batch-wrap .video-and-batch .video-wrapper .video-area {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.home6-about-section .about-video-and-img .video-and-batch-wrap .video-and-batch .video-wrapper .video-area .icon {
  position: relative;
}
.home6-about-section .about-video-and-img .video-and-batch-wrap .video-and-batch .video-wrapper .video-area .icon .video-circle {
  stroke: var(--white-color);
  fill: none;
  transition: 0.5s;
}
.home6-about-section .about-video-and-img .video-and-batch-wrap .video-and-batch .video-wrapper .video-area .icon .video-circle .top-half {
  stroke-dasharray: 320;
  stroke-dashoffset: 320;
  transition: stroke-dashoffset 0.8s cubic-bezier(0.33, 1, 0.68, 1);
}
.home6-about-section .about-video-and-img .video-and-batch-wrap .video-and-batch .video-wrapper .video-area .icon i {
  color: var(--white-color);
  font-size: 30px;
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.home6-about-section .about-video-and-img .video-and-batch-wrap .video-and-batch .video-wrapper .video-area h6 {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.48px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.home6-about-section .about-video-and-img .video-and-batch-wrap .video-and-batch .video-wrapper .video-area:hover .icon .video-circle .top-half {
  stroke-dashoffset: 0;
}
.home6-about-section .about-video-and-img .about-section-img {
  position: relative;
}
@media (max-width: 1199px) {
  .home6-about-section .about-video-and-img .about-section-img {
    display: none;
  }
}
.home6-about-section .about-video-and-img .about-section-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(16, 12, 8, 0.5);
}

/*=====================================
  66 .Home6 Tour pack section
========================================*/
.home6-tourpack-section {
  background-image: url(../img/home6/tourpack-section-bg.png), linear-gradient(180deg, #fff9f3 0%, #fff9f3 100%);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 110px 0;
  position: relative;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home6-tourpack-section {
    padding: 90px 0;
  }
}
@media (max-width: 991px) {
  .home6-tourpack-section {
    padding: 80px 0;
  }
}
@media (max-width: 767px) {
  .home6-tourpack-section {
    padding: 60px 0;
  }
}
.home6-tourpack-section::before {
  content: url(../img/home6/vector/tourpack-section-vector1.png);
  position: absolute;
  top: 0;
  left: 0;
}
@media (max-width: 991px) {
  .home6-tourpack-section::before {
    display: none;
  }
}
.home6-tourpack-section::after {
  content: url(../img/home6/vector/tourpack-section-vector2.png);
  position: absolute;
  bottom: 0;
  right: 0;
  line-height: 0.7;
}

/*=====================================
  67 .Home6 Activities section
========================================*/
.home6-activities-section .activities-tab-wrapper .nav-pills {
  position: relative;
}
.home6-activities-section .activities-tab-wrapper .nav-pills .nav-item .nav-link {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  background-color: var(--white-color);
  border: 1px solid rgba(16, 12, 8, 0.2);
  padding: 15px 20px;
  border-radius: unset;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-right: none;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home6-activities-section .activities-tab-wrapper .nav-pills .nav-item .nav-link {
    gap: 5px;
    padding: 15px 10px;
  }
}
@media (max-width: 1399px) {
  .home6-activities-section .activities-tab-wrapper .nav-pills .nav-item .nav-link {
    gap: 5px;
    padding: 15px 10px;
    font-size: 17px;
  }
}
@media (max-width: 1199px) {
  .home6-activities-section .activities-tab-wrapper .nav-pills .nav-item .nav-link {
    border: 1px solid rgba(16, 12, 8, 0.2);
  }
}
@media (max-width: 767px) {
  .home6-activities-section .activities-tab-wrapper .nav-pills .nav-item .nav-link {
    font-size: 15px;
  }
}
.home6-activities-section .activities-tab-wrapper .nav-pills .nav-item .nav-link svg {
  fill: var(--title-color);
}
.home6-activities-section .activities-tab-wrapper .nav-pills .nav-item .nav-link.active {
  background-color: var(--primary-color1);
  color: var(--white-color);
  border-color: var(--primary-color1);
}
.home6-activities-section .activities-tab-wrapper .nav-pills .nav-item .nav-link.active svg {
  fill: var(--white-color);
}
.home6-activities-section .activities-tab-wrapper .nav-pills .nav-item:last-child .nav-link {
  border: 1px solid rgba(16, 12, 8, 0.1);
}
@media (max-width: 1199px) {
  .home6-activities-section .activities-tab-wrapper .nav-pills {
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 576px) {
  .home6-activities-section .activities-tab-wrapper .nav-pills {
    margin-bottom: 30px;
  }
}
.home6-activities-section .activities-tab-wrapper .nav-pills .slider-btn-grp .slider-btn {
  background-color: var(--white-color);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -26px;
  z-index: 2;
}
@media (max-width: 1399px) {
  .home6-activities-section .activities-tab-wrapper .nav-pills .slider-btn-grp .slider-btn {
    left: 0;
  }
}
.home6-activities-section .activities-tab-wrapper .nav-pills .slider-btn-grp .slider-btn.home6-activities-nav-next {
  left: unset;
  right: -26px;
}
@media (max-width: 1399px) {
  .home6-activities-section .activities-tab-wrapper .nav-pills .slider-btn-grp .slider-btn.home6-activities-nav-next {
    left: unset;
    right: 0;
  }
}
.home6-activities-section .activities-tab-wrapper .nav-pills .slider-btn-grp .slider-btn:hover {
  background-color: var(--title-color);
}
.home6-activities-section .activities-tab-wrapper .nav-pills .slider-btn-grp .slider-btn:hover svg {
  fill: var(--white-color);
}
.home6-activities-section .activities-tab-wrapper .activities-tab-content-wrap .activities-content .service-list {
  -moz-columns: 2;
       columns: 2;
}
@media (max-width: 576px) {
  .home6-activities-section .activities-tab-wrapper .activities-tab-content-wrap .activities-content .service-list {
    -moz-columns: unset;
         columns: unset;
  }
}
.home6-activities-section .activities-tab-wrapper .activities-tab-content-wrap .activities-content .service-list li {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--title-color);
  font-family: var(--font-jost);
  font-size: 17px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 0.51px;
}
@media (max-width: 767px) {
  .home6-activities-section .activities-tab-wrapper .activities-tab-content-wrap .activities-content .service-list li {
    font-size: 16px;
    margin-bottom: 15px;
  }
}
.home6-activities-section .activities-tab-wrapper .activities-tab-content-wrap .activities-content .service-list li svg {
  fill: var(--primary-color1);
}
.home6-activities-section .activities-tab-wrapper .activities-tab-content-wrap .activities-content .content-bottom-area {
  display: flex;
  align-items: center;
  gap: 30px;
  padding-top: 30px;
}
@media (max-width: 576px) {
  .home6-activities-section .activities-tab-wrapper .activities-tab-content-wrap .activities-content .content-bottom-area {
    flex-wrap: wrap;
  }
}
.home6-activities-section .activities-tab-wrapper .activities-tab-content-wrap .activities-content .content-bottom-area .primary-btn1 {
  padding: 16px 25px;
}
.home6-activities-section .activities-tab-wrapper .activities-tab-content-wrap .activities-content .content-bottom-area .video-area {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.home6-activities-section .activities-tab-wrapper .activities-tab-content-wrap .activities-content .content-bottom-area .video-area .icon {
  position: relative;
}
.home6-activities-section .activities-tab-wrapper .activities-tab-content-wrap .activities-content .content-bottom-area .video-area .icon .video-circle {
  stroke: var(--title-color);
  fill: none;
  transition: 0.5s;
}
.home6-activities-section .activities-tab-wrapper .activities-tab-content-wrap .activities-content .content-bottom-area .video-area .icon .video-circle .top-half {
  stroke-dasharray: 320;
  stroke-dashoffset: 320;
  transition: stroke-dashoffset 0.8s cubic-bezier(0.33, 1, 0.68, 1);
}
.home6-activities-section .activities-tab-wrapper .activities-tab-content-wrap .activities-content .content-bottom-area .video-area .icon i {
  color: var(--title-color);
  font-size: 30px;
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.home6-activities-section .activities-tab-wrapper .activities-tab-content-wrap .activities-content .content-bottom-area .video-area h6 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.48px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.home6-activities-section .activities-tab-wrapper .activities-tab-content-wrap .activities-content .content-bottom-area .video-area:hover .icon .video-circle .top-half {
  stroke-dashoffset: 0;
}
.home6-activities-section .activities-tab-wrapper .activities-tab-content-wrap .tab-with-slider-img-wrap {
  position: relative;
}
.home6-activities-section .activities-tab-wrapper .activities-tab-content-wrap .tab-with-slider-img-wrap .tab-with-slider-img {
  position: relative;
  border-radius: 5px;
}
@media (max-width: 991px) {
  .home6-activities-section .activities-tab-wrapper .activities-tab-content-wrap .tab-with-slider-img-wrap .tab-with-slider-img {
    display: none;
    visibility: hidden;
  }
}
.home6-activities-section .activities-tab-wrapper .activities-tab-content-wrap .tab-with-slider-img-wrap .tab-with-slider-img img {
  border-radius: 5px;
}
@media (max-width: 991px) {
  .home6-activities-section .activities-tab-wrapper .activities-tab-content-wrap .tab-with-slider-img-wrap .tab-with-slider-img img {
    width: 100%;
  }
}
.home6-activities-section .activities-tab-wrapper .activities-tab-content-wrap .tab-with-slider-img-wrap .tab-with-slider-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(16, 12, 8, 0.3);
  border-radius: 5px;
}
.home6-activities-section .activities-tab-wrapper .activities-tab-content-wrap .tab-with-slider-img-wrap .slider-btn-grp2 {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: var(--white-color);
  gap: 25px;
  padding: 15px;
  border-radius: 5px 0 0px 0;
  z-index: 1;
}
@media (max-width: 991px) {
  .home6-activities-section .activities-tab-wrapper .activities-tab-content-wrap .tab-with-slider-img-wrap .slider-btn-grp2 {
    display: none;
    visibility: hidden;
  }
}
.home6-activities-section .activities-tab-wrapper .activities-tab-content-wrap .tab-with-slider-img-wrap .slider-btn-grp2 .slider-btn {
  min-width: 28px;
  max-width: 28px;
  height: 28px;
}

/*=====================================
  68  .Home6 Banner2 section
========================================*/
.home6-banner2-area {
  background-image: url(../img/home6/home6-banner2-bg.png), linear-gradient(180deg, #1d231f 0%, #1d231f 100%);
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}
.home6-banner2-area .home6-banner2-content {
  text-align: center;
}
@media (max-width: 991px) {
  .home6-banner2-area .home6-banner2-content {
    padding: 50px 20px;
  }
}
.home6-banner2-area .home6-banner2-content span {
  color: var(--primary-color1);
  font-family: var(--font-satisfy);
  font-size: 25px;
  font-weight: 400;
  line-height: 1;
  display: inline-block;
  margin-bottom: 20px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home6-banner2-area .home6-banner2-content span {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .home6-banner2-area .home6-banner2-content span {
    margin-bottom: 10px;
  }
}
.home6-banner2-area .home6-banner2-content h2 {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 57px;
  font-weight: 700;
  line-height: 1.1;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home6-banner2-area .home6-banner2-content h2 {
    font-size: 50px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .home6-banner2-area .home6-banner2-content h2 {
    font-size: 42px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home6-banner2-area .home6-banner2-content h2 {
    font-size: 35px;
  }
}
@media (max-width: 767px) {
  .home6-banner2-area .home6-banner2-content h2 {
    font-size: 50px;
  }
}
@media (max-width: 576px) {
  .home6-banner2-area .home6-banner2-content h2 {
    font-size: 32px;
  }
}
.home6-banner2-area .home6-banner2-content h2 strong {
  color: var(--primary-color2);
  font-size: 45px;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .home6-banner2-area .home6-banner2-content h2 strong {
    font-size: 40px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .home6-banner2-area .home6-banner2-content h2 strong {
    font-size: 38px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home6-banner2-area .home6-banner2-content h2 strong {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .home6-banner2-area .home6-banner2-content h2 strong {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .home6-banner2-area .home6-banner2-content h2 strong {
    font-size: 28px;
    font-weight: 600;
  }
}
.home6-banner2-area .home6-banner2-content p {
  color: var(--white-color);
  font-family: var(--font-jost);
  font-size: 18px;
  font-weight: 400;
  line-height: 1.7;
  letter-spacing: 0.54px;
  text-transform: capitalize;
  max-width: 420px;
  width: 100%;
  margin: 0 auto 35px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home6-banner2-area .home6-banner2-content p {
    font-size: 16px;
    margin: 0 auto 20px;
  }
}
@media (max-width: 767px) {
  .home6-banner2-area .home6-banner2-content p {
    margin: 0 auto 20px;
  }
}
@media (max-width: 576px) {
  .home6-banner2-area .home6-banner2-content p {
    font-size: 15px;
  }
}
@media (max-width: 991px) {
  .home6-banner2-area .home6-banner2-img {
    display: none;
  }
}

/*=====================================
  69  .Home6 Feature section
========================================*/
.home6-feature-section .feature-content .single-feature {
  display: flex;
  gap: 15px;
}
@media (max-width: 767px) {
  .home6-feature-section .feature-content .single-feature {
    gap: 10px;
  }
}
.home6-feature-section .feature-content .single-feature .icon {
  min-width: 40px;
  max-width: 40px;
}
.home6-feature-section .feature-content .single-feature .content h5 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 22px;
  font-weight: 600;
  line-height: 1.2;
  text-transform: capitalize;
  margin-bottom: 8px;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .home6-feature-section .feature-content .single-feature .content h5 {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .home6-feature-section .feature-content .single-feature .content h5 {
    font-size: 20px;
    margin-bottom: 5px;
  }
}
.home6-feature-section .feature-content .single-feature .content p {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 15px;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.45px;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .home6-feature-section .feature-content .single-feature .content p {
    font-size: 14px;
  }
}
.home6-feature-section .feature-img-wrap {
  position: relative;
  width: 100%;
}
@media (max-width: 1199px) {
  .home6-feature-section .feature-img-wrap {
    display: none;
  }
}
.home6-feature-section .feature-img-wrap .feature-top-img {
  position: relative;
  max-width: 470px;
  width: 100%;
}
.home6-feature-section .feature-img-wrap .feature-top-img img {
  border-radius: 5px;
}
.home6-feature-section .feature-img-wrap .feature-top-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(16, 12, 8, 0.3);
  border-radius: 5px;
}
.home6-feature-section .feature-img-wrap .feature-bottom-img {
  position: relative;
  border-radius: 340px;
  margin-left: -200px;
  transform: translateY(170px);
}
.home6-feature-section .feature-img-wrap .feature-bottom-img img {
  border-radius: 340px;
}
.home6-feature-section .feature-img-wrap .feature-bottom-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(16, 12, 8, 0.2);
  border-radius: 340px;
}

/*=====================================
  70 .Home6 Visa Application section
========================================*/
.home6-visa-application-section {
  background-image: url(../img/home6/home6-visa-section-bg.png), linear-gradient(180deg, #eef3f1 0%, #eef3f1 100%);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 110px 0;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home6-visa-application-section {
    padding: 90px 0;
  }
}
@media (max-width: 991px) {
  .home6-visa-application-section {
    padding: 70px 0;
  }
}

/*=====================================
  71 .Home6 Testimonial section
========================================*/
.home6-testimonial-section {
  background-image: url(../img/home6/home6-testimonial-bg.png), linear-gradient(180deg, #f4f9f1 0%, #f4f9f1 100%);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 110px 0;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home6-testimonial-section {
    padding: 90px 0;
  }
}
@media (max-width: 991px) {
  .home6-testimonial-section {
    padding: 70px 0;
  }
}
.home6-testimonial-section .testimonial-slider-area {
  position: relative;
}
.home6-testimonial-section .testimonial-slider-area .swiper-slide-active .author-img {
  animation: fadeInDown 1.7s;
}
.home6-testimonial-section .testimonial-slider-area .swiper-slide-active h3 {
  animation: fadeInDown 1.7s;
}
.home6-testimonial-section .testimonial-slider-area .swiper-slide-active p {
  animation: fadeInDown 1.7s;
}
.home6-testimonial-section .testimonial-slider-area .swiper-slide-active .testimonial-bottom {
  animation: fadeInUp 1.7s;
}
.home6-testimonial-section .testimonial-slider-area .testimonial-wrapper {
  text-align: center;
}
.home6-testimonial-section .testimonial-slider-area .testimonial-wrapper .author-img {
  margin-bottom: 30px;
}
@media (max-width: 1199px) {
  .home6-testimonial-section .testimonial-slider-area .testimonial-wrapper .author-img {
    margin-bottom: 20px;
  }
}
@media (max-width: 576px) {
  .home6-testimonial-section .testimonial-slider-area .testimonial-wrapper .author-img {
    margin-bottom: 15px;
  }
}
.home6-testimonial-section .testimonial-slider-area .testimonial-wrapper .author-img img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.home6-testimonial-section .testimonial-slider-area .testimonial-wrapper h3 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 30px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.9px;
  margin-bottom: 25px;
}
@media (max-width: 1199px) {
  .home6-testimonial-section .testimonial-slider-area .testimonial-wrapper h3 {
    font-size: 28px;
    margin-bottom: 20px;
  }
}
@media (max-width: 576px) {
  .home6-testimonial-section .testimonial-slider-area .testimonial-wrapper h3 {
    font-size: 24px;
    margin-bottom: 15px;
  }
}
.home6-testimonial-section .testimonial-slider-area .testimonial-wrapper p {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 21px;
  font-weight: 400;
  line-height: 2.3;
  letter-spacing: 0.84px;
  margin-bottom: 30px;
}
@media (max-width: 1199px) {
  .home6-testimonial-section .testimonial-slider-area .testimonial-wrapper p {
    font-size: 18px;
    margin-bottom: 25px;
  }
}
@media (max-width: 576px) {
  .home6-testimonial-section .testimonial-slider-area .testimonial-wrapper p {
    font-size: 16px;
    margin-bottom: 20px;
  }
}
.home6-testimonial-section .testimonial-slider-area .testimonial-wrapper .testimonial-bottom .quote {
  margin-bottom: 25px;
}
@media (max-width: 576px) {
  .home6-testimonial-section .testimonial-slider-area .testimonial-wrapper .testimonial-bottom .quote {
    margin-bottom: 15px;
  }
}
.home6-testimonial-section .testimonial-slider-area .testimonial-wrapper .testimonial-bottom .quote svg {
  fill: var(--title-color);
  opacity: 0.15;
}
.home6-testimonial-section .testimonial-slider-area .testimonial-wrapper .testimonial-bottom .author-name-and-desig {
  margin-bottom: 15px;
  line-height: 1;
}
.home6-testimonial-section .testimonial-slider-area .testimonial-wrapper .testimonial-bottom .author-name-and-desig h5 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 22px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 5px;
}
@media (max-width: 576px) {
  .home6-testimonial-section .testimonial-slider-area .testimonial-wrapper .testimonial-bottom .author-name-and-desig h5 {
    font-size: 20px;
  }
}
.home6-testimonial-section .testimonial-slider-area .testimonial-wrapper .testimonial-bottom .author-name-and-desig span {
  color: var(--text-color);
  font-family: var(--font-rubik);
  font-size: 13px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.26px;
}
.home6-testimonial-section .testimonial-slider-area .testimonial-wrapper .testimonial-bottom .rating {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-bottom: 15px;
}
.home6-testimonial-section .testimonial-slider-area .testimonial-wrapper .testimonial-bottom .rating li {
  line-height: 1;
}
.home6-testimonial-section .testimonial-slider-area .testimonial-wrapper .testimonial-bottom .rating li i {
  color: #FFC107;
  font-size: 16px;
}
.home6-testimonial-section .testimonial-slider-area .slider-btn-grp {
  display: block;
}
.home6-testimonial-section .testimonial-slider-area .slider-btn-grp .slider-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.home6-testimonial-section .testimonial-slider-area .slider-btn-grp .home6-testimonial-next {
  right: 0;
  left: unset;
}

/*=====================================
  Inner Pages section
========================================*/
.breadcrumb-section {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 120px 0 130px;
}
.breadcrumb-section .banner-content h1 {
  color: var(--white-color);
  text-align: center;
  font-family: var(--font-rubik);
  font-size: 52px;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 10px;
  line-height: 1.2;
}
@media (max-width: 767px) {
  .breadcrumb-section .banner-content h1 {
    font-size: 50px;
  }
}
@media (max-width: 576px) {
  .breadcrumb-section .banner-content h1 {
    font-size: 40px;
  }
}
.breadcrumb-section .banner-content .breadcrumb-list {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.breadcrumb-section .banner-content .breadcrumb-list li {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 15px;
  font-weight: 400;
  text-transform: capitalize;
  position: relative;
  padding-left: 26px;
}
.breadcrumb-section .banner-content .breadcrumb-list li a {
  color: var(--primary-color1);
}
.breadcrumb-section .banner-content .breadcrumb-list li::before {
  content: url(../img/innerpage/icon/arrow.svg);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.breadcrumb-section .banner-content .breadcrumb-list li:first-child {
  padding-left: 0;
}
.breadcrumb-section .banner-content .breadcrumb-list li:first-child::before {
  content: "";
}

/*=====================================
 72 . Destination Details Page
========================================*/
.destination-details-wrap {
  position: relative;
  z-index: 1;
}
.destination-details-wrap::before {
  content: url(../img/home1/section-vector1.png);
  position: absolute;
  top: 120px;
  left: 0;
  z-index: -1;
}
@media (max-width: 767px) {
  .destination-details-wrap::before {
    display: none;
    visibility: hidden;
  }
}
.destination-details-wrap::after {
  content: url(../img/home1/section-vector2.png);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  z-index: -1;
}
@media (max-width: 767px) {
  .destination-details-wrap::after {
    display: none;
    visibility: hidden;
  }
}
.destination-details-wrap h2 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .destination-details-wrap h2 {
    font-size: 35px;
  }
}
.destination-details-wrap p {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 16px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0.48px;
}
.destination-details-wrap ul {
  -moz-columns: 2;
       columns: 2;
  margin-bottom: 40px;
}
@media (max-width: 576px) {
  .destination-details-wrap ul {
    -moz-columns: 1;
         columns: 1;
  }
}
.destination-details-wrap ul li {
  color: var(--title-color);
  font-family: var(--font-jost);
  font-size: 17px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 0.51px;
  padding-top: 10px;
  padding-left: 17px;
  position: relative;
}
.destination-details-wrap ul li::before {
  content: "";
  height: 9px;
  width: 9px;
  border-radius: 50%;
  background-color: var(--primary-color1);
  position: absolute;
  left: 0;
  top: 17px;
}

.destination-sidebar .destination-info {
  border-radius: 5px;
  border: 1px solid rgba(16, 12, 8, 0.1);
  background: #FFF;
  padding: 30px;
}
.destination-sidebar .destination-info .single-info {
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
  padding-top: 20px;
}
.destination-sidebar .destination-info .single-info:first-child {
  padding-top: 0;
}
.destination-sidebar .destination-info .single-info:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.destination-sidebar .destination-info .single-info span {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 15px;
  font-weight: 400;
  display: inline-block;
  position: relative;
  padding-left: 25px;
}
.destination-sidebar .destination-info .single-info span::before {
  content: "";
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 1px solid var(--primary-color1);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.destination-sidebar .destination-info .single-info span::after {
  content: "";
  height: 11px;
  width: 11px;
  border-radius: 50%;
  background-color: var(--primary-color1);
  position: absolute;
  left: 2px;
  top: 50%;
  transform: translateY(-50%);
}
.destination-sidebar .destination-info .single-info h5 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
}

.destination-gallery .gallery-img-wrap {
  position: relative;
  transition: all 0.45s;
  height: 100%;
}
.destination-gallery .gallery-img-wrap img {
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
}
@media (max-width: 767px) {
  .destination-gallery .gallery-img-wrap img {
    height: auto;
  }
}
.destination-gallery .gallery-img-wrap a {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(16, 12, 8, 0.6);
  color: var(--white-color);
  text-align: center;
  font-family: var(--font-rubik);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.6px;
  flex-direction: column;
  transition: all 0.45s;
  transform: scale(0.4);
  opacity: 0;
}
.destination-gallery .gallery-img-wrap a i {
  color: var(--primary-color2);
  font-size: 35px;
}
.destination-gallery .gallery-img-wrap:hover a {
  transform: scale(1);
  opacity: 1;
}

.destination-location-gallery h3 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 30px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 35px;
}
@media (max-width: 767px) {
  .destination-location-gallery h3 {
    font-size: 26px;
  }
}
@media (max-width: 576px) {
  .destination-location-gallery h3 {
    margin-bottom: 20px;
  }
}
.destination-location-gallery .nav-pills {
  -moz-columns: 6;
       columns: 6;
  display: block;
}
@media (max-width: 1199px) {
  .destination-location-gallery .nav-pills {
    -moz-columns: unset;
         columns: unset;
    display: flex;
    align-items: center;
    gap: 25px;
  }
}
@media (max-width: 576px) {
  .destination-location-gallery .nav-pills {
    display: block;
    -moz-columns: 2;
         columns: 2;
  }
}
@media (max-width: 576px) {
  .destination-location-gallery .nav-pills .nav-item {
    padding-top: 18px;
  }
}
.destination-location-gallery .nav-pills .nav-item .nav-link {
  border-radius: 10px;
  background: rgba(221, 238, 214, 0.5);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  color: var(--title-color);
  text-align: center;
  font-family: var(--font-rubik);
  font-size: 17px;
  font-weight: 500;
  width: 100%;
  padding: 50px 15px;
}
@media (max-width: 1199px) {
  .destination-location-gallery .nav-pills .nav-item .nav-link {
    padding: 50px 25px;
    min-width: 154px;
  }
}
@media (max-width: 576px) {
  .destination-location-gallery .nav-pills .nav-item .nav-link {
    padding: 30px 25px;
  }
}
.destination-location-gallery .nav-pills .nav-item .nav-link.active {
  background-color: unset;
  background-image: linear-gradient(270deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) 101.02%), url(../img/innerpage/location-gallery-bg1.jpg);
  color: var(--white-color);
}
.destination-location-gallery .nav-pills .nav-item .nav-link.active#location2-tab {
  background-image: linear-gradient(270deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) 101.02%), url(../img/innerpage/gallery-01.jpg);
}
.destination-location-gallery .nav-pills .nav-item .nav-link.active#location3-tab {
  background-image: linear-gradient(270deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) 101.02%), url(../img/innerpage/gallery-02.jpg);
}
.destination-location-gallery .nav-pills .nav-item .nav-link.active#location4-tab {
  background-image: linear-gradient(270deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) 101.02%), url(../img/innerpage/gallery-03.jpg);
}
.destination-location-gallery .nav-pills .nav-item .nav-link.active#location5-tab {
  background-image: linear-gradient(270deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) 101.02%), url(../img/innerpage/gallery-04.jpg);
}
.destination-location-gallery .nav-pills .nav-item .nav-link.active#location6-tab {
  background-image: linear-gradient(270deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) 101.02%), url(../img/innerpage/destination-img-01.jpg);
}

.desti-title h3 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 35px;
  font-weight: 600;
}
@media (max-width: 767px) {
  .desti-title h3 {
    font-size: 28px;
  }
}

.destination-activitis-wrap .slider-btn-grp2 {
  width: 100px;
}

.package-card-tab-slider {
  padding: 12px;
  margin: -12px;
}

.width-100 {
  width: 100px;
}

.list-grid-product-wrap.list-group-wrapper .item {
  width: 100%;
}
.list-grid-product-wrap.list-group-wrapper .item .package-card {
  display: flex;
  align-items: center;
  gap: 20px;
}
.list-grid-product-wrap.list-group-wrapper .item .package-card .package-card-img-wrap {
  max-width: 350px;
  width: 100%;
}
@media (max-width: 1199px) {
  .list-grid-product-wrap.list-group-wrapper .item .package-card .package-card-img-wrap {
    max-width: 290px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .list-grid-product-wrap.list-group-wrapper .item .package-card .package-card-content .card-content-top h5 a {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .list-grid-product-wrap.list-group-wrapper .item {
    display: none;
  }
}

.visa-with-sidebar-section .list-grid-product-wrap.grid-group-wrapper .item {
  width: 50%;
}
.visa-with-sidebar-section .list-grid-product-wrap.grid-group-wrapper .item .package-card4 {
  display: block;
}
.visa-with-sidebar-section .list-grid-product-wrap.grid-group-wrapper .item .package-card4 .package-card-img {
  min-width: unset;
  max-width: unset;
  border-radius: 5px 5px 0 0;
}
.visa-with-sidebar-section .list-grid-product-wrap.grid-group-wrapper .item .package-card4 .package-card-img img {
  min-width: unset;
  max-width: unset;
  width: 100%;
  border-radius: 5px 5px 0 0;
  -o-object-position: center;
     object-position: center;
}

/*=====================================
 73 . Error Page
========================================*/
.error-section {
  background-image: url(../img/innerpage/error-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 200px 0;
}
@media (max-width: 1199px) {
  .error-section {
    padding: 170px 0;
  }
}
@media (max-width: 576px) {
  .error-section {
    padding: 130px 0;
  }
}
.error-section .error-wrapper {
  text-align: center;
}
.error-section .error-wrapper h1 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 100px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-bottom: 25px;
}
@media (max-width: 1399px) {
  .error-section .error-wrapper h1 {
    font-size: 90px;
  }
}
@media (max-width: 1199px) {
  .error-section .error-wrapper h1 {
    font-size: 75px;
    margin-bottom: 20px;
  }
}
@media (max-width: 576px) {
  .error-section .error-wrapper h1 {
    font-size: 55px;
    margin-bottom: 15px;
  }
}
.error-section .error-wrapper h1 span {
  color: var(--primary-color2);
  font-weight: 300;
}
.error-section .error-wrapper p {
  color: var(--title-color);
  font-family: var(--font-jost);
  font-size: 15px;
  font-weight: 300;
  line-height: 2.3;
  letter-spacing: 0.45px;
  margin-bottom: 50px;
}
@media (max-width: 1199px) {
  .error-section .error-wrapper p {
    margin-bottom: 35px;
  }
}
@media (max-width: 576px) {
  .error-section .error-wrapper p {
    margin-bottom: 25px;
  }
}

/*=====================================
 74 . Faq Page
========================================*/
.faq-section .faq-content-wrap .faq-content-title h3 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 35px;
  font-weight: 700;
  line-height: 1.1;
  letter-spacing: 1.05px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  gap: 10px;
}
@media (max-width: 767px) {
  .faq-section .faq-content-wrap .faq-content-title h3 {
    font-size: 30px;
  }
}
.faq-section .faq-content-wrap .faq-content-title h3 svg {
  fill: var(--primary-color1);
}

/*=====================================
 75 . contact Page
========================================*/
.contact-page .single-contact {
  border: 1px solid #eee;
  border-radius: 10px;
  padding: 20px 50px;
  display: flex;
  align-items: center;
  gap: 15px;
  position: relative;
}
@media (max-width: 1399px) {
  .contact-page .single-contact {
    padding: 20px 30px;
  }
}
.contact-page .single-contact .title {
  line-height: 1;
  background-color: var(--white-color);
  position: absolute;
  left: 35px;
  top: -8px;
  padding: 0 20px;
}
.contact-page .single-contact .title h6 {
  color: var(--text-color);
  font-family: var(--font-rubik);
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 0;
}
.contact-page .single-contact .icon {
  height: 44px;
  min-width: 44px;
  max-width: 44px;
  border-radius: 50px;
  background-color: var(--primary-color1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-page .single-contact .icon svg {
  fill: var(--white-color);
}
.contact-page .single-contact .content {
  line-height: 1;
}
.contact-page .single-contact .content h6 {
  margin-bottom: 0;
}
.contact-page .single-contact .content h6 a {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 16px;
  font-weight: 500;
  padding-top: 10px;
  transition: 0.35s;
}
.contact-page .single-contact .content h6 a:hover {
  color: var(--primary-color1);
}

.contact-form-area {
  background: linear-gradient(125deg, rgba(99, 171, 69, 0.1) 0%, rgba(251, 176, 59, 0.1) 100%);
  border-radius: 10px;
  padding: 60px 70px;
}
@media (max-width: 1399px) {
  .contact-form-area {
    padding: 60px 30px;
  }
}
.contact-form-area h3 {
  font-size: 30px;
  font-family: var(--font-rubik);
  font-weight: 700;
  margin-bottom: 20px;
}

.contact-map {
  line-height: 0.6;
  position: relative;
}
.contact-map .banner3-section {
  margin: 0;
  position: absolute;
  width: 100%;
  bottom: -130px;
}
@media (max-width: 991px) {
  .contact-map .banner3-section {
    bottom: -70px;
  }
}
.contact-map .banner3-section .banner3-content .from-inner button {
  padding: 16px 16px;
  border-radius: 0 10px 10px 0;
}
.contact-map iframe {
  width: 100%;
  min-height: 700px;
}

.form-inner {
  line-height: 1;
}
.form-inner label {
  color: #5d5b58;
  font-family: var(--font-rubik);
  font-size: 13px;
  font-weight: 500;
  display: inline-block;
  line-height: 1;
  margin-bottom: 7px;
}
.form-inner label span {
  color: red;
  font-size: 16px;
}
.form-inner input {
  width: 100%;
  border-radius: 0px;
  background: #fff;
  color: var(--title-color);
  font-family: var(--font-jost);
  font-size: 13px;
  font-weight: 400;
  padding: 10px 20px;
  height: 50px;
  border: 1px solid #eee;
}
.form-inner input::-moz-placeholder {
  color: #5E5E5E;
}
.form-inner input::placeholder {
  color: #5E5E5E;
}
.form-inner textarea {
  width: 100%;
  border-radius: 5px;
  background: #fff;
  color: #5E5E5E;
  border: 1px solid #eee;
  outline: none;
  font-family: var(--font-jost);
  font-size: 13px;
  font-weight: 400;
  padding: 20px 25px;
  min-height: 150px;
}
.form-inner .surname-options {
  display: flex;
  align-items: center;
  gap: 15px;
}
.form-inner .surname-options .form-check {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  background: var(--white-color);
  margin: 0;
  padding: 0;
  max-width: 52px;
  min-width: 52px;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
}
.form-inner .surname-options .form-check .form-check-input {
  margin: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: none;
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid transparent;
  transition: 0.35s;
  cursor: pointer;
  z-index: 2;
}
.form-inner .surname-options .form-check .form-check-input:focus {
  box-shadow: none;
}
.form-inner .surname-options .form-check .form-check-label {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 0;
  z-index: 1;
  border: 1px solid #eee;
  border-radius: 5px;
  max-width: 52px;
  min-width: 52px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-inner .surname-options .form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: unset;
  border-color: var(--primary-color1);
}
.form-inner .surname-options .form-check-input:checked[type=radio] ~ .form-check-label {
  background-color: var(--primary-color1);
  color: var(--white-color);
}

.visa-details-pages .mb-70 {
  margin-bottom: 70px;
}
.visa-details-pages .visa-thumb {
  margin-bottom: 35px;
}
.visa-details-pages .visa-thumb img {
  border-radius: 5px;
}
.visa-details-pages .visa-title {
  line-height: 1;
  margin-bottom: 25px;
}
.visa-details-pages .visa-title h3 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 35px;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 1.3;
}
.visa-details-pages .visa-meta {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 22px;
  margin-bottom: 50px;
}
.visa-details-pages .visa-meta li {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
  text-transform: capitalize;
  padding-right: 20px;
}
.visa-details-pages .visa-meta li span {
  color: var(--title-color);
  font-weight: 500;
}
.visa-details-pages .visa-required-document h3 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 20px;
}
.visa-details-pages .visa-required-document .document-list {
  border-radius: 5px;
  border: 1px solid rgba(238, 238, 238, 0.8);
  background: #FFF;
  padding: 30px;
}
.visa-details-pages .visa-required-document .document-list h6 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.54px;
  margin-bottom: 20px;
}
.visa-details-pages .visa-required-document .document-list h6 span {
  color: red;
}
.visa-details-pages .visa-required-document .document-list ul li {
  color: var(--text-color);
  font-family: Jost;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.32px;
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}
.visa-details-pages .visa-required-document .document-list ul li:last-child {
  margin-bottom: 0;
}
.visa-details-pages .visa-required-document .document-list ul li svg {
  fill: var(--primary-color1);
  margin-top: 5px;
  min-width: 18px;
}
.visa-details-pages .visa-sidebar .sidebar-top {
  background: linear-gradient(125deg, rgba(99, 171, 69, 0.1) 0%, rgba(251, 176, 59, 0.1) 100%);
  box-shadow: 4px 3px 40px 0px rgba(16, 33, 34, 0.06);
  padding: 30px;
  border-radius: 5px;
  margin-bottom: 30px;
}
.visa-details-pages .visa-sidebar .sidebar-top h4 {
  color: var(--title-color);
  text-align: center;
  font-family: var(--font-rubik);
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 0;
}
.visa-details-pages .visa-sidebar .sidebar-top p {
  color: var(--text-color);
  text-align: center;
  font-family: var(--font-jost);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 0;
}
.visa-details-pages .visa-sidebar .sidebar-top h6 {
  color: var(--primary-color1);
  text-align: center;
  font-family: var(--font-rubik);
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 10px;
}
.visa-details-pages .visa-sidebar .sidebar-top h6 span {
  color: var(--text-color);
  font-weight: 400;
  font-size: 16px;
}
.visa-details-pages .visa-sidebar .inquery-form {
  background: linear-gradient(125deg, rgba(99, 171, 69, 0.1) 0%, rgba(251, 176, 59, 0.1) 100%);
  box-shadow: 4px 3px 40px 0px rgba(16, 33, 34, 0.06);
  padding: 30px;
  border-radius: 5px;
}
.visa-details-pages .visa-sidebar .inquery-form h4 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 15px;
}
.visa-details-pages .visa-sidebar .inquery-form .form-title {
  margin-bottom: 10px;
}
.visa-details-pages .visa-sidebar .inquery-form .form-title h4 {
  margin-bottom: 7px;
}
.visa-details-pages .visa-sidebar .inquery-form .form-title p {
  color: var(--text-color);
  font-size: 14px;
  line-height: 26px;
}
.visa-details-pages .visa-sidebar .inquery-form .form-inner .nice-select {
  background-color: #FAF8FB;
  border: 1px solid #eee;
  border-radius: 0px;
  height: 50px;
  padding: 0px 20px;
}
.visa-details-pages .visa-sidebar .inquery-form .form-inner .nice-select .list {
  border-radius: 0px;
}
.visa-details-pages .visa-sidebar .inquery-form .form-inner .nice-select .list .option.selected {
  background-color: rgba(var(--primary-color1-opc), 0.3);
  color: var(--title-color);
}
.visa-details-pages .visa-sidebar .inquery-form .form-inner .nice-select .list .option:hover {
  background-color: rgba(var(--primary-color1-opc), 0.3);
  color: var(--title-color);
}
.visa-details-pages .visa-sidebar .inquery-form .form-inner textarea {
  padding: 10px 20px;
  border-radius: 0px;
}
.visa-details-pages .visa-sidebar .inquery-form .form-inner .primary-btn1 {
  padding: 19px 34px;
}
.visa-details-pages .visa-sidebar .inquery-form .form-inner .surname-options .form-check {
  cursor: pointer;
}
.visa-details-pages .visa-sidebar .inquery-form .form-inner .surname-options .form-check .form-check-input {
  background-color: transparent;
}

.product-card {
  border: 1px solid #ececec;
  padding: 15px;
  transition: 0.5s;
  position: relative;
}
.product-card .product-card-img {
  position: relative;
  overflow: hidden;
}
.product-card .product-card-img > a img {
  transition: all 0.5s ease-out;
}
.product-card .product-card-img > a .batch {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5px;
}
.product-card .product-card-img > a .batch > span {
  width: 52px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white-color);
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  font-family: var(--font-rubik);
  letter-spacing: 0.3px;
  text-transform: uppercase;
  background-color: var(--primary-color1);
  z-index: 1;
}
.product-card .product-card-img .cart-area {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: bottom;
  transition: 0.5s ease-in-out;
}
.product-card .product-card-img .cart-area .add-cart-btn {
  color: var(--white-color);
  text-align: center;
  font-size: 15px;
  font-family: var(--font-jost);
  font-weight: 500;
  background: var(--primary-color1);
  padding: 12px 18px;
  line-height: 1;
  display: flex;
  align-items: baseline;
  gap: 7px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  white-space: nowrap;
  transition: 0.5s;
}
.product-card .product-card-img .cart-area .add-cart-btn::after {
  position: absolute;
  content: "";
  display: block;
  left: 15%;
  right: -20%;
  top: -4%;
  height: 150%;
  width: 150%;
  bottom: 0;
  border-radius: 2px;
  background-color: var(--primary-color2);
  transform: skewX(45deg) scale(0, 1);
  z-index: -1;
  transition: all 0.5s ease-out 0s;
}
.product-card .product-card-img .cart-area .add-cart-btn:hover {
  color: var(--title-color);
}
.product-card .product-card-img .cart-area .add-cart-btn:hover::after {
  transform: skewX(45deg) scale(1, 1);
}
.product-card .product-card-content {
  padding-top: 25px;
  padding-bottom: 10px;
  text-align: center;
}
.product-card .product-card-content h6 {
  margin-bottom: 15px;
}
.product-card .product-card-content h6 a {
  color: var(--title-color);
  font-size: 20px;
  font-family: var(--font-rubik);
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: capitalize;
  margin-bottom: 5px;
  line-height: 1.3;
  transition: 0.5s;
}
.product-card .product-card-content h6 a:hover {
  color: var(--primary-color1);
}
.product-card .product-card-content span {
  color: var(--primary-color1);
  font-size: 20px;
  font-family: var(--font-rubik);
  font-weight: 500;
  margin-bottom: 5px;
}
.product-card .product-card-content span del {
  color: rgba(16, 12, 8, 0.5);
  font-size: 15px;
}
.product-card .product-card-content .rating {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 8px;
}
.product-card .product-card-content .rating ul {
  display: flex;
  align-items: center;
  gap: 4px;
}
.product-card .product-card-content .rating ul li {
  line-height: 1;
}
.product-card .product-card-content .rating ul li i {
  color: #FFC200;
  font-size: 12px;
}
.product-card .product-card-content .rating span {
  color: var(--text-color);
  font-size: 13px;
  font-family: var(--font-jost);
  font-weight: 400;
  line-height: 1;
}
.product-card .for-border {
  width: 0;
}
.product-card .for-border::before {
  content: "";
  position: absolute;
  right: -0.5px;
  top: 0;
  height: 0%;
  width: 0.5px;
  background: rgba(0, 0, 0, 0.6);
  transition: 0.3s;
  transition-delay: 0.5s;
}
.product-card .for-border::after {
  content: "";
  position: absolute;
  left: -0.5px;
  bottom: 0;
  height: 0%;
  width: 0.5px;
  background: #000;
  transition: 0.3s;
  transition-delay: 0.6s;
}
.product-card::before {
  content: "";
  position: absolute;
  left: 0;
  top: -0.5px;
  width: 0%;
  height: 0.5px;
  background: #000;
  transition: 0.3s;
  transition-delay: 0.9s;
}
.product-card::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -0.5px;
  width: 0%;
  height: 0.5px;
  background: #000;
  transition: 0.3s;
  transition-delay: 0.9s;
}
.product-card:hover .product-card-img a img {
  transform: scale(1.1);
}
.product-card:hover .product-card-img .cart-area {
  opacity: 1;
  transform: scaleY(1);
}
.product-card:hover::before {
  width: 100%;
  transition-delay: 0s;
}
.product-card:hover::after {
  width: 100%;
  transition-delay: 0s;
}
.product-card:hover .for-border::before {
  height: 100%;
  transition-delay: 0.3s;
}
.product-card:hover .for-border::after {
  height: 100%;
  transition-delay: 0.3s;
}

.checkout-page .inquiry-form {
  border-radius: 10px;
  background: linear-gradient(125deg, rgba(99, 171, 69, 0.1) 0%, rgba(251, 176, 59, 0.1) 100%);
  padding: 45px 35px 50px;
}
@media (max-width: 767px) {
  .checkout-page .inquiry-form {
    padding: 45px 20px 50px;
  }
}
.checkout-page .inquiry-form .title {
  margin-bottom: 25px;
}
.checkout-page .inquiry-form .title h4 {
  margin-bottom: 0;
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 24px;
  font-weight: 600;
}
.checkout-page .inquiry-form .title p {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
}
.checkout-page .inquiry-form .form-inner {
  position: relative;
  line-height: 1;
}
.checkout-page .inquiry-form .form-inner label.containerss {
  display: flex;
  width: 100%;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.checkout-page .inquiry-form .form-inner label.containerss span {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 16px;
  font-weight: 400 !important;
  line-height: 1;
}
.checkout-page .inquiry-form .form-inner label.containerss span.text {
  font-weight: 600;
}
.checkout-page .inquiry-form .form-inner label.containerss input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkout-page .inquiry-form .form-inner label.containerss input:checked ~ .checkmark {
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
  border-radius: 2px;
}
.checkout-page .inquiry-form .form-inner label.containerss input:checked ~ .checkmark::after {
  content: url(../img/innerpage/checkbox-check.svg);
  left: 2.5px;
  top: -2px;
  position: absolute;
}
.checkout-page .inquiry-form .form-inner label.containerss .checkmark {
  position: absolute;
  top: 0px;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: var(--primary-color1);
  border: 1px solid var(--primary-color1);
  border-radius: 2px;
}
.checkout-page .inquiry-form .form-inner label.containerss:hover input ~ .checkmark {
  border-color: var(--primary-color1);
}
.checkout-page .inquiry-form .form-inner .primary-btn1 {
  justify-content: center;
  width: 100%;
}
.checkout-page .cart-menu {
  min-height: 10rem;
  z-index: 99;
  transform-origin: top;
}
.checkout-page .cart-body ul {
  margin-bottom: 50px;
}
.checkout-page .cart-body ul .single-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}
.checkout-page .cart-body ul .single-item:last-child {
  margin-bottom: 0;
}
.checkout-page .cart-body ul .single-item .item-area {
  display: flex;
  align-items: center;
  gap: 20px;
  position: relative;
  width: 100%;
}
.checkout-page .cart-body ul .single-item .item-area .close-btn {
  background: transparent;
  transition: 0.35s;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 72, 32, 0.14);
}
.checkout-page .cart-body ul .single-item .item-area .close-btn i {
  color: rgb(255, 72, 32);
  line-height: 1;
  font-size: 20px;
  transition: 0.35s;
}
.checkout-page .cart-body ul .single-item .item-area .close-btn:hover {
  background-color: #FF4820;
}
.checkout-page .cart-body ul .single-item .item-area .close-btn:hover i {
  color: var(--white-color);
}
.checkout-page .cart-body ul .single-item .item-area .main-item {
  display: flex;
  gap: 15px;
  width: 100%;
}
@media (max-width: 767px) {
  .checkout-page .cart-body ul .single-item .item-area .main-item {
    gap: 12px;
  }
}
.checkout-page .cart-body ul .single-item .item-area .main-item .item-img {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.checkout-page .cart-body ul .single-item .item-area .main-item .item-img img {
  height: 70px;
  max-width: 90px;
  min-width: 90px;
  border-radius: 5px;
}
@media (max-width: 767px) {
  .checkout-page .cart-body ul .single-item .item-area .main-item .item-img img {
    width: 45px;
    height: unset;
  }
}
.checkout-page .cart-body ul .single-item .item-area .main-item .content-and-quantity {
  width: 100%;
}
.checkout-page .cart-body ul .single-item .item-area .main-item .content {
  line-height: 1;
  margin-bottom: 10px;
}
.checkout-page .cart-body ul .single-item .item-area .main-item .content span {
  font-family: var(--font-rubik);
  font-weight: 600;
  font-size: 15px;
  color: var(--title-color);
  display: inline-block;
}
.checkout-page .cart-body ul .single-item .item-area .main-item .content h6 {
  margin-bottom: 0;
  line-height: 1;
}
.checkout-page .cart-body ul .single-item .item-area .main-item .content h6 a {
  font-family: var(--font-rubik);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  color: var(--title-color);
  transition: 0.5s;
}
@media (max-width: 767px) {
  .checkout-page .cart-body ul .single-item .item-area .main-item .content h6 a {
    font-size: 14px;
  }
}
.checkout-page .cart-body ul .single-item .item-area .main-item .content h6 a:hover {
  color: var(--primary-color1);
}
.checkout-page .cart-body ul .single-item .quantity-area .quantity {
  display: flex;
  gap: 5px;
}
.checkout-page .cart-body ul .single-item .quantity-area .quantity a {
  height: 24px;
  width: 34px;
  border-radius: 4px;
  background: var(--white-color);
  border: 1px solid rgba(238, 238, 238, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: var(--title-color);
  font-weight: 600;
  cursor: pointer;
  transition: 0.35s;
}
.checkout-page .cart-body ul .single-item .quantity-area .quantity a:hover {
  background: var(--primary-color1);
  border: 1px solid --primary-color1;
  color: var(--white-color);
}
.checkout-page .cart-body ul .single-item .quantity-area .quantity input {
  height: 24px;
  width: 34px;
  border-radius: 4px;
  background: var(--white-color);
  border: 1px solid rgba(238, 238, 238, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: var(--title-color);
  font-weight: 600;
  text-align: center;
}
.checkout-page .cart-footer {
  justify-content: flex-start;
  padding: 0;
  border-top: 1px solid #ddd;
}
.checkout-page .cart-footer .pricing-area {
  width: 100%;
}
.checkout-page .cart-footer .pricing-area ul {
  border-bottom: 1px solid #ddd;
  width: 100%;
  padding: 10px 0px 10px;
}
.checkout-page .cart-footer .pricing-area ul:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 30px;
}
.checkout-page .cart-footer .pricing-area ul li {
  font-family: var(--font-rubik);
  font-weight: 600;
  font-size: 13px;
  color: var(--title-color);
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.checkout-page .cart-footer .pricing-area ul.total li {
  font-weight: 700;
}
.checkout-page .choose-payment-method {
  margin-bottom: 40px;
  line-height: 1;
}
.checkout-page .choose-payment-method h6 {
  margin-bottom: 10px;
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 16px;
  font-weight: 600;
}
.checkout-page .choose-payment-method .payment-option {
  line-height: 1;
}
.checkout-page .choose-payment-method .payment-option ul {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.checkout-page .choose-payment-method .payment-option ul li {
  width: 110px;
  height: 68px;
  background-color: var(--white-color);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}
.checkout-page .choose-payment-method .payment-option ul li .checked {
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  background: #D2E7EF;
  -webkit-clip-path: polygon(0 0, 0% 100%, 100% 0);
          clip-path: polygon(0 0, 0% 100%, 100% 0);
  border-top-left-radius: 5px;
}
.checkout-page .choose-payment-method .payment-option ul li .checked i {
  opacity: 0;
  color: var(--white-color);
  margin-left: 2px;
}
.checkout-page .choose-payment-method .payment-option ul li.active .checked {
  background: var(--primary-color1);
}
.checkout-page .choose-payment-method .payment-option ul li.active .checked i {
  opacity: 1;
}

.cart-page .cart-widget-title {
  margin-bottom: 35px;
}
@media (max-width: 991px) {
  .cart-page .cart-widget-title {
    margin-bottom: 25px;
  }
}
.cart-page .cart-widget-title h4 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 24px;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 0;
}
.cart-page .cart-shopping-wrapper .cart-table {
  width: 100%;
}
.cart-page .cart-shopping-wrapper .cart-table thead tr {
  border-top: 1px solid rgba(238, 238, 238, 0.93);
  border-bottom: 1px solid rgba(238, 238, 238, 0.93);
}
.cart-page .cart-shopping-wrapper .cart-table thead tr th {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  padding: 20px 15px;
}
@media (max-width: 767px) {
  .cart-page .cart-shopping-wrapper .cart-table thead tr th {
    display: none;
  }
}
.cart-page .cart-shopping-wrapper .cart-table thead tr th:first-child {
  padding-left: 0;
}
@media (max-width: 767px) {
  .cart-page .cart-shopping-wrapper .cart-table tbody tr {
    border-bottom: 1px solid rgba(238, 238, 238, 0.93);
  }
}
.cart-page .cart-shopping-wrapper .cart-table tbody tr td {
  padding: 30px 15px;
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
}
.cart-page .cart-shopping-wrapper .cart-table tbody tr td span {
  color: var(--text-color);
}
.cart-page .cart-shopping-wrapper .cart-table tbody tr td:first-child {
  padding-left: 0;
}
@media (max-width: 767px) {
  .cart-page .cart-shopping-wrapper .cart-table tbody tr td {
    text-align: right;
  }
}
.cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}
@media (max-width: 767px) {
  .cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper {
    align-items: flex-end;
    flex-direction: column;
  }
}
.cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-img img {
  border-radius: 5px;
  max-width: 120px;
}
.cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-content h6 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 18px;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 10px;
}
.cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-content p {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 35px;
}
.cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-content p span {
  color: var(--title-color);
  font-weight: 500;
}
.cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-content ul {
  display: flex;
  align-items: center;
  gap: 20px;
}
@media (max-width: 767px) {
  .cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-content ul {
    justify-content: flex-end;
  }
}
.cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-content ul li {
  color: var(--text-color);
  font-family: var(--font-rubik);
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  cursor: pointer;
  position: relative;
  transition: 0.5s;
}
.cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-content ul li:last-child::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -10px;
  width: 1px;
  height: 11px;
  background-color: rgba(16, 12, 8, 0.2);
}
.cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-content ul li .quantity-area, .cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-content ul li .quantity-area2 {
  position: absolute;
  top: -30px;
  right: -60px;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: bottom;
}
@media (max-width: 991px) {
  .cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-content ul li .quantity-area, .cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-content ul li .quantity-area2 {
    right: unset;
    left: -60px;
  }
}
.cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-content ul li .quantity-area .quantity, .cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-content ul li .quantity-area2 .quantity {
  display: flex;
  gap: 5px;
}
.cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-content ul li .quantity-area .quantity a, .cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-content ul li .quantity-area2 .quantity a {
  height: 24px;
  width: 34px;
  border-radius: 4px;
  background-color: #f3f3f3;
  border: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: var(--title-color);
  font-weight: 600;
  cursor: pointer;
  transition: 0.35s;
}
.cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-content ul li .quantity-area .quantity a i, .cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-content ul li .quantity-area2 .quantity a i {
  color: var(--title-color);
  transition: 0.35s;
}
.cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-content ul li .quantity-area .quantity a:hover, .cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-content ul li .quantity-area2 .quantity a:hover {
  background: var(--primary-color1);
  border-color: var(--primary-color1);
}
.cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-content ul li .quantity-area .quantity a:hover i, .cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-content ul li .quantity-area2 .quantity a:hover i {
  color: var(--white-color);
}
.cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-content ul li .quantity-area .quantity input, .cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-content ul li .quantity-area2 .quantity input {
  height: 24px;
  width: 34px;
  border-radius: 4px;
  background: #FFF;
  border: 1px solid rgba(19, 20, 26, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: var(--title-color);
  font-weight: 600;
  text-align: center;
}
.cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-content ul li .quantity-area.active, .cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-content ul li .quantity-area2.active {
  opacity: 1;
  transform: scaleY(1);
  animation: fade-down 0.3s linear;
}
.cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-content ul li:hover {
  color: rgb(255, 72, 32);
}
.cart-page .cart-shopping-wrapper .cart-table tbody tr td .product-info-wrapper .product-info-content ul li:hover:last-child {
  color: var(--primary-color1);
}
@media (max-width: 767px) {
  .cart-page .cart-shopping-wrapper .cart-table tbody tr td {
    display: block;
    width: 100%;
    text-align: right;
    position: relative;
    padding: 15px;
  }
  .cart-page .cart-shopping-wrapper .cart-table tbody tr td::before {
    content: attr(data-label);
    position: absolute;
    left: 15px;
    color: var(--title-color);
    font-family: var(--font-jost);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    text-transform: capitalize;
  }
}
@media (max-width: 767px) and (max-width: 576px) {
  .cart-page .cart-shopping-wrapper .cart-table tbody tr td::before {
    font-size: 13px;
    font-weight: 700;
  }
}
@media (min-width: 992px) {
  .cart-page .cart-order-sum-area {
    padding-left: 15px;
  }
}
.cart-page .cart-order-sum-area .order-summary-wrap {
  padding: 40px;
  border: 1px solid #eee;
  border-radius: 10px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .cart-page .cart-order-sum-area .order-summary-wrap {
    padding: 40px 25px;
  }
}
@media (max-width: 576px) {
  .cart-page .cart-order-sum-area .order-summary-wrap {
    padding: 35px 25px;
  }
}
.cart-page .cart-order-sum-area .order-summary-wrap .order-summary-list {
  width: 100%;
}
.cart-page .cart-order-sum-area .order-summary-wrap .order-summary-list li {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 30px;
  line-height: 1;
}
.cart-page .cart-order-sum-area .order-summary-wrap .order-summary-list li:first-child {
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}
.cart-page .cart-order-sum-area .order-summary-wrap .order-summary-list li span {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  display: inline-block;
}
.cart-page .cart-order-sum-area .order-summary-wrap .order-summary-list li .order-info {
  text-align: right;
}
.cart-page .cart-order-sum-area .order-summary-wrap .order-summary-list li .order-info p {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 0;
}
.cart-page .cart-order-sum-area .order-summary-wrap .order-summary-list li .order-info p strong {
  font-weight: 500;
}
.cart-page .cart-order-sum-area .order-summary-wrap .order-summary-list li .order-info p.shipping {
  margin-bottom: 10px;
}
.cart-page .cart-order-sum-area .order-summary-wrap .order-summary-list li .coupon-area {
  width: 100%;
  margin-bottom: 10px;
}
.cart-page .cart-order-sum-area .order-summary-wrap .order-summary-list li .coupon-area span {
  margin-bottom: 15px;
}
.cart-page .cart-order-sum-area .order-summary-wrap .order-summary-list li .coupon-area .form-inner {
  position: relative;
}
.cart-page .cart-order-sum-area .order-summary-wrap .order-summary-list li .coupon-area .form-inner input {
  width: 100%;
  height: 42px;
  padding: 10px 100px 10px 20px;
  background-color: #F6F2F8;
  border-radius: 5px 0 0 5px;
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
}
.cart-page .cart-order-sum-area .order-summary-wrap .order-summary-list li .coupon-area .form-inner input::-moz-placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.cart-page .cart-order-sum-area .order-summary-wrap .order-summary-list li .coupon-area .form-inner input::placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.cart-page .cart-order-sum-area .order-summary-wrap .order-summary-list li .coupon-area .form-inner .primary-btn1 {
  padding: 12px 23px;
  border-radius: 0 5px 5px 0;
  background-color: var(--title-color);
  border: 1px solid var(--title-color);
  position: absolute;
  top: 0;
  right: 0;
}
.cart-page .cart-order-sum-area .order-summary-wrap .order-summary-list li .coupon-area .form-inner .primary-btn1::after {
  background-color: var(--white-color);
}
.cart-page .cart-order-sum-area .order-summary-wrap .order-summary-list li:last-child {
  margin-bottom: 40px;
}
.cart-page .cart-order-sum-area .order-summary-wrap .order-summary-list li:last-child span {
  font-size: 16px;
  font-weight: 600;
}
.cart-page .cart-order-sum-area .order-summary-wrap > .primary-btn1 {
  width: 100%;
  justify-content: center;
  padding: 18px 25px;
}

.product-details-top-section .product-details-img {
  position: relative;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .product-details-top-section .product-details-img {
    flex-direction: column;
    gap: 40px;
  }
}
@media (max-width: 576px) {
  .product-details-top-section .product-details-img {
    flex-direction: column;
    gap: 40px;
  }
}
.product-details-top-section .product-details-img .product-details-tab-img {
  position: relative;
  overflow: hidden;
}
@media (max-width: 991px) {
  .product-details-top-section .product-details-img .product-details-tab-img img {
    width: 100%;
  }
}
.product-details-top-section .product-details-img .nav-pills {
  padding-top: 30px;
  -moz-columns: 4;
       columns: 4;
  display: block;
}
@media (max-width: 576px) {
  .product-details-top-section .product-details-img .nav-pills {
    -moz-columns: unset;
         columns: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
}
.product-details-top-section .product-details-img .nav-pills .nav-link {
  background-color: unset;
  border-radius: unset;
  padding: 0;
  position: relative;
}
@media (max-width: 576px) {
  .product-details-top-section .product-details-img .nav-pills .nav-link img {
    max-width: 100px;
    min-width: 100px;
  }
}
.product-details-top-section .product-details-img .nav-pills .nav-link::after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: var(--title-color);
  opacity: 0.5;
}
.product-details-top-section .product-details-img .nav-pills .nav-link.active::after {
  display: none;
}
.product-details-top-section .product-details-content h1 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 40px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.7px;
  text-transform: capitalize;
  margin-bottom: 10px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .product-details-top-section .product-details-content h1 {
    font-size: 33px;
  }
}
@media (max-width: 576px) {
  .product-details-top-section .product-details-content h1 {
    font-size: 30px;
    margin-bottom: 15px;
  }
}
.product-details-top-section .product-details-content .rating-review {
  display: flex;
  align-items: center;
  gap: 40px;
  margin-bottom: 20px;
}
@media (max-width: 576px) {
  .product-details-top-section .product-details-content .rating-review {
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
  }
}
.product-details-top-section .product-details-content .rating-review .rating {
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 1;
}
.product-details-top-section .product-details-content .rating-review .rating .star {
  display: flex;
  align-items: center;
  gap: 6px;
}
.product-details-top-section .product-details-content .rating-review .rating .star i {
  font-size: 14px;
  color: #ffc107;
}
.product-details-top-section .product-details-content .rating-review .rating a {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 15px;
  font-weight: 400;
  text-transform: capitalize;
  transition: 0.5s;
}
.product-details-top-section .product-details-content .rating-review .rating a:hover {
  color: var(--primary-color1);
}
.product-details-top-section .product-details-content p {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.8;
  letter-spacing: 0.48px;
  margin-bottom: 20px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .product-details-top-section .product-details-content p {
    font-size: 15px;
  }
}
.product-details-top-section .product-details-content .price-area {
  margin-bottom: 35px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .product-details-top-section .product-details-content .price-area {
    margin-bottom: 30px;
  }
}
.product-details-top-section .product-details-content .price-area span {
  color: var(--primary-color1);
  font-family: var(--font-rubik);
  font-size: 25px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 0;
}
.product-details-top-section .product-details-content .price-area span del {
  color: rgba(16, 12, 8, 0.5);
  font-size: 18px;
}
.product-details-top-section .product-details-content .quantity-area {
  margin-bottom: 35px;
}
.product-details-top-section .product-details-content .quantity-area h6 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.48px;
  margin-bottom: 20px;
}
@media (max-width: 576px) {
  .product-details-top-section .product-details-content .quantity-area h6 {
    margin-bottom: 15px;
  }
}
.product-details-top-section .product-details-content .quantity-area .quantity-counter {
  display: flex;
  align-items: center;
  gap: 10px;
}
.product-details-top-section .product-details-content .quantity-area .quantity-counter a {
  width: 40px;
  height: 40px;
  background-color: var(--white-color);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--title-color);
  border: 1px solid var(--title-color);
  transition: 0.5s;
}
.product-details-top-section .product-details-content .quantity-area .quantity-counter a:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}
.product-details-top-section .product-details-content .quantity-area .quantity-counter .quantity__input {
  max-width: 58px;
  width: 100%;
  height: 40px;
  border: 1px solid var(--title-color);
  text-align: center;
  color: var(--title-color);
  font-family: var(--font-jost);
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.48px;
}
.product-details-top-section .product-details-content .quantity-area .quantity-counter .quantity__input:focus {
  border: 1px solid #ddd;
}
.product-details-top-section .product-details-content .product-details-btn {
  display: flex;
  align-items: center;
  gap: 30px;
  border-bottom: 1px solid var(--primary-color1);
  padding-bottom: 30px;
  flex-wrap: wrap;
}
@media (max-width: 576px) {
  .product-details-top-section .product-details-content .product-details-btn {
    gap: 20px;
  }
}
.product-details-top-section .product-details-content .product-details-btn .primary-btn1 {
  padding: 16px 25px;
}
.product-details-top-section .product-details-content .product-details-btn .secondary-btn4 {
  padding: 16px 26px;
}
.product-details-top-section .product-details-content .product-info {
  padding-top: 25px;
  margin-bottom: 30px;
}
.product-details-top-section .product-details-content .product-info .product-info-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 70px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .product-details-top-section .product-details-content .product-info .product-info-list {
    gap: 40px;
  }
}
@media (max-width: 576px) {
  .product-details-top-section .product-details-content .product-info .product-info-list {
    flex-wrap: wrap;
    gap: 8px;
  }
}
.product-details-top-section .product-details-content .product-info .product-info-list li {
  color: var(--text-color);
  font-family: var(--font-rubik);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.32px;
  text-transform: capitalize;
}
.product-details-top-section .product-details-content .product-info .product-info-list li a {
  color: var(--text-color);
  transition: 0.35s;
}
.product-details-top-section .product-details-content .product-info .product-info-list li a:hover {
  color: var(--primary-color1);
}
@media (max-width: 576px) {
  .product-details-top-section .product-details-content .product-info .product-info-list li {
    margin-right: 15px;
  }
}
.product-details-top-section .product-details-content .product-info .product-info-list li span {
  color: var(--title-color);
  font-family: var(--font-rubik);
}
.product-details-top-section .product-details-content .payment-method {
  padding: 17px 30px;
  border: 1px solid rgba(34, 34, 34, 0.1000000015);
  max-width: 460px;
  width: 100%;
  margin-bottom: 20px;
}
.product-details-top-section .product-details-content .payment-method h6 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.48px;
  text-transform: capitalize;
  margin-bottom: 15px;
}
.product-details-top-section .product-details-content .payment-method .payment-card-list {
  display: flex;
  align-items: center;
  gap: 20px;
}
.product-details-top-section .product-details-content .product-shipping-delivers li {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}
.product-details-top-section .product-details-content .product-shipping-delivers li:last-child {
  margin-bottom: 0;
}
.product-details-top-section .product-details-content .product-shipping-delivers li svg {
  fill: var(--primary-color1);
}
.product-details-top-section .product-details-content .product-shipping-delivers li p {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.32px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.product-details-top-section .product-details-content .product-shipping-delivers li p a {
  color: var(--text-color);
  background: linear-gradient(to bottom, #100C08 0%, #100C08 98%);
  background-size: 100% 2px;
  background-repeat: no-repeat;
  background-position: left 100%;
  transition: background-size 0.75s;
}
.product-details-top-section .product-details-content .product-shipping-delivers li p a:hover {
  background-size: 0 2px;
  background-position: 0% 100%;
  color: var(--primary-color1);
}
.product-details-top-section .product-details-content .wishlist-area {
  padding-top: 30px;
}
@media (max-width: 576px) {
  .product-details-top-section .product-details-content .wishlist-area {
    padding-top: 20px;
  }
}
.product-details-top-section .product-details-content .wishlist-area a {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  gap: 7px;
  transition: 0.5s;
}
.product-details-top-section .product-details-content .wishlist-area a span {
  width: 23px;
  height: 23px;
  border-radius: 50%;
  border: 1px solid var(--title-color);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
}
.product-details-top-section .product-details-content .wishlist-area a span svg {
  fill: var(--title-color);
  transition: 0.5s;
}
.product-details-top-section .product-details-content .wishlist-area a:hover {
  color: var(--primary-color1);
}
.product-details-top-section .product-details-content .wishlist-area a:hover span {
  border-color: var(--primary-color1);
  background-color: var(--primary-color1);
}
.product-details-top-section .product-details-content .wishlist-area a:hover span svg {
  fill: var(--white-color);
}

.product-details-description {
  scroll-margin-top: 150px;
}
.product-details-description .product-details-description-nav .nav-tabs {
  border-bottom: 1px solid rgba(34, 34, 34, 0.1000000015);
  padding-left: 80px;
  gap: 65px;
}
@media (max-width: 991px) {
  .product-details-description .product-details-description-nav .nav-tabs {
    gap: 50px;
  }
}
@media (max-width: 767px) {
  .product-details-description .product-details-description-nav .nav-tabs {
    gap: 20px;
    padding-left: 0;
  }
}
@media (max-width: 576px) {
  .product-details-description .product-details-description-nav .nav-tabs {
    gap: 10px;
  }
}
.product-details-description .product-details-description-nav .nav-tabs .nav-link {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.36px;
  text-transform: capitalize;
  border: none;
  background: linear-gradient(to bottom, #222222 0%, #222222 98%);
  background-size: 0px 3px;
  background-repeat: no-repeat;
  background-position: right 100%;
  transition: background-size 0.75s;
}
@media (max-width: 576px) {
  .product-details-description .product-details-description-nav .nav-tabs .nav-link {
    padding-bottom: 5px;
    margin-right: 15px;
    padding: 0;
  }
}
.product-details-description .product-details-description-nav .nav-tabs .nav-link.active {
  color: var(--title-color);
  font-weight: 500;
  background-size: 100% 3px;
  background-position: 0% 100%;
}
.product-details-description .product-details-description-tab .description-content p {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.8;
  letter-spacing: 0.45px;
}
.product-details-description .product-details-description-tab .addithonal-information .total-table2 {
  margin-bottom: 0;
}
.product-details-description .product-details-description-tab .addithonal-information .total-table2 tbody tr {
  border-width: 1px;
  border-color: #eee;
}
.product-details-description .product-details-description-tab .addithonal-information .total-table2 tbody tr td {
  border-width: 1px;
  border-color: #eee;
  padding: 15px;
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 14px;
  line-height: 30px;
  vertical-align: middle;
}
@media (max-width: 767px) {
  .product-details-description .product-details-description-tab .addithonal-information .total-table2 tbody tr td {
    padding: 10px;
  }
}
.product-details-description .product-details-description-tab .addithonal-information .total-table2 tbody tr td span {
  font-weight: 600;
  font-size: 16px;
  color: var(--title-color);
  font-family: var(--font-rubik);
  text-align: start;
  line-height: 26px;
}
@media (min-width: 992px) {
  .product-details-description .product-details-description-tab .addithonal-information .total-table2 tbody tr td span {
    width: 25%;
  }
}
.product-details-description .product-details-description-tab .reviews-area .number-of-review {
  line-height: 1;
  margin-bottom: 40px;
}
.product-details-description .product-details-description-tab .reviews-area .number-of-review h4 {
  line-height: 1;
  margin-bottom: 0;
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 24px;
  font-weight: 600;
}
@media (max-width: 576px) {
  .product-details-description .product-details-description-tab .reviews-area .number-of-review h4 {
    font-size: 22px;
  }
}
.product-details-description .product-details-description-tab .reviews-area .comment {
  margin: 0;
  padding: 0;
  list-style: none;
}
.product-details-description .product-details-description-tab .reviews-area .comment > li {
  margin-bottom: 40px;
}
.product-details-description .product-details-description-tab .reviews-area .comment > li > .single-comment-area {
  margin-bottom: 24px;
}
.product-details-description .product-details-description-tab .reviews-area .comment > li:last-child {
  margin-bottom: 0;
}
.product-details-description .product-details-description-tab .reviews-area .comment > li:last-child > .single-comment-area {
  margin-bottom: 0;
}
.product-details-description .product-details-description-tab .reviews-area .comment .single-comment-area {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}
.product-details-description .product-details-description-tab .reviews-area .comment .single-comment-area .author-img img {
  max-width: 50px;
  min-width: 50px;
  height: 50px;
  border-radius: 50%;
}
.product-details-description .product-details-description-tab .reviews-area .comment .single-comment-area .comment-content .author-and-review .author-name-deg {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}
.product-details-description .product-details-description-tab .reviews-area .comment .single-comment-area .comment-content .author-and-review .author-name-deg h6 {
  line-height: 1;
  margin-bottom: 0;
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 15px;
  font-weight: 600;
}
.product-details-description .product-details-description-tab .reviews-area .comment .single-comment-area .comment-content .author-and-review .author-name-deg span {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
}
.product-details-description .product-details-description-tab .reviews-area .comment .single-comment-area .comment-content .author-and-review .review {
  gap: 5px;
  line-height: 1;
}
.product-details-description .product-details-description-tab .reviews-area .comment .single-comment-area .comment-content .author-and-review .review li {
  line-height: 1;
}
.product-details-description .product-details-description-tab .reviews-area .comment .single-comment-area .comment-content .author-and-review .review li i {
  color: #DDA701;
  font-size: 12px;
}
.product-details-description .product-details-description-tab .reviews-area .comment .single-comment-area .comment-content p {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 15px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 12px;
}
.product-details-description .product-details-description-tab .reviews-area .comment .single-comment-area .comment-content .replay-btn {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 14px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  line-height: 1;
  cursor: pointer;
  transition: 0.35s;
}
.product-details-description .product-details-description-tab .reviews-area .comment .single-comment-area .comment-content .replay-btn svg {
  transition: 0.35s;
  fill: var(--text-color);
}
.product-details-description .product-details-description-tab .reviews-area .comment .single-comment-area .comment-content .replay-btn:hover {
  color: var(--primary-color1);
}
.product-details-description .product-details-description-tab .reviews-area .comment .single-comment-area .comment-content .replay-btn:hover svg {
  fill: var(--primary-color1);
}
.product-details-description .product-details-description-tab .reviews-area .comment .comment-replay {
  margin-left: 70px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 24px 0;
}
.product-details-description .product-details-description-tab .reviews-area .comment .comment-replay > li {
  margin-bottom: 30px;
}
.product-details-description .product-details-description-tab .reviews-area .comment .comment-replay > li:last-child {
  margin-bottom: 0;
}
.product-details-description .product-details-description-tab .review-form {
  background: linear-gradient(125deg, rgba(99, 171, 69, 0.1) 0%, rgba(251, 176, 59, 0.1) 100%);
  box-shadow: 4px 3px 40px 0px rgba(16, 33, 34, 0.06);
  padding: 50px 40px;
  border-radius: 10px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .product-details-description .product-details-description-tab .review-form {
    padding: 40px 30px;
  }
}
@media (max-width: 576px) {
  .product-details-description .product-details-description-tab .review-form {
    padding: 40px 25px;
  }
}
.product-details-description .product-details-description-tab .review-form .number-of-review {
  line-height: 1;
  margin-bottom: 30px;
}
.product-details-description .product-details-description-tab .review-form .number-of-review h4 {
  line-height: 1;
  margin-bottom: 0;
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 24px;
  font-weight: 600;
}
.product-details-description .product-details-description-tab .review-form .form-inner2 .review-rate-area {
  margin-bottom: 15px;
}
.product-details-description .product-details-description-tab .review-form .form-inner2 .review-rate-area p {
  margin-bottom: 5px;
  color: var(--title-color);
}
.product-details-description .product-details-description-tab .review-form .form-inner2 .review-rate-area .rate {
  float: left;
}
.product-details-description .product-details-description-tab .review-form .form-inner2 .review-rate-area .rate:not(:checked) > input {
  position: absolute;
  top: -9999px;
}
.product-details-description .product-details-description-tab .review-form .form-inner2 .review-rate-area .rate:not(:checked) > label {
  float: right;
  width: 1em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 16px;
  line-height: 1;
  color: #ccc;
  padding-right: 8px;
  display: inline-block;
  margin-right: 8px;
}
.product-details-description .product-details-description-tab .review-form .form-inner2 .review-rate-area .rate:not(:checked) > label::before {
  content: "\f586";
  font-family: bootstrap-icons !important;
}
.product-details-description .product-details-description-tab .review-form .form-inner2 .review-rate-area .rate input:checked ~ label {
  color: #DDA701;
}
.product-details-description .product-details-description-tab .review-form .form-inner2 .review-rate-area .rate:not(:checked) > label:hover,
.product-details-description .product-details-description-tab .review-form .form-inner2 .review-rate-area .rate:not(:checked) > label:hover ~ label {
  color: #DDA701;
}
.product-details-description .product-details-description-tab .review-form .form-inner2 .review-rate-area input:checked + label:hover,
.product-details-description .product-details-description-tab .review-form .form-inner2 .review-rate-area input:checked + label:hover ~ label,
.product-details-description .product-details-description-tab .review-form .form-inner2 .review-rate-area input:checked ~ label:hover,
.product-details-description .product-details-description-tab .review-form .form-inner2 .review-rate-area input:checked ~ label:hover ~ label,
.product-details-description .product-details-description-tab .review-form .form-inner2 .review-rate-area label:hover ~ input:checked ~ label {
  color: #DDA701;
}
.product-details-description .product-details-description-tab .review-form .primary-btn3 {
  padding: 7px 25px;
  margin-top: 30px;
  font-family: var(--font-rubik);
}

.room-suits-card {
  border: 1px solid #eee;
  border-radius: 5px;
}
.room-suits-card .hotel-img-slider {
  height: 100%;
  position: relative;
}
.room-suits-card .hotel-img-slider .batch {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  text-transform: capitalize;
  display: inline-block;
  padding: 7px 16px;
  border-radius: 5px 0 5px 0;
  background-color: #228B22;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.5s;
  z-index: 2;
}
.room-suits-card .hotel-img-slider .swiper-pagination5 {
  position: absolute;
  bottom: 15px;
  width: unset !important;
  display: flex;
  z-index: 9;
  gap: 5px;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 576px) {
  .room-suits-card .hotel-img-slider .swiper-pagination5 {
    display: none;
  }
}
.room-suits-card .hotel-img-slider .swiper-pagination5 .swiper-pagination-bullet {
  height: 12px;
  width: 12px;
  background-color: transparent;
  border: 1px solid var(--white-color);
  opacity: 1;
  position: relative;
}
.room-suits-card .hotel-img-slider .swiper-pagination5 .swiper-pagination-bullet.swiper-pagination-bullet-active {
  border-color: var(--primary-color1);
}
.room-suits-card .hotel-img-slider .swiper-pagination5 .swiper-pagination-bullet.swiper-pagination-bullet-active::after {
  content: "";
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: var(--primary-color1);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.room-suits-card .room-img {
  height: 100%;
  display: inline-block;
  height: 100%;
  position: relative;
  border-radius: 5px 0 0 5px;
}
@media (max-width: 767px) {
  .room-suits-card .room-img {
    width: 100%;
  }
}
.room-suits-card .room-img .batch {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  text-transform: capitalize;
  display: inline-block;
  padding: 7px 16px;
  border-radius: 5px 0 5px 0;
  background-color: #228B22;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.5s;
  z-index: 2;
}
.room-suits-card .room-img::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(1deg, rgba(21, 47, 0, 0.56) 1.03%, rgba(21, 47, 0, 0) 90.67%);
  border-radius: 5px 5px 0 0;
  z-index: 1;
}
@media (max-width: 767px) {
  .room-suits-card .room-img {
    width: 100%;
  }
}
.room-suits-card .room-img img {
  border-radius: 5px 0 0 5px;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 767px) {
  .room-suits-card .room-img img {
    width: 100%;
    border-radius: 5px 5px 0 0;
  }
}
.room-suits-card .room-img a {
  display: inline-block;
  height: 100%;
  position: relative;
  z-index: 1;
}
.room-suits-card .room-img a::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(1deg, rgba(21, 47, 0, 0.56) 1.03%, rgba(21, 47, 0, 0) 90.67%);
  border-radius: 5px 5px 0 0;
  z-index: 1;
}
@media (max-width: 767px) {
  .room-suits-card .room-img a {
    width: 100%;
  }
}
.room-suits-card .room-img a img {
  border-radius: 5px 0 0 5px;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 767px) {
  .room-suits-card .room-img a img {
    width: 100%;
    border-radius: 5px 5px 0 0;
  }
}
.room-suits-card .room-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 25px;
}
@media (max-width: 576px) {
  .room-suits-card .room-content {
    padding: 20px 15px;
  }
}
.room-suits-card .room-content .content-top {
  margin-bottom: 20px;
}
.room-suits-card .room-content .content-top .reviews {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
}
.room-suits-card .room-content .content-top .reviews ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}
.room-suits-card .room-content .content-top .reviews ul li {
  line-height: 1;
}
.room-suits-card .room-content .content-top .reviews ul li i {
  color: #DDA701;
  font-size: 12px;
}
.room-suits-card .room-content .content-top .reviews span {
  font-size: 14px;
  color: var(--text-color);
  font-family: var(--font-jost);
  line-height: 1;
}
.room-suits-card .room-content .content-top h5 {
  margin-bottom: 10px;
  line-height: 1;
}
.room-suits-card .room-content .content-top h5 a {
  font-size: 22px;
  font-family: var(--font-rubik);
  color: var(--title-color);
  font-weight: 500;
  line-height: 1.2;
  transition: 0.35s;
}
@media (max-width: 767px) {
  .room-suits-card .room-content .content-top h5 a {
    font-size: 18px;
  }
}
.room-suits-card .room-content .content-top h5 a:hover {
  color: var(--primary-color1);
}
.room-suits-card .room-content .content-top .loaction-area {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 20px;
}
.room-suits-card .room-content .content-top .loaction-area li {
  margin-bottom: 0;
  font-size: 13px;
  font-weight: 400;
  font-family: var(--font-jost);
  color: var(--text-color);
  display: flex;
  align-items: center;
  gap: 5px;
  margin-right: 10px;
  line-height: 1;
}
.room-suits-card .room-content .content-top .loaction-area li a {
  color: var(--primary-color1);
  text-decoration: underline;
}
.room-suits-card .room-content .content-top .facilisis {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}
.room-suits-card .room-content .content-top .facilisis li {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-right: 13px;
  font-size: 14px;
  font-weight: 400;
  font-family: var(--font-jost);
  line-height: 1;
  color: var(--title-color);
}
.room-suits-card .room-content .content-top .facilisis li svg {
  fill: var(--primary-color1);
}
.room-suits-card .room-content .content-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 576px) {
  .room-suits-card .room-content .content-bottom {
    gap: 20px;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
}
.room-suits-card .room-content .content-bottom .room-type {
  line-height: 1;
}
.room-suits-card .room-content .content-bottom .room-type h6 {
  font-size: 14px;
  font-weight: 500;
  color: var(--title-color);
  font-family: var(--font-rubik);
  margin-bottom: 0;
}
.room-suits-card .room-content .content-bottom .room-type > span {
  font-size: 14px;
  font-weight: 400;
  color: var(--text-color);
  font-family: var(--font-jost);
}
.room-suits-card .room-content .content-bottom .room-type .deals {
  padding-top: 7px;
}
.room-suits-card .room-content .content-bottom .room-type .deals span {
  font-size: 14px;
  font-weight: 400;
  color: var(--text-color);
  font-family: var(--font-jost);
  line-height: 1.3;
}
.room-suits-card .room-content .content-bottom .room-type .deals span strong {
  color: var(--primary-color1);
  font-family: var(--font-rubik);
  font-weight: 500;
}
.room-suits-card .room-content .content-bottom .price-and-book {
  display: flex;
  flex-direction: column;
  align-items: end;
}
@media (max-width: 576px) {
  .room-suits-card .room-content .content-bottom .price-and-book {
    align-items: start;
  }
}
.room-suits-card .room-content .content-bottom .price-area {
  margin-bottom: 10px;
  text-align: end;
}
@media (max-width: 576px) {
  .room-suits-card .room-content .content-bottom .price-area {
    text-align: start;
    margin-bottom: 20px;
  }
}
.room-suits-card .room-content .content-bottom .price-area span {
  color: var(--primary-color1);
  font-family: var(--font-rubik);
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  display: inline-block;
  text-transform: capitalize;
  padding-top: 7px;
}
.room-suits-card .room-content .content-bottom .price-area span del {
  color: rgba(16, 12, 8, 0.5);
  font-size: 16px;
  font-weight: 500;
}
.room-suits-card .room-content .content-bottom .price-area p {
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 400;
  color: var(--text-color);
  font-family: var(--font-rubik);
  line-height: 1;
}
.room-suits-card.two .hotel-img-slider {
  width: 100%;
}
.room-suits-card.two .hotel-img-slider .swiper-wrapper {
  width: 100%;
}
.room-suits-card.two .hotel-img-slider .swiper-wrapper .swiper-slide {
  width: 100%;
}
.room-suits-card.two .hotel-img-slider .swiper-wrapper .swiper-slide .room-img {
  width: 100%;
  height: 300px;
}
.room-suits-card.two .hotel-img-slider .swiper-wrapper .swiper-slide .room-img img {
  width: 100%;
}
.room-suits-card.two .room-img {
  width: 100%;
  height: 300px;
}
.room-suits-card.two .room-img a {
  width: 100%;
}
.room-suits-card.two .room-img a img {
  width: 100%;
}

.room-details-area .room-img-group {
  border-radius: 10px;
  background: #FFF;
  padding: 10px;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.08);
}
.room-details-area .room-img-group .gallery-img-wrap {
  position: relative;
  transition: all 0.45s;
  height: 100%;
}
.room-details-area .room-img-group .gallery-img-wrap img {
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  border-radius: 10px;
}
@media (max-width: 767px) {
  .room-details-area .room-img-group .gallery-img-wrap img {
    height: auto;
  }
}
.room-details-area .room-img-group .gallery-img-wrap a {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(16, 12, 8, 0.6);
  color: var(--white-color);
  text-align: center;
  font-family: var(--font-rubik);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.6px;
  flex-direction: column;
  transition: all 0.45s;
  transform: scale(0.4);
  border-radius: 10px;
  opacity: 0;
}
.room-details-area .room-img-group .gallery-img-wrap a i {
  color: var(--primary-color2);
  font-size: 35px;
}
.room-details-area .room-img-group .gallery-img-wrap button {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(16, 12, 8, 0.6);
  color: var(--white-color);
  text-align: center;
  font-family: var(--font-rubik);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.6px;
  flex-direction: column;
  transition: all 0.45s;
  transform: scale(0.4);
  border-radius: 10px;
  opacity: 0;
}
.room-details-area .room-img-group .gallery-img-wrap button i {
  color: var(--primary-color2);
  font-size: 20px;
}
.room-details-area .room-img-group .gallery-img-wrap:hover a {
  transform: scale(1);
  opacity: 1;
}
.room-details-area .room-img-group .gallery-img-wrap:hover button {
  transform: scale(1);
  opacity: 1;
}
.room-details-area .room-img-group .gallery-img-wrap.active a {
  transform: scale(1);
  opacity: 1;
}
.room-details-area .room-img-group .gallery-img-wrap.active button {
  transform: scale(1);
  opacity: 1;
}
.room-details-area h2 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 40px;
  line-height: 1.25;
  font-weight: 500;
  letter-spacing: 0.75px;
  margin-bottom: 20px;
}
@media (max-width: 1399px) {
  .room-details-area h2 {
    font-size: 35px;
  }
}
@media (max-width: 576px) {
  .room-details-area h2 {
    font-size: 32px;
  }
}
.room-details-area .location-and-review {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 30px;
}
@media (max-width: 576px) {
  .room-details-area .location-and-review {
    flex-wrap: wrap;
    gap: 10px;
  }
}
.room-details-area .location-and-review .location p {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 0;
}
.room-details-area .location-and-review .location p a {
  color: var(--title-color);
  font-weight: 500;
  text-decoration: underline;
}
.room-details-area .location-and-review .review-area ul {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 5px;
}
@media (max-width: 576px) {
  .room-details-area .location-and-review .review-area ul {
    justify-content: start;
  }
}
.room-details-area .location-and-review .review-area ul li {
  line-height: 1;
}
.room-details-area .location-and-review .review-area ul li i {
  font-size: 14px;
  color: #DDA701;
}
.room-details-area .location-and-review .review-area span {
  font-size: 14px;
  font-family: var(--font-rubik);
  line-height: 1;
  color: var(--text-color);
  white-space: nowrap;
}
.room-details-area .location-and-review .review-area span strong {
  font-weight: 500;
  color: var(--title-color);
}
.room-details-area .room-meta-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.room-details-area .room-meta-list li {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  color: var(--title-color);
  font-family: var(--font-jost);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.48px;
}
@media (max-width: 576px) {
  .room-details-area .room-meta-list li {
    padding-right: 15px;
  }
}
.room-details-area .room-meta-list li::after {
  content: "";
  position: absolute;
  right: -60px;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 20px;
  background-color: var(--primary-color1);
}
@media (max-width: 1199px) {
  .room-details-area .room-meta-list li::after {
    right: -40px;
  }
}
@media (max-width: 767px) {
  .room-details-area .room-meta-list li::after {
    right: -20px;
  }
}
@media (max-width: 576px) {
  .room-details-area .room-meta-list li::after {
    display: none;
    visibility: hidden;
  }
}
.room-details-area .room-meta-list li:last-child::after {
  display: none;
  visibility: hidden;
}
.room-details-area h6 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.48px;
  margin-bottom: 7px;
}
.room-details-area .price-area {
  margin-bottom: 15px;
}
.room-details-area .price-area h6 {
  font-size: 28px;
  font-family: var(--font-jost);
  color: var(--primary-color1);
  line-height: 1.25;
  font-weight: 700;
  letter-spacing: 0.75px;
  margin-bottom: 0;
}
.room-details-area .price-area h6 span {
  font-family: var(--font-jost);
  font-size: 18px;
  line-height: 1.25;
  font-weight: 400;
}
.room-details-area p {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.54px;
  margin-bottom: 25px;
}
.room-details-area h4 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.75px;
  margin-bottom: 5px;
}
.room-details-area .room-features {
  -moz-columns: 3;
       columns: 3;
  padding-top: 15px;
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .room-details-area .room-features {
    -moz-columns: 2;
         columns: 2;
    margin-bottom: 35px;
  }
}
.room-details-area .room-features li {
  border: 1px solid #EEE;
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 12px 15px;
  margin-bottom: 20px;
  color: var(--title-color);
  font-family: var(--font-jost);
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.66px;
}
.room-details-area .room-features li svg {
  fill: var(--primary-color1);
}
@media (max-width: 576px) {
  .room-details-area .room-features li {
    font-size: 15px;
    gap: 10px;
    padding: 12px;
    line-height: 1.1;
  }
}
.room-details-area .extra-service {
  -moz-columns: 4;
       columns: 4;
}
@media (max-width: 991px) {
  .room-details-area .extra-service {
    -moz-columns: 3;
         columns: 3;
  }
}
@media (max-width: 576px) {
  .room-details-area .extra-service {
    -moz-columns: 2;
         columns: 2;
  }
}
.room-details-area .extra-service li {
  display: flex;
  align-items: center;
  gap: 7px;
  padding-top: 15px;
}
.room-details-area .extra-service li svg {
  fill: var(--primary-color1);
}
.room-details-area .tour-location .map-area {
  padding-top: 15px;
}
.room-details-area .tour-location .map-area iframe {
  width: 100%;
  height: 300px;
  filter: grayscale(199%) invert(98%) contrast(122%);
}

.package-details-area .booking-form-wrap p {
  color: var(--text-color);
  text-align: center;
  font-family: var(--font-jost);
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 0;
  border-bottom: 1px solid #ddd;
  padding-bottom: 30px;
  margin-bottom: 35px;
}

.booking-form-wrap {
  border-radius: 10px;
  background: linear-gradient(125deg, rgba(99, 171, 69, 0.1) 0%, rgba(251, 176, 59, 0.1) 100%);
  border-radius: 5px;
  padding: 25px 25px;
}
.booking-form-wrap h6 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.48px;
  margin-bottom: 7px;
}
.booking-form-wrap h4 {
  color: var(--title-color);
  text-align: center;
  font-family: var(--font-rubik);
  font-size: 28px;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 5px;
}
.booking-form-wrap p {
  color: var(--text-color);
  text-align: center;
  font-family: var(--font-jost);
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 0;
  border-bottom: 1px solid #ddd;
  padding-bottom: 30px;
  margin-bottom: 35px;
}
.booking-form-wrap .nav-pills {
  justify-content: space-between;
}
.booking-form-wrap .nav-pills .nav-link {
  color: var(--primary-color1);
  text-align: center;
  font-family: var(--font-jost);
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #ddd;
  border-radius: 0;
  width: 48%;
  padding: 5px 15px;
}
.booking-form-wrap .nav-pills .nav-link.active {
  border-color: var(--primary-color1);
  background-color: var(--primary-color1);
  color: var(--white-color);
}
.booking-form-wrap .sidebar-booking-form .tour-date-wrap h6 {
  margin-bottom: 15px;
}
.booking-form-wrap .sidebar-booking-form .tour-date-wrap .customdate {
  display: flex;
  width: 100%;
}
.booking-form-wrap .sidebar-booking-form .tour-date-wrap .customdate .form-check-label {
  width: 0 !important;
}
.booking-form-wrap .sidebar-booking-form .tour-date-wrap .customdate .form-group {
  width: 100%;
}
.booking-form-wrap .sidebar-booking-form .tour-date-wrap .form-group {
  position: relative;
  display: block;
}
.booking-form-wrap .sidebar-booking-form .tour-date-wrap .form-group span {
  font-size: 14px;
  font-weight: 500;
  display: block;
  color: var(--title-color);
  margin-bottom: 10px;
  display: block;
}
.booking-form-wrap .sidebar-booking-form .tour-date-wrap .form-group input {
  width: 100%;
  border-radius: 5px;
  background: #FFF;
  color: var(--title-color);
  font-family: var(--font-jost);
  font-size: 13px;
  font-weight: 400;
  padding: 10px 25px;
  height: 50px;
  border: 1px solid #eee;
}
.booking-form-wrap .sidebar-booking-form .tour-date-wrap .form-group svg {
  fill: var(--primary-color1);
  position: absolute;
  right: 15px;
  bottom: 18px;
}
.booking-form-wrap .sidebar-booking-form .tour-date-wrap .form-check {
  position: relative;
  padding-left: 30px;
  line-height: 1;
}
.booking-form-wrap .sidebar-booking-form .tour-date-wrap .form-check .form-check-input {
  display: none;
  visibility: hidden;
}
.booking-form-wrap .sidebar-booking-form .tour-date-wrap .form-check .form-check-input:focus {
  box-shadow: none;
}
.booking-form-wrap .sidebar-booking-form .tour-date-wrap .form-check .form-check-input:checked:focus {
  box-shadow: none;
}
.booking-form-wrap .sidebar-booking-form .tour-date-wrap .form-check .form-check-input:checked ~ .form-check-label::brfore {
  border-color: var(--primary-color1);
}
.booking-form-wrap .sidebar-booking-form .tour-date-wrap .form-check .form-check-input:checked ~ .form-check-label::after {
  background-color: var(--primary-color1);
}
.booking-form-wrap .sidebar-booking-form .tour-date-wrap .form-check .form-check-label {
  cursor: pointer;
  position: relative;
  width: 100%;
}
.booking-form-wrap .sidebar-booking-form .tour-date-wrap .form-check .form-check-label::before {
  content: "";
  height: 18px;
  width: 18px;
  border: 1px solid #bbb3b3;
  position: absolute;
  left: -30px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
}
.booking-form-wrap .sidebar-booking-form .tour-date-wrap .form-check .form-check-label::after {
  content: "";
  height: 12px;
  width: 12px;
  background-color: #bbb3b3;
  position: absolute;
  left: -26.5px;
  top: 50%;
  transform: translateY(-50%);
}
.booking-form-wrap .sidebar-booking-form .tour-date-wrap .form-check .form-check-label .tour-date {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.booking-form-wrap .sidebar-booking-form .tour-date-wrap .form-check .form-check-label .tour-date i {
  color: var(--primary-color1);
  font-size: 20px;
}
.booking-form-wrap .sidebar-booking-form .tour-date-wrap .form-check .form-check-label .tour-date .start-date, .booking-form-wrap .sidebar-booking-form .tour-date-wrap .form-check .form-check-label .tour-date .end-date {
  font-size: 13px;
  font-weight: 400;
  font-family: var(--font-rubik);
  line-height: 1;
}
.booking-form-wrap .sidebar-booking-form .tour-date-wrap .form-check .form-check-label .tour-date .start-date span:first-child, .booking-form-wrap .sidebar-booking-form .tour-date-wrap .form-check .form-check-label .tour-date .end-date span:first-child {
  font-size: 14px;
  font-weight: 500;
  display: block;
  color: var(--title-color);
  margin-bottom: 7px;
}
.booking-form-wrap .sidebar-booking-form .form-inner .form-group {
  position: relative;
}
.booking-form-wrap .sidebar-booking-form .form-inner .form-group span {
  color: var(--title-color);
  font-family: var(--font-jost);
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 5px;
}
.booking-form-wrap .sidebar-booking-form .form-inner .form-group svg {
  fill: var(--primary-color1);
  position: absolute;
  right: 15px;
  bottom: 18px;
}
.booking-form-wrap .sidebar-booking-form .number-input-item {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  align-items: center;
}
.booking-form-wrap .sidebar-booking-form .number-input-item .number-input-lable {
  min-width: 155px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: var(--font-rubik);
  font-size: 18px;
  font-weight: 400;
  color: var(--title-color);
  gap: 15px;
}
.booking-form-wrap .sidebar-booking-form .number-input-item .number-input-lable span {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  gap: 3px;
  line-height: 1;
  font-weight: 500;
}
.booking-form-wrap .sidebar-booking-form .number-input-item .number-input-lable span del {
  font-size: 15px;
  color: var(--text-color);
}
.booking-form-wrap .sidebar-booking-form .number-input-item .quantity-counter {
  border-radius: 17px;
  border: 1px solid rgba(144, 185, 86, 0.2);
  background: #FAFAFA;
  width: 125px;
  display: flex;
  align-items: center;
  padding: 5px 8px;
}
.booking-form-wrap .sidebar-booking-form .number-input-item .quantity-counter a {
  height: 24px;
  min-width: 24px;
  max-width: 24px;
  border-radius: 50%;
  border: 1px solid var(--primary-color1);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.35s;
  font-size: 18px;
  color: var(--primary-color1);
}
.booking-form-wrap .sidebar-booking-form .number-input-item .quantity-counter a:hover {
  color: var(--white-color);
  background-color: var(--primary-color1);
}
.booking-form-wrap .sidebar-booking-form .number-input-item .quantity-counter input {
  width: 100%;
  background-color: transparent;
  padding: 0px 15px;
  text-align: center;
  line-height: 1;
  color: var(--title-color);
  font-family: var(--font-jost);
  font-size: 15px;
  font-weight: 500;
}
.booking-form-wrap .sidebar-booking-form .checkbox-container {
  padding-top: 10px;
  padding-bottom: 22px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 35px;
}
.booking-form-wrap .sidebar-booking-form .checkbox-container .check-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 28px;
  position: relative;
  cursor: pointer;
  margin-bottom: 5px;
  color: var(--title-color);
  font-family: var(--font-jost);
  font-size: 15px;
  font-weight: 500;
}
.booking-form-wrap .sidebar-booking-form .checkbox-container .check-container:last-child {
  margin-bottom: 0;
}
.booking-form-wrap .sidebar-booking-form .checkbox-container .check-container .checkmark {
  height: 18px;
  width: 18px;
  border: 1px solid #bbb3b3;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
}
.booking-form-wrap .sidebar-booking-form .checkbox-container .check-container .checkmark:after {
  content: "";
  height: 12px;
  width: 12px;
  background-color: #bbb3b3;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.booking-form-wrap .sidebar-booking-form .checkbox-container .check-container .services_check {
  display: none;
}
.booking-form-wrap .sidebar-booking-form .checkbox-container .check-container .services_check:checked ~ .checkmark {
  border-color: var(--primary-color1);
}
.booking-form-wrap .sidebar-booking-form .checkbox-container .check-container .services_check:checked ~ .checkmark:after {
  background-color: var(--primary-color1);
}
.booking-form-wrap .single-total {
  border: 1px solid #ddd;
  padding: 20px 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.booking-form-wrap .single-total span {
  position: absolute;
  left: 12px;
  top: -10px;
  color: var(--title-color);
  font-family: var(--font-jost);
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  padding: 0px 8px;
  background-color: #f7f6eb;
}
.booking-form-wrap .single-total ul {
  display: flex;
  align-items: center;
  gap: 15px;
  line-height: 1;
}
.booking-form-wrap .single-total ul li {
  color: #666;
  font-family: var(--font-jost);
  font-size: 12px;
  font-weight: 400;
}
.booking-form-wrap .single-total ul li strong {
  display: block;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 5px;
}
.booking-form-wrap .single-total ul li i {
  color: var(--title-color);
}
.booking-form-wrap .single-total svg {
  fill: #b1afaf;
}
.booking-form-wrap .single-total .total {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 16px;
  font-weight: 600;
}
.booking-form-wrap .total-price {
  color: var(--title-color);
  text-align: center;
  font-family: var(--font-jost);
  font-size: 28px;
  font-weight: 700;
  border-top: 1px solid #ddd;
  padding-top: 30px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.booking-form-wrap .total-price span {
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
}
.booking-form-wrap .primary-btn1 {
  width: 100%;
  justify-content: center;
  padding: 18px 34px;
}

.review-box {
  border: 1px solid rgba(238, 238, 238, 0.4);
  background: #F8F8F8;
  padding: 30px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 25px;
  margin-bottom: 50px;
  gap: 15px;
}
.review-box .total-review {
  display: flex;
  align-items: center;
  gap: 15px;
}
.review-box .total-review h2 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 55px;
  font-weight: 500;
  margin-bottom: 0;
}
.review-box .total-review .review-wrap .star-list {
  display: flex;
  align-items: center;
  line-height: 1;
  gap: 10px;
  margin-bottom: 5px;
}
.review-box .total-review .review-wrap .star-list li {
  color: #DDA701;
}
.review-box .total-review .review-wrap span {
  color: #666;
  font-family: var(--font-jost);
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
}
.review-box .modal .modal-dialog {
  width: 100%;
  max-width: 1120px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 991px) {
  .review-box .modal .modal-dialog {
    max-width: 570px;
  }
}
.review-box .modal .modal-dialog .modal-content {
  border: none;
}
.review-box .modal .modal-dialog .modal-content .modal-body {
  padding: 0;
  background: linear-gradient(125deg, rgba(99, 171, 69, 0.1) 0%, rgba(251, 176, 59, 0.1) 100%);
  border-radius: 10px;
  position: relative;
}
.review-box .modal .modal-dialog .modal-content .modal-body .btn-close {
  height: 28px;
  width: 28px;
  background-color: var(--white-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  opacity: 1;
  position: absolute;
  right: 15px;
  top: 18px;
  --bs-btn-close-bg: none;
  font-size: 14px;
  color: var(--title-color);
  transition: 0.35s;
}
.review-box .modal .modal-dialog .modal-content .modal-body .btn-close:hover {
  background-color: red;
  color: var(--white-color);
}
.review-box .modal .modal-dialog .modal-content .modal-body .btn-close:focus {
  box-shadow: none;
}
.review-box .modal .modal-dialog .modal-content .modal-body .review-from-wrapper {
  padding: 30px 20px 40px;
}
.review-box .modal .modal-dialog .modal-content .modal-body .review-from-wrapper h4 {
  margin-bottom: 20px;
}
.review-box .modal .modal-dialog .modal-content .modal-body .review-from-wrapper .star-rating-wrapper .star-rating-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 12px;
  list-style: none;
  padding: 0;
  margin: 0;
}
.review-box .modal .modal-dialog .modal-content .modal-body .review-from-wrapper .star-rating-wrapper .star-rating-list li {
  margin-right: 10px;
}
.review-box .modal .modal-dialog .modal-content .modal-body .review-from-wrapper .star-rating-wrapper .star-rating-list li .rating-container {
  line-height: 1;
}
.review-box .modal .modal-dialog .modal-content .modal-body .review-from-wrapper .star-rating-wrapper .star-rating-list li .rating-container i {
  color: var(--text-color);
  font-size: 13px;
  cursor: pointer;
}
.review-box .modal .modal-dialog .modal-content .modal-body .review-from-wrapper .star-rating-wrapper .star-rating-list li span {
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  color: var(--title-color);
}
.review-box .modal .modal-dialog .modal-content .modal-body .modal-form-image {
  height: 100%;
}
.review-box .modal .modal-dialog .modal-content .modal-body .modal-form-image img {
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 0 10px 10px 0;
}

.comment-area {
  scroll-margin-top: 150px;
}

.review-area .comment {
  margin: 0;
  padding: 0;
  list-style: none;
}
.review-area .comment > li {
  margin-bottom: 40px;
}
.review-area .comment > li > .single-comment-area {
  margin-bottom: 24px;
}
.review-area .comment > li:last-child {
  margin-bottom: 0;
}
.review-area .comment > li:last-child > .single-comment-area {
  margin-bottom: 0;
}
.review-area .comment .single-comment-area {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}
@media (max-width: 576px) {
  .review-area .comment .single-comment-area {
    flex-wrap: wrap;
    gap: 10px;
  }
}
.review-area .comment .single-comment-area .author-img img {
  max-width: 50px;
  min-width: 50px;
  height: 50px;
  border-radius: 50%;
}
.review-area .comment .single-comment-area .comment-content .author-name-deg {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}
.review-area .comment .single-comment-area .comment-content .author-name-deg h6 {
  line-height: 1;
  margin-bottom: 0;
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 15px;
  font-weight: 600;
}
.review-area .comment .single-comment-area .comment-content .author-name-deg span {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
}
.review-area .comment .single-comment-area .comment-content .review-item-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
@media (max-width: 576px) {
  .review-area .comment .single-comment-area .comment-content .review-item-list {
    align-items: start;
    justify-content: flex-start;
  }
}
@media (max-width: 576px) {
  .review-area .comment .single-comment-area .comment-content .review-item-list > li {
    padding-right: 20px;
  }
}
.review-area .comment .single-comment-area .comment-content .review-item-list > li span {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
}
.review-area .comment .single-comment-area .comment-content .review-item-list > li .star-list {
  display: flex;
  align-items: centre;
  gap: 8px;
  line-height: 1;
}
.review-area .comment .single-comment-area .comment-content .review-item-list > li .star-list li i {
  font-size: 13px;
  color: #DDA701;
}
.review-area .comment .single-comment-area .comment-content p {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 12px;
}
.review-area .comment .single-comment-area .comment-content .replay-btn {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 14px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  line-height: 1;
  cursor: pointer;
  transition: 0.35s;
}
.review-area .comment .single-comment-area .comment-content .replay-btn svg {
  transition: 0.35s;
  fill: var(--text-color);
}
.review-area .comment .single-comment-area .comment-content .replay-btn:hover {
  color: var(--primary-color1);
}
.review-area .comment .single-comment-area .comment-content .replay-btn:hover svg {
  fill: var(--primary-color1);
}
.review-area .comment .comment-replay {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-left: 70px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 24px 0;
}
.review-area .comment .comment-replay > li {
  margin-bottom: 30px;
}
.review-area .comment .comment-replay > li:last-child {
  margin-bottom: 0;
}

.package-details-area .package-img-group {
  border-radius: 10px;
  background: #FFF;
  padding: 10px;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.08);
}
.package-details-area .package-img-group .gallery-img-wrap {
  position: relative;
  transition: all 0.45s;
  height: 100%;
}
.package-details-area .package-img-group .gallery-img-wrap img {
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  border-radius: 10px;
}
@media (max-width: 767px) {
  .package-details-area .package-img-group .gallery-img-wrap img {
    height: auto;
  }
}
.package-details-area .package-img-group .gallery-img-wrap a {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(16, 12, 8, 0.6);
  color: var(--white-color);
  text-align: center;
  font-family: var(--font-rubik);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.6px;
  flex-direction: column;
  transition: all 0.45s;
  transform: scale(0.4);
  border-radius: 10px;
  opacity: 0;
}
.package-details-area .package-img-group .gallery-img-wrap a i {
  color: var(--primary-color2);
  font-size: 35px;
}
.package-details-area .package-img-group .gallery-img-wrap button {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(16, 12, 8, 0.6);
  color: var(--white-color);
  text-align: center;
  font-family: var(--font-rubik);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.6px;
  flex-direction: column;
  transition: all 0.45s;
  transform: scale(0.4);
  border-radius: 10px;
  opacity: 0;
}
.package-details-area .package-img-group .gallery-img-wrap button i {
  color: var(--primary-color2);
  font-size: 20px;
}
.package-details-area .package-img-group .gallery-img-wrap:hover a {
  transform: scale(1);
  opacity: 1;
}
.package-details-area .package-img-group .gallery-img-wrap:hover button {
  transform: scale(1);
  opacity: 1;
}
.package-details-area .package-img-group .gallery-img-wrap.active a {
  transform: scale(1);
  opacity: 1;
}
.package-details-area .package-img-group .gallery-img-wrap.active button {
  transform: scale(1);
  opacity: 1;
}
.package-details-area .eg-tag2 {
  -webkit-mask-image: url(../img/home1/vector/activities-content-tag.svg);
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: cover;
  background-color: var(--primary-color2);
  padding: 8px 25px;
  white-space: nowrap;
  display: inline-flex;
  margin-bottom: 15px;
}
.package-details-area .eg-tag2 span {
  color: var(--white-color);
  font-family: var(--font-satisfy);
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.72px;
}
.package-details-area .eg-tag2 span svg {
  fill: var(--white-color);
}
.package-details-area h2 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 40px;
  line-height: 1.25;
  font-weight: 500;
  letter-spacing: 0.75px;
  margin-bottom: 20px;
}
@media (max-width: 1399px) {
  .package-details-area h2 {
    font-size: 35px;
  }
}
@media (max-width: 576px) {
  .package-details-area h2 {
    font-size: 32px;
  }
}
.package-details-area .tour-price {
  display: flex;
  align-items: baseline;
  margin-bottom: 15px;
}
.package-details-area .tour-price h3 {
  font-size: 28px;
  font-family: var(--font-jost);
  color: var(--primary-color1);
  line-height: 1.25;
  font-weight: 700;
  letter-spacing: 0.75px;
  margin-bottom: 0;
}
.package-details-area .tour-price span {
  font-family: var(--font-jost);
  font-size: 18px;
  line-height: 1.25;
  font-weight: 400;
}
.package-details-area .tour-info-metalist {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
}
.package-details-area .tour-info-metalist li {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-right: 40px;
  font-size: 16px;
  font-weight: 500;
  color: var(--title-color);
  font-family: var(--font-rubik);
}
.package-details-area .tour-info-metalist li svg {
  fill: var(--primary-color1);
}
.package-details-area h6 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.48px;
  margin-bottom: 7px;
}
.package-details-area p {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.54px;
  margin-bottom: 16px;
}
.package-details-area h4 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.75px;
  margin-bottom: 10px;
  padding-top: 10px;
}
.package-details-area .includ-and-exclud-area {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}
@media (max-width: 576px) {
  .package-details-area .includ-and-exclud-area {
    display: block;
  }
}
.package-details-area .includ-and-exclud-area ul {
  width: 50%;
}
@media (max-width: 576px) {
  .package-details-area .includ-and-exclud-area ul {
    display: block;
    width: 100%;
  }
}
.package-details-area .includ-and-exclud-area ul li {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
  display: flex;
  gap: 8px;
  padding-top: 10px;
}
.package-details-area .includ-and-exclud-area ul li i {
  color: var(--primary-color1);
  font-weight: normal;
  font-size: 13px;
}
.package-details-area .includ-and-exclud-area ul.exclud li i {
  color: red;
}
.package-details-area .highlight-tour ul li {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
  display: flex;
  gap: 8px;
  padding-top: 10px;
}
.package-details-area .highlight-tour ul li span {
  height: 20px;
  min-width: 20px;
  max-width: 20px;
  background-color: rgba(var(--primary-color1-opc), 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}
.package-details-area .highlight-tour ul li span i {
  font-size: 18px;
  color: var(--primary-color1);
}
.package-details-area .highlight-tour ul li.moretext {
  display: none;
}
.package-details-area .highlight-tour ul li.moretext.item {
  display: flex !important;
}
.package-details-area .highlight-tour .moreless-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-right: 20px;
  font-size: 16px;
  font-weight: 400;
  color: var(--title-color);
  font-family: var(--font-rubik);
  cursor: pointer;
}
.package-details-area .highlight-tour .moreless-button.bi-chevron-down::before {
  display: none;
  visibility: hidden;
}
.package-details-area .highlight-tour .moreless-button.bi-chevron-down::after {
  content: "\f282";
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -0.125em;
  -webkit-font-smoothing: antialiased;
}
.package-details-area .highlight-tour .moreless-button.bi-chevron-up::before {
  display: none;
  visibility: hidden;
}
.package-details-area .highlight-tour .moreless-button.bi-chevron-up::after {
  content: "\f286";
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -0.125em;
  -webkit-font-smoothing: antialiased;
}
.package-details-area .tour-plan {
  padding-top: 15px;
  margin-bottom: 30px;
}
.package-details-area .tour-plan .accordion-item {
  border: none;
}
.package-details-area .tour-plan .accordion-item .accordion-header .accordion-button {
  padding: 0;
  border: 1px solid #eee;
  border-radius: 5px;
  background-color: transparent;
  font-family: var(--font-rubik);
  font-size: 20px;
  font-weight: 500;
  color: var(--title-color);
  display: flex;
  align-items: center;
  gap: 25px;
}
.package-details-area .tour-plan .accordion-item .accordion-header .accordion-button span {
  min-width: 120px;
  max-width: 120px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white-color);
  background-color: var(--primary-color1);
  border-radius: 5px 0 0 5px;
}
@media (max-width: 576px) {
  .package-details-area .tour-plan .accordion-item .accordion-header .accordion-button span {
    min-width: 90px;
    max-width: 90px;
    height: 50px;
  }
}
@media (max-width: 576px) {
  .package-details-area .tour-plan .accordion-item .accordion-header .accordion-button {
    font-size: 18px;
    gap: 18px;
  }
}
.package-details-area .tour-plan .accordion-item .accordion-header .accordion-button:focus {
  box-shadow: none;
}
.package-details-area .tour-plan .accordion-item .accordion-header .accordion-button:not(.collapsed) {
  box-shadow: none;
}
.package-details-area .tour-plan .accordion-item .accordion-header .accordion-button::after {
  margin-right: 20px;
}
.package-details-area .tour-plan .accordion-item .accordion-body {
  padding-top: 0;
  padding-bottom: 40px;
}
.package-details-area .tour-plan .accordion-item .accordion-body p {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.54px;
  margin-bottom: 16px;
}
.package-details-area .tour-plan .accordion-item .accordion-body ul li {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
  display: flex;
  gap: 8px;
  padding-top: 10px;
}
.package-details-area .tour-plan .accordion-item .accordion-body ul li strong {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-weight: 500;
}
.package-details-area .tour-plan .accordion-item .accordion-body ul li i {
  color: var(--primary-color1);
  font-weight: normal;
  font-size: 13px;
}
.package-details-area .map-area {
  padding-top: 15px;
}
.package-details-area .map-area iframe {
  width: 100%;
  height: 300px;
  filter: grayscale(199%) invert(98%) contrast(122%);
}

.transport-details-section .booking-form-wrap p {
  color: var(--text-color);
  text-align: center;
  font-family: var(--font-jost);
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 0;
  border-bottom: 1px solid #ddd;
  padding-bottom: 30px;
  margin-bottom: 35px;
}
.transport-details-section .transport-image-area .transport-img {
  position: relative;
}
.transport-details-section .transport-image-area .transport-img img {
  border-radius: 5px;
}
.transport-details-section .transport-image-area .transport-img .slider-btn-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 95%;
}
.transport-details-section .transport-image-area .transport-img .slider-btn-group .swiper-arrow {
  height: 28px;
  width: 28px;
  background-color: var(--primary-color1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.transport-details-section .transport-image-area .transport-img .slider-btn-group .swiper-arrow svg {
  fill: var(--white-color);
}
.transport-details-section .transport-image-area .nav-tabs {
  border: none;
  -moz-columns: 5;
       columns: 5;
  -moz-column-gap: 20px;
       column-gap: 20px;
  display: block;
}
.transport-details-section .transport-image-area .nav-tabs .nav-item .nav-link {
  border: none;
  padding: 0;
  position: relative;
  border-radius: 5px;
}
.transport-details-section .transport-image-area .nav-tabs .nav-item .nav-link::after {
  content: "";
  height: 100%;
  width: 100%;
  border-radius: 5px;
  position: absolute;
  left: 0;
  top: 0;
  background-color: var(--title-color);
  opacity: 0.5;
}
.transport-details-section .transport-image-area .nav-tabs .nav-item .nav-link img {
  border-radius: 5px;
}
.transport-details-section .transport-image-area .nav-tabs .nav-item .nav-link.active::after {
  display: none;
}
.transport-details-section h3 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 35px;
  line-height: 1.25;
  font-weight: 500;
  letter-spacing: 0.75px;
  margin-bottom: 15px;
}
@media (max-width: 991px) {
  .transport-details-section h3 {
    font-size: 28px;
  }
}
@media (max-width: 576px) {
  .transport-details-section h3 {
    font-size: 24px;
  }
}
.transport-details-section .fetures {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 25px;
  gap: 15px;
  margin-top: 10px;
}
.transport-details-section .fetures li {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-right: 30px;
  font-size: 16px;
  font-weight: 500;
  color: var(--title-color);
  font-family: var(--font-rubik);
  line-height: 1;
}
.transport-details-section .fetures li svg {
  fill: var(--primary-color1);
}
.transport-details-section .fetures li span {
  height: 20px;
  min-width: 20px;
  max-width: 20px;
  background-color: rgba(var(--primary-color1-opc), 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.transport-details-section .fetures li span i {
  font-size: 18px;
  color: var(--primary-color1);
}
.transport-details-section p {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.54px;
  margin-bottom: 16px;
}
.transport-details-section h4 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.75px;
  margin-bottom: 10px;
  padding-top: 10px;
}
.transport-details-section .includ-and-exclud-area ul li {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
  display: flex;
  gap: 8px;
  padding-top: 10px;
}
.transport-details-section .includ-and-exclud-area ul li i {
  color: var(--primary-color1);
  font-weight: normal;
  font-size: 13px;
}
.transport-details-section .includ-and-exclud-area ul.exclud li i {
  color: red;
}
.transport-details-section .booking-form-wrap h4 {
  color: var(--title-color);
  text-align: center;
  font-family: var(--font-rubik);
  font-size: 28px;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 5px;
}
@media (max-width: 576px) {
  .transport-details-section .booking-form-wrap h4 {
    font-size: 22px;
  }
}
.transport-details-section .booking-form-wrap p {
  font-size: 16px;
  line-height: 27px;
}
.transport-details-section .booking-form-wrap h6 {
  margin-bottom: 12px;
}
.transport-details-section .booking-form-wrap .transport-type {
  gap: 10px;
  margin-bottom: 30px;
}
.transport-details-section .booking-form-wrap .transport-type h6 {
  margin-bottom: 0;
  line-height: 1;
}
.transport-details-section .booking-form-wrap .transport-type ul {
  -moz-columns: 2;
       columns: 2;
  width: 100%;
}
.transport-details-section .booking-form-wrap .transport-type ul li {
  padding-top: 15px;
}
.transport-details-section .booking-form-wrap .transport-type .form-check {
  text-align: center;
  position: relative;
  width: 100%;
  border-radius: 5px;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(#fff, #fff) padding-box, linear-gradient(180deg, #fff 0%, #ddd 100%) border-box;
  background-origin: padding-box, border-box;
  background-repeat: no-repeat;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
.transport-details-section .booking-form-wrap .transport-type .form-check .form-check-input {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  margin: 0;
  border: none;
  position: absolute;
  box-shadow: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  cursor: pointer;
}
.transport-details-section .booking-form-wrap .transport-type .form-check .form-check-input:checked {
  border: 1px solid var(--primary-color1);
}
.transport-details-section .booking-form-wrap .transport-type .form-check .form-check-input:checked ~ .form-check-label svg {
  fill: var(--primary-color1);
}
.transport-details-section .booking-form-wrap .transport-type .form-check .form-check-input:checked ~ .form-check-label .title {
  color: var(--primary-color1);
}
.transport-details-section .booking-form-wrap .transport-type .form-check .form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: unset;
}
.transport-details-section .booking-form-wrap .transport-type .form-check .form-check-label {
  cursor: pointer;
  padding: 7px 5px 10px;
}
.transport-details-section .booking-form-wrap .transport-type .form-check .form-check-label svg {
  fill: var(--title-color);
  margin-bottom: 0px;
}
.transport-details-section .booking-form-wrap .transport-type .form-check .form-check-label span {
  display: block;
  line-height: 1.4;
  font-size: 14px;
  font-weight: 400;
  font-family: var(--font-rubik);
  color: var(--text-color);
}
.transport-details-section .booking-form-wrap .transport-type .form-check .form-check-label span.title {
  font-weight: 500;
  font-size: 16px;
  color: var(--title-color);
}
.transport-details-section .booking-form-wrap .transport-type .form-check .form-check-label span.price {
  font-weight: 500;
  color: var(--title-color);
  display: inline-block;
}

.transport-card {
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.08);
  transition: all 0.5s ease-out;
}
.transport-card .transport-img {
  display: block;
  border-radius: 5px 5px 0 0;
  position: relative;
}
.transport-card .transport-img span {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  text-transform: capitalize;
  display: inline-block;
  padding: 7px 16px;
  border-radius: 5px 0 5px 0;
  background-color: var(--primary-color1);
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.5s;
  z-index: 2;
}
.transport-card .transport-img img {
  border-radius: 5px 5px 0 0;
}
.transport-card .transport-img::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(1deg, rgba(21, 47, 0, 0.56) 1.03%, rgba(21, 47, 0, 0) 90.67%);
  border-radius: 5px 5px 0 0;
  z-index: 1;
}
.transport-card .transport-content {
  padding: 25px 20px 30px;
}
.transport-card .transport-content h4 {
  margin-bottom: 15px;
}
.transport-card .transport-content h4 a {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 23px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 0.23px;
  text-transform: capitalize;
  transition: 0.5s;
}
.transport-card .transport-content h4 a:hover {
  color: var(--primary-color1);
}
.transport-card .transport-content .transport-type h6 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 15px;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.23px;
  margin-bottom: 5px;
}
.transport-card .transport-content .transport-type .single-transport {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #eee;
  line-height: 1.3;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
}
.transport-card .transport-content .fetures {
  display: flex;
  align-items: center;
}
.transport-card .transport-content .fetures li {
  font-size: 14px;
  margin-bottom: 5px;
  color: var(--text-color);
  display: flex;
  align-items: center;
  gap: 8px;
}
.transport-card .transport-content .card-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
}
.transport-card .transport-content .card-bottom .details-btn p {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  color: var(--text-color);
  display: flex;
  gap: 8px;
}
.transport-card .transport-content .card-bottom .details-btn p span {
  max-height: 22px;
  min-height: 22px;
  min-width: 22px;
  max-width: 22px;
  background-color: rgba(var(--primary-color1-opc), 0.3);
  color: var(--title-color);
  border-radius: 50%;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}
.transport-card .transport-content .card-bottom .review-area {
  line-height: 1;
  text-align: end;
}
.transport-card .transport-content .card-bottom .review-area .rating {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 5px;
  margin-bottom: 7px;
}
.transport-card .transport-content .card-bottom .review-area .rating li {
  line-height: 1;
  font-size: 13px;
}
.transport-card .transport-content .card-bottom .review-area .rating li i {
  color: #FFC107;
  line-height: 1;
}
.transport-card .transport-content .card-bottom .review-area span {
  font-size: 14px;
  line-height: 1;
  color: var(--text-color);
}

.single-activitis {
  display: flex;
  align-items: center;
  gap: 30px;
}
.single-activitis .activitis-content {
  max-width: 470px;
  width: 100%;
}
.single-activitis .activitis-content .eg-tag2 {
  -webkit-mask-image: url(../img/home1/vector/activities-content-tag.svg);
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: cover;
  background-color: var(--primary-color2);
  padding: 8px 25px;
  white-space: nowrap;
  display: inline-flex;
  margin-bottom: 15px;
}
.single-activitis .activitis-content .eg-tag2 span {
  color: var(--white-color);
  font-family: var(--font-satisfy);
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.72px;
}
.single-activitis .activitis-content .eg-tag2 span svg {
  fill: var(--white-color);
}
.single-activitis .activitis-content h2 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 30px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0.6px;
  margin-bottom: 20px;
}
@media (max-width: 576px) {
  .single-activitis .activitis-content h2 {
    font-size: 25px;
    margin-bottom: 15px;
  }
}
.single-activitis .activitis-content p {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.9;
  letter-spacing: 0.48px;
  margin-bottom: 30px;
}
@media (max-width: 576px) {
  .single-activitis .activitis-content p {
    font-size: 14px;
    margin-bottom: 20px;
  }
}
.single-activitis .activitis-content ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 45px;
}
.single-activitis .activitis-content ul li {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--title-color);
  font-family: var(--font-jost);
  font-size: 17px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 0.51px;
}
.single-activitis .activitis-content ul li svg {
  fill: var(--primary-color1);
}
.single-activitis .activitis-content .content-bottom-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}
@media (max-width: 576px) {
  .single-activitis .activitis-content .content-bottom-area {
    flex-wrap: wrap;
  }
}
.single-activitis .activitis-content .content-bottom-area .primary-btn1 {
  padding: 16px 25px;
}
.single-activitis .activitis-content .content-bottom-area .video-area {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.single-activitis .activitis-content .content-bottom-area .video-area .icon {
  position: relative;
}
.single-activitis .activitis-content .content-bottom-area .video-area .icon .video-circle {
  stroke: var(--title-color);
  fill: none;
  transition: 0.5s;
}
.single-activitis .activitis-content .content-bottom-area .video-area .icon .video-circle .top-half {
  stroke-dasharray: 320;
  stroke-dashoffset: 320;
  transition: stroke-dashoffset 0.8s cubic-bezier(0.33, 1, 0.68, 1);
}
.single-activitis .activitis-content .content-bottom-area .video-area .icon i {
  color: var(--title-color);
  font-size: 30px;
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.single-activitis .activitis-content .content-bottom-area .video-area h6 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.48px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.single-activitis .activitis-content .content-bottom-area .video-area:hover .icon .video-circle .top-half {
  stroke-dashoffset: 0;
}
@media (max-width: 767px) {
  .single-activitis .activitis-img {
    display: none;
  }
}

.review-wrapper h4 {
  font-size: 25px;
}

/*=====================================
 76 . Blog Grid Page
========================================*/
.inner-pagination-area .pagination-list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inner-pagination-area .pagination-list li {
  margin-right: 20px;
}
.inner-pagination-area .pagination-list li .shop-pagi-btn {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid var(--title-color);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
}
.inner-pagination-area .pagination-list li .shop-pagi-btn i {
  color: var(--title-color);
  transition: 0.5s;
}
.inner-pagination-area .pagination-list li .shop-pagi-btn:hover {
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
}
.inner-pagination-area .pagination-list li .shop-pagi-btn:hover i {
  color: var(--white-color);
}
.inner-pagination-area .pagination-list li a {
  color: rgba(16, 12, 8, 0.5);
  font-family: var(--font-jost);
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
  text-transform: capitalize;
}
.inner-pagination-area .pagination-list li a.active {
  color: var(--title-color);
}
.inner-pagination-area .pagination-list li:first-child {
  margin-right: 30px;
}
.inner-pagination-area .pagination-list li:last-child {
  padding-left: 10px;
  margin-right: 0;
}

/*=====================================
 77 . Blog Sidebar Page
========================================*/
.sidebar-area .faq-content .accordion .accordion-item .accordion-header .accordion-button {
  font-size: 15px;
  text-transform: initial;
}
.sidebar-area .widget-title {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 20px;
  font-weight: 500;
  line-height: 0.9;
  letter-spacing: 0.4px;
  border-bottom: 1px solid var(--title-color);
  display: inline-block;
  margin-bottom: 30px;
}
.sidebar-area .single-widget {
  padding: 25px;
  border-radius: 5px;
  box-shadow: 0px 0px 25px 0px rgba(16, 16, 16, 0.05);
}
@media (min-width: 992px) and (max-width: 1199px) {
  .sidebar-area .single-widget {
    padding: 25px 20px;
  }
}
.sidebar-area .single-widget .checkbox-container .form-inner {
  margin-bottom: 25px;
}
.sidebar-area .single-widget .checkbox-container .form-inner input {
  width: 100%;
  border-radius: 5px;
  background: #FFF;
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 13px;
  font-weight: 400;
  height: 40px;
  padding: 10px 20px;
  border: 1px solid #fff;
}
.sidebar-area .single-widget .checkbox-container .form-inner input::-moz-placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.sidebar-area .single-widget .checkbox-container .form-inner input::placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.sidebar-area .single-widget .checkbox-container .form-inner input:focus {
  border-color: var(--primary-color1);
}
.sidebar-area .single-widget .checkbox-container .form-inner .nice-select {
  background: #fff;
  height: 40px;
  padding: 0px 30px 0 20px;
  border: 1px solid #fff;
}
.sidebar-area .single-widget .checkbox-container .form-inner .nice-select .current {
  font-size: 13px;
}
.sidebar-area .single-widget .checkbox-container .form-inner .nice-select::after {
  right: 21px;
}
.sidebar-area .single-widget .checkbox-container .form-inner .nice-select:focus {
  border-color: var(--primary-color1);
}
.sidebar-area .single-widget .checkbox-container > ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.sidebar-area .single-widget .checkbox-container > ul > li {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 26px;
}
.sidebar-area .single-widget .checkbox-container > ul > li:last-child {
  margin-bottom: 0;
}
.sidebar-area .single-widget .checkbox-container > ul > li .containerss {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  width: 100%;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.sidebar-area .single-widget .checkbox-container > ul > li .containerss input {
  display: none;
}
.sidebar-area .single-widget .checkbox-container > ul > li .containerss span {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
}
.sidebar-area .single-widget .checkbox-container > ul > li .containerss .qty {
  color: var(--title-color);
  font-family: var(--font-jost);
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  padding: 2px 6px;
  border: 1px solid var(--text-color);
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
}
.sidebar-area .single-widget .checkbox-container > ul > li .containerss .checkmark {
  position: absolute;
  top: 0px;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid var(--title-color);
  border-radius: 2px;
}
.sidebar-area .single-widget .checkbox-container > ul > li .containerss:hover input ~ .checkmark {
  border-color: var(--primary-color1);
  background-color: var(--primary-color1);
}
.sidebar-area .single-widget .checkbox-container > ul > li .containerss input:checked ~ .checkmark {
  border-color: var(--primary-color1);
  background-color: var(--primary-color1);
}
.sidebar-area .single-widget .checkbox-container > ul > li .containerss input:checked ~ .checkmark::after {
  content: url(../img/innerpage/checkbox-check.svg);
  left: 1.5px;
  top: -1px;
  position: absolute;
}
.sidebar-area .single-widget .checkbox-container > ul > li .containerss .stars {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 5px;
  line-height: 1;
  margin-top: -2px;
}
.sidebar-area .single-widget .checkbox-container > ul > li .containerss .stars i {
  color: #DDA701;
  font-size: 13px;
}
.sidebar-area .single-widget .checkbox-container > ul > li .containerss .stars a {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 14px;
  font-weight: 500;
}
.sidebar-area .single-widget .category-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.sidebar-area .single-widget .category-list li {
  margin-bottom: 15px;
  position: relative;
  padding-left: 25px;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  transition: 0.5s;
}
.sidebar-area .single-widget .category-list li:last-child {
  margin-bottom: 0;
}
.sidebar-area .single-widget .category-list li a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  gap: 5px;
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.3px;
  transition: 0.5s;
}
.sidebar-area .single-widget .category-list li a span {
  font-size: 14px;
}
.sidebar-area .single-widget .category-list li::before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid var(--title-color);
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.5s;
}
.sidebar-area .single-widget .category-list li:hover a {
  color: var(--primary-color1);
}
.sidebar-area .single-widget .category-list li:hover::before {
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
}
.sidebar-area .single-widget .category-list.two li a span {
  color: var(--title-color);
  font-family: var(--font-jost);
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  padding: 2px 6px;
  border: 1px solid var(--text-color);
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
}
.sidebar-area .single-widget .category-list.two li:hover a span {
  border: 1px solid var(--primary-color1);
  color: var(--primary-color1);
}
.sidebar-area .single-widget .recent-post-widget {
  display: flex;
  align-items: center;
  gap: 15px;
}
.sidebar-area .single-widget .recent-post-widget .recent-post-img {
  border-radius: 5px;
}
.sidebar-area .single-widget .recent-post-widget .recent-post-img img {
  min-width: 92px;
  max-width: 92px;
  height: 71px;
  border-radius: 5px;
}
.sidebar-area .single-widget .recent-post-widget .recent-post-content > a {
  color: rgba(16, 12, 8, 0.5);
  font-family: var(--font-jost);
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 3px;
  transition: 0.5s;
}
.sidebar-area .single-widget .recent-post-widget .recent-post-content > a:hover {
  color: var(--primary-color1);
}
.sidebar-area .single-widget .recent-post-widget .recent-post-content h6 {
  margin-bottom: 0;
}
.sidebar-area .single-widget .recent-post-widget .recent-post-content h6 a {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.32px;
  text-transform: capitalize;
  transition: 0.5s;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .sidebar-area .single-widget .recent-post-widget .recent-post-content h6 a {
    font-size: 14px;
  }
}
.sidebar-area .single-widget .recent-post-widget .recent-post-content h6 a:hover {
  color: var(--primary-color1);
}
.sidebar-area .single-widget .tag-list {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .sidebar-area .single-widget .tag-list {
    gap: 14px;
  }
}
.sidebar-area .single-widget .tag-list li a {
  color: rgba(34, 34, 34, 0.5);
  font-family: var(--font-jost);
  font-size: 13px;
  font-weight: 400;
  display: block;
  line-height: 1;
  padding: 8px 20px;
  border: 1px solid rgba(16, 12, 8, 0.4);
  border-radius: 5px;
  transition: 0.5s;
}
.sidebar-area .single-widget .tag-list li:hover a {
  border: 1px solid var(--primary-color1);
  color: var(--white-color);
  background-color: var(--primary-color1);
}
.sidebar-area .single-widget .search-box {
  display: flex;
  align-items: center;
}
.sidebar-area .single-widget .search-box input {
  width: 100%;
  padding: 8px 20px;
  font-family: var(--font-jost);
  font-size: 14px;
  height: 50px;
  border: 1px solid rgba(var(--primary-color1-opc), 0.3);
}
.sidebar-area .single-widget .search-box input:focus {
  border: 1px solid #ddd;
}
.sidebar-area .single-widget .search-box button {
  background-color: var(--primary-color1);
  min-width: 90px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.sidebar-area .single-widget .search-box button::after {
  position: absolute;
  content: "";
  display: block;
  left: 15%;
  right: -20%;
  top: -4%;
  height: 150%;
  width: 150%;
  bottom: 0;
  border-radius: 2px;
  background-color: var(--title-color);
  transform: skewX(45deg) scale(0, 1);
  z-index: -1;
  transition: all 0.5s ease-out 0s;
}
.sidebar-area .single-widget .search-box button i {
  color: var(--white-color);
  font-size: 22px;
}
@media (max-width: 576px) {
  .sidebar-area .single-widget .search-box button {
    min-width: 70px;
  }
}
.sidebar-area .single-widget .search-box button:hover {
  color: var(--white-color);
}
.sidebar-area .single-widget .search-box button:hover::after {
  transform: skewX(45deg) scale(1, 1);
}

/*=======================================
 Range Slider
=======================================*/
.range-wrap {
  padding-top: 10px;
}
.range-wrap .slider-labels {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.range-wrap .slider-labels .caption {
  color: rgba(34, 34, 34, 0.6000000238);
  font-family: var(--font-jost);
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.3px;
}
.range-wrap .slider-labels a {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.3px;
}
.range-wrap .noUi-target,
.range-wrap .range-wrap .noUi-target * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  touch-action: none;
  -moz-user-select: none;
  user-select: none;
  box-sizing: border-box;
}
.range-wrap .noUi-target {
  position: relative;
  direction: ltr;
}
.range-wrap .noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  /* Fix 401 */
}
.range-wrap .noUi-origin {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}
.range-wrap .noUi-handle {
  position: relative;
  z-index: 1;
}
.range-wrap .noUi-stacking .noUi-handle {
  /* This class is applied to the lower origin when
     its values is > 50%. */
  z-index: 10;
}
.range-wrap .noUi-state-tap .noUi-origin {
  transition: left 0.3s, top 0.3s;
}
.range-wrap .noUi-state-drag * {
  cursor: inherit !important;
}
.range-wrap .noUi-base,
.range-wrap .range-wrap .noUi-handle {
  transform: translate3d(0, 0, 0);
}
.range-wrap .noUi-horizontal {
  height: 4px;
}
.range-wrap .noUi-horizontal .noUi-handle {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  left: 0px;
  right: 0px;
  top: -4px;
  background-color: var(--primary-color1);
  cursor: pointer;
}
.range-wrap .noUi-horizontal .noUi-handle::after {
  content: "";
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background-color: var(--white-color);
  position: absolute;
  left: 7px;
  top: 7px;
  transform: translate(-50%, -50%);
}
.range-wrap .noUi-background {
  background: rgba(33, 33, 33, 0.5);
  height: 5px;
  border-radius: 10px;
}
.range-wrap .noUi-connect {
  background: var(--primary-color1);
  transition: background 450ms;
  border-radius: 10px;
  height: 5px;
}
.range-wrap .noUi-target {
  border-radius: 10px;
}

/*=====================================
 78 . Blog Standard Page
========================================*/
.blog-meta {
  margin-bottom: 25px;
}
@media (max-width: 767px) {
  .blog-meta {
    margin-bottom: 20px;
  }
}
.blog-meta .author-area {
  display: flex;
  align-items: center;
  gap: 10px;
}
.blog-meta .author-area .author-img img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.blog-meta .author-area .author-content h6 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  text-transform: capitalize;
  margin-bottom: 0;
}
.blog-meta .author-area .author-content h6 a {
  color: var(--primary-color1);
  text-decoration: underline;
}
.blog-meta ul {
  display: flex;
  flex-wrap: wrap;
}
.blog-meta ul li {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.3px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 7px;
  margin-right: 50px;
  position: relative;
}
.blog-meta ul li svg {
  fill: rgba(16, 12, 8, 0.5);
  transition: 0.5s;
}
@media (max-width: 767px) {
  .blog-meta ul li {
    font-size: 13px;
  }
}
@media (max-width: 576px) {
  .blog-meta ul li {
    margin-right: 15px;
    font-size: 12px;
  }
}
.blog-meta ul li:last-child {
  margin-right: 0;
}
.blog-meta ul li::before {
  content: "";
  position: absolute;
  left: -25px;
  top: 3px;
  width: 2px;
  height: 10px;
  line-height: 5px;
  background-color: var(--primary-color1);
  border-radius: 4px;
}
@media (max-width: 576px) {
  .blog-meta ul li::before {
    left: -10px;
  }
}
.blog-meta ul li:first-child::before {
  display: none;
}
.blog-meta ul li a {
  color: var(--title-color);
  transition: 0.5s;
}
.blog-meta ul li a:hover {
  color: var(--primary-color1);
}
.blog-meta ul li a:hover svg {
  fill: var(--primary-color1);
}
.blog-meta.two {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
  width: 100%;
  padding: 20px 0;
  border-top: 1px solid rgba(16, 12, 8, 0.05);
  border-bottom: 1px solid rgba(16, 12, 8, 0.05);
}

.blog-st-card .blog-img-wrap {
  position: relative;
}
.blog-st-card .blog-img-wrap .card-img {
  overflow: hidden;
  display: block;
  position: relative;
  border-radius: 5px;
  transition: all 0.5s ease-out;
}
.blog-st-card .blog-img-wrap .card-img img {
  border-radius: 5px;
  transition: all 0.5s ease-out;
}
@media (max-width: 991px) {
  .blog-st-card .blog-img-wrap .card-img img {
    width: 100%;
  }
}
.blog-st-card .blog-img-wrap .card-img::after {
  position: absolute;
  width: 200%;
  height: 0%;
  left: 50%;
  top: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  transform: translate(-50%, -50%) rotate(-45deg);
  content: "";
  z-index: 1;
}
.blog-st-card .blog-img-wrap .date {
  position: absolute;
  top: 13px;
  left: 13px;
  text-align: center;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background: var(--primary-color1);
  -webkit-backdrop-filter: blur(1.7px);
          backdrop-filter: blur(1.7px);
  transition: 0.5s;
  z-index: 9;
}
.blog-st-card .blog-img-wrap .date span {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.22px;
  text-transform: capitalize;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s;
}
.blog-st-card .blog-img-wrap .date span strong {
  font-size: 22px;
  font-weight: 600;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .blog-st-card .blog-img-wrap .date {
    top: 5px;
    left: 5px;
    width: 60px;
    height: 60px;
  }
  .blog-st-card .blog-img-wrap .date span {
    font-size: 10px;
  }
  .blog-st-card .blog-img-wrap .date span strong {
    font-size: 16px;
  }
}
@media (max-width: 576px) {
  .blog-st-card .blog-img-wrap .date {
    top: 5px;
    left: 5px;
    width: 60px;
    height: 60px;
  }
  .blog-st-card .blog-img-wrap .date span {
    font-size: 10px;
  }
  .blog-st-card .blog-img-wrap .date span strong {
    font-size: 16px;
  }
}
.blog-st-card .blog-img-wrap:hover .card-img img {
  transform: scale(1.05);
}
.blog-st-card .blog-img-wrap:hover .card-img::after {
  height: 250%;
  transition: all 600ms linear;
  background-color: transparent;
}
.blog-st-card .blog-img-wrap:hover .date {
  background-color: var(--primary-color2);
}
.blog-st-card .blog-img-wrap:hover .date span {
  color: var(--title-color);
}
.blog-st-card .blog-content {
  padding: 35px 20px 0;
}
@media (max-width: 767px) {
  .blog-st-card .blog-content {
    padding: 25px 10px 0;
  }
}
@media (max-width: 576px) {
  .blog-st-card .blog-content {
    padding: 25px 0 0;
  }
}
.blog-st-card .blog-content h2 {
  margin-bottom: 20px;
}
.blog-st-card .blog-content h2 a {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 35px;
  font-weight: 600;
  line-height: 1.3;
  text-transform: capitalize;
  transition: 0.5s;
}
@media (max-width: 1199px) {
  .blog-st-card .blog-content h2 a {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .blog-st-card .blog-content h2 a {
    font-size: 25px;
  }
}
@media (max-width: 576px) {
  .blog-st-card .blog-content h2 a {
    font-size: 22px;
  }
}
.blog-st-card .blog-content h2 a:hover {
  color: var(--primary-color1);
}
@media (max-width: 767px) {
  .blog-st-card .blog-content h2 {
    margin-bottom: 15px;
  }
}
.blog-st-card .blog-content p {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.9;
  letter-spacing: 0.32px;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .blog-st-card .blog-content p {
    margin-bottom: 20px;
  }
}
@media (max-width: 576px) {
  .blog-st-card .blog-content p {
    font-size: 14px;
  }
}
.blog-st-card .blog-content > a {
  color: var(--primary-color1);
  font-family: var(--font-rubik);
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  text-decoration-line: underline;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: 0.5s;
}
.blog-st-card .blog-content > a > span {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid rgba(var(--primary-color1-opc), 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
}
.blog-st-card .blog-content > a > span svg {
  stroke: var(--primary-color1);
  transition: 0.5s;
}
.blog-st-card .blog-content > a:hover {
  color: var(--primary-color2);
}
.blog-st-card .blog-content > a:hover span {
  border-color: var(--primary-color2);
}
.blog-st-card .blog-content > a:hover span svg {
  transform: rotate(28deg);
  stroke: var(--primary-color2);
}
.blog-st-card.two .blog-content {
  text-align: center;
}
.blog-st-card.two .blog-content .blog-meta ul {
  justify-content: center;
}
.blog-st-card.two .blog-content a {
  justify-content: center;
}

/*=====================================
 79 . Package Category Page
========================================*/
.package-category-nav-section {
  margin-top: -80px;
}
.package-category-nav-section .nav-pills {
  position: relative;
  display: block;
}
.package-category-nav-section .nav-pills .tour-tab-slider {
  padding: 10px;
  margin: -10px;
}
.package-category-nav-section .nav-pills .nav-item .nav-link {
  border-radius: 5px;
  background: var(--white-color);
  text-align: center;
  padding: 25px 20px;
  box-shadow: 0px 6px 8px 0px rgba(16, 16, 16, 0.05);
  cursor: pointer;
}
.package-category-nav-section .nav-pills .nav-item .nav-link .icon {
  margin-bottom: 15px;
}
.package-category-nav-section .nav-pills .nav-item .nav-link .icon svg {
  fill: var(--title-color);
}
.package-category-nav-section .nav-pills .nav-item .nav-link .content h5 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 0;
}
.package-category-nav-section .nav-pills .nav-item .nav-link.active {
  background-color: var(--primary-color1);
}
.package-category-nav-section .nav-pills .nav-item .nav-link.active .icon svg {
  fill: var(--white-color);
}
.package-category-nav-section .nav-pills .nav-item .nav-link.active .content h5 {
  color: var(--white-color);
}
.package-category-nav-section .nav-pills .slider-btn-grp4 .slider-btn {
  width: 44px;
  height: 32px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -26px;
  z-index: 1;
}
@media (max-width: 576px) {
  .package-category-nav-section .nav-pills .slider-btn-grp4 .slider-btn {
    left: -10px;
  }
}
.package-category-nav-section .nav-pills .slider-btn-grp4 .slider-btn.tour-tab-slider-next {
  right: -26px;
  left: unset;
}
@media (max-width: 576px) {
  .package-category-nav-section .nav-pills .slider-btn-grp4 .slider-btn.tour-tab-slider-next {
    right: -10px;
  }
}

/*=====================================
 80 . Package Top Search Page
========================================*/
.package-search-filter-wrapper {
  margin-top: -50px;
}
.package-search-filter-wrapper .filter-group {
  background-color: var(--white-color);
  border: 1px solid rgba(99, 171, 69, 0.1);
  border-radius: 20px;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.08);
  padding: 40px 30px;
}
@media (max-width: 767px) {
  .package-search-filter-wrapper .filter-group {
    padding: 35px 25px;
  }
}
.package-search-filter-wrapper .filter-group form {
  display: flex;
  align-items: center;
  gap: 15px;
}
@media (max-width: 1199px) {
  .package-search-filter-wrapper .filter-group form {
    flex-direction: row;
  }
}
@media (max-width: 767px) {
  .package-search-filter-wrapper .filter-group form {
    flex-direction: column;
  }
}
@media (max-width: 1199px) {
  .package-search-filter-wrapper .filter-group form {
    flex-direction: column;
  }
}
.package-search-filter-wrapper .filter-group form .filter-area {
  padding: 0;
  width: 100%;
}
@media (max-width: 1199px) {
  .package-search-filter-wrapper .filter-group form .filter-area {
    margin-bottom: 0;
  }
}
.package-search-filter-wrapper .filter-group form .filter-area .single-search-box {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid rgba(16, 12, 8, 0.15);
  padding: 11px 15px;
  width: 100%;
  position: relative;
}
@media (max-width: 1799px) {
  .package-search-filter-wrapper .filter-group form .filter-area .single-search-box {
    padding: 8px 10px;
  }
}
@media (max-width: 1399px) {
  .package-search-filter-wrapper .filter-group form .filter-area .single-search-box {
    width: 100%;
  }
}
.package-search-filter-wrapper .filter-group form .filter-area .single-search-box .icon svg {
  fill: var(--primary-color1);
}
.package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input {
  line-height: 1;
}
@media (max-width: 1199px) {
  .package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input {
    width: 100%;
  }
}
.package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input label {
  color: #6A6A6A;
  font-family: var(--font-rubik);
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  display: block;
  margin-bottom: 5px;
}
.package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .select-input {
  display: flex;
  align-items: center;
  line-height: 1;
  cursor: pointer;
}
.package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .select-input input {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.3px;
  background-color: transparent;
  width: 100%;
  cursor: pointer;
}
.package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .select-input input::-moz-placeholder {
  color: var(--title-color);
}
.package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .select-input input::placeholder {
  color: var(--title-color);
}
.package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .select-input i {
  color: var(--title-color);
  font-weight: 500;
}
.package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .select-input h6 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.3px;
  min-width: 165px;
  width: 100%;
  margin-bottom: 0;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .select-input h6 {
    min-width: 150px;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .select-input h6 {
    min-width: 145px;
  }
}
.package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap {
  padding: 10px 0;
  background-color: var(--white-color);
  border-radius: 5px;
  max-width: 300px;
  min-width: 300px;
  width: 100%;
  max-height: 250px;
  overflow-y: scroll;
  position: absolute;
  top: 60px;
  left: 0;
  z-index: 9;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.08);
  display: none;
  transform: scaleY(0);
  transform-origin: top;
}
.package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap::-webkit-scrollbar {
  width: 5px; /* width of the entire scrollbar */
}
.package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap::-webkit-scrollbar-track {
  background: #f5f4f4;
}
.package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap::-webkit-scrollbar-thumb {
  background-color: #ddd; /* color of the scroll thumb */
  border-radius: 20px; /* creates padding around scroll thumb */
}
.package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap.no-scroll {
  overflow-y: initial;
  max-height: unset !important;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap {
    max-width: 100%;
    min-width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap {
    left: 0;
  }
}
@media (max-width: 576px) {
  .package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap {
    left: 50%;
    max-width: 100%;
    min-width: 100%;
  }
}
.package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap .custom-select-search-area {
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 0 10px 5px 10px;
}
.package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap .custom-select-search-area i {
  color: var(--primary-color1);
}
.package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap .custom-select-search-area input {
  background-color: transparent;
  color: var(--title-color);
  font-size: 14px;
  font-weight: 400;
  width: 100%;
}
.package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap .option-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.5s;
  cursor: pointer;
  padding: 0 10px;
  line-height: 1;
  margin-bottom: 5px;
  position: relative;
}
.package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap .option-list li::before {
  content: "";
  height: 100%;
  width: 3px;
  background-color: var(--title-color);
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transition: 0.5s;
}
.package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap .option-list li:last-child {
  margin-bottom: 0;
}
.package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap .option-list li .destination h6 {
  font-size: 14px;
  font-family: var(--font-rubik);
  color: var(--title-color);
  font-weight: 500;
  line-height: 1.4;
  margin-bottom: 0;
}
@media (max-width: 576px) {
  .package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap .option-list li .destination h6 {
    font-size: 13px;
  }
}
.package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap .option-list li .destination p {
  font-size: 12px;
  font-family: var(--font-jost);
  color: var(--text-color);
  font-weight: 400;
  margin-bottom: 0;
  line-height: 1.4;
}
@media (max-width: 576px) {
  .package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap .option-list li .destination p {
    font-size: 11px;
  }
}
.package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap .option-list li .tour span {
  color: var(--white-color);
  font-weight: 400;
  font-family: var(--font-jost);
  font-size: 12px;
  line-height: 1;
  text-align: center;
  background-color: var(--primary-color1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 576px) {
  .package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap .option-list li .tour span {
    font-size: 13px;
  }
}
.package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap .option-list li.single-item {
  padding: 10px 15px;
  border-bottom: 1px solid #f5f4f4;
  margin-bottom: 0;
}
.package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap .option-list li.single-item:last-child {
  border-bottom: none;
}
.package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap .option-list li.single-item h6 {
  font-size: 13px;
  font-weight: 500;
  font-family: var(--font-rubik);
  color: var(--text-color);
  margin-bottom: 0;
}
.package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap .option-list li:hover {
  background: rgba(211, 211, 211, 0.231372549);
}
.package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap .option-list li:hover::before {
  opacity: 1;
}
.package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap.active {
  display: block;
  transform: scaleY(1);
  animation: fade-down 0.3s linear;
}
@keyframes fade-down2 {
  0% {
    opacity: 0;
    transform: scaleY(0) translateX(-50%);
    visibility: hidden;
  }
  100% {
    opacity: 1;
    transform: scaleY(1) translateX(-50%);
    visibility: visible;
  }
}
@media (max-width: 576px) {
  .package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap.active {
    transform: scaleY(1) translateX(-50%);
    animation: fade-down2 0.3s linear;
  }
}
.package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap.two {
  max-width: 200px;
  min-width: 200px;
  max-height: 180px;
  padding: 0;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.08);
}
@media (min-width: 992px) and (max-width: 1199px) {
  .package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap.two {
    max-width: 100%;
    min-width: 100%;
  }
}
@media (max-width: 991px) {
  .package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap.two {
    max-width: 100%;
    min-width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap.two {
    left: 0;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap.two {
    left: -35px;
  }
}
.package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap.two .guest-count .single-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px;
  border-bottom: 1px solid #f5f4f4;
}
.package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap.two .guest-count .single-item .title h6 {
  font-size: 14px;
  font-weight: 500;
  font-family: var(--font-rubik);
  color: var(--text-color);
  margin-bottom: 0;
}
.package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap.two .guest-count .single-item .title span {
  font-size: 13px;
  font-weight: 400;
  font-family: var(--font-jost);
  color: var(--text-color);
  margin-bottom: 0;
}
.package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap.two .guest-count .single-item .quantity-counter {
  display: flex;
  align-items: center;
}
.package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap.two .guest-count .single-item .quantity-counter a {
  height: 20px;
  min-width: 20px;
  max-width: 20px;
  border-radius: 50%;
  border: 1px solid var(--primary-color1);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.35s;
  font-size: 17px;
  color: var(--primary-color1);
}
.package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap.two .guest-count .single-item .quantity-counter a:hover {
  color: var(--white-color);
  background-color: var(--primary-color1);
}
.package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .custom-select-dropdown .custom-select-wrap.two .guest-count .single-item .quantity-counter input {
  width: 40px;
  background-color: transparent;
  text-align: center;
  line-height: 1;
  color: var(--title-color);
  font-family: var(--font-jost);
  font-size: 14px;
  font-weight: 500;
}
.package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .quantity-counter {
  position: relative;
}
.package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .quantity-counter a {
  color: var(--title-color);
  line-height: 1;
}
.package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .quantity-counter a i {
  font-size: 14px;
}
.package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .quantity-counter .quantity__plus {
  position: absolute;
  top: -8px;
  right: 20px;
}
@media (max-width: 1199px) {
  .package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .quantity-counter .quantity__plus {
    right: 0;
  }
}
.package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .quantity-counter .quantity__minus {
  position: absolute;
  top: 8px;
  right: 20px;
}
@media (max-width: 1199px) {
  .package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .quantity-counter .quantity__minus {
    right: 0;
  }
}
.package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .quantity-counter .quantity__input {
  width: 100%;
  height: unset;
  border: 1px solid #eee;
  border-radius: 5px;
  background-color: transparent;
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.48px;
  border: none;
}
.package-search-filter-wrapper .filter-group form .filter-area .single-search-box .searchbox-input .quantity-counter .quantity__input:focus {
  border: none;
}
.package-search-filter-wrapper .filter-group form button {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.54px;
  white-space: nowrap;
  background-color: var(--primary-color1);
  padding: 21px 44px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.package-search-filter-wrapper .filter-group form button::after {
  position: absolute;
  content: "";
  display: block;
  left: 15%;
  right: -20%;
  top: -4%;
  height: 150%;
  width: 150%;
  bottom: 0;
  border-radius: 2px;
  background-color: var(--title-color);
  transform: skewX(45deg) scale(0, 1);
  z-index: -1;
  transition: all 0.5s ease-out 0s;
}
.package-search-filter-wrapper .filter-group form button:hover {
  color: var(--white-color);
}
.package-search-filter-wrapper .filter-group form button:hover::after {
  transform: skewX(45deg) scale(1, 1);
}
@media (max-width: 1199px) {
  .package-search-filter-wrapper .filter-group form button {
    padding: 20px 53px;
    border-radius: 5px;
  }
}
@media (max-width: 576px) {
  .package-search-filter-wrapper .filter-group form button {
    padding: 16px 40px;
    font-size: 16px;
  }
}

/*=====================================
 81 . Blog Details Page
========================================*/
.blog-details-section .post-title h1 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 40px;
  font-weight: 600;
  line-height: 1.4;
  text-transform: capitalize;
  margin-bottom: 0;
}
@media (max-width: 1399px) {
  .blog-details-section .post-title h1 {
    font-size: 35px;
  }
}
@media (max-width: 991px) {
  .blog-details-section .post-title h1 {
    font-size: 30px;
  }
}
@media (max-width: 576px) {
  .blog-details-section .post-title h1 {
    font-size: 23px;
  }
}
.blog-details-section p {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 16px;
  font-weight: 400;
  line-height: 2.2;
  letter-spacing: 0.32px;
  margin-bottom: 0;
}
@media (max-width: 576px) {
  .blog-details-section p {
    font-size: 15px;
  }
}
.blog-details-section p.first-para {
  margin-bottom: 5px;
}
.blog-details-section p.first-para::first-letter {
  font-size: 75px;
  font-family: var(--font-rubik);
  font-weight: 500;
  color: var(--title-color);
  line-height: 1;
  float: left;
  margin-right: 15px;
}
.blog-details-section blockquote {
  background-color: #FAF8FB;
  padding: 40px;
  position: relative;
  margin-top: 55px;
  margin-bottom: 50px;
}
@media (max-width: 576px) {
  .blog-details-section blockquote {
    padding: 30px 20px;
  }
}
.blog-details-section blockquote .quoat-icon {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background-color: var(--primary-color1);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 40px;
  top: -23px;
}
.blog-details-section blockquote .quoat-icon svg {
  fill: var(--primary-color1);
}
.blog-details-section blockquote p {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 15px;
}
@media (max-width: 576px) {
  .blog-details-section blockquote p {
    font-size: 16px;
  }
}
.blog-details-section blockquote cite {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  padding-left: 50px;
  position: relative;
}
.blog-details-section blockquote cite::before {
  content: "";
  height: 1px;
  width: 40px;
  background-color: var(--primary-color1);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.blog-details-section h2 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 30px;
  font-weight: 600;
  line-height: 1.2;
  text-transform: capitalize;
  margin-bottom: 20px;
}
.blog-details-section .tag-and-social-area {
  padding-top: 45px;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(16, 12, 8, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  flex-wrap: wrap;
}
.blog-details-section .tag-and-social-area .bolg-tag .tag-list {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .blog-details-section .tag-and-social-area .bolg-tag .tag-list {
    gap: 14px;
  }
}
.blog-details-section .tag-and-social-area .bolg-tag .tag-list li a {
  color: rgba(34, 34, 34, 0.5);
  font-family: var(--font-jost);
  font-size: 13px;
  font-weight: 400;
  display: block;
  line-height: 1;
  padding: 8px 20px;
  border: 1px solid rgba(16, 12, 8, 0.4);
  border-radius: 5px;
  transition: 0.5s;
}
.blog-details-section .tag-and-social-area .bolg-tag .tag-list li:hover a {
  border: 1px solid var(--primary-color1);
  color: var(--white-color);
  background-color: var(--primary-color1);
}
.blog-details-section .tag-and-social-area .social-area {
  display: flex;
  align-items: center;
  gap: 20px;
  line-height: 1;
}
.blog-details-section .tag-and-social-area .social-area h6 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
  text-transform: capitalize;
  margin-bottom: 0;
}
.blog-details-section .tag-and-social-area .social-area .social-link {
  display: flex;
  align-items: center;
  gap: 30px;
}
.blog-details-section .tag-and-social-area .social-area .social-link li a i {
  font-size: 18px;
  color: var(--text-color);
  transition: 0.5s;
}
.blog-details-section .tag-and-social-area .social-area .social-link li a svg {
  fill: var(--text-color);
  transition: 0.5s;
}
.blog-details-section .tag-and-social-area .social-area .social-link li a:hover i {
  color: var(--primary-color1);
}
.blog-details-section .tag-and-social-area .social-area .social-link li a:hover svg {
  fill: var(--primary-color1);
}
.blog-details-section .details-navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
@media (max-width: 576px) {
  .blog-details-section .details-navigation {
    justify-content: center;
    flex-wrap: wrap;
  }
}
.blog-details-section .details-navigation .single-navigation {
  display: flex;
  align-items: center;
  gap: 20px;
  transition: 0.35s;
}
.blog-details-section .details-navigation .single-navigation .arrow {
  width: 30px;
  height: 60px;
  border-radius: 5px;
  border: 1px solid rgba(13, 23, 32, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.35s;
}
.blog-details-section .details-navigation .single-navigation .arrow svg {
  fill: rgba(13, 23, 32, 0.16);
  transition: 0.35s;
}
.blog-details-section .details-navigation .single-navigation .arrow:hover {
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
}
.blog-details-section .details-navigation .single-navigation .arrow:hover svg {
  fill: var(--white-color);
}
.blog-details-section .details-navigation .single-navigation .content {
  max-width: 301px;
  width: 100%;
  line-height: 1;
  transition: 0.35s;
}
.blog-details-section .details-navigation .single-navigation .content > a {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 14px;
  font-weight: 500;
  transition: 0.35s;
  margin-bottom: 5px;
  display: inline-block;
}
.blog-details-section .details-navigation .single-navigation .content h6 {
  transition: 0.35s;
  margin-bottom: 0;
  line-height: 1;
}
.blog-details-section .details-navigation .single-navigation .content h6 a {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  transition: 0.35s;
}
.blog-details-section .details-navigation .single-navigation .content h6 a:hover {
  color: var(--primary-color1);
}
.blog-details-section .comment-title {
  display: flex;
  align-items: center;
  gap: 14px;
  margin-bottom: 40px;
}
.blog-details-section .comment-title h4 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 0;
  line-height: 1;
  white-space: nowrap;
}
.blog-details-section .comment-title .dash {
  position: relative;
  width: 100%;
}
.blog-details-section .comment-title .dash::after {
  content: "";
  height: 1px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(238, 238, 238, 0.93);
}
.blog-details-section .comment {
  margin: 0;
  padding: 0;
  list-style: none;
}
.blog-details-section .comment > li {
  margin-bottom: 40px;
}
.blog-details-section .comment > li > .single-comment-area {
  margin-bottom: 24px;
}
.blog-details-section .comment > li:last-child {
  margin-bottom: 0;
}
.blog-details-section .comment > li:last-child > .single-comment-area {
  margin-bottom: 0;
}
.blog-details-section .comment .single-comment-area {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}
.blog-details-section .comment .single-comment-area .author-img img {
  max-width: 50px;
  min-width: 50px;
  height: 50px;
  border-radius: 50%;
}
.blog-details-section .comment .single-comment-area .comment-content .author-name-deg {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}
.blog-details-section .comment .single-comment-area .comment-content .author-name-deg h6 {
  line-height: 1;
  margin-bottom: 0;
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 15px;
  font-weight: 600;
}
.blog-details-section .comment .single-comment-area .comment-content .author-name-deg span {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
}
.blog-details-section .comment .single-comment-area .comment-content p {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 12px;
}
.blog-details-section .comment .single-comment-area .comment-content .replay-btn {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 14px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  line-height: 1;
  cursor: pointer;
  transition: 0.35s;
}
.blog-details-section .comment .single-comment-area .comment-content .replay-btn svg {
  transition: 0.35s;
  fill: var(--text-color);
}
.blog-details-section .comment .single-comment-area .comment-content .replay-btn:hover {
  color: var(--primary-color1);
}
.blog-details-section .comment .single-comment-area .comment-content .replay-btn:hover svg {
  fill: var(--primary-color1);
}
.blog-details-section .comment .comment-replay {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-left: 70px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 24px 0;
}
.blog-details-section .comment .comment-replay > li {
  margin-bottom: 30px;
}
.blog-details-section .comment .comment-replay > li:last-child {
  margin-bottom: 0;
}
.blog-details-section .inquiry-form {
  background: linear-gradient(125deg, rgba(99, 171, 69, 0.1) 0%, rgba(251, 176, 59, 0.1) 100%);
  padding: 45px 40px 50px;
}
@media (max-width: 767px) {
  .blog-details-section .inquiry-form {
    padding: 45px 25px 50px;
  }
}
@media (max-width: 576px) {
  .blog-details-section .inquiry-form {
    padding: 40px 20px 45px;
  }
}
.blog-details-section .inquiry-form .title {
  margin-bottom: 30px;
}
@media (max-width: 576px) {
  .blog-details-section .inquiry-form .title {
    margin-bottom: 20px;
  }
}
.blog-details-section .inquiry-form .title h4 {
  margin-bottom: 0;
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 0;
}
@media (max-width: 576px) {
  .blog-details-section .inquiry-form .title h4 {
    font-size: 25px;
  }
}
.blog-details-section .inquiry-form .form-inner {
  position: relative;
  line-height: 1;
}
.blog-details-section .inquiry-form .form-inner label {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 14px;
  font-weight: 600;
  display: block;
  margin-bottom: 10px;
}
.blog-details-section .inquiry-form .form-inner input {
  background: var(--white-color);
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 14px;
  font-weight: 400;
  height: 50px;
  width: 100%;
  padding: 10px 20px;
  outline: none;
  border: 1px solid #fff;
}
.blog-details-section .inquiry-form .form-inner input::-moz-placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.blog-details-section .inquiry-form .form-inner input::placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.blog-details-section .inquiry-form .form-inner textarea {
  background: var(--white-color);
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  padding: 20px 20px;
  outline: none;
  border: 1px solid #fff;
  min-height: 120px;
}
.blog-details-section .inquiry-form .form-inner textarea::-moz-placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.blog-details-section .inquiry-form .form-inner textarea::placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.blog-details-section .inquiry-form .form-check {
  min-height: unset;
  margin-bottom: 0;
  line-height: 1;
}
.blog-details-section .inquiry-form .form-check .form-check-input {
  cursor: pointer;
  height: 14px;
  width: 14px;
  margin-top: 2px;
}
.blog-details-section .inquiry-form .form-check .form-check-input:focus {
  box-shadow: none;
}
.blog-details-section .inquiry-form .form-check .form-check-input:checked {
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
}
.blog-details-section .inquiry-form .form-check .form-check-input[type=checkbox] {
  border-radius: unset;
}
.blog-details-section .inquiry-form .form-check .form-check-label {
  cursor: pointer;
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 14px;
  font-weight: 400;
}

/*=====================================
 82 . Package Grid With Sidebar Page
========================================*/
.package-inner-title-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 767px) {
  .package-inner-title-section {
    flex-wrap: wrap;
    gap: 20px;
  }
}
@media (max-width: 576px) {
  .package-inner-title-section {
    flex-direction: column;
    align-items: flex-start;
  }
}
.package-inner-title-section p {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  text-transform: capitalize;
  margin-bottom: 0;
}
.package-inner-title-section .selector-and-grid {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}
@media (max-width: 576px) {
  .package-inner-title-section .selector-and-grid {
    justify-content: space-between;
    width: 100%;
  }
}
.package-inner-title-section .selector-and-grid .selector {
  margin-right: 20px;
}
@media (max-width: 767px) {
  .package-inner-title-section .selector-and-grid .selector {
    margin-right: 0;
  }
}
.package-inner-title-section .selector-and-grid .selector .nice-select {
  width: 195px;
  height: 40px;
  border-radius: 30px;
  border: 1px solid var(--primary-color1);
  color: var(--primary-color1);
  font-family: var(--font-jost);
  font-size: 15px;
  font-weight: 400;
  text-transform: capitalize;
  padding-left: 25px;
  display: flex;
  align-items: center;
}
.package-inner-title-section .selector-and-grid .selector .nice-select:focus {
  border: 1px solid #ddd;
}
@media (max-width: 576px) {
  .package-inner-title-section .selector-and-grid .selector .nice-select {
    width: 200px;
    height: 35px;
  }
}
.package-inner-title-section .selector-and-grid .selector .nice-select::after {
  border-bottom: 2px solid var(--primary-color1);
  border-right: 2px solid var(--primary-color1);
  width: 8px;
  height: 8px;
  right: 28px;
  top: 48%;
}
@media (max-width: 576px) {
  .package-inner-title-section .selector-and-grid .selector .nice-select::after {
    width: 8px;
    height: 8px;
    top: 45%;
  }
}
.package-inner-title-section .selector-and-grid .selector .nice-select.open .list {
  width: 100%;
}
.package-inner-title-section .selector-and-grid .selector .nice-select.open .list .option {
  padding-left: 25px;
}
.package-inner-title-section .selector-and-grid .selector.two {
  padding-right: 15px;
}
@media (max-width: 991px) {
  .package-inner-title-section .selector-and-grid .selector.two {
    margin: 0;
  }
}
.package-inner-title-section .selector-and-grid .selector.two .nice-select {
  border: unset;
  height: unset;
  line-height: 1;
  width: 120px;
  padding: 0;
}
@media (max-width: 991px) {
  .package-inner-title-section .selector-and-grid .selector.two .nice-select {
    width: 100%;
    border: 1px solid #222;
    padding: 10px 40px 10px 20px;
  }
}
.package-inner-title-section .selector-and-grid .selector.two .nice-select::after {
  right: -15px;
}
@media (max-width: 991px) {
  .package-inner-title-section .selector-and-grid .selector.two .nice-select::after {
    right: 22px;
  }
}
.package-inner-title-section .selector-and-grid .selector.two .nice-select.open .list {
  margin-top: 25px;
  width: unset;
}
@media (max-width: 991px) {
  .package-inner-title-section .selector-and-grid .selector.two .nice-select.open .list {
    left: unset;
    right: 0;
  }
}
@media (max-width: 576px) {
  .package-inner-title-section .selector-and-grid .selector.two .nice-select.open .list {
    right: unset;
    left: 0;
  }
}
.package-inner-title-section .selector-and-grid .grid-view {
  display: flex;
  align-items: center;
  gap: 20px;
}
@media (max-width: 767px) {
  .package-inner-title-section .selector-and-grid .grid-view {
    display: none;
  }
}
.package-inner-title-section .selector-and-grid .grid-view li {
  line-height: 1;
  cursor: pointer;
}
.package-inner-title-section .selector-and-grid .grid-view li svg {
  fill: rgba(16, 12, 8, 0.4);
}
.package-inner-title-section .selector-and-grid .grid-view li.active svg {
  fill: var(--primary-color1);
}

.tour-facilites-section .package-card-slider-wrap {
  position: relative;
}
@media (max-width: 991px) {
  .tour-facilites-section .package-card-slider-wrap .slider-btn-grp4 {
    display: none;
  }
}
.tour-facilites-section .package-card-slider-wrap .slider-btn-grp4 .package-card-slider-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -100px;
  z-index: 1;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .tour-facilites-section .package-card-slider-wrap .slider-btn-grp4 .package-card-slider-prev {
    left: -28px;
    top: 54%;
  }
}
@media (max-width: 1399px) {
  .tour-facilites-section .package-card-slider-wrap .slider-btn-grp4 .package-card-slider-prev {
    left: -28px;
  }
}
@media (max-width: 1199px) {
  .tour-facilites-section .package-card-slider-wrap .slider-btn-grp4 .package-card-slider-prev {
    left: -22px;
    top: 45%;
  }
}
.tour-facilites-section .package-card-slider-wrap .slider-btn-grp4 .package-card-slider-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -100px;
  z-index: 1;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .tour-facilites-section .package-card-slider-wrap .slider-btn-grp4 .package-card-slider-next {
    right: -28px;
    top: 54%;
  }
}
@media (max-width: 1399px) {
  .tour-facilites-section .package-card-slider-wrap .slider-btn-grp4 .package-card-slider-next {
    right: -28px;
  }
}
@media (max-width: 1199px) {
  .tour-facilites-section .package-card-slider-wrap .slider-btn-grp4 .package-card-slider-next {
    right: -22px;
    top: 45%;
  }
}
.tour-facilites-section .nav-tabs {
  border-bottom: none;
}
.tour-facilites-section .nav-tabs .nav-item .nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  border-radius: 5px;
  border-right: none;
  border: none;
}
.tour-facilites-section .nav-tabs .nav-item .nav-link svg {
  fill: var(--title-color);
}
.tour-facilites-section .nav-tabs .nav-item .nav-link.active {
  color: var(--primary-color1);
}
.tour-facilites-section .nav-tabs .nav-item .nav-link.active svg {
  fill: var(--primary-color1);
}/*# sourceMappingURL=style.css.map */