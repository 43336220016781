.hori-timeline .events {
  border-top: 3px solid #e9ecef;
}
.hori-timeline .events .event-list {
  display: block;
  position: relative;
  text-align: center;
  padding-top: 70px;
  margin-right: 0;
}
.hori-timeline .events .event-list:before {
  content: "";
  position: absolute;
  height: 36px;
  border-right: 2px dashed #dee2e6;
  top: 0;
}
.hori-timeline .events .event-list .event-date {
  position: absolute;
  top: 38px;
  left: 0;
  right: 0;
  width: 75px;
  margin: 0 auto;
  border-radius: 4px;
  padding: 2px 4px;
  background: #20a397;
  color: white;
}
@media (min-width: 1140px) {
  .hori-timeline .events .event-list {
      display: inline-block;
      width: 24%;
      padding-top: 45px;
  }
  .hori-timeline .events .event-list .event-date {
      top: -12px;
  }
}

.slider-card {
  border: none;
  margin-bottom: 24px;
  -webkit-box-shadow: 0 0 13px 0 rgba(236,236,241,.44);
  box-shadow: 0 0 13px 0 rgba(236,236,241,.44);
}

.event-label {
  border-radius: 20px;
  padding: 8px 10px;
  font-size: 14px;
  text-align: center;
  position: relative;
  display: block;
  cursor: pointer;
  color: #fff;
  background: #20a397;
}

.event-label:before
{
  width: 0;
  height: 0;
  border-bottom: 10px solid #20a397;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  position: absolute;
  top: -10px;
  left: 50%;
  margin-left: -3px;
  content: '';
}

.selected-indicator {
  position: relative;
  top: 50%;

  transform: translate(-50%, -50%);
}

.round-box {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #007bff; /* Change the color as desired */
}

.event-checkbox {
  display: none;
}

.event-list.selected {
  background-color: #f0f0f0; /* Change the background color as desired */
}

.event-list.selected .event-checkbox {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  accent-color: #007bff; /* Change the checkbox color as desired */
}