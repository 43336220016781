.banner-section {
  position: relative;
  overflow: hidden;
}

.banner-carousel {
  position: relative;
}

.slide-item {
  display: none; /* Hide all slides by default */
  background-size: cover;
  background-position: center;
  height: 400px; /* Adjust height as necessary */
  position: relative;
  opacity: 0; /* Make slides invisible by default */
  transform: scale(1); /* Default scale */
  transition: opacity 1s ease-in-out, transform 1s ease-in-out; /* Transition effect */
}

.slide-item.active {
  display: block; /* Show active slide */
  opacity: 1; /* Make active slide visible */
  transform: scale(1.05); /* Slightly zoom in the active slide */
}

.slide-item.next {
  opacity: 0; /* Next slide will fade in */
  transform: scale(1); /* Reset scale */
}

.big-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 50px;
  color: rgba(255, 255, 255, 0.1);
}

.auto-container {
  position: relative;
  z-index: 1;
}

.content-box {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: #fff;
}

.special-text {
  font-weight: bold;
}

.booking-form {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  margin-top: 20px;
  border-radius: 5px;
}
