:root {
    --primary-color: #63ab45;
    --primary-base-content: #ffffff;

    --primary-bg: #ffffff;

    --secondary-base: #edf2de;
    --secondary-content: #6a6a6a;

    --font-size: 14px;
}


.paste-button {
    position: relative;
    display: block;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.button {
    background-color: var(--secondary-base);
    color: var(--secondary-content);
    padding: 10px 15px;
    font-size: var(--font-size);
    font-weight: bold;
    /* border: 2px solid transparent; */
    /* border-radius: 15px; */
    cursor: pointer;
}

.dropdown-content {
    display: none;
    font-size: 13px;
    position: absolute;
    z-index: 1;
    min-width: 200px;
    background-color: var(--secondary-base);
    /* border: 2px solid #4CAF50; */
    /* border-radius: 0px 0px 15px 15px; */
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown-content a {
    color: var(--secondary-content);
    padding: 8px 10px;
    text-decoration: none;
    display: block;
    transition: 0.1s;
}

.dropdown-content a:hover {
    background-color: var(--secondary-base);
    color: var(--secondary-content);
}

.dropdown-content a:focus {
    background-color: var(--secondary-base);
    /* color: #4CAF50; */
}

.dropdown-content #top:hover {
    border-radius: 0px 13px 0px 0px;
}

.dropdown-content #bottom:hover {
    border-radius: 0px 0px 13px 13px;
}

.paste-button:hover button {
    border-radius: 15px 15px 0px 0px;
}

.paste-button:hover .dropdown-content {
    display: block;
}

/*  */

.paste-button {
    position: relative;
    min-width: 200px;
    /* min-width: 100%; */
    /* border: 1px solid red; */
}

.button {
    cursor: pointer;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 20;
}

.custom-select-search-area {
    display: flex;
    align-items: center;
    padding: 10px;
}

.custom-select-search-area input {
    margin-left: 10px;
    flex-grow: 1;
}

.paste-button .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.paste-button .dropdown-content a:hover {
    background-color: #ddd;
}

.paste-button .dropdown-content {
    display: block;
    /* Ensure the dropdown-content is displayed when isOpen is true */
}

/* .selected {
    background-color: var(--secondary-base);
    color: white;
} */

.search-dropdown {
    border: 1px solid var(--primary-color);
}