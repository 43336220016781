.dashboard-layout {
  .sidebar-wrapper .metismenu a {
    background-color: #f5f5f5;
    color: #333 !important;
    font-weight: bold;
  }
  .sidebar-wrapper .metismenu ul {
    border: none;
  }
}

.page-content {
  width: 100% !important;
  margin: 0 auto;
  padding: 0px;
  margin-left: 0 !important;
  margin-top: 0 !important;
}

@media print {
  .dashboard-layout {
    width: 100%;
    margin: 0;
    .navbar {
      display: none;
      margin: 0;
      width: 0;
    }
    .sidebar-wrapper {
      display: none;
      margin: 0;
      width: 0;
    }
    .page-content {
      width: 100% !important;
      margin: 0 auto;
      padding: 10px;
      margin-left: 0 !important;
      margin-top: 0 !important;
    }
  }
}
