/* .tabstyle-packagetab { */
/* border: 1px solid red; */
/* } */

/* @media (max-width: 450px) {
    .tabstyle-packagetab {
        display: flex;
        width: 100%;
        overflow-x: scroll;
    }
} */
/* @media (min-width: 451px) {
    .tabstyle-packagetab {
        
    }
} */

/* @media only screen and (max-width: 443px) {
    .sm-tab {
        visibility: visible;
        display: flex;
        overflow-x: scroll;
    }

    .lg-tab {
        
        visibility: hidden;
        height: 0px;
        width: 0px;
        border: 1px solid red;
    }
}

@media only screen and (min-width: 451px) {
    .sm-tab {
        visibility: hidden;
        height: 0px;
        width: 0px;
    }

    .lg-tab {
        visibility: visible;
    }
} 

@media (max-width: 768px) {
    .package-card-with-tab .nav-pills .nav-item {
        flex: 1 1 auto;
        text-align: center;
        padding: 10px 0;
    }

    .package-card-with-tab .nav-pills .nav-link {
        display: block;
        padding: 10px;
        background-size: cover;
        background-position: center;
    }

    .swiper-slide {
        width: 100% !important;
    }
}

@media (max-width: 480px) {
    .package-card-with-tab .nav-pills .nav-item {
        font-size: 14px;
    }

    .swiper-slide {
        width: 100% !important;
    }
} */


@media only screen and (max-width: 768px) {
    .tabstyle-packagetab {
        /* visibility: visible; */
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
    }
}