.carousel-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
  
  .carousel-content {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }
  
  .category-block-one {
    flex: 0 0 calc(100% / 5); /* 5 items visible */
    box-sizing: border-box;
    text-align: center;
    padding: 20px;
  }
  
  .category-block-one img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .prev-btn, .next-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    color: white;
    font-size: 24px;
    padding: 10px;
    cursor: pointer;
    z-index: 2;
  }
  
  .prev-btn {
    left: 10px;
  }
  
  .next-btn {
    right: 10px;
  }
  
  .carousel-dots {
    text-align: center;
    margin-top: 15px;
  }
  
  .dot {
    display: inline-block;
    height: 12px;
    width: 12px;
    margin: 0 5px;
    background-color: #ccc;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .dot.active {
    background-color: #ff6600;
  }
  