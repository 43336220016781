:root {
  --font-rubik: "Rubik", sans-serif;
  --font-jost: "Jost", sans-serif;
  --font-satisfy: "Satisfy", sans-serif;
  --font-sansita: "Sansita", sans-serif;
  --white-color: #fff;
  --black-color: #000;
  --title-color: #100C08;
  --text-color: #787878;
  --primary-color1: #63AB45;
  --primary-color1-opc: 99, 171, 69;
  --primary-color2: #FBB03B;
  --primary-color2-opc: 251, 176, 59;
}

/*================================================
2. Mixins Css
=================================================*/
/*================================================
3. Global Css
=================================================*/
html {
  font-size: 100%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-rubik);
  color: var(--title-color);
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-rubik);
  font-weight: 600;
  line-height: 1.4;
  color: var(--title-color);
}

input {
  border: none;
  outline: none;
}

button {
  outline: none;
  border: none;
}

i.bx {
  vertical-align: middle;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
}

p {
  font-family: var(--font-jost);
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sec-mar {
  margin: 100px 0;
}
@media (max-width: 991px) {
  .sec-mar {
    margin: 8ch 0;
  }
}

.pt-120 {
  padding-top: 120px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .pt-120 {
    padding-top: 100px;
  }
}
@media (max-width: 991px) {
  .pt-120 {
    padding-top: 90px;
  }
}

.pb-120 {
  padding-bottom: 120px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .pb-120 {
    padding-bottom: 100px;
  }
}
@media (max-width: 991px) {
  .pb-120 {
    padding-bottom: 90px;
  }
}

.pt-100 {
  padding-top: 110px;
}
@media (max-width: 991px) {
  .pt-100 {
    padding-top: 80px;
  }
}

.pb-100 {
  padding-bottom: 110px;
}
@media (max-width: 991px) {
  .pb-100 {
    padding-bottom: 80px;
  }
}

.pt-90 {
  padding-top: 90px;
}
@media (max-width: 991px) {
  .pt-90 {
    padding-top: 80px;
  }
}
@media (max-width: 767px) {
  .pt-90 {
    padding-top: 70px;
  }
}

.pb-90 {
  padding-bottom: 90px;
}
@media (max-width: 991px) {
  .pb-90 {
    padding-bottom: 80px;
  }
}
@media (max-width: 767px) {
  .pb-90 {
    padding-bottom: 70px;
  }
}

.pb-80 {
  padding-bottom: 80px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .pb-80 {
    padding-bottom: 60px;
  }
}

.pb-65 {
  padding-bottom: 65px;
}

.mt-120 {
  margin-top: 120px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .mt-120 {
    margin-top: 100px;
  }
}
@media (max-width: 991px) {
  .mt-120 {
    margin-top: 90px;
  }
}

.mb-120 {
  margin-bottom: 120px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .mb-120 {
    margin-bottom: 100px;
  }
}
@media (max-width: 991px) {
  .mb-120 {
    margin-bottom: 90px;
  }
}

.mb-130 {
  margin-bottom: 130px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .mb-130 {
    margin-bottom: 100px;
  }
}
@media (max-width: 991px) {
  .mb-130 {
    margin-bottom: 90px;
  }
}

.mb-100 {
  margin-bottom: 110px;
}
@media (max-width: 991px) {
  .mb-100 {
    margin-bottom: 80px;
  }
}

.mt-100 {
  margin-top: 110px !important;
}
@media (max-width: 991px) {
  .mt-100 {
    margin-top: 80px !important;
  }
}

.mb-90 {
  margin-bottom: 90px;
}
@media (max-width: 991px) {
  .mb-90 {
    margin-bottom: 70px;
  }
}
@media (max-width: 767px) {
  .mb-90 {
    margin-bottom: 50px;
  }
}

.mb-80 {
  margin-bottom: 80px;
}
@media (max-width: 991px) {
  .mb-80 {
    margin-bottom: 70px;
  }
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-10 {
  margin-bottom: 10px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-30 {
  padding-top: 30px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pe-80 {
  padding-right: 80px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pl-110 {
  padding-left: 110px;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .pl-110 {
    padding-left: 70px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .pl-110 {
    padding-left: 40px;
  }
}
@media (max-width: 1199px) {
  .pl-110 {
    padding-left: unset;
  }
}

.mb-60 {
  margin-bottom: 60px;
}
@media (max-width: 1199px) {
  .mb-60 {
    margin-bottom: 50px;
  }
}
@media (max-width: 767px) {
  .mb-60 {
    margin-bottom: 40px;
  }
}

.mb-70 {
  margin-bottom: 70px;
}
@media (max-width: 767px) {
  .mb-70 {
    margin-bottom: 40px;
  }
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-35 {
  margin-top: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}
@media (max-width: 1199px) {
  .mb-40 {
    margin-bottom: 30px;
  }
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-25 {
  margin-top: 25px;
}

.mb-50 {
  margin-bottom: 50px;
}
@media (max-width: 767px) {
  .mb-50 {
    margin-bottom: 40px;
  }
}

.mt-60 {
  margin-top: 60px;
}
@media (max-width: 767px) {
  .mt-60 {
    margin-top: 40px;
  }
}

.mt-70 {
  margin-top: 70px;
}
@media (max-width: 991px) {
  .mt-70 {
    margin-top: 40px;
  }
}

.dashboard-wrapper {
  background-color: #FAF8FB;
}
.dashboard-wrapper .dashboard-sidebar-wrapper {
  transition: 0.55s ease;
  background: #fff;
  padding: 50px 25px;
  max-width: 305px;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 80px;
  left: 0;
  z-index: 99;
  overflow-y: auto;
}
.dashboard-wrapper .dashboard-sidebar-wrapper.slide {
  left: -100%;
}
@media (max-width: 1399px) {
  .dashboard-wrapper .dashboard-sidebar-wrapper {
    top: 70px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .dashboard-wrapper .dashboard-sidebar-wrapper {
    padding: 50px 15px;
  }
}
@media (max-width: 1199px) {
  .dashboard-wrapper .dashboard-sidebar-wrapper {
    padding: 50px 15px;
  }
}
@media (max-width: 991px) {
  .dashboard-wrapper .dashboard-sidebar-wrapper {
    left: -100%;
  }
  .dashboard-wrapper .dashboard-sidebar-wrapper.slide {
    left: 0%;
  }
}
@media (max-width: 576px) {
  .dashboard-wrapper .dashboard-sidebar-wrapper {
    top: 64px;
  }
}
.dashboard-wrapper .dashboard-sidebar-wrapper .dashboard-sidebar-logo {
  padding-bottom: 100px;
}
.dashboard-wrapper .dashboard-sidebar-wrapper .dashboard-sidebar-menu {
  padding-bottom: 100px;
}
.dashboard-wrapper .dashboard-sidebar-wrapper .dashboard-sidebar-menu > ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.dashboard-wrapper .dashboard-sidebar-wrapper .dashboard-sidebar-menu > ul > li {
  position: relative;
}
.dashboard-wrapper .dashboard-sidebar-wrapper .dashboard-sidebar-menu > ul > li > a {
  color: var(--title-color);
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 14px 25px;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .dashboard-wrapper .dashboard-sidebar-wrapper .dashboard-sidebar-menu > ul > li > a {
    padding: 15px 25px;
  }
}
@media (max-width: 1399px) {
  .dashboard-wrapper .dashboard-sidebar-wrapper .dashboard-sidebar-menu > ul > li > a {
    padding: 15px 25px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .dashboard-wrapper .dashboard-sidebar-wrapper .dashboard-sidebar-menu > ul > li > a {
    font-size: 14px;
  }
}
.dashboard-wrapper .dashboard-sidebar-wrapper .dashboard-sidebar-menu > ul > li > a svg {
  fill: var(--title-color);
}
@media (max-width: 767px) {
  .dashboard-wrapper .dashboard-sidebar-wrapper .dashboard-sidebar-menu > ul > li > a svg {
    width: 25px;
    height: 25px;
  }
}
.dashboard-wrapper .dashboard-sidebar-wrapper .dashboard-sidebar-menu > ul > li > a h6 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0;
}
.dashboard-wrapper .dashboard-sidebar-wrapper .dashboard-sidebar-menu > ul > li .dropdown-icon {
  font-size: 20px;
  color: var(--title-color);
  position: absolute;
  right: 20px;
  top: 10px;
  cursor: pointer;
}
.dashboard-wrapper .dashboard-sidebar-wrapper .dashboard-sidebar-menu > ul > li .dropdown-icon.active::before {
  content: "\f2ea";
}
.dashboard-wrapper .dashboard-sidebar-wrapper .dashboard-sidebar-menu > ul > li .sub-menu {
  position: static;
  min-width: 200px;
  background: 0 0;
  border: none;
  opacity: 1;
  visibility: visible;
  box-shadow: none;
  transform: none;
  transition: none;
  display: none;
  left: 0;
  right: 0;
  top: auto;
  margin: 0;
  text-align: left;
  transform-origin: top;
  float: none;
  padding-left: 53px;
}
.dashboard-wrapper .dashboard-sidebar-wrapper .dashboard-sidebar-menu > ul > li .sub-menu li a {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
  transition: 0.35s;
}
.dashboard-wrapper .dashboard-sidebar-wrapper .dashboard-sidebar-menu > ul > li .sub-menu li a:hover {
  color: var(--primary-color1);
}
.dashboard-wrapper .dashboard-sidebar-wrapper .dashboard-sidebar-menu > ul > li .sub-menu li.active > a {
  color: var(--primary-color1);
}
.dashboard-wrapper .dashboard-sidebar-wrapper .dashboard-sidebar-menu > ul > li.active > a {
  background-color: rgba(var(--primary-color1-opc), 0.1);
  border-radius: 5px;
}
.dashboard-wrapper .dashboard-sidebar-wrapper .dashboard-sidebar-menu .logout {
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--title-color);
  white-space: nowrap;
  padding: 20px 25px;
}
@media (max-width: 1199px) {
  .dashboard-wrapper .dashboard-sidebar-wrapper .dashboard-sidebar-menu .logout {
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .dashboard-wrapper .dashboard-sidebar-wrapper .dashboard-sidebar-menu .logout {
    justify-content: center;
  }
}
@media (max-width: 576px) {
  .dashboard-wrapper .dashboard-sidebar-wrapper .dashboard-sidebar-menu .logout {
    padding: 15px 0;
  }
}
.dashboard-wrapper .dashboard-sidebar-wrapper .dashboard-sidebar-menu .logout svg {
  fill: var(--title-color);
}
@media (max-width: 767px) {
  .dashboard-wrapper .dashboard-sidebar-wrapper .dashboard-sidebar-menu .logout svg {
    width: 25px;
    height: 25px;
  }
}
.dashboard-wrapper .dashboard-sidebar-wrapper .dashboard-sidebar-menu .logout h6 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
}
@media (max-width: 767px) {
  .dashboard-wrapper .dashboard-sidebar-wrapper .dashboard-sidebar-menu .logout h6 {
    display: none;
  }
}
.dashboard-wrapper .main-content {
  transition: 0.55s ease;
  padding: 60px 40px 160px;
  margin-left: 305px;
  max-width: calc(100% - 305px);
  width: 100%;
}
.dashboard-wrapper .main-content.slide {
  margin-left: 0;
  max-width: calc(100% - 0px);
}
@media (max-width: 1699px) {
  .dashboard-wrapper .main-content {
    padding: 60px 30px 160px;
  }
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .dashboard-wrapper .main-content {
    padding: 60px 20px 160px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .dashboard-wrapper .main-content {
    padding: 60px 15px 160px;
  }
}
@media (max-width: 1199px) {
  .dashboard-wrapper .main-content {
    padding: 60px 20px 160px;
  }
}
@media (max-width: 991px) {
  .dashboard-wrapper .main-content {
    max-width: 100%;
    margin-left: 0;
  }
  .dashboard-wrapper .main-content.slide {
    margin-left: 0;
    max-width: 100%;
  }
}
.dashboard-wrapper .main-content .form-inner {
  position: relative;
  line-height: 1;
}
.dashboard-wrapper .main-content .form-inner label {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 14px;
  font-weight: 500;
  display: block;
  margin-bottom: 10px;
}
.dashboard-wrapper .main-content .form-inner label.containerss {
  display: flex;
  width: 100%;
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.dashboard-wrapper .main-content .form-inner label.containerss span {
  color: var(--title-color);
  font-family: var(--font-open-sans);
  font-size: 15px;
  font-weight: 400;
  line-height: 1.4;
}
@media (max-width: 576px) {
  .dashboard-wrapper .main-content .form-inner label.containerss span {
    font-size: 14px;
  }
}
.dashboard-wrapper .main-content .form-inner label.containerss input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.dashboard-wrapper .main-content .form-inner label.containerss input:checked ~ .checkmark {
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
  border-radius: 2px;
}
.dashboard-wrapper .main-content .form-inner label.containerss input:checked ~ .checkmark::after {
  content: url(../img/innerpage/icon/checkbox-check.svg);
  left: 1.5px;
  top: -5px;
  position: absolute;
}
.dashboard-wrapper .main-content .form-inner label.containerss .checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: var(--primary-color1);
  border: 1px solid var(--primary-color1);
  border-radius: 2px;
}
.dashboard-wrapper .main-content .form-inner label.containerss:hover input ~ .checkmark {
  border-color: var(--primary-color1);
}
.dashboard-wrapper .main-content .form-inner input {
  border-radius: 5px;
  background: var(--white-color);
  color: var(--text-color);
  font-family: var(--font-jost);
  border: 1px solid #EEE;
  font-size: 13px;
  font-weight: 400;
  height: 50px;
  width: 100%;
  padding: 10px 20px;
}
.dashboard-wrapper .main-content .form-inner input::-moz-placeholder {
  color: var(--text-color);
}
.dashboard-wrapper .main-content .form-inner input::placeholder {
  color: var(--text-color);
}
.dashboard-wrapper .main-content .form-inner input:focus {
  border: 1px solid var(--primary-color1);
}
.dashboard-wrapper .main-content .form-inner textarea {
  border-radius: 5px;
  background: var(--white-color);
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 13px;
  width: 100%;
  padding: 20px 20px;
  outline: none;
  border: none;
  min-height: 150px;
  border: 1px solid #EEE;
}
.dashboard-wrapper .main-content .form-inner textarea::-moz-placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.dashboard-wrapper .main-content .form-inner textarea::placeholder {
  color: rgba(19, 20, 26, 0.5);
}
.dashboard-wrapper .main-content .form-inner textarea:focus {
  border: 1px solid var(--primary-color1);
}
.dashboard-wrapper .main-content .form-inner > i {
  position: absolute;
  right: 20px;
  bottom: 15px;
  color: var(--primary-color1);
  cursor: pointer;
}
.dashboard-wrapper .main-content .form-inner .primary-btn3 {
  padding: 17px 25px;
  justify-content: center;
}
.dashboard-wrapper .main-content .form-inner .nice-select {
  background-color: var(--white-color);
}
.dashboard-wrapper .main-content .location-map iframe {
  width: 100%;
  min-height: 200px;
}
.dashboard-wrapper .main-content .eg-profile-card {
  border-radius: 5px;
  position: relative;
  min-width: 0;
  width: 100%;
  background-color: #fff;
  background-clip: border-box;
  padding: 15px;
  margin-bottom: 20px;
}
.dashboard-wrapper .main-content .eg-profile-card .profile-img {
  margin-bottom: 15px;
}
.dashboard-wrapper .main-content .eg-profile-card .profile-img img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.dashboard-wrapper .main-content .eg-profile-card .profile-img .eg-btn {
  position: absolute;
  right: 5px;
  top: 5px;
  background: rgba(16, 185, 129, 0.15);
  color: #10B981;
  font-size: 13px;
  font-weight: 600;
  padding: 5px 12px;
  border-radius: 5px;
  line-height: 1;
  transition: all 0.4s ease-in-out;
}
.dashboard-wrapper .main-content .eg-profile-card .profile-bio h4 {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 5px;
  font-family: var(--font-rubik);
}
@media (max-width: 1399px) {
  .dashboard-wrapper .main-content .eg-profile-card .profile-bio h4 {
    font-size: 18px;
  }
}
.dashboard-wrapper .main-content .eg-profile-card .profile-bio h6 {
  color: var(--text-color);
  font-size: 15px;
  font-weight: 400;
  font-family: var(--font-jost);
  margin-bottom: 18px;
}
.dashboard-wrapper .main-content .eg-profile-card .card-action {
  border-top: 1px solid #F1F2F7;
  margin: 0 -15px;
  padding: 15px 15px 0px 15px;
}
.dashboard-wrapper .main-content .eg-profile-card .card-action .eg-btn {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  border: 1px solid transparent;
  transition: all 0.45s ease;
  text-transform: capitalize;
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.dashboard-wrapper .main-content .eg-profile-card .card-action .eg-btn.add--btn {
  background: #6C2EB9;
  color: #fff;
  z-index: 1;
  position: relative;
}
.dashboard-wrapper .main-content .eg-profile-card .card-action .eg-btn.delete--btn {
  background: #DD344A;
  color: #fff;
  z-index: 1;
  position: relative;
}
.dashboard-wrapper .main-content .eg-profile-card .card-action .eg-btn.delete--btn i {
  font-size: 1rem;
}
.dashboard-wrapper .main-content .eg-profile-card .card-action .eg-btn.account--btn {
  background: #10B981;
  color: #fff;
  z-index: 1;
  position: relative;
}
.dashboard-wrapper .main-content .eg-profile-card .card-action .form-switch {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard-wrapper .main-content .eg-profile-card .card-action .form-switch .form-check-input {
  cursor: pointer;
}
.dashboard-wrapper .main-content .eg-profile-card .card-action .form-switch .form-check-input:focus {
  box-shadow: none;
}
.dashboard-wrapper .main-content .eg-profile-card .card-action .form-switch .form-check-input:checked {
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
  box-shadow: none;
}
.dashboard-wrapper .main-content .pagination-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}
@media (max-width: 576px) {
  .dashboard-wrapper .main-content .pagination-area {
    flex-wrap: wrap;
  }
}
.dashboard-wrapper .main-content .pagination-area .paginations {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 15px;
}
.dashboard-wrapper .main-content .pagination-area .paginations .page-item a {
  color: var(--title-color);
  font-family: var(--font-jost);
  font-size: 13px;
  font-weight: 600;
  line-height: 1;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid rgba(22, 25, 30, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.dashboard-wrapper .main-content .pagination-area .paginations .page-item.active a {
  background-color: var(--primary-color1);
}
.dashboard-wrapper .main-content .pagination-area .paginations-buttons {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 40px;
}
.dashboard-wrapper .main-content .pagination-area .paginations-buttons li a {
  color: var(--text-color);
  font-family: var(--font-rubik);
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  transition: 0.5s;
}
.dashboard-wrapper .main-content .pagination-area .paginations-buttons li a svg {
  fill: var(--text-color);
  transition: 0.5s;
}
.dashboard-wrapper .main-content .pagination-area .paginations-buttons li a:hover {
  color: var(--primary-color1);
}
.dashboard-wrapper .main-content .pagination-area .paginations-buttons li a:hover svg {
  fill: var(--primary-color1);
}
.dashboard-wrapper .main-content .pagination-area .paginations-buttons li:last-child {
  position: relative;
}
.dashboard-wrapper .main-content .pagination-area .paginations-buttons li:last-child::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -20px;
  width: 1px;
  height: 14px;
  background-color: rgba(22, 25, 30, 0.4);
}
.dashboard-wrapper .main-content .page-title {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
}
.dashboard-wrapper .main-content .page-title h4 {
  margin-bottom: 0;
}
.dashboard-wrapper .main-content .page-title .nav-pills {
  border-bottom: none;
}
.dashboard-wrapper .main-content .page-title .nav-pills .nav-item .nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  border-radius: 5px;
  border-right: none;
  border: none;
}
.dashboard-wrapper .main-content .page-title .nav-pills .nav-item .nav-link svg {
  fill: var(--title-color);
}
.dashboard-wrapper .main-content .page-title .nav-pills .nav-item .nav-link.active {
  color: var(--primary-color1);
  background-color: transparent;
}
.dashboard-wrapper .main-content .page-title .nav-pills .nav-item .nav-link.active svg {
  fill: var(--primary-color1);
}
.dashboard-wrapper .main-content .main-content-title-profile {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  gap: 15px;
}
@media (max-width: 767px) {
  .dashboard-wrapper .main-content .main-content-title-profile {
    flex-wrap: wrap;
  }
}
@media (max-width: 576px) {
  .dashboard-wrapper .main-content .main-content-title-profile {
    flex-direction: column;
  }
}
.dashboard-wrapper .main-content .main-content-title-profile .main-content-title {
  display: flex;
  align-items: center;
  gap: 10px;
}
.dashboard-wrapper .main-content .main-content-title-profile .main-content-title h3 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 30px;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 0;
}
@media (max-width: 576px) {
  .dashboard-wrapper .main-content .main-content-title-profile .main-content-title h3 {
    font-size: 24px;
  }
}
@media (max-width: 576px) {
  .dashboard-wrapper .main-content .main-content-title-profile .main-content-title img {
    display: none;
  }
}
.dashboard-wrapper .main-content .main-content-title-profile .search-area .search-box {
  display: flex;
  align-items: center;
  max-width: 320px;
  width: 100%;
}
.dashboard-wrapper .main-content .main-content-title-profile .search-area .search-box input {
  width: 100%;
  padding: 8px 20px;
  font-family: var(--font-jost);
  font-size: 14px;
  height: 50px;
  border: 1px solid rgba(var(--primary-color1-opc), 0.3);
}
.dashboard-wrapper .main-content .main-content-title-profile .search-area .search-box button {
  background-color: var(--primary-color1);
  min-width: 60px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.dashboard-wrapper .main-content .main-content-title-profile .search-area .search-box button i {
  color: var(--white-color);
  font-size: 22px;
}
.dashboard-wrapper .main-content .main-content-title-profile .profile a {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 13px;
  font-weight: 600;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 6px;
  transition: 0.5s;
}
.dashboard-wrapper .main-content .main-content-title-profile .profile a svg {
  fill: var(--title-color);
  transition: 0.5s;
}
.dashboard-wrapper .main-content .main-content-title-profile .profile a:hover {
  color: var(--primary-color1);
}
.dashboard-wrapper .main-content .main-content-title-profile .profile a:hover svg {
  transform: rotate(45deg);
  fill: var(--primary-color1);
}
.dashboard-wrapper .main-content .main-content-title-profile .author-area {
  display: flex;
  align-items: center;
  gap: 15px;
}
.dashboard-wrapper .main-content .main-content-title-profile .author-area .author-img img {
  height: 85px;
  width: 90px;
  border-radius: 5px;
}
.dashboard-wrapper .main-content .main-content-title-profile .author-area .author-content span {
  font-family: var(--font-jost);
  font-weight: 600;
  font-size: 1.125rem;
  color: var(--text-color1);
  display: inline-block;
  margin-bottom: 5px;
  line-height: 1;
  position: relative;
}
.dashboard-wrapper .main-content .main-content-title-profile .author-area .author-content span::after {
  content: "";
  height: 1px;
  width: 50px;
  background-color: var(--primary-color1);
  position: absolute;
  right: -60px;
  top: 50%;
  transform: translateY(-50%);
}
.dashboard-wrapper .main-content .main-content-title-profile .author-area .author-content h4 {
  margin-bottom: 0;
  font-family: var(--font-rubik);
  font-weight: 700;
  font-size: 1.625rem;
  letter-spacing: 0.06em;
  color: var(--title-color);
}
.dashboard-wrapper .main-content .counter-area {
  margin-bottom: 40px;
}
.dashboard-wrapper .main-content .counter-area .counter-single {
  background-color: #03C170;
  border-radius: 10px;
  padding: 25px 25px;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 25px;
  position: relative;
}
.dashboard-wrapper .main-content .counter-area .counter-single .counter-icon svg {
  fill: var(--white-color);
  margin-top: -2px;
}
.dashboard-wrapper .main-content .counter-area .counter-single .counter-content p {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
  margin-bottom: 3px;
}
.dashboard-wrapper .main-content .counter-area .counter-single .counter-content .number {
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 30px;
  font-weight: 600;
  line-height: 1;
  display: flex;
  align-items: center;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .dashboard-wrapper .main-content .counter-area .counter-single .counter-content .number {
    font-size: 25px;
  }
}
.dashboard-wrapper .main-content .counter-area .counter-single .counter-content .number h3 {
  margin-bottom: 0;
  color: var(--white-color);
  font-family: var(--font-rubik);
  font-size: 30px;
  font-weight: 600;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .dashboard-wrapper .main-content .counter-area .counter-single .counter-content .number h3 {
    font-size: 25px;
  }
}
.dashboard-wrapper .main-content .counter-area .counter-single .counter-area-vector {
  position: absolute;
  top: -70px;
  left: 45px;
}
@media (max-width: 1199px) {
  .dashboard-wrapper .main-content .counter-area .counter-single .counter-area-vector {
    display: none;
  }
}
.dashboard-wrapper .main-content .counter-area .counter-single.two {
  background: #3093EF;
}
.dashboard-wrapper .main-content .counter-area .counter-single.three {
  background: #F27C3A;
}
.dashboard-wrapper .main-content .counter-area .counter-single.four {
  background: #118CB2;
}
.dashboard-wrapper .main-content .counter-area .counter-single.five {
  background: #b9ac00;
}
.dashboard-wrapper .main-content .recent-listing-area {
  border-radius: 10px;
  background: #FFF;
  padding: 50px;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .dashboard-wrapper .main-content .recent-listing-area {
    padding: 50px 30px;
  }
}
@media (max-width: 1399px) {
  .dashboard-wrapper .main-content .recent-listing-area {
    padding: 50px 30px;
  }
}
@media (max-width: 1199px) {
  .dashboard-wrapper .main-content .recent-listing-area {
    padding: 50px 25px;
  }
}
.dashboard-wrapper .main-content .recent-listing-area > h6 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 24px;
  font-weight: 500;
  line-height: 1.4;
  margin-bottom: 45px;
}
@media (max-width: 1399px) {
  .dashboard-wrapper .main-content .recent-listing-area > h6 {
    margin-bottom: 30px;
  }
}
.dashboard-wrapper .main-content .recent-listing-area .title-and-tab {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 45px;
  gap: 12px;
}
@media (max-width: 1399px) {
  .dashboard-wrapper .main-content .recent-listing-area .title-and-tab {
    margin-bottom: 30px;
  }
}
.dashboard-wrapper .main-content .recent-listing-area .title-and-tab > h6 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 24px;
  font-weight: 500;
  line-height: 1.4;
  margin-bottom: 0;
}
.dashboard-wrapper .main-content .recent-listing-area .title-and-tab .nav-tabs {
  border-bottom: none;
  gap: 15px;
}
.dashboard-wrapper .main-content .recent-listing-area .title-and-tab .nav-tabs .nav-item .nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  border-radius: 0;
  border-right: none;
  border: none;
  padding: 0;
  border: 1px solid var(--title-color);
  border-radius: 50px;
  padding: 5px 15px;
}
.dashboard-wrapper .main-content .recent-listing-area .title-and-tab .nav-tabs .nav-item .nav-link svg {
  fill: var(--title-color);
}
.dashboard-wrapper .main-content .recent-listing-area .title-and-tab .nav-tabs .nav-item .nav-link.active {
  color: var(--primary-color1);
  background-color: transparent;
}
.dashboard-wrapper .main-content .recent-listing-area .title-and-tab .nav-tabs .nav-item .nav-link.active svg {
  fill: var(--primary-color1);
}
.dashboard-wrapper .main-content .recent-listing-area .title-and-tab .nav-tabs .nav-item:nth-child(1) .nav-link {
  border-color: #03C170;
  color: #03C170;
}
.dashboard-wrapper .main-content .recent-listing-area .title-and-tab .nav-tabs .nav-item:nth-child(1) .nav-link.active {
  background-color: #03C170;
  color: #fff;
}
.dashboard-wrapper .main-content .recent-listing-area .title-and-tab .nav-tabs .nav-item:nth-child(2) .nav-link {
  border-color: #3093EF;
  color: #3093EF;
}
.dashboard-wrapper .main-content .recent-listing-area .title-and-tab .nav-tabs .nav-item:nth-child(2) .nav-link.active {
  background-color: #3093EF;
  color: #fff;
}
.dashboard-wrapper .main-content .recent-listing-area .title-and-tab .nav-tabs .nav-item:nth-child(3) .nav-link {
  border-color: #F27C3A;
  color: #F27C3A;
}
.dashboard-wrapper .main-content .recent-listing-area .title-and-tab .nav-tabs .nav-item:nth-child(3) .nav-link.active {
  background-color: #F27C3A;
  color: #fff;
}
.dashboard-wrapper .main-content .recent-listing-area .title-and-tab .nav-tabs .nav-item:nth-child(4) .nav-link {
  border-color: #118CB2;
  color: #118CB2;
}
.dashboard-wrapper .main-content .recent-listing-area .title-and-tab .nav-tabs .nav-item:nth-child(4) .nav-link.active {
  background-color: #118CB2;
  color: #fff;
}
.dashboard-wrapper .main-content .recent-listing-area .title-and-tab .search-area .search-box {
  display: flex;
  align-items: center;
  max-width: 320px;
  width: 100%;
}
.dashboard-wrapper .main-content .recent-listing-area .title-and-tab .search-area .search-box input {
  width: 100%;
  padding: 8px 20px;
  font-family: var(--font-jost);
  font-size: 14px;
  height: 50px;
  border: 1px solid rgba(var(--primary-color1-opc), 0.3);
}
.dashboard-wrapper .main-content .recent-listing-area .title-and-tab .search-area .search-box button {
  background-color: var(--primary-color1);
  min-width: 60px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.dashboard-wrapper .main-content .recent-listing-area .title-and-tab .search-area .search-box button i {
  color: var(--white-color);
  font-size: 22px;
}
.dashboard-wrapper .main-content .recent-listing-area .recent-listing-table .eg-table2 {
  width: 100%;
  margin-bottom: 30px;
}
.dashboard-wrapper .main-content .recent-listing-area .recent-listing-table .eg-table2 thead tr {
  border-bottom: 1px solid #eee;
}
.dashboard-wrapper .main-content .recent-listing-area .recent-listing-table .eg-table2 thead tr th {
  padding: 0 15px 20px;
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 15px;
  font-weight: 500;
  line-height: 1.4;
  text-transform: capitalize;
  text-align: start;
}
.dashboard-wrapper .main-content .recent-listing-area .recent-listing-table .eg-table2 thead tr th:first-child {
  padding-left: 0;
  text-align: start;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .dashboard-wrapper .main-content .recent-listing-area .recent-listing-table .eg-table2 thead tr th {
    padding: 0 10px 20px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .dashboard-wrapper .main-content .recent-listing-area .recent-listing-table .eg-table2 thead tr th {
    padding: 0 10px 20px;
  }
}
@media (max-width: 1199px) {
  .dashboard-wrapper .main-content .recent-listing-area .recent-listing-table .eg-table2 thead tr th {
    display: none;
  }
}
.dashboard-wrapper .main-content .recent-listing-area .recent-listing-table .eg-table2 tbody tr {
  border-bottom: 1px solid #E9E7E7;
}
.dashboard-wrapper .main-content .recent-listing-area .recent-listing-table .eg-table2 tbody tr td {
  padding: 35px 15px;
  margin-bottom: 0;
  text-align: start;
  line-height: 1.2;
  font-size: 15px;
  font-weight: 400;
  font-family: var(--font-jost);
  color: var(--title-color);
}
.dashboard-wrapper .main-content .recent-listing-area .recent-listing-table .eg-table2 tbody tr td .product-name {
  display: flex;
  align-items: center;
  gap: 15px;
}
@media (max-width: 767px) {
  .dashboard-wrapper .main-content .recent-listing-area .recent-listing-table .eg-table2 tbody tr td .product-name {
    flex-wrap: wrap;
    padding-top: 30px;
  }
}
.dashboard-wrapper .main-content .recent-listing-area .recent-listing-table .eg-table2 tbody tr td .product-name .img img {
  height: 80px;
  width: 80px;
  border-radius: 5px;
}
.dashboard-wrapper .main-content .recent-listing-area .recent-listing-table .eg-table2 tbody tr td .product-name .product-content h6 {
  margin-bottom: 10px;
}
.dashboard-wrapper .main-content .recent-listing-area .recent-listing-table .eg-table2 tbody tr td .product-name .product-content h6 a {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 16px;
  font-weight: 500;
  transition: 0.35s;
}
.dashboard-wrapper .main-content .recent-listing-area .recent-listing-table .eg-table2 tbody tr td .product-name .product-content h6 a:hover {
  color: var(--primary-color1);
}
.dashboard-wrapper .main-content .recent-listing-area .recent-listing-table .eg-table2 tbody tr td .product-name .product-content p {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  gap: 7px;
}
.dashboard-wrapper .main-content .recent-listing-area .recent-listing-table .eg-table2 tbody tr td .product-name .product-content p svg {
  fill: var(--primary-color1);
}
.dashboard-wrapper .main-content .recent-listing-area .recent-listing-table .eg-table2 tbody tr td:first-child {
  padding-left: 0;
  text-align: start;
  width: 35%;
}
@media (max-width: 1199px) {
  .dashboard-wrapper .main-content .recent-listing-area .recent-listing-table .eg-table2 tbody tr td:first-child {
    width: 100%;
  }
  .dashboard-wrapper .main-content .recent-listing-area .recent-listing-table .eg-table2 tbody tr td:first-child .product-name {
    justify-content: end;
  }
}
.dashboard-wrapper .main-content .recent-listing-area .recent-listing-table .eg-table2 tbody tr td .confirmed {
  color: #63AB45;
}
.dashboard-wrapper .main-content .recent-listing-area .recent-listing-table .eg-table2 tbody tr td .pending {
  color: #FBB03B;
}
.dashboard-wrapper .main-content .recent-listing-area .recent-listing-table .eg-table2 tbody tr td .rejected {
  color: #f1416c;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .dashboard-wrapper .main-content .recent-listing-area .recent-listing-table .eg-table2 tbody tr td {
    padding: 30px 10px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .dashboard-wrapper .main-content .recent-listing-area .recent-listing-table .eg-table2 tbody tr td {
    padding: 30px 10px;
  }
}
@media (max-width: 1199px) {
  .dashboard-wrapper .main-content .recent-listing-area .recent-listing-table .eg-table2 tbody tr td {
    display: block;
    width: 100%;
    text-align: right;
    position: relative;
    padding: 15px;
  }
  .dashboard-wrapper .main-content .recent-listing-area .recent-listing-table .eg-table2 tbody tr td::before {
    content: attr(data-label);
    position: absolute;
    left: 15px;
    color: var(--title-color);
    font-family: var(--font-Jost);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    text-transform: capitalize;
  }
}
@media (max-width: 1199px) and (max-width: 576px) {
  .dashboard-wrapper .main-content .recent-listing-area .recent-listing-table .eg-table2 tbody tr td::before {
    font-size: 13px;
    font-weight: 700;
  }
}
@media (max-width: 576px) {
  .dashboard-wrapper .main-content .recent-listing-area .recent-listing-table .eg-table2 tbody tr td {
    padding-left: 40%;
  }
}
@media (max-width: 767px) {
  .dashboard-wrapper .main-content .recent-listing-area .recent-listing-table .eg-table2 tbody tr {
    display: block;
    width: 100%;
  }
}
.dashboard-wrapper .main-content .dashboard-profile-wrapper {
  border-radius: 10px;
  background-color: var(--white-color);
  padding: 50px;
  display: flex;
  gap: 40px;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .dashboard-wrapper .main-content .dashboard-profile-wrapper {
    padding: 50px 30px;
  }
}
@media (max-width: 1399px) {
  .dashboard-wrapper .main-content .dashboard-profile-wrapper {
    padding: 50px 30px;
    gap: 30px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .dashboard-wrapper .main-content .dashboard-profile-wrapper {
    padding: 40px 25px;
    gap: 20px;
  }
}
@media (max-width: 991px) {
  .dashboard-wrapper .main-content .dashboard-profile-wrapper {
    flex-direction: column;
    padding: 40px 25px;
  }
}
@media (max-width: 576px) {
  .dashboard-wrapper .main-content .dashboard-profile-wrapper {
    padding: 30px 15px;
  }
}
.dashboard-wrapper .main-content .dashboard-profile-wrapper.two {
  padding: 0;
  width: 100%;
  background-color: unset;
  display: block;
}
.dashboard-wrapper .main-content .dashboard-profile-wrapper.two .dashboard-profile-tab-content {
  padding: 0;
}
.dashboard-wrapper .main-content .dashboard-profile-wrapper .dashboard-profile-nav {
  border-radius: 5px;
  background: #FAF8FB;
  padding: 30px;
  max-width: 280px;
  min-width: 280px;
  height: 322px;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .dashboard-wrapper .main-content .dashboard-profile-wrapper .dashboard-profile-nav {
    max-width: 260px;
    min-width: 260px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .dashboard-wrapper .main-content .dashboard-profile-wrapper .dashboard-profile-nav {
    max-width: 240px;
    min-width: 240px;
  }
}
@media (max-width: 576px) {
  .dashboard-wrapper .main-content .dashboard-profile-wrapper .dashboard-profile-nav {
    max-width: 230px;
    min-width: 230px;
    padding: 30px 20px;
  }
}
.dashboard-wrapper .main-content .dashboard-profile-wrapper .dashboard-profile-nav .nav-pills {
  width: 100%;
}
.dashboard-wrapper .main-content .dashboard-profile-wrapper .dashboard-profile-nav .nav-pills .nav-item {
  margin-bottom: 20px;
}
.dashboard-wrapper .main-content .dashboard-profile-wrapper .dashboard-profile-nav .nav-pills .nav-item .nav-link {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 0;
  width: 100%;
}
.dashboard-wrapper .main-content .dashboard-profile-wrapper .dashboard-profile-nav .nav-pills .nav-item .nav-link svg {
  fill: var(--title-color);
}
.dashboard-wrapper .main-content .dashboard-profile-wrapper .dashboard-profile-nav .nav-pills .nav-item .nav-link.active {
  color: var(--primary-color1);
  border-radius: 17px;
  background: var(--white-color);
  padding: 10px 15px;
}
.dashboard-wrapper .main-content .dashboard-profile-wrapper .dashboard-profile-tab-content {
  border-radius: 5px;
  background: #FAF8FB;
  padding: 50px 70px;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .dashboard-wrapper .main-content .dashboard-profile-wrapper .dashboard-profile-tab-content {
    padding: 50px;
  }
}
@media (max-width: 1399px) {
  .dashboard-wrapper .main-content .dashboard-profile-wrapper .dashboard-profile-tab-content {
    padding: 50px 40px;
  }
}
@media (max-width: 1199px) {
  .dashboard-wrapper .main-content .dashboard-profile-wrapper .dashboard-profile-tab-content {
    padding: 40px 25px;
  }
}
@media (max-width: 576px) {
  .dashboard-wrapper .main-content .dashboard-profile-wrapper .dashboard-profile-tab-content {
    padding: 40px 15px;
  }
}
.dashboard-wrapper .main-content .dashboard-profile-wrapper .dashboard-profile-tab-content .profile-tab-content-title {
  margin-bottom: 40px;
}
.dashboard-wrapper .main-content .dashboard-profile-wrapper .dashboard-profile-tab-content .profile-tab-content-title h6 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 0;
}
.dashboard-wrapper .main-content .dashboard-profile-wrapper .dashboard-profile-tab-content .upload-img-area {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 30px;
}
@media (max-width: 576px) {
  .dashboard-wrapper .main-content .dashboard-profile-wrapper .dashboard-profile-tab-content .upload-img-area {
    flex-wrap: wrap;
    gap: 10px;
  }
}
.dashboard-wrapper .main-content .dashboard-profile-wrapper .dashboard-profile-tab-content .upload-img-area .upload-img-wrapper {
  width: 81px;
  height: 60px;
  padding: 3px 4px;
  border-radius: 5px;
  background: #FFF;
  position: relative;
}
.dashboard-wrapper .main-content .dashboard-profile-wrapper .dashboard-profile-tab-content .upload-img-area .upload-img-wrapper .drag-area {
  width: 73px;
  height: 54px;
  border-radius: 5px;
  border: 1px dashed var(--title-color);
  display: flex;
  align-items: center;
  justify-content: center;
}
.dashboard-wrapper .main-content .dashboard-profile-wrapper .dashboard-profile-tab-content .upload-img-area .upload-img-wrapper .drag-area img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 5px;
}
.dashboard-wrapper .main-content .dashboard-profile-wrapper .dashboard-profile-tab-content .upload-img-area .upload-img-wrapper .drag-area .upload-btn {
  background-color: transparent;
  color: var(--title-color);
  padding: 0;
  position: absolute;
  height: 100%;
  width: 100%;
}
.dashboard-wrapper .main-content .dashboard-profile-wrapper .dashboard-profile-tab-content .upload-img-area .upload-img-wrapper .drag-area .upload-btn i {
  color: var(--title-color);
  font-size: 20px;
}
.dashboard-wrapper .main-content .dashboard-profile-wrapper .dashboard-profile-tab-content .upload-img-area .upload-img-wrapper .drag-area input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.dashboard-wrapper .main-content .dashboard-profile-wrapper .dashboard-profile-tab-content .upload-img-area .upload-img-area-content h6 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2;
}
@media (max-width: 767px) {
  .dashboard-wrapper .main-content .dashboard-profile-wrapper .dashboard-profile-tab-content .upload-img-area .upload-img-area-content h6 {
    margin-bottom: 5px;
  }
}
.dashboard-wrapper .main-content .dashboard-profile-wrapper .dashboard-profile-tab-content .upload-img-area .upload-img-area-content p {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 15px;
  font-weight: 400;
  line-height: 1.4;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .dashboard-wrapper .main-content .dashboard-profile-wrapper .dashboard-profile-tab-content .upload-img-area .upload-img-area-content p {
    font-size: 14px;
  }
}
.dashboard-wrapper .main-content .dashboard-profile-wrapper .dashboard-profile-tab-content .preference-list {
  padding: 0;
  margin: 0;
  list-style: none;
  width: 100%;
  margin-bottom: 40px;
}
.dashboard-wrapper .main-content .dashboard-profile-wrapper .dashboard-profile-tab-content .preference-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(22, 25, 30, 0.08);
  margin-bottom: 30px;
}
@media (max-width: 576px) {
  .dashboard-wrapper .main-content .dashboard-profile-wrapper .dashboard-profile-tab-content .preference-list li {
    flex-wrap: wrap;
  }
}
.dashboard-wrapper .main-content .dashboard-profile-wrapper .dashboard-profile-tab-content .preference-list li:last-child {
  margin-bottom: 0;
}
.dashboard-wrapper .main-content .dashboard-profile-wrapper .dashboard-profile-tab-content .preference-list li .preference-list-content {
  max-width: 600px;
  width: 100%;
}
.dashboard-wrapper .main-content .dashboard-profile-wrapper .dashboard-profile-tab-content .preference-list li .preference-list-content h6 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 15px;
}
.dashboard-wrapper .main-content .dashboard-profile-wrapper .dashboard-profile-tab-content .preference-list li .preference-list-content p {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 0;
}
.dashboard-wrapper .main-content .dashboard-profile-wrapper .dashboard-profile-tab-content .preference-list li .form-switch .form-check-input {
  width: 40px;
  height: 18px;
  border-width: 3px;
  border-color: #E8E1EC;
  background-color: #E8E1EC;
  background-image: url(../img/innerpage/icon/dashboard-switch-icon.svg);
}
.dashboard-wrapper .main-content .dashboard-profile-wrapper .dashboard-profile-tab-content .preference-list li .form-switch .form-check-input:checked {
  background-color: #24BC61;
  border-color: #24BC61;
  background-image: url(../img/innerpage/icon/dashboard-switch-icon.svg);
}
.dashboard-wrapper .main-content .dashboard-profile-wrapper .dashboard-profile-tab-content .preference-list li .form-switch .form-check-input:focus {
  box-shadow: none;
  background-image: url(../img/innerpage/icon/dashboard-switch-icon.svg);
}
.dashboard-wrapper .main-content .dashboard-profile-wrapper .dashboard-profile-tab-content .change-password-form-btns {
  display: flex;
  align-items: center;
  gap: 30px;
}
@media (max-width: 576px) {
  .dashboard-wrapper .main-content .dashboard-profile-wrapper .dashboard-profile-tab-content .change-password-form-btns {
    flex-wrap: wrap;
    gap: 10px;
  }
}
.dashboard-wrapper .main-content .dashboard-profile-wrapper .dashboard-profile-tab-content .change-password-form-btns .primary-btn3.cancel {
  background-color: var(--primary-color2);
}
.dashboard-wrapper .main-content .dashboard-faqs-wrapper {
  border-radius: 10px;
  background-color: var(--white-color);
  padding: 50px;
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .dashboard-wrapper .main-content .dashboard-faqs-wrapper {
    padding: 50px 30px;
  }
}
@media (max-width: 1399px) {
  .dashboard-wrapper .main-content .dashboard-faqs-wrapper {
    padding: 50px 25px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .dashboard-wrapper .main-content .dashboard-faqs-wrapper {
    padding: 40px 25px;
  }
}
@media (max-width: 991px) {
  .dashboard-wrapper .main-content .dashboard-faqs-wrapper {
    padding: 40px 25px;
  }
}
@media (max-width: 576px) {
  .dashboard-wrapper .main-content .dashboard-faqs-wrapper {
    padding: 30px 15px;
  }
}
.dashboard-wrapper .main-content .dashboard-faqs-wrapper .dashboard-faqs-title h6 {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 0;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .dashboard-wrapper .main-content .dashboard-faqs-wrapper .dashboard-faqs-title h6 {
    font-size: 18px;
  }
}
@media (max-width: 576px) {
  .dashboard-wrapper .main-content .dashboard-faqs-wrapper .dashboard-faqs-title h6 {
    font-size: 18px;
  }
}
.dashboard-wrapper .main-content .dashboard-faqs-wrapper .dashboard-faqs-title p {
  color: var(--text-color);
  font-family: var(--font-jost);
  font-size: 15px;
  font-weight: 400;
  line-height: 1.8;
  margin-bottom: 0;
}
@media (max-width: 576px) {
  .dashboard-wrapper .main-content .dashboard-faqs-wrapper .dashboard-faqs-title p {
    font-size: 14px;
  }
}
.dashboard-wrapper .main-content .dashboard-faqs-wrapper .dashboard-faqs-title.two {
  margin-bottom: 30px;
}
@media (max-width: 576px) {
  .dashboard-wrapper .main-content .dashboard-faqs-wrapper .dashboard-faqs-title.two {
    margin-bottom: 25px;
  }
}
.dashboard-wrapper .main-content .dashboard-faqs-wrapper .dashboard-faqs-title.two h6 {
  margin-bottom: 10px;
}
.dashboard-wrapper .main-content .dashboard-faqs-wrapper .dashboard-faqs-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 40px;
}
@media (max-width: 767px) {
  .dashboard-wrapper .main-content .dashboard-faqs-wrapper .dashboard-faqs-top {
    flex-wrap: wrap;
  }
}
.dashboard-wrapper .main-content .dashboard-faqs-wrapper .dashboard-faqs-top .dashboard-faqs-filter {
  padding: 6px 6px 6px 20px;
  border-radius: 5px;
  border: 1px solid #EEE;
  display: flex;
  align-items: center;
  gap: 20px;
}
.dashboard-wrapper .main-content .dashboard-faqs-wrapper .dashboard-faqs-top .dashboard-faqs-filter > span {
  color: var(--title-color);
  font-family: var(--font-rubik);
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
}
.dashboard-wrapper .main-content .dashboard-faqs-wrapper .dashboard-faqs-top .dashboard-faqs-filter .filter-dropdown .nice-select {
  height: 30px;
  padding-left: 15px;
  padding-right: 35px;
  background-color: var(--primary-color1);
}
.dashboard-wrapper .main-content .dashboard-faqs-wrapper .dashboard-faqs-top .dashboard-faqs-filter .filter-dropdown .nice-select .current {
  color: var(--title-color);
  font-family: var(--font-jost);
  font-size: 13px;
  font-weight: 600;
}
.dashboard-wrapper .main-content .dashboard-faqs-wrapper .dashboard-faqs-top .dashboard-faqs-filter .filter-dropdown .nice-select::after {
  right: 18px;
  width: 7px;
  height: 7px;
  border-color: var(--title-color);
}
.dashboard-wrapper .dashboard-footer {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 555;
  transition: 0.55s ease;
  padding: 20px 60px;
  background-color: var(--white-color);
  margin-left: 305px;
  max-width: calc(100% - 305px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  box-shadow: 5px 3px 40px rgba(0, 72, 88, 0.1);
}
.dashboard-wrapper .dashboard-footer.slide {
  margin-left: 0;
  max-width: calc(100% - 0px);
}
@media (max-width: 1699px) {
  .dashboard-wrapper .dashboard-footer {
    margin-left: 270px;
    max-width: calc(100% - 270px);
  }
}
@media (min-width: 1400px) and (max-width: 1599px) {
  .dashboard-wrapper .dashboard-footer {
    margin-left: 235px;
    max-width: calc(100% - 235px);
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .dashboard-wrapper .dashboard-footer {
    margin-left: 220px;
    max-width: calc(100% - 220px);
  }
}
@media (max-width: 1199px) {
  .dashboard-wrapper .dashboard-footer {
    margin-left: 200px;
    max-width: calc(100% - 200px);
  }
}
@media (max-width: 991px) {
  .dashboard-wrapper .dashboard-footer {
    padding: 20px 40px;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    gap: 10px;
    margin-left: 0px;
    max-width: 100%;
  }
  .dashboard-wrapper .dashboard-footer.slide {
    max-width: 100%;
  }
}
.dashboard-wrapper .dashboard-footer .copyright-area p {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
}
.dashboard-wrapper .dashboard-footer .copyright-area p a {
  color: var(--primary-color1);
}
.dashboard-wrapper .dashboard-footer .footer-menu-list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 10px;
  line-height: 1;
}
@media (max-width: 576px) {
  .dashboard-wrapper .dashboard-footer .footer-menu-list {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.dashboard-wrapper .dashboard-footer .footer-menu-list li {
  margin-right: 20px;
}
@media (max-width: 576px) {
  .dashboard-wrapper .dashboard-footer .footer-menu-list li {
    margin-right: 0;
  }
}
.dashboard-wrapper .dashboard-footer .footer-menu-list li:last-child {
  margin-right: 0;
}
.dashboard-wrapper .dashboard-footer .footer-menu-list li a {
  color: var(--text-color);
  font-family: var(--font-open-sans);
  font-size: 13px;
  font-weight: 600;
}/*# sourceMappingURL=dashboard.css.map */