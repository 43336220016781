.tourtype-card-image {
    width: 262px; 
    height: 245px; 
    object-fit: cover;
}

/* Mobile view */
@media (max-width: 576px) {
    .tourtype-card-image {
        width: 380px;
        height: 380px;
        object-fit: cover;
    }
}