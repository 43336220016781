.photo-grid-continer {
    display: grid;
    justify-content: center;
    align-items: center;
}

.photo-grid {
    display: grid;
    grid-template-areas:
        "item-1 item-2"
        "item-1 item-3";
    gap: 10px; /* Spacing between images */
    justify-content: center;
    align-items: center;
}

.photo-grid-item {
    border-radius: 8px; /* Optional: rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: shadow for aesthetics */
    object-fit: cover;
    transition: transform 0.9s ease-in-out;
}

.item-1 {
    grid-area: item-1;
    width: 500px;
    height: 400px;
}

.item-2 {
    grid-area: item-2;
    width: 200px;
    height: 168px;
}

.item-3 {
    grid-area: item-3;
    width: 200px;
    height: 230px;
}

.photo-grid-item:hover {
    transform: scale(1.05); 
    /* transition: all;
    transition-delay: 1000ms; */
}