.layout-relative {
  position: relative !important;
}

.modalContainer {
  position: absolute !important;
  width: 100vw;
  height: 100%;
  background: #000;
  z-index: 9998;
}

.modalForm {
  position: absolute !important;
  /* left: 50% !important;
    right: 50vw !important; */
  width: fit-content;
  /* top: 50%; */
  top: 30vh;
  /* left: 30%; */
  /* transform: "translate(-50%, -50%)"; */
  z-index: 9999;
}