.offer-card-style-color {
    background-color: #005d9c !important;
}

.offer-card-theme-color {
    background-color: #005d9c !important;
}

.offer-btn-text {
    font-size: 15px !important;
}

.image-style-offer {
    object-fit: cover; /* Ensures the image covers the entire area without distortion */
    width: 100%;
}

@media (max-width: 768px) {
    .image-style-offer {
        max-width: 358px;
        max-height: 326px; 
        min-width: 100%;
        min-height: 100%;
    }
}

@media (min-width: 769px) {
    .image-style-offer {
        min-width: 358px;
        min-height: 326px; /* Large Screen */
        max-width: 100%;
        max-height: 326px;
    }
}
