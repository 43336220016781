.max-height-card {
    /* border: 2px solid blue; */
    padding: 0px;
    height: 430px;
}

.cardp {
    margin-bottom: 25px;
}

.inner-box {
    padding: 0px;
    /* margin: 0px; */
    /* text-align: center; */
    height: 100%;
    /* flex-grow: 1; */
}

.namep {
    max-width: 98%;
}

.package-image {
    object-fit: cover;
    /* Default dimensions lg screen */
    width: 318px;
    height: 223px;
}

.package-card-img-wrap {
    position: relative;
}

.hot_deals_tag {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 40px;
    min-width: 40px;
    background-color: aliceblue;
    border-radius: 2px;
}

.tourTypeLink {
    color: black;
}

/* Mobile view */
@media (max-width: 576px) {
    .package-image {
        width: 372px;
        height: 258px;
    }
}