/* Apply a full-screen background image */
body, html {
    height: 100%;
    margin: 0;
    font-family: Arial, sans-serif;
}

.error-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url('https://source.unsplash.com/1600x900/?nature,water');
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;
}

.error-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
}

.error-content {
    text-align: center;
    background: rgba(255, 255, 255, 0.1);
    padding: 50px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    color: white;
    z-index: 1;
    animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.error-title {
    font-size: 6rem;
    margin-bottom: 20px;
    color: #ff4757;
}

.error-message {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #f1f2f6;
}

.error-code {
    font-size: 1rem;
    margin-bottom: 30px;
    color: #ced6e0;
}

.error-button, .refresh-button {
    padding: 15px 30px;
    font-size: 1.1rem;
    color: white;
    background-color: #ff4757;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    margin: 5px;
    transition: background-color 0.3s ease;
}

.error-button:hover {
    background-color: #aa1c27;
}

.refresh-button {
    background-color: #008aae;
}

.refresh-button:hover {
    background-color: #005e9e;
}



/* .error-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f9fa;
}

.error-content {
    text-align: center;
    background: white;
    padding: 50px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.error-title {
    font-size: 6rem;
    margin-bottom: 20px;
    color: #343a40;
}

.error-message {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #6c757d;
    width: 100vw;
}

.error-code {
    font-size: 1rem;
    margin-bottom: 30px;
    color: #adb5bd;
}

.error-button {
    padding: 10px 20px;
    font-size: 1.1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    text-decoration: none;
}

.error-button:hover {
    background-color: #0056b3;
} */
