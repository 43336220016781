.dwESUy {
  background-color: #fff !important;
  color: inherit !important;
}

a
{
  color: inherit;
  &:hover
  {
    color: inherit;
  }
}

.medical-report-container {
  button {
    font-weight: 900 !important;
  }
  // select {
  //   font-weight: 900 !important;
  // }
  form {
    font-weight: 900 !important;
  }
  @media print
  {
    form
    {
      display: none;
      padding: 0;
      margin: 0;
    }
  }
}

.medical-report {
  font-weight: 900;
  input {
    font-weight: 900;
  }
  select {
    font-weight: 900;
  }
}

.patient-info-table-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 900;
  font-size: 15px;
}

.medical-form-select {
  font-size: 14px;
}

.medical-info-search {
  padding: 0;
}

.medical-info-btn-group {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 20px;
  .custom-font-size {
    font-size: 22px !important;
  }
}

.custom-font-size {
  font-size: 18px !important;
  cursor: pointer;
}

.bOuXQL {
  padding: 0 !important;
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.ipOzdx div:first-child {
  white-space: pre-wrap !important;
  text-overflow: initial !important;
}

.gXszBw {
  background-color: inherit !important;
  color: inherit !important;
}

.d-grid-iocns {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  .physical-icon {
    order: 1 !important;
    cursor: pointer;
  }
  .x-ray-icon {
    order: 3 !important;
    cursor: pointer;
  }
  .blood-icon,
  .urine-icon {
    order: 2 !important;
    cursor: pointer;
  }
  .prescription-icon,
  .health-card-icon {
    order: 4 !important;
    cursor: pointer;
  }
}

.table {
  font-size: 12xp;
  .created-at {
    width: 80px;
  }
  .user-registration-no,
  .user-role {
    width: 115px;
  }
  .action-icon {
    padding: 0;
    border: none;
    line-height: 14px;
    i {
      cursor: pointer;
    }
  }
  .golden-rode {
    color: #ffb700;
    &:hover {
      color: #ffb700;
    }
  }
}

#th-11 {
  width: 220px;
}
